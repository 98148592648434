import { FC, useState } from 'react'

interface GsTypeModalProps {
    close: () => void;
    set_preferance_type: (data) => void;
}
const GsTypeModal: FC<GsTypeModalProps> = ({ close, set_preferance_type }) => {

    const [hover, setHover] = useState<any>('')

    return (
        <div className='popup-model z-2'>
            <div className='model-sm mt-5 gap-4'>
                <div className='w-100 d-flex align-content-center justify-content-between'>
                    <span className='fs-20'>Add Ground Station(s)</span>
                    <i onClick={close} className="fe fe-x-circle me-1 fs-25 cursor-pointer text-white"></i>
                </div>

                <div className="modal_devider" />

                <div className={`d-flex flex-column border border-radius-4 cursor-pointer ${hover === 'Map' ? 'border-secondary' : 'border-primary'} `}
                    onMouseEnter={() => { setHover('Map') }}
                    onMouseLeave={() => { setHover('') }}
                    onClick={() => {
                        set_preferance_type('Map')
                        close()
                    }}>
                    <h5 className='color-atmos'>Select from Map</h5>
                    <span>Choose one or more pre-integrated Ground Stations from an interactive map.</span>
                </div>
                <div className={`d-flex flex-column border cursor-pointer border-radius-4 ${hover === 'Manually' ? 'border-secondary' : 'border-primary'} `}
                    onMouseEnter={() => { setHover('Manually') }}
                    onMouseLeave={() => { setHover('') }}
                    onClick={() => {
                        set_preferance_type('Manual_Create')
                        close()
                    }}>
                    <h5 className='color-atmos'>Direct Input </h5>
                    <span>Manually provide all details required to define a single Ground Station and its capabilities.</span>
                </div>

                <div className="modal_devider" />

                <div className="d-flex w-100 align-items-center justify-content-end py-1">
                    <button className="btn btn-outline-primary px-5" onClick={close}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GsTypeModal
