
import { CircularProgress } from '@mui/material';


const LoaderBlack = () => (
  <div className='loader-page backgroundBlack h-100'>
    <CircularProgress color='inherit'/>
  </div>
);

export default LoaderBlack;