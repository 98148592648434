import { useEffect, useState } from "react"
import CommandCenterService from "../../../service/CommandCenterService";
import useUrlParams from "./useUrlParams";

const useDownloadFile = () => {
    const [downloadFiles, setDownloadFiles] = useState([])
    const { truetwin_id, dashboard } = useUrlParams()

    const get_truetwin_datastore_files = () => {
        CommandCenterService.getTrueTwinDataStoreFiles(truetwin_id)?.then((res) => {
            if (res?.data && res?.data?.length > 0) {
                const sortedArray = res.data.slice().sort((a, b) => b?.time - a?.time);
                setDownloadFiles(sortedArray)
            } else {
                setDownloadFiles([])
            }
        }).catch((err) => {
            console.error(err);
        });
    }
    useEffect(() => {
        if (truetwin_id) {
            const Interval = setInterval(() => {
                get_truetwin_datastore_files()
                if (dashboard === 'summary') {
                    clearInterval(Interval)
                }
            }, 10000)
            return () => clearInterval(Interval);
        }
    }, [truetwin_id])

    return {
        downloadFiles
    }
}

export default useDownloadFile