
interface ToggleMapButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    className?: string;
}

const ToggleMapButton = ({ onClick, children, className }: ToggleMapButtonProps) => {
    return (
        <button className={`minimize__maximize__btn ${className}`} onClick={onClick}>
            {children}
        </button>
    )
}

export default ToggleMapButton