const TaskModal = ({ task, closeModal, open_modal, sidebar_small}) => {
  return (
    <div className={`taskQueueTeleModal ${open_modal && 'active'}`} style={{left: sidebar_small ? '320px' : '740px'}}>
      <div className="taskQueueTeleModalHeader bottomBorder">
        <div className="orderTitleMainDiv">
          <div className="orderTitleDiv">
           <div className="subOrderTitleName grey">Current Schedule&ensp;&gt;&ensp;</div>
            <div className="subOrderTitleName white" title={task?.taskName}>{task?.taskName?.length > 15 ? task?.taskName?.substring(0, 15) + '...' : task?.taskName}</div>
          </div>
          {task?.geoTrigger !== "None" && <div className="orderTitleDiv">
          {task?.type==="Payload Task"?
             <i className={`fa fa-gear  subOrderImg`} ></i>
             :
            <img className="subOrderImg" src={require("../ImgAssets/XBandSmallIcon.svg").default} />
          }
            <div className="subOrderTitleName grey">{task?.geoTrigger}&ensp;|&ensp;</div>
            <div className="subOrderTitleName yellow" title={task?.location}>{task?.location?.length > 10 ? task?.location?.substring(0, 10) + '...' : task?.location}</div>
          </div>}
        </div>
        <div className="taskQueueTeleModalCloseBtn" onClick={closeModal}>
          &times;
        </div>
      </div>

      <div className="taskQueueTeleModalContent modalScrollableSection">
  <div className="d-flex flex-column gap-3">
    <div className="border d-flex align-items-center justify-content-between fs-14 w-100 px-3 py-2">
      <span className="w-35">SUB SYSTEM</span> 
      <span className="w-35">COMMAND</span>
      <span className="w-15 d-flex align-items-center justify-content-center">START TIME</span>
      <span className="w-15  d-flex align-items-center justify-content-center">DURATION</span>
    </div>
    <div className="d-flex flex-column gap-2">
      {task?.["commandsList"]?.map((tele, index) => {
        return (
          <div className="d-flex align-items-center justify-content-between w-100 px-3 py-2" key={index}>
              <div className="d-flex w-35 ">
                <button className="orderProgressDivPendingBtn " style={{padding: "0px"}}>
                  {tele?.['status'] === "complete" && <img className="pauseIcon noTop" src={require("../ImgAssets/GreenTickSmallIcon.svg").default} />}
                  {tele?.['status'] === "waiting" && <img className="pauseIcon noTop " src={require("../ImgAssets/PendingIcon.svg").default} />}
                  {tele?.['status'] === "active" && <img className="pauseIcon noTop rotationIcon" src={require("../ImgAssets/Active.svg").default} />}
                  {tele?.['status'] === "Not Completed" && <img className="pauseIcon noTop" src={require("../ImgAssets/Partiallycompleted.svg").default} />}
                  {tele?.['status'] === "Not Performed" && <img className="pauseIcon noTop" src={require("../ImgAssets/NotPerfomed.svg").default} />}
                </button>
                <div className="orderTitleMainDiv">
                  <div className="orderTitleDiv">
                    <div className="subOrderTitleName grey">{tele?.["subsystem"]}</div>
                  </div>
                </div>
              </div>
              <span className="spikeItemText w-35">{tele?.['name']}</span>
            
              <div className="spikeItemText w-15  d-flex align-items-center justify-content-center">
                {tele?.["epoch"] &&
                  (() => {
                    const date = new Date(tele["epoch"] * 1000);
                    const iso = date.toISOString().split('.')[0].replace('T', ' ');
                    const time = iso.split(' ')[1];
                    const utc = date.toUTCString().split(' ')[4];
                    return (
                        <span>{utc} UTC</span>
                    );
                  })()
                }
              </div>
              <span className="spikeItemText w-15  d-flex align-items-center justify-content-center">{tele?.["duration"]} sec</span>
          
          </div>
        );
      })}
    </div>
    </div>
</div>

    </div>
  );
};

export default TaskModal;
