// @ts-nocheck
import Panel from "./ResizableSidePanel"
import Orbit from '../../../../OrbitVisualization/Orbit';
import { useRef } from "react";
function CesiumView({ simulationStatus, selectedTabBtn, isQuickDeploy, isScenarioAutomated, taskListQueue, isShowPanel, setLoading }) {

    const containerRef = useRef(null);

    return (
        <div className='w-100 h-100 d-flex' >
            {isShowPanel &&
                <div>
                    <Panel taskListQueue={taskListQueue} dashboardType='simulation' />
                </div>
            }
            <div
                ref={containerRef}
                className={selectedTabBtn === 'Overview' ? 'orbit__viewer__container' : 'orbit__viewer__container__full'}>
                <Orbit simulationStatus={simulationStatus}
                    selectedTabBtn={selectedTabBtn}
                    isQuickDeploy={isQuickDeploy}
                    isScenarioAutomated={isScenarioAutomated}
                    setLoading={setLoading}
                    containerRef={containerRef}
                />
            </div>
        </div>
    );
}

export default CesiumView;
