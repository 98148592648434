import { useContext } from "react"
import { ScenarioContext } from "../providers/ScenarioProvider";

const useScenario = () => {
    const context = useContext(ScenarioContext);
    if (context === undefined) {
        throw new Error('useScenario must be used within a ScenarioProvider');
    }
    const { scenario, setScenario, loading } = context;
    return {
        loading,
        scenario,
        setScenario,
    };
}

export default useScenario