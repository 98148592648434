import useViewer from '../hooks/useViewer';
import './PreviewSlider.css'
import React, { useState } from 'react';

const PreviewSlider = () => {

    const { viewer } = useViewer()
    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        setValue(newValue);
        if (viewer !== null) {
            viewer.clock.multiplier = value;
        }
    };

    return (
        <div className="preview__slider__container">
            <input
                type="range"
                min="-50"
                max="50"
                value={value}
                onChange={handleChange}
                className="preview__slider__input"
            // style={{
            //     '--webkit-slider-thumb-width': '80px',
            //     '--webkit-slider-thumb-height': '20px',
            // } as React.CSSProperties}
            />
            <div className="preview__slider__indicators">
                <div className='preview__slider__indicator' />
            </div>
        </div>
    );
};

export default PreviewSlider;
