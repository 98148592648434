import React, {useState} from "react";
import "../ReportComponents.css";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import MissionModelingService from "../../../MissionModelingService";
import MapAnalysisInputs from "../MapAnalysisInputs";

const PowerAnalysisHiFiReport = ({ props }) => {
  const [powerAnalysisItems, setPowerAnalysisItems] = useState({
    Analysis_Inputs: false,
    Output: true,
  });

  const spaceCraftObject = [
    {
      title: "SpaceObject_01",
      planes: [
        { title: "Plane_01", satellites: [{ title: "Mission_Sat_01" }, { title: "Mission_Sat_02" }, { title: "Mission_Sat_03" }] },
        { title: "Plane_02", satellites: [{ title: "Mission_Sat_04" }] },
      ],
      startTime: "2023-10-10T08:12:36Z",
      duration: "10 hours",
    },
    {
      title: "SpaceObject_02",
      planes: [
        { title: "Plane_11", satellites: [{ title: "Mission_Sat_11" }, { title: "Mission_Sat_12" }, { title: "Mission_Sat_13" }] },
        { title: "Plane_12", satellites: [{ title: "Mission_Sat_14" }] },
      ],
      startTime: "2023-8-9T07:19:72Z",
      duration: "20 hours",
    },
  ];

  const [powerOutputItems, setPowerOutputItems] = useState({ Power: true, Power_Profile: true });

  const [powerProfileValues, setPowerProfileValues] = useState([
    { label: "Time to Charge Battery", value: "64 hours " },
    { label: "Imaging Operation ", value: "23" },
    { label: "Ground Contacts", value: "12" },
    { label: "Min", value: "12" },
    { label: "TT&C per orbit", value: "12" },
    { label: "Data Downlink", value: "530 mb" },
    { label: "Contact Time Avg.", value: "3 mins" },
  ]);

  const powerOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  const labels = ["S", "M", "T", "W", "T", "F", "S"];

  const PowerProfileOutputDiv = ({ label, value }) => (
    <>
      <div className="reportDataBoxText">{label}</div>
      <div className="reportDataBoxData">{value}</div>
    </>
  );

  const powerData = {
    labels,
    datasets: [
      {
        label: "Stored Power",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "#2465B2",
        backgroundColor: "#2465B2",
        borderWidth: 1,
      },
      {
        label: "Net Power",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "#1B724C",
        backgroundColor: "#1B724C",
        borderWidth: 1,
      },
      {
        label: "Panel Power",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "#F09144",
        backgroundColor: "#F09144",
        borderWidth: 1,
      },
      {
        label: "Power Draw",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "#F30000",
        backgroundColor: "#F30000",
        borderWidth: 1,
      },
    ],
  };

  const powerProfileData = {
    labels,
    datasets: [
      {
        fill: true,
        label: "ADCS",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "rgba(119, 152, 24)",
        backgroundColor: "rgba(119, 152, 24, 0.20)",
        borderWidth: 1,
      },
    ],
  };

  const mappedDatasets = powerData.datasets.map((item) => {
    return (
        <div className="legendItem">
        <div className="legendColor" style={{background: item.backgroundColor}}></div>
        <div className="legendLabel">{item.label}</div>
      </div>
    );
  });

  return (
    <div className="reportDiv">
      <div className="reportTitleDiv">
        <div className="reportTitle">Power Analysis</div>
        <div className="reportDate">{moment(new Date).format('DD - MM - HH - YYYY')}</div>
      </div>
      <div className="modalCheckboxDiv reportCheckBoxDiv">
        <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
        <button
          className="modalDropDownIconBtn"
          onClick={() =>
            setPowerAnalysisItems({
              Analysis_Inputs: !powerAnalysisItems.Analysis_Inputs,
              Output: powerAnalysisItems.Output,
            })
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d={powerAnalysisItems.Analysis_Inputs ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
              stroke="#F0F0F0"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="modalCheckboxText modalWhiteSubHeader">Analysis Inputs</div>
      </div>

      {powerAnalysisItems.Analysis_Inputs && <MapAnalysisInputs spaceCraftObject={spaceCraftObject} />}

      <div className="modalCheckboxDiv reportCheckBoxDiv">
        <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
        <button
          className="modalDropDownIconBtn"
          onClick={() =>
            setPowerAnalysisItems({
              Analysis_Inputs: powerAnalysisItems.Analysis_Inputs,
              Output: !powerAnalysisItems.Output,
            })
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d={powerAnalysisItems.Output ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
              stroke="#F0F0F0"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="modalCheckboxText modalWhiteSubHeader">Output</div>
        <button className="reportCommentBtn">
          <img src={require("../../../ImageSvgs/Comment-Button.svg").default} />
        </button>
        <button className="reportCommentBtn reportTagBtn">
          <img src={require("../../../ImageSvgs/Tag-Button.svg").default} />
        </button>
      </div>

      {powerAnalysisItems.Output && (
        <>
          <div className="modalCheckboxDiv reportCheckBoxDiv modalCheckboxDivNoMargin">
            <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
            <button className="modalDropDownIconBtn" onClick={() => setPowerOutputItems({ ...powerOutputItems, Power: !powerOutputItems.Power })}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d={powerOutputItems.Power ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                  stroke="#F0F0F0"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="reportConfigHeader white analysisPlaneHeader">Power</div>
            <div className="selectBorderDiv">
              <select
                name="reportOutputTime"
                id="powerDropdown"
                className={"reportConfigDropdown reportAnalysisDropdown"}
                onChange={(event) => {
                  event.target.value != "" ? (event.target.style.color = "#CCF54E") : (event.target.style.color = "#F2A93B");
                }}
              >
                <option selected hidden value="">
                  Power Type
                </option>
                <option value="Stored Power">Stored Power</option>
                <option value="Net Power">Net Power</option>
                <option value="Panel Power">Panel Power</option>
                <option value="Power Draw">Power Draw</option>
              </select>
            </div>
          </div>

          {console.log(powerData.datasets)}
          {powerOutputItems.Power && (
            <>
              <Line className="reportBarGraph planeBarGraph" options={powerOptions} data={powerData} />
              <div className="legendDiv">
                {mappedDatasets}
              </div>
            </>
          )}

          {/* <div className="reportCommentsHeader">Comments</div>
  <input type="text" placeholder="Add Comment" className="reportCommentsInput"></input>
  <div className="reportCommentsHeader">Tags</div>
  <input type="text" placeholder="Add Comment" className="reportCommentsInput"></input> */}

          <div className="modalCheckboxDiv reportCheckBoxDiv">
            <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
            <button className="modalDropDownIconBtn" onClick={() => setPowerOutputItems({ ...powerOutputItems, Power_Profile: !powerOutputItems.Power_Profile })}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d={powerOutputItems.Power_Profile ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                  stroke="#F0F0F0"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="reportConfigHeader white analysisPlaneHeader">Power Profile</div>
            <div className="selectBorderDiv">
              <select
                name="reportOutputTime"
                id="powerDropdown"
                className={"reportConfigDropdown reportAnalysisDropdown"}
                onChange={(event) => {
                  event.target.value != "" ? (event.target.style.color = "#CCF54E") : (event.target.style.color = "#F2A93B");
                }}
              >
                <option selected hidden value="">
                  Power Profile
                </option>
                <option value="ADCS">ADCS</option>
                <option value="ADCS">X-Band</option>
                <option value="ADCS">S-Band</option>
              </select>
            </div>
          </div>

          {powerOutputItems.Power_Profile && (
            <>
              <Line className="reportBarGraph planeBarGraph" options={powerOptions} data={powerProfileData} />
              <div className="reportDataBox reportDataBoxHeight">{powerProfileValues.map(PowerProfileOutputDiv)}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PowerAnalysisHiFiReport;
