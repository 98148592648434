// @ts-nocheck

import ConsolePanel from "./ConsolePanel"
import GrafanaPanel from "../GrafanaPanel"
import CesiumView from "../SimulationDashBoard/CesiumView"
import { useParams } from "react-router-dom";


const QuickDeployDashboard = ({selectedTabBtn,TrueTwin,simulationStatus,isQuickDeploy,isScenarioAutomated, setLoading, taskListQueue}) => {

  
  const params = useParams();
  const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, scenario_id } = params

  return (
    <div className='w-100 h-100 d-flex'>
        {selectedTabBtn === 'Console' && <ConsolePanel TrueTwin={TrueTwin} isScenarioAutomated={isScenarioAutomated}/>}
        {selectedTabBtn === 'Telemetry' && <GrafanaPanel TrueTwin={TrueTwin} taskListQueue={taskListQueue}/>}
        {selectedTabBtn === 'Visualization' && <CesiumView 
        simulationStatus={simulationStatus} 
        selectedTabBtn={selectedTabBtn} 
        isQuickDeploy={isQuickDeploy} 
        isScenarioAutomated={isScenarioAutomated} 
        setLoading={setLoading}       
      />}
    </div>
  )
}

export default QuickDeployDashboard
