const RecommendationBox = ({ recommendationsItems }) => {
  return (
    <div>
      {recommendationsItems.map((item) => (
        <>
          <div className="reportDataBoxHeaderText">{item.header}</div>
          {item.items.map((items) => (
            <>
              <div className={items.class}>
                <div className="reportDataHalfBoxBold">{items.value}</div>
                <div className="reportDataHalfBoxNormal">{items.label}</div>
              </div>
            </>
          ))}
          <button className="reportDataBoxRoundIcon">{item.iconSvg}</button>
          <button className="reportDataBoxAddRecommendationBtn">{item.recommendationAction}</button>
        </>
      ))}
    </div>
  );
};

export default RecommendationBox;
