import { useState } from 'react'
import './Sidebar.css'
import { useParams } from 'react-router-dom';
import OperateService from '../../../../service/OperateService';
import { toast } from 'react-toastify';
import CustomToolTip from '../../../CommonComponents/CustomToolTip';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';

interface SidebarProps {
  openModal: (data) => void;
  sidebarLoader: any;
  setSidebarLoader: (data) => void;
  promotedSatellites: any[]
  fetchPromotedSatellites: () => void
  calculate_orbit_details: (data) => void
}

const Sidebar: React.FC<SidebarProps> = ({openModal, sidebarLoader, setSidebarLoader ,promotedSatellites, fetchPromotedSatellites, calculate_orbit_details}) => {
  const params = useParams();
  const { mission_id } = params
  const [openSlider, setOpenSlider] = useState(true)
  const [activeBtnGroup, setActiveBtnGroup] = useState<any>(false)
  const [showContent, setShowContent] = useState(null)
  
  const delete_promoted_satellite = (promotedId) => {
    setSidebarLoader(true)
    OperateService.deletePromoteSatellite(promotedId)?.then((res) => {
      if (res.data) {
        fetchPromotedSatellites()
        toast.success('Satellite deleted successfully')
      }
    }).catch((err) => {
      console.error(err);
      setSidebarLoader(false)
      toast.error('Something went wrong while deleting satellite')
    })
  }

  return (
    <div className={openSlider ? "show_side_bar side_bar" : "side_bar"}>
      <div className='slider_header'>
        <div className='w-100 d-flex pt-6 px-3'>
          <button className={`slider_header__btn`} onClick={()=>{openModal({type: 'promote'})}} >
            <span className='fs-20'>Promote Satellite</span>
            <i className='fe fe-plus slider_header__btn__icon fs-18'/>
          </button>
        </div>
        <div className='close__side_bar_icon' onClick={() => setOpenSlider(!openSlider)}>
          <i className='fe fe-chevron-right fs-30'/>
        </div>
      </div>
      <div className='slider_caontainer'>
        {sidebarLoader && <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
          <CircularProgress color='inherit'/>
        </div>}
        {!sidebarLoader && promotedSatellites.map((item: any, i) => (
          <div key={i} className={`slider_content ${showContent === item ? 'active' : ''}`}>
            <div className='slider_content_header'>
              <div className='d-flex align-items-center gap-1 name_section text-truncate' onClick={() => {
                  calculate_orbit_details(item)
                  // setShowContent(showContent === item ? null : item )
                }}>
                <i className='fe fe-chevron-right fs-25 slider_content_body__icon'/>
                <span className='header_text w-90 text-truncate'>{item?.['name']}</span>
              </div>
              <div className={`btn_action_section gap-2 ${activeBtnGroup === i ? 'active' : ''}`}>
                <ButtonGroup size="sm" className="flex-nowrap">
                    <div className="btn-list more-button">
                        <Dropdown drop="end">
                            <Dropdown.Toggle size="sm" variant="transparent">
                                <i className="fe fe-more-vertical fs-20" onClick={(e)=>{
                                  e.preventDefault();
                                  setActiveBtnGroup(activeBtnGroup !== i ? i : false);
                                }}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={(e) => {
                                        if(item?.['tle'] || item?.['noradId']){
                                          openModal({
                                            type: 'update', data: {
                                            trueTwinId: item?.['trueTwinId'],
                                            orbitParams: item?.['scenarioDetails']?.['Orbit'] ? {
                                              epoch: item?.['scenarioDetails']?.['Epoch'],
                                              ...item?.['scenarioDetails']?.['Orbit']
                                            } : {},
                                            satellite: {label: item?.['name'], value: {'uniqueId': item?.['satId'], 'name': item?.['name']}},
                                            [item?.['tle'] ? 'tle' : 'noradId']: item?.['tle'] ? item?.['tle'] : item?.['noradId']
                                          }})
                                        }else{
                                          openModal({
                                            type: 'update', data: {
                                            trueTwinId: item?.['trueTwinId'],
                                            orbitParams: item?.['scenarioDetails']?.['Orbit'] ? {
                                              epoch: item?.['scenarioDetails']?.['Epoch'],
                                              ...item?.['scenarioDetails']?.['Orbit']
                                            } : {},
                                            satellite: {label: item?.['name'], value: {'uniqueId': item?.['satId'], 'name': item?.['name']}}
                                          }})
                                        }
                                        setActiveBtnGroup(false);
                                    }}
                                >
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={(e) => {
                                        delete_promoted_satellite(item?.['trueTwinId'])
                                        setActiveBtnGroup(false);
                                    }}
                                >
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </ButtonGroup>
              </div>
            </div>
            <div className='slider_content_body'>
              <span>Orbital Details</span>
              <span>Ground Stations</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar