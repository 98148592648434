//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import MissionModelingService from "../../MissionModelingService";
import { set } from "lodash";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

const DeOrbitingAnalysis = ({ props, parameterProps }) => {
  const params = useParams()
  const {
    mission_name,
    mission_id
  } = params;
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [satelliteMass,setSatellteMass]=useState("80")
  const [surfaceArea, setSurfaceArea] = useState("0.3");
  const [areaDragCoefficent,setAreaDragCoefficient]=useState('2')
  const [geoMagneticIndex,setGeoMagneticIndex] =useState('10')
  const[solarFlux,setSolarFlux]=useState("150")
  const [deorbitAltitude,setDeorbitAltitude]=useState("300")
  const [eclipsePlanes, setEclipsePlanes] = useState<any>([]);
  const [loading, setLoading] = useState(false)

  const [deOrbitingItems, showDeOrbitingItems] = useState({
    Satellites: true,
    Drag_Parameters: true,
    Atmospheric_Models: true,
    Propulsion: true,
  });

  

  const [deOrbitingSats, showDeOrbitingSats] = useState({
    Mission_Sat_01: true,
  });

  const [atmosphericModels, showAtmosphericModels] = useState({
    Model_01: true,
    Model_02: true,
    Model_03: true,
  });
  const eclipseHistory = useRef(new Set());
  const [eclipseItems, showEclipseItems] = useState({
    Planes: true,
    Analysis_Timeframe: true,
  });
  const [propulsionFields, showPropulsionFields] = useState("")

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
      props.setBottomBarReport("De-Orbiting Analysis")
    }, 2000);
  };

  useEffect(() => {
    if(props.selectedComponent === 'Satellite' || props.selectedComponent === 'Plane' || props.selectedComponent === 'Spaceobject'){
      const currentEclipseObject = {
        objectId: props?.selectedComponentId,
        name: props?.selectedComponentName,
        objectType: props?.selectedComponent,
      };

      let alreadyHas = eclipsePlanes?.some(item => JSON.stringify(item?.['objectId']) === JSON.stringify(currentEclipseObject?.['objectId']))
      if(!alreadyHas){
        setEclipsePlanes([...eclipsePlanes, currentEclipseObject]);
      }
    }
  }, [props]);


  const postDeorbitData = () => {
    setLoading(true)
    let spaceObjects:any = []
    eclipsePlanes?.map((item: any) => {
      spaceObjects.push({objectId: item?.['objectId'], objectType: item?.['objectType']})
    })

    console.log("parameterProps deorbit", parameterProps);

    let body = {
      "spaceObjects": spaceObjects,
      "mass": parseFloat(satelliteMass),
      "solarFlux": parseFloat(solarFlux),
      "satelliteArea": parseFloat(surfaceArea),
      "dragCoeff": parseFloat(areaDragCoefficent),
      "geomagneticIndex": parseFloat(geoMagneticIndex),
      "deorbitAltitude": parseFloat(deorbitAltitude),
    };

    MissionModelingService.postDeorbitAnalysisData(body, mission_id)
      .then((res) => {
        showSuccessMessage("De-Orbit Analysis successfull");
        props.setSideReportBarProps({isSideReportBarVisible: true, isDeorbitComponrntUpdate: !props.isDeorbitComponrntUpdate});
        setLoading(false)
        setisModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };
  const removeEclipsePlanes = (item) => {
    const updatedPlanes = eclipsePlanes?.filter((eclipsePlanes) => eclipsePlanes?.['objectId'] !== item?.['objectId']);
    setEclipsePlanes(updatedPlanes);
  };
  return (
    <>
      {isSuccessMsgVisible && (
        <ModalCreationMessage props={props} message={message} />
      )}
      {isModalVisible && (
        <>
          <div
            className={
              props.isSidebarVisible
                ? "mainModal sidebarOpenModal"
                : "mainModal"
            }
            id="deOrbitingAnalysis"
          >
            <div className="modalHeader">
              <div className="modalHeaderImg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="22"
                  viewBox="0 0 17 22"
                  fill="none"
                >
                  <path
                    d="M11.5187 16.2395C14.4123 16.2395 16.758 13.8937 16.758 11.0001C16.758 8.10648 14.4123 5.76074 11.5187 5.76074C8.62504 5.76074 6.2793 8.10648 6.2793 11.0001C6.2793 13.8937 8.62504 16.2395 11.5187 16.2395Z"
                    fill="#F0F0F0"
                  />
                  <mask
                    id="path-2-outside-1_2235_2206"
                    maskUnits="userSpaceOnUse"
                    x="0.242188"
                    y="-0.25"
                    width="16"
                    height="22"
                    fill="black"
                  >
                    <rect
                      fill="#F0F0F0"
                      x="0.242188"
                      y="-0.25"
                      width="16"
                      height="22"
                    />
                    <path d="M1.24219 11C1.24219 5.32915 5.84966 0.75 11.5188 0.75C16.1256 0.75 16.8332 5.31868 13.4779 8.51064C16.5263 5.15311 15.7946 1.46277 11.5188 1.46277C6.25792 1.46277 1.98155 5.73913 1.98155 11C1.98155 16.2609 6.25792 20.5372 11.5188 20.5372V21.0372L11.4922 21.0372V21.25C5.84923 21.25 1.24219 16.6704 1.24219 11Z" />
                  </mask>
                  <path
                    d="M1.24219 11C1.24219 5.32915 5.84966 0.75 11.5188 0.75C16.1256 0.75 16.8332 5.31868 13.4779 8.51064C16.5263 5.15311 15.7946 1.46277 11.5188 1.46277C6.25792 1.46277 1.98155 5.73913 1.98155 11C1.98155 16.2609 6.25792 20.5372 11.5188 20.5372V21.0372L11.4922 21.0372V21.25C5.84923 21.25 1.24219 16.6704 1.24219 11Z"
                    fill="#F0F0F0"
                  />
                  <path
                    d="M13.4779 8.51064L13.2928 8.34259C13.2024 8.44218 13.207 8.59546 13.3032 8.68946C13.3994 8.78346 13.5527 8.78448 13.6502 8.69177L13.4779 8.51064ZM11.5188 20.5372H11.7688V20.2872H11.5188V20.5372ZM11.5188 21.0372L11.5185 21.2872L11.7688 21.2876V21.0372H11.5188ZM11.4922 21.0372L11.4925 20.7872L11.2422 20.7869V21.0372H11.4922ZM11.4922 21.25V21.5H11.7422V21.25H11.4922ZM11.5188 0.5C5.71246 0.5 0.992188 5.19021 0.992188 11H1.49219C1.49219 5.46809 5.98686 1 11.5188 1V0.5ZM13.6502 8.69177C15.3721 7.05371 16.0852 5.02963 15.7285 3.38048C15.5495 2.55289 15.1011 1.82734 14.3867 1.3111C13.6738 0.795979 12.7115 0.5 11.5188 0.5V1C12.6295 1 13.4832 1.27511 14.0938 1.71636C14.7029 2.1565 15.0855 2.77262 15.2398 3.48619C15.5498 4.91937 14.939 6.77561 13.3056 8.32951L13.6502 8.69177ZM11.5188 1.71277C13.5837 1.71277 14.7029 2.5932 15.0024 3.7884C15.3103 5.01689 14.7881 6.69568 13.2928 8.34259L13.663 8.67869C15.2161 6.96807 15.8523 5.12292 15.4874 3.66686C15.1142 2.1775 13.7297 1.21277 11.5188 1.21277V1.71277ZM2.23155 11C2.23155 5.87721 6.39599 1.71277 11.5188 1.71277V1.21277C6.11985 1.21277 1.73155 5.60106 1.73155 11H2.23155ZM11.5188 20.2872C6.39599 20.2872 2.23155 16.1228 2.23155 11H1.73155C1.73155 16.3989 6.11985 20.7872 11.5188 20.7872V20.2872ZM11.7688 21.0372V20.5372H11.2688V21.0372H11.7688ZM11.4919 21.2872L11.5185 21.2872L11.5191 20.7872L11.4925 20.7872L11.4919 21.2872ZM11.7422 21.25V21.0372H11.2422V21.25H11.7422ZM0.992188 11C0.992188 16.8089 5.7116 21.5 11.4922 21.5V21C5.98686 21 1.49219 16.5319 1.49219 11H0.992188Z"
                    fill="black"
                    mask="url(#path-2-outside-1_2235_2206)"
                  />
                  <circle cx="12.2422" cy="10" r="1" fill="#08080A" />
                </svg>
              </div>
              <span>De-Orbiting</span>
              <button
                className="modalCloseBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                <img src={require("../../ImageSvgs/CloseButtonWhite.svg").default}/>
              </button>
              <button
                className="modalCloseBtn modalMinimizeBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                <img src={require("../../ImageSvgs/MinimizeButtonWhite.svg").default} />
              </button>
            </div>

            <div className="modalScrollableSection">
            <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showEclipseItems({
                      ...eclipseItems,
                      Planes: !eclipseItems.Planes,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={eclipseItems.Planes ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellites</div>
              </div>

              {eclipseItems.Planes && (
                <div className="modalrefOrbitDiv">
                  <div className="modalrefOrbitsScrollSpacer">
                    {eclipsePlanes?.map((item) => {
                      console.log("map item eclipse", item);

                      if (Object.keys(item).length === 0 && item.constructor === Object) {
                        return null;
                      }

                      return (
                        <div className="modalrefOrbitsBtn orbitsBtnSpacer">
                          <span>{item?.["name"]}</span>
                          <button
                            className="modalrefOrbitsCloseBtn"
                            onClick={() => {
                              removeEclipsePlanes(item);
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                              <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                              <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}


              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDeOrbitingItems({
                      ...deOrbitingItems,
                      Drag_Parameters: !deOrbitingItems.Drag_Parameters,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d={
                        deOrbitingItems.Drag_Parameters
                          ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344"
                          : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"
                      }
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">
                  Drag Parameters
                </div>
              </div>

              {deOrbitingItems.Drag_Parameters && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Satellite Mass</div>
                    <input
                      value={satelliteMass}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Satellite_Mass: event.target.value,
                        });
                        setSatellteMass(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in kg"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Surface area</div>
                    <input
                      value={surfaceArea}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Surface_area: event.target.value,
                        });
                        setSurfaceArea(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in m2"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Area Drag Coefficient</div>
                    <input
                      value={areaDragCoefficent}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Area_Drag_coefficient: event.target.value,
                        });
                        setAreaDragCoefficient(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in m2"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Geo-Magnetic Index </div>
                    <input
                      value={geoMagneticIndex}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Geo_Magnetic_Index: event.target.value,
                        });
                        setGeoMagneticIndex(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in Kp"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Solar Flux</div>
                    <input
                      value={solarFlux}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Solar_Flux: event.target.value,
                        });
                        setSolarFlux(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in sfu"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Deorbit Altitude</div>
                    <input
                      value={deorbitAltitude}
                      onChange={(event) => {
                        parameterProps.setDeOrbitingParameters({
                          ...parameterProps.deOrbitingParameters,
                          Solar_Flux: event.target.value,
                        });
                        setDeorbitAltitude(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in km"}
                      style={{ width: "148px" }}
                    />
                  </div>
                </>
              )}

             
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  postDeorbitData();
                  props.setShowSidebarReportComponent("Orbital");
                }}
              >
                 {!loading ?"Analysis":"Analyzing..."}&nbsp;
                {loading && 
                 <CircularProgress size={10} className="text-white" />
                }
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeOrbitingAnalysis;
