//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Button,
} from "react-bootstrap";
import {
  PayloadSteps,
  Steps
} from "../../Stepper.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  MissionStore,
  setEditPayloadData,
  setFilterPayloadDeviceList,
  setIsNewPayloadCatelog,
  setIsPayloadEdit,
  setPayloadIndex,
  setPayloadSliderFalse,
} from "../../../../../store/MissionSlice";
import { toast } from "react-toastify";
import PayloadSlider from "./PayloadSlider";
import { calculatePayloadUsage, calculate_Total_AOP_Usage, navigateTo, uuidv4 } from "../../../../CommonComponents/CommonFunctions";
import PageHeader from "../../../../../Layouts/PageHeader/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import MissionService from "../../../../../service/MissionService";
import { usageStore } from "../../../../../store/UsageSlice";
import CloseButton from "../../../../CommonComponents/CloseButton";
import Dialog from "../../../../CommonComponents/Dialog";
import SecondStep from "./PayloadStepper/SecondStep";
import FirstStep from "./PayloadStepper/FirstStep";
import Loader from "../../../../../Layouts/Loader/Loader";

const Payloads = () => {
  const params = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mission_name, mission_id, sat_name, sat_id, payload_name, payload_id } = params

  const [selectedPayload, setSelectedPayload] = useState({})
  const [payloadSliderData, setPayloadSliderData] = useState({})

  const [pyloadSrc, setPyloadSrc] = useState<string>("");
  const [payloadCatalouge, setPayloadCatalouge] = useState<string>("");
  const [payloadType, setPayloadType] = useState('');
  const [payloadSubType, setPayloadSubType] = useState("");
  const [loading, setLoading] = useState(true);
  //...................................................//
  const [hover, setHover] = useState(true)
  const [satPayloads, setSatPayloads] = useState<any[]>([]);
  const [satellieteDeta, setSatellieteData] = useState<any>({});
  const isPayloadSlider = useSelector((state: { mission: MissionStore }) => state.mission.isPayloadSlider);
  const isNewPayloadCatelog = useSelector((state: { mission: MissionStore }) => state.mission.isNewPayloadCatelog);
  const payloadIndex = useSelector((state: { mission: MissionStore }) => state.mission.payloadIndex);

  const [deleteCatalougeId, setDeleteCatalougeId] = useState<any>('');
  const [openDeleteModel, setOpenDeleteModal] = useState<any>(false);
  //...................................................//

  const [selectedBands, setSelectedBands] = useState<any>([]);

  const payloadSizeUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadSizeUsage);
  const payloadWeightUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadWeightUsage);
  const payloadPowerUsage = useSelector((state: { usage: usageStore }) => state.usage.payloadPowerUsage);

  const isEditPayload = useSelector(
    (state: { mission: MissionStore }) => state.mission.isEditPayload
  );
  const EditPayloadData = useSelector(
    (state: { mission: MissionStore }) => state.mission.EditPayloadData
  );

  const [userInput, setUserInput] = useState<any>({
    payloadName: '',
    time_per_orbit: '5',
    AltMeasureAccuracy: '1',
    downlinkDatasize: '1',
  });



  useEffect(() => {
    if (sat_id) {
      MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
        if (sat?.data) {
          let payloads = sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] : []
          setSatPayloads(payloads)
          setSatellieteData(sat?.data)
          calculatePayloadUsage(payloads, dispatch);
          calculate_Total_AOP_Usage(payloads, [], dispatch);
          if (payload_id && payload_id !== undefined) {
            let edit_payload = payloads.find(pay => pay?.['user_data']?.['id'] == payload_id)
            dispatch(setEditPayloadData(edit_payload));
            dispatch(setIsPayloadEdit(true));
          } else {
            setLoading(false)
          }
          if (isNewPayloadCatelog) {
            dispatch(setIsNewPayloadCatelog(false))
          }
        }
      }).catch((err) => {
        console.log('Some thing went wrong while getting satellite');
        setLoading(false)
      })
    }
    dispatch(setPayloadIndex(1))
  }, [sat_id]);


  const update_satellite = (payloads: any) => {
    setLoading(true)
    let sat = {
      ...satellieteDeta,
      name: satellieteDeta?.['name'],
      draftMode: 'Yes',
      "containing-mission": satellieteDeta?.['containing-mission'],
      versions: [
        {
          assembly: {
            'Payloads': payloads,
            'Edge': satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'],
            'Template': '',
          },
        },
      ],
      "swap-info": {
        ...satellieteDeta?.['"swap-info"'],
        totalPayloadSize: payloadSizeUsage,
        totalPayloadMass: payloadWeightUsage,
        totalPayloadPower: payloadPowerUsage,
      },
    };
    MissionService.update_satellites(sat_id, sat)?.then((res) => {
      if (res.data) {
        toast.success(`Payload ${isEditPayload ? 'updated' : 'added'} successfully`, { toastId: 'o' });
        setLoading(false)
        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
      }
    })
      .catch((err) => {
        toast.error("Something went wrong while adding payload ", { toastId: 'o' });
        setLoading(false)
      });
  }


  useEffect(() => {
    if (isPayloadSlider) {
      dispatch(setPayloadSliderFalse())
    }
  }, [])

  useEffect(() => {
    if (payloadIndex === 1 && Object.keys(selectedPayload).length > 0) {
      let filter_payloads = satPayloads?.filter(pay => pay?.['user_data']?.['id'] !== selectedPayload?.['user_data']?.['id'])
      calculatePayloadUsage(filter_payloads, dispatch);
      calculate_Total_AOP_Usage(filter_payloads, [], dispatch);
    }
  }, [payloadIndex])

  const onPreviousStepper = () => {
    if (payloadIndex !== 0) {
      dispatch(setPayloadIndex(payloadIndex - 1));
      setHover(true)
      dispatch(setFilterPayloadDeviceList([]));
    }
  };


  useEffect(() => {
    if (isEditPayload) {
      dispatch(setPayloadIndex(2))
      setPayloadCatalouge(EditPayloadData?.["atmos-data"]?.["component_specific_attributes"]?.["payloadType"]);
      setSelectedPayload(EditPayloadData);
      setPayloadSliderData(EditPayloadData);
      setPyloadSrc(EditPayloadData?.["atmos-data"]?.["src"]);
    }
  }, [isEditPayload]);

  const onNextStepper = () => {
    if (onCheckSteps()) {
      if (payloadIndex === 1) {
        dispatch(setPayloadIndex(payloadIndex + 1))
        setLoading(true)
      } else {
        if (isEditPayload) {
          let filter_payloads = satPayloads?.filter(pay => pay?.['user_data']?.['id'] !== payload_id)
          if ((userInput?.['time_per_orbit'] !== '' && Number(userInput?.['time_per_orbit']) > 0) && userInput?.['AltMeasureAccuracy'] !== '') {
            update_satellite([...filter_payloads, selectedPayload])
            dispatch(setIsPayloadEdit(false));
            dispatch(setEditPayloadData({}));
          } else {
            toast.error(`${(userInput?.['time_per_orbit'] === '' || Number(userInput?.['time_per_orbit']) <= 0) ? 'Average active duration per orbit can not be 0 or empty' : 'Pointing accuracy is required'}.`, { toastId: 'o' });
          }
        } else {
          if ((userInput?.['time_per_orbit'] !== '' && Number(userInput?.['time_per_orbit']) > 0) && userInput?.['AltMeasureAccuracy'] !== '') {
            update_satellite([...satPayloads, selectedPayload])
          } else {
            toast.error(`${(userInput?.['time_per_orbit'] === '' || Number(userInput?.['time_per_orbit']) <= 0) ? 'Average active duration per orbit can not be 0 or empty' : 'Pointing accuracy is required'}.`, { toastId: 'o' });
          }
        }
      }
    }
  };



  const onCheckSteps = () => {
    if ((payloadIndex === 1) && (payloadCatalouge === "")) {
      toast.error("Select any payload category to proceed.", { toastId: 'o' });
      return false
    } else if (payloadIndex === 2 && Object.keys(selectedPayload).length === 0) {
      toast.error("Select any device to proceed.", { toastId: 'o' });
      return false
    } else if (payloadIndex === 2 && Object.keys(selectedPayload).length > 0) {
      const all_payloads = isEditPayload ? satPayloads?.filter(pay => pay?.['user_data']?.['id'] !== payload_id) : satPayloads
      const payload_name_exist = all_payloads?.find(pay => pay?.['user_data']?.['payload_name'].toLowerCase().trim() === selectedPayload?.['user_data']?.['payload_name'].toLowerCase().trim())
      if (payload_name_exist) {
        toast.error("Payload name already exist", { toastId: 'o' });
        return false
      } else {
        return true
      }
    }
    else {
      return true
    }
  };


  const onSelectPayload = (data) => {
    setPayloadCatalouge(data?.type);
    setPyloadSrc(data?.['src']);
  };

  const navigate_to_step = (step) => {
    if (isEditPayload) {
      dispatch(setIsPayloadEdit(false));
      dispatch(setEditPayloadData({}));
    }
    if (satPayloads?.length > 0) {
      if (step === 'Payloads' && satEdges?.length > 0) {
        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
      }
      if (step === 'Edge') {
        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
      }
    } else {
      toast.error("Select any device to proceed", { toastId: 'o' })
    }
  }

  return (
    <div className='h-100 w-100'>
      {loading && <Loader />}
      <PageHeader
        sat_name={sat_name?.length > 15 ? sat_name.substring(0, 15) + " ..." : sat_name}
        titles={!isNewPayloadCatelog ? "Configure Payload Device" : "Add Payload Device"}
        description={!isNewPayloadCatelog ? "Payloads currently configured in this satellite" : "Define a new payload device for this satellite"}
        active="New Mission"
        items={["Apps"]}
        isButton={false}
        showSwap={true}
        showPercent={false}
        type=''
        value=''
      />
      {payloadIndex === 1 && (<div className="gap-3 p-0">
        <div className="row-cards row-box mt-5">
          <div className=" ">
            <ol className="c-stepper">
              {PayloadSteps.map((data) => {
                return (
                  <li
                    key={data.stepIndex}
                    className={
                      payloadIndex === data.stepIndex
                        ? "c-stepper__item active"
                        : payloadIndex > data.stepIndex
                          ? "done c-stepper__item"
                          : "c-stepper__item"
                    }
                  >
                    <h3 className="c-stepper__title">{data.title}</h3>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
        <FirstStep payloadCatalouge={payloadCatalouge} onSelectPayload={onSelectPayload} />
      </div>)}
      {payloadIndex === 2 && (
        <SecondStep payloadCatalouge={payloadCatalouge} setPayloadCatalouge={setPayloadCatalouge} onCheckSteps={onCheckSteps} onSelectPayload={onSelectPayload} satPayloads={satPayloads} pyloadSrc={pyloadSrc}
          setPayloadType={setPayloadType} payloadType={payloadType} setPayloadSubType={setPayloadSubType} payloadSubType={payloadSubType} selectedPayload={selectedPayload} setPayloadSliderData={setPayloadSliderData}
          setSelectedBands={setSelectedBands} selectedBands={selectedBands} setSelectedPayload={setSelectedPayload} setLoading={setLoading} setUserInput={setUserInput} userInput={userInput}
        />
      )}

      {isPayloadSlider && (
        <PayloadSlider payloadSliderData={payloadSliderData} />
      )}

      {/* //................................................................// */}

      {!isNewPayloadCatelog && <div className="navigator-btn gap-3">
        {(payloadIndex === 1 && satPayloads?.length === 0) && <CloseButton />}
        {satPayloads?.length > 0 && <Button
          variant="primary-outline"
          type="button"
          className="btn btn-outline-danger pt-1 pb-1 pe-6 ps-6 me-2 fs-14"
          onClick={() => {
            if (isEditPayload) {
              dispatch(setIsPayloadEdit(false));
            }
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
          }}
        >
          Cancel
        </Button>}
        <div className="btn-group btn-grp-nav">
          {payloadIndex !== 1 && <Button
            variant={hover ? "outline-primary" : "primary"}
            type="button"
            className={`d-flex align-items-center btn ${hover ? "btn-outline-primary" : "btn-primary"
              } pt-1 pb-1 pe-4 ps-3 fs-14`}
            onClick={onPreviousStepper}
            onMouseEnter={() => {
              setHover(false);
            }}
            onMouseLeave={() => {
              setHover(true);
            }}
          >
            <i className="fe fe-chevron-left me-1 fs-16"></i> Back
          </Button>}
          <Button
            variant={hover ? "primary" : "outline-primary"}
            type="submit"
            className={`d-flex align-items-center btn ${hover ? "btn-primary" : "btn-outline-primary"
              } pt-1 pb-1 pe-3 ps-4 fs-14`}
            onClick={onNextStepper}
          >
            {payloadIndex !== 2 ? "Next" : isEditPayload ? 'Update Payload' : 'Save & Continue'}
            <i className="fe fe-chevron-right ms-1 fs-16"></i>
          </Button>
        </div>
      </div>}


      {!isNewPayloadCatelog && <div className="stepper-container">
        <div className="breadcrumb">
          {Steps.map((data) => {
            return <a
              onClick={() => {
                navigate_to_step(data.title)
              }}
              className={data.title === 'Payloads' ? 'active' : ''}
              key={data.title}>
              <span className={"breadcrumb__inner"}>
                <span className="breadcrumb__title">
                  {data.svg}
                </span>
                <span className={"breadcrumb__desc"}>{data.title}</span>
              </span>
            </a>
          })}
        </div>
      </div>}

      <Dialog
        onClick={(event) => {
          if (event) {
            deleteUserCatalouge(deleteCatalougeId)
          }
          setOpenDeleteModal(false)
          setDeleteCatalougeId('')
        }}
        openModel={openDeleteModel} comment='Are you sure you want to delete this payload device ?'
      />
    </div>
  );
};
export default Payloads;
