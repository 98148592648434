import { useEffect, useState } from "react";
import './Schedule.scss'
import { CollapseSvg, GroundSvg, PinSvg } from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import { useSearchParams } from "react-router-dom";
import useUrlParams from "../../../../hooks/useUrlParams";

const GroundSchedule = () => {
    const [searchParams] = useSearchParams();
    const [modal, setModal] = useState({
        open: false,
        pinned: false,
        current_modal: ''
    })
    const [expand, setExpand] = useState(false);
    const { handleUpdateUrl, getModalDetails } = useModals()

    useEffect(() => {
        const details = getModalDetails('ground-schedule')
        setModal(details)
    }, [searchParams])

    const open_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('ground-schedule', 'pinned')
        } else {
            handleUpdateUrl('ground-schedule', 'open')
        }
    }

    const pinned_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('ground-schedule', 'open')
        } else {
            handleUpdateUrl('ground-schedule', 'pinned')
        }
    }
    const close_modal = (event: any) => {
        event.stopPropagation()
        handleUpdateUrl('ground-schedule', 'close')
    }

    useEffect(() => {
        const close_modal = () => {
            const details = getModalDetails('ground-schedule')
            if (details.open && !details.pinned) {
                handleUpdateUrl('ground-schedule', 'close')
            }
        }
        window.addEventListener('click', close_modal)
        return () => {
            window.removeEventListener('click', close_modal)
        }
    }, [])


    return (
        <Modal
            open={modal.open ? true : false}
            pinned={modal.current_modal === 'ground-schedule' && modal.pinned}
            disabled={modal.current_modal === 'ground-schedule' && modal.pinned}
            class_name={`${modal.current_modal === 'ground-schedule' ? 'current__modal' : ''}`}
        >
            <div className={`schedule__panel__container ${expand && 'expand'} `} onClick={open_modal}>
                <div className="schedule__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <GroundSvg />
                        <span className="panel__header__title">Gound Schedule</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`} onClick={pinned_modal}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`} onClick={close_modal}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="schedule__panel__body"></div>
            </div>
        </Modal>
    )
}

export default GroundSchedule