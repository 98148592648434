import React, { Fragment, useEffect, useRef, useState } from 'react'
import CustomToolTip from '../../../CommonComponents/CustomToolTip';
import useUrlParams from '../../hooks/useUrlParams';

interface NotificationProps {
    show_all: boolean;
    set_show_all: (e: any) => void
}

const Notification: React.FC<NotificationProps> = ({ show_all, set_show_all }) => {

    const { dashboard } = useUrlParams()

    const [notifications, setNotifications] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);;
    const [showDetails, setShowDetails] = useState<string | null>(null);
    const [deletedNotification, setDeletedNotification] = useState<string | null>(null);
    const notificationRef = useRef<HTMLButtonElement | []>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const deleteNotification = (notification: string) => {
        setDeletedNotification(notification);
    }

    useEffect(() => {
        if (deletedNotification && notificationRef) {
            const notification_element = notificationRef.current[deletedNotification];
            if (!notification_element) return;
            notification_element.classList.add('notification__delete__animation');

            const onAnimationEnd = () => {
                const filter_notification = notifications.filter((n) => n !== deletedNotification);
                setNotifications(filter_notification);
                setDeletedNotification(null)
                notification_element.classList.remove('notification__delete__animation');
            };

            notification_element.addEventListener('transitionend', onAnimationEnd);
            return () => notification_element.removeEventListener('transitionend', onAnimationEnd);
        }
    }, [deletedNotification, notificationRef])

    useEffect(() => {
        const onClick = () => {
            setShowDetails(null)
        };
        window.addEventListener('click', onClick);
        return () => window.removeEventListener('click', onClick)
    }, [])

    useEffect(() => {
        if (showDetails) {
            const notification_element = notificationRef.current[showDetails];
            const container = containerRef.current; // Assuming the first element in the ref is the container

            if (notification_element && container) {
                const elementTop = notification_element.offsetTop; // Get element's top offset from container
                const containerScrollTop = container.scrollTop; // Get current scroll position
                const containerHeight = container.clientHeight; // Get container's visible height
                const elementHeight = notification_element.offsetHeight; // Get element's height

                // Calculate new scroll position
                let newScrollTop;

                // Check if element is already partially or fully visible
                const elementBottom = elementTop + elementHeight; // Calculate element's bottom position
                const isVisible = elementBottom <= containerScrollTop + containerHeight; // Check if element is visible

                if (isVisible) {
                    // Element is visible, no scrolling needed
                    newScrollTop = containerScrollTop;
                } else if (elementTop < containerScrollTop) {
                    // Element is above viewport, scroll to top
                    newScrollTop = elementTop;
                } else {
                    // Element is below viewport, scroll to bring it into view
                    newScrollTop = Math.min(elementTop, containerScrollTop + elementTop - containerHeight);
                }

                container.scrollTo({ top: newScrollTop, behavior: 'smooth' }); // Smooth scroll
            }
        }
    }, [showDetails, notificationRef, containerRef])


    return (
        <div ref={containerRef} className={`header__notification__container ${(notifications.length > 9 && show_all) && 'active'} ${dashboard === 'operate' && 'show__notification'}`}
            onClick={(e) => { e.stopPropagation() }}
        >
            {notifications.map((notification: string, index) => {
                const total_notifications = notifications.length;
                const position = !show_all ? `${index * 5}px` : (showDetails !== null && notification > showDetails) ? `${index * 50 + 90}px` : `${index * 50}px`;
                const opacity = index === 0 ? 1 : index === 1 ? 0.4 : index === 2 ? 0.2 : 0;
                const zindex = index === 0 ? 1 : 0;
                return (
                    <div key={notification} className="notification__container"
                        ref={(el) => (notificationRef.current[notification] = el)}
                        style={{
                            position: 'absolute',
                            opacity: !show_all ? opacity : 1,
                            zIndex: zindex,
                            transform: `scale(${show_all ? 1 : index === 0 ? 1 : index === 1 ? 0.99 : 0.98}) translateY(${position})`,
                            transition: 'all 0.5s ease',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (show_all) {
                                setShowDetails(showDetails === notification ? null : notification)
                            } else {
                                set_show_all(e)
                            }
                        }}
                    >
                        <div className='d-flex align-items-start gap-2 flex-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99999 16.7C10.9095 16.7 12.7409 15.9415 14.0912 14.5912C15.4414 13.241 16.2 11.4096 16.2 9.50005C16.2 7.59049 15.4414 5.75914 14.0912 4.40888C12.7409 3.05862 10.9095 2.30005 8.99999 2.30005C7.09043 2.30005 5.25908 3.05862 3.90882 4.40888C2.55856 5.75914 1.79999 7.59049 1.79999 9.50005C1.79999 11.4096 2.55856 13.241 3.90882 14.5912C5.25908 15.9415 7.09043 16.7 8.99999 16.7ZM7.83629 7.06375C7.66655 6.89981 7.4392 6.80909 7.20323 6.81114C6.96725 6.81319 6.74152 6.90785 6.57465 7.07471C6.40778 7.24158 6.31313 7.46731 6.31108 7.70329C6.30903 7.93927 6.39975 8.16661 6.56369 8.33635L7.72739 9.50005L6.56369 10.6637C6.47773 10.7468 6.40916 10.8461 6.362 10.9559C6.31483 11.0657 6.29 11.1838 6.28896 11.3033C6.28792 11.4228 6.3107 11.5413 6.35595 11.6519C6.4012 11.7625 6.46803 11.863 6.55253 11.9475C6.63704 12.032 6.73752 12.0988 6.84813 12.1441C6.95874 12.1893 7.07725 12.2121 7.19675 12.2111C7.31625 12.21 7.43435 12.1852 7.54415 12.138C7.65396 12.0909 7.75327 12.0223 7.83629 11.9363L8.99999 10.7726L10.1637 11.9363C10.3334 12.1003 10.5608 12.191 10.7967 12.189C11.0327 12.1869 11.2585 12.0923 11.4253 11.9254C11.5922 11.7585 11.6868 11.5328 11.6889 11.2968C11.6909 11.0608 11.6002 10.8335 11.4363 10.6637L10.2726 9.50005L11.4363 8.33635C11.6002 8.16661 11.6909 7.93927 11.6889 7.70329C11.6868 7.46731 11.5922 7.24158 11.4253 7.07471C11.2585 6.90785 11.0327 6.81319 10.7967 6.81114C10.5608 6.80909 10.3334 6.89981 10.1637 7.06375L8.99999 8.22745L7.83629 7.06375Z" fill="#F0F0F0" />
                            </svg>
                            <div className={`notification__details ${showDetails === notification && 'active'}`}>
                                <span className='fs-16'>{showDetails !== notification ? `Danger alert! Change a few things up and try submitting again. ${notification}` : `Oh snap !`}</span>
                                <span className='fs-14'>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</span>
                                <button>Action</button>
                            </div>
                        </div>
                        {!show_all && <div className='notification__count__badge'>{total_notifications}</div>}
                        {show_all &&
                            <div className='d-flex align-items-center gap-2'>
                                <CustomToolTip title={showDetails === notification ? 'Hide Details' : 'View Details'} placement='top'>
                                    <button className={`notification__icon ${showDetails === notification && 'active'}`}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 5.52393L10 9.69059L15 5.52393" stroke="#F0F0F0" stroke-width="1.66667" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5 11.1904L10 15.4761L15 11.1904" stroke="#F0F0F0" stroke-width="1.66667" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </CustomToolTip>
                                <CustomToolTip title='Delete' placement='top'>
                                    <button className='notification__icon'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setShowDetails(null);
                                            deleteNotification(notification)
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29303 4.29308C4.48056 4.10561 4.73487 4.00029 5.00003 4.00029C5.26519 4.00029 5.5195 4.10561 5.70703 4.29308L10 8.58608L14.293 4.29308C14.3853 4.19757 14.4956 4.12139 14.6176 4.06898C14.7396 4.01657 14.8709 3.98898 15.0036 3.98783C15.1364 3.98668 15.2681 4.01198 15.391 4.06226C15.5139 4.11254 15.6255 4.18679 15.7194 4.28069C15.8133 4.37458 15.8876 4.48623 15.9379 4.60913C15.9881 4.73202 16.0134 4.8637 16.0123 4.99648C16.0111 5.12926 15.9835 5.26048 15.9311 5.38249C15.8787 5.50449 15.8025 5.61483 15.707 5.70708L11.414 10.0001L15.707 14.2931C15.8892 14.4817 15.99 14.7343 15.9877 14.9965C15.9854 15.2587 15.8803 15.5095 15.6948 15.6949C15.5094 15.8803 15.2586 15.9855 14.9964 15.9878C14.7342 15.99 14.4816 15.8892 14.293 15.7071L10 11.4141L5.70703 15.7071C5.51843 15.8892 5.26583 15.99 5.00363 15.9878C4.74143 15.9855 4.49062 15.8803 4.30521 15.6949C4.1198 15.5095 4.01463 15.2587 4.01236 14.9965C4.01008 14.7343 4.11087 14.4817 4.29303 14.2931L8.58603 10.0001L4.29303 5.70708C4.10556 5.51955 4.00024 5.26525 4.00024 5.00008C4.00024 4.73492 4.10556 4.48061 4.29303 4.29308Z" fill="white" />
                                        </svg>
                                    </button>
                                </CustomToolTip>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default Notification