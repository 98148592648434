import { Fragment, useEffect, useState } from 'react';
import Timeline, {
  CursorMarker,
  CustomMarker,
  TodayMarker,
  TimelineMarkers,
  DateHeader,
  TimelineHeaders,
  SidebarHeader
} from "react-calendar-timeline";

import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { ReactComponent as SvgIconTtc } from '../../../../../assets/images/svgs/ttc.svg'
import { ReactComponent as SvgIconLive } from '../../../../../assets/images/svgs/live.svg'
import MissionService from '../../../../../service/MissionService';

const TimelineComponent = ({ scenarioId, absTime, setAbsTime }) => {
  const [groups1, setGroups] = useState([]);
  const [items1, setItems] = useState([]);
  const [timeLine, setTimeLine] = useState();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [hoveredItem, setHoveredItem] = useState(null);

  const convertDateString = (dateString) => {
    const inputFormat = "ddd, DD MMM YYYY HH:mm:ss [GMT]";
    const outputFormat = "YYYY-MM-DD HH:mm:ss";
    return moment(dateString, inputFormat).format(outputFormat);
  }

  const formatDateInCustomFormat = (epochTimestamp) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthsOfYear = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(epochTimestamp * 1000); // Convert to milliseconds
    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const dayOfMonth = String(date.getUTCDate()).padStart(2, '0');
    const month = monthsOfYear[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month} ${year} ${hours}:${minutes}:${seconds} [GMT]`;
    return formattedDate;
  }


  const defaultTimeStart = moment(convertDateString(formatDateInCustomFormat(absTime))).add(-10, "minute");
  const defaultTimeEnd = moment(convertDateString(formatDateInCustomFormat(absTime))).add(110, "minute");

  useEffect(() => {
    setTimeLine(moment(convertDateString(formatDateInCustomFormat((JSON.parse((sessionStorage.getItem("CurrentSimTime") / 1000)))))));
  }, [sessionStorage.getItem("CurrentSimTime")])

  useEffect(() => {
    MissionService.getGroundStations(scenarioId)
      .then((res) => {
        if (res?.data) {
          let index = 0;
          const groupsList = [];
          const groundItems = [];
          res?.data?.['groundStations']?.forEach((item) => {
            groupsList.push({ id: item?.id, title: item?.name });
            item?.passes?.forEach((items) => {
              const startTime = new Date(items?.[0] * 1000);
              const endTime = new Date(items?.[1] * 1000);
              groundItems.push({
                id: index,
                group: item?.id,
                typeBand: item?.band,
                type: item?.type,
                duration: items?.[2],
                start_time: moment().set({
                  year: startTime.getUTCFullYear(),
                  month: startTime.getUTCMonth(),
                  date: startTime.getUTCDate(),
                  hour: startTime.getUTCHours(),
                  minute: startTime.getUTCMinutes(),
                  second: startTime.getUTCSeconds()
                }),
                end_time: moment().set({
                  year: endTime.getUTCFullYear(),
                  month: endTime.getUTCMonth(),
                  date: endTime.getUTCDate(),
                  hour: endTime.getUTCHours(),
                  minute: endTime.getUTCMinutes(),
                  second: endTime.getUTCSeconds()
                }),
              });
              index++;
            })
          });
          setGroups(groupsList);
          setItems(groundItems);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [scenarioId]);

  const [isHovered, setIsHovered] = useState(false); // Track hover state



  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected
      ? itemContext.dragging
        ? "red"
        : item.selectedBgColor
      : item.bgColor;
    const borderColor = itemContext.resizing ? "red" : item.color;

    const handleMouseEnter = (e) => {
      setHoveredItem(item);
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseLeave = () => {
      setHoveredItem(null);
    };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor,
              color: item.color,
              borderColor,
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 4,
              borderLeftWidth: itemContext.selected ? 3 : 1,
              borderRightWidth: itemContext.selected ? 3 : 1,
              cursor: "default",
            },
            onMouseDown: () => {
            }
          })}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

          <div
            style={{
              height: itemContext.dimensions.height,
              // overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 3,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              backgroundColor: item?.duration > 5 ? "" : "#CCF54E",
              opacity: item?.duration > 5 ? "" : 0.2,
            }}
          >
            {item.duration > 5 && <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <div className='groundItems'>
                {/* <span> {item.type === "TT&C" ? <SvgIconTtc /> : <SvgIconLive />} </span> */}
                <span className='groundContactItem' style={{
                }}> {item.type?.length > 8 ? item.type.substring(0, 5) + ".." : item.type} </span>
              </div>
              <div className='groundContactSubItem' >{item.duration + " Mins"}</div>
            </div>}

          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}

        </div>

      </div>
    );
  };

  const groupRenderer = ({ group }) => {
    // Customize the appearance of the groups here
    return (
      <div className='groundContactGroup'>
        <div title={group.title}>{group?.title?.length > 7 ? group?.title?.substring(0, 7) + ".." : group?.title}</div>
      </div>
    );
  };

  return (
    <div className='w-100 h-100 position-relative' style={{ background: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(25px)', zIndex: '99', borderRadius: '8px' }}>
      {groups1?.length > 0 && items1?.length > 0 && (
        <Timeline
          groups={groups1}
          items={items1}
          defaultTimeStart={defaultTimeStart}
          itemRenderer={itemRenderer}
          defaultTimeEnd={defaultTimeEnd}
          lineHeight={100}
          groupRenderer={groupRenderer}
          stackItems
          fixedHeader="fixed"
          itemHeightRatio={0.75}
          showCursorLine
          canMove={false}
          canResize={false}
        >

          <TimelineHeaders className="sticky">
            <TimelineMarkers>
              <TodayMarker />
              <CustomMarker date={timeLine} interval={1000}>
                {({ styles }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: 'red',
                    width: '2px'
                  };
                  return <div style={customStyles} />;
                }}
              </CustomMarker>
              <CursorMarker />
            </TimelineMarkers>

            <SidebarHeader>
              {({ getRootProps }) => {
                return <div {...getRootProps()} style={{ margin: "auto" }}>Ground Contacts</div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>

        </Timeline>
      )}

      {hoveredItem && (
        <div className='position-absolute d-flex flex-column justify-content-center align-items-center'
          style={{
            top: cursorPosition.y - 50,
            left: cursorPosition.x,
            background: '#000000',
            zIndex: 1000,
            borderStyle: "solid",
            borderColor: '#CCF54E',
            borderWidth: 1,
            borderRadius: 4,
            padding: 10,
          }}>
          <span> {hoveredItem.type === "TT&C" ? <SvgIconTtc /> : <SvgIconLive />} {hoveredItem.type}</span>
          <span>{hoveredItem.duration} Mins.</span>
        </div>
      )}
    </div>
  );

};

export default TimelineComponent;
