
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog from './Dialog';
import { eraseMissionData, navigateTo } from './CommonFunctions';
const CloseButton = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [isShowModal, setIsShowModal] = useState(false);
    const params = useParams();
    const { mission_name, mission_id } = params

  return (
    <Fragment>
        <button
            className="d-flex align-items-center btn btn-outline-danger pt-1 pb-1 pe-5 ps-4 fs-14 "
            onClick={() => {
                setIsShowModal(true)
            }}
        >
            <i className="fe fe-x me-1 fs-16"></i>
            Close
        </button>
        <Dialog 
            onClick={(event) => {
                if (event) {
                    eraseMissionData(dispatch)
                    navigateTo(`${mission_name}/_details/${mission_id}/Satellites`, navigate)
                }
                setIsShowModal(false)
            }}
            openModel={isShowModal} comment= 'Are you sure you want to exit?'/>
    </Fragment>
  )
}

export default CloseButton