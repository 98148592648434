import { useEffect, useState } from "react";
import GroundStationService from "../../../service/GroundStationService";


const useGroundStationData = () => {

    const [all_ground_stations, set_all_ground_stations] = useState<any[]>([])

    const get_all_ground_stations = () => {
        GroundStationService.getGroundStationsByMissionId()?.then((res) => {
            if (res?.data) {
                set_all_ground_stations(res?.data)
            }
        }).catch((err) => {
            console.error(err);
        });
    }
    useEffect(() => {
        get_all_ground_stations()
    }, [])

    return { all_ground_stations }
}

export default useGroundStationData
