//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "../Modals.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import ModalCreationMessage from "../ModalCreationMessage";
import MissionModelingService from "../../MissionModelingService";
import DateTimePicker from "react-datetime-picker";

import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

const EclipseAnalysis = ({ props }) => {
  const params = useParams()
  const {
    mission_name,
    mission_id
  } = params;
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState("Day");
  const [loading, setLoading] = useState(false)

  const [eclipseItems, showEclipseItems] = useState({
    Planes: true,
    Analysis_Timeframe: true,
  });

  const [eclipsePlanes, setEclipsePlanes] = useState<any>([]);

  const [startTime, setStartTime] = useState(new Date());
  const [startTimeEpoch, setStartTimeEpoch] = useState(() => {
    const date = new Date();
    const utcEpoch = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    ) / 1000;
  
    return utcEpoch;
  });  

  const handleDateChange = (date) => {
    if (date) {
      // Convert selected date to UTC
      const utcDate = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      ));
  
      // Get UTC epoch value
      const utcEpoch = utcDate.getTime();
  
      // Set the UTC date in the state or perform any other action
      setStartTime(utcDate);
      setStartTimeEpoch(utcEpoch / 1000);
  
    } else {
      // Handle the case where date is null (remove date)
      setStartTime(null);
      setStartTimeEpoch(null);
    }
  };
  

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
      props.setBottomBarReport("Eclipse Analysis")
    }, 2000);
  };

  useEffect(() => {
    if(props.selectedComponent === 'Satellite' || props.selectedComponent === 'Plane' || props.selectedComponent === 'Spaceobject'){
      const currentEclipseObject = {
        objectId: props?.selectedComponentId,
        name: props?.selectedComponentName,
        objectType: props?.selectedComponent,
      };

      let alreadyHas = eclipsePlanes?.some(item => JSON.stringify(item?.['objectId']) === JSON.stringify(currentEclipseObject?.['objectId']))
      if(!alreadyHas){
        setEclipsePlanes([...eclipsePlanes, currentEclipseObject]);
      }
    }
  }, [props]);

  const postEclipseData = () => {
    setLoading(true)
    let spaceObjects:any = []
    eclipsePlanes?.map((item: any) => {
      spaceObjects.push({objectId: item?.['objectId'], objectType: item?.['objectType']})
    })

    let body = {
      spaceobjects: spaceObjects,
      startTime:startTimeEpoch,
      duration: duration,
    };

    MissionModelingService.postEclipseAnalysisData(body, mission_id)
      .then((res) => {
        showSuccessMessage("Eclipse Analysis successfull");
        props.setSideReportBarProps({ isSideReportBarVisible: true, isEclipseComponentUpdate: !props.isEclipseComponentUpdate});
        // props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
        setLoading(false)
        setisModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };

  const removeEclipsePlanes = (item) => {
    const updatedPlanes = eclipsePlanes?.filter((eclipsePlanes) => eclipsePlanes?.['objectId'] !== item?.['objectId']);
    setEclipsePlanes(updatedPlanes);
  };
  
  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal" : "mainModal"} id="eclipseAnalysis">
            <div className="modalHeader">
              <div className="modalHeaderImg">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                  <path
                    d="M8.94991 2.17536C8.06955 1.28045 6.90047 0.785889 5.64233 0.785889C4.74971 0.785889 3.88926 1.03797 3.15699 1.51316C1.80605 2.3638 1 3.82775 1 5.42789C1 5.91427 1.07431 6.39443 1.22151 6.85504C1.7696 8.5533 3.25494 9.78225 5.02118 10.0215C5.86385 10.8786 7.0347 11.4121 8.32875 11.4121C10.888 11.4121 12.9698 9.32964 12.9698 6.7706C12.9698 4.42258 11.2155 2.48077 8.94991 2.17536ZM1.74667 6.68652C1.61743 6.28137 1.55165 5.85721 1.55165 5.42789C1.55165 4.0185 2.2617 2.72912 3.45406 1.9775C4.09957 1.55884 4.85602 1.33734 5.64216 1.33734C6.53461 1.33734 7.37443 1.62124 8.07381 2.14176C5.63256 2.27527 3.68641 4.29743 3.68641 6.77042C3.68641 7.71866 3.97353 8.60059 4.46384 9.33657C3.1929 8.95311 2.16214 7.97412 1.74667 6.68652ZM8.32875 10.8604C6.07327 10.8604 4.23824 9.026 4.23824 6.7706C4.23824 4.5152 6.07327 2.68077 8.32875 2.68077C10.5837 2.68077 12.4182 4.5152 12.4182 6.7706C12.4182 9.026 10.5835 10.8604 8.32875 10.8604Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M3.62164 11.0863L2.19603 12.5115C2.0883 12.6192 2.0883 12.7938 2.19603 12.9015C2.2499 12.9554 2.32048 12.9824 2.39106 12.9824C2.46164 12.9824 2.53221 12.9554 2.58626 12.9015L4.01187 11.4763C4.1196 11.3688 4.1196 11.194 4.01187 11.0863C3.90413 10.9787 3.72955 10.9787 3.62164 11.0863Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M8.32865 12.8757C8.17629 12.8757 8.05273 12.9991 8.05273 13.1516V15.1667C8.05273 15.319 8.17611 15.4426 8.32865 15.4426C8.48118 15.4426 8.60456 15.3192 8.60456 15.1667V13.1516C8.60438 12.9991 8.481 12.8757 8.32865 12.8757Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M13.0353 11.0863C12.9276 10.9787 12.753 10.9787 12.6453 11.0863C12.5375 11.194 12.5375 11.3688 12.6453 11.4763L14.0703 12.9015C14.1244 12.9554 14.1948 12.9824 14.2655 12.9824C14.3359 12.9824 14.4067 12.9554 14.4606 12.9015C14.5683 12.7938 14.5683 12.6192 14.4606 12.5115L13.0353 11.0863Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M16.7239 6.49414H14.7088C14.5564 6.49414 14.4329 6.61734 14.4329 6.77004C14.4329 6.92239 14.5562 7.04595 14.7088 7.04595H16.7239C16.8763 7.04595 16.9998 6.92257 16.9998 6.77004C16.9998 6.61751 16.8764 6.49414 16.7239 6.49414Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M12.8401 2.53429C12.9107 2.53429 12.9813 2.50727 13.0353 2.45341L14.4604 1.0282C14.5681 0.920473 14.5681 0.745901 14.4604 0.638171C14.3528 0.530441 14.1781 0.530441 14.0703 0.638171L12.6453 2.0632C12.5375 2.17093 12.5375 2.3455 12.6453 2.45323C12.6989 2.50727 12.7695 2.53429 12.8401 2.53429Z"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="0.2"
                  />
                </svg>
              </div>
              <span>Eclipse Analysis</span>
              <button
                className="modalCloseBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                <img src={require("../../ImageSvgs/CloseButtonWhite.svg").default} />
              </button>
              <button
                className="modalCloseBtn modalMinimizeBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                <img src={require("../../ImageSvgs/MinimizeButtonWhite.svg").default} />
              </button>
            </div>

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showEclipseItems({
                      ...eclipseItems,
                      Planes: !eclipseItems.Planes,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={eclipseItems.Planes ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellites</div>
              </div>

              {eclipseItems.Planes && (
                <div className="modalrefOrbitDiv">
                  <div className="modalrefOrbitsScrollSpacer">
                    {eclipsePlanes?.map((item) => {
                      return (
                        <div key={item?.['objectId']} className="modalrefOrbitsBtn orbitsBtnSpacer">
                          <span>{item?.["name"]}</span>
                          <button
                            className="modalrefOrbitsCloseBtn"
                            onClick={() => {
                              removeEclipsePlanes(item);
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                              <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                              <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() => {
                    showEclipseItems({
                      ...eclipseItems,
                      Analysis_Timeframe: !eclipseItems.Analysis_Timeframe,
                    });
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={eclipseItems.Analysis_Timeframe ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Analysis timeframe</div>
              </div>

              {eclipseItems.Analysis_Timeframe && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Start Time</div>
                   
                  <DateTimePicker
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yy"
                      hourPlaceholder="hh"  // Use 'hh' for 12-hour format or 'HH' for 24-hour format
                      minutePlaceholder="mm"
                      secondPlaceholder="ss"
                      format={"y-MM-dd HH:mm:ss"}  // Use 'HH' for 24-hour format
                      onChange={handleDateChange}
                      value={startTime}
                      disableClock={true}
                      maxDetail={"second"}
                      locale="en-GB"
                    />
                  </div>

                  <div className="modalAnalysisDiv blockDisplay">
                    <div className="modalAnalysisText blockDisplay">Duration</div>

                    <select className="modalSelect blockDisplay eclipseAnalysis">
                      <option className="modalSelectOption" value="Cuboid" disabled>
                        Hours
                      </option>
                      <option className="modalSelectOption" value="Cuboid" disabled>
                        Minutes
                      </option>
                      <option className="modalSelectOption" selected value="Cube" >
                        Day
                      </option>
                    </select>
                    {/* <input
                      className="modalAnalysisInput blockDisplay"
                      type="number"
                      value={duration}
                      onChange={(event) => {
                        setDuration(event.target.value);
                      }}
                      placeholder={"#"}
                      style={{ width: "25px", marginRight: "8px" }}
                    /> */}
                  </div>
                </>
              )}
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  postEclipseData();
                  // props.setShowSidebarReportComponent("Orbital");
                }}
              >
                 {!loading ?"Analysis":"Analyzing..."}&nbsp;
                {loading && 
                 <CircularProgress size={10} className="text-white" />
                }
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EclipseAnalysis;
