import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";

const useUrlParams = () => {
    const [loading, setLoading] = useState<boolean>(true)

    const params = useParams();
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id, tab_name } = params

    useEffect(() => {
        if (mission_name && mission_id && sat_name && sat_id && truetwin_name && truetwin_id && dashboard && scenario_name && scenario_id && tab_name) {
            setLoading(false)
        }
    }, [mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id, tab_name])

    return {
        loading,
        mission_name,
        mission_id,
        sat_name,
        sat_id,
        truetwin_name,
        truetwin_id,
        dashboard,
        scenario_name,
        scenario_id,
        tab_name
    }
}
export default useUrlParams