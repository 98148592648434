//@ts-nocheck
import { FC, Fragment, useEffect, useState } from 'react'
import * as Cesium from "cesium";

interface GsVisualizationProps {
    ground_stations: any[];
    selected_stations: any[]
    selectedEntity: any;
    selectFromList: boolean;
    setSelectedEntity: (data) => void
}

const GsVisualization: FC<GsVisualizationProps> = ({ ground_stations, selected_stations, selectedEntity, selectFromList, setSelectedEntity }) => {

    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);
    const [hoveredGs, setHoveredGs] = useState<any>(null)

    const resetPosition = (viewer: Cesium.Viewer | null, position: number) => {
        if (viewer) {
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(0, 0, position)
            });
        }
    }

    let imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'satellite-v9',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN
    });

    const getGroundStationPackets = () => {
        let gsPacketList: any[] = [];
        gsPacketList.push({
            "clock": {
                "multiplier": 1,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "Ground Stations",
            "version": "1.0"
        })
        ground_stations?.map((station) => {
            gsPacketList.push(
                {
                    "billboard": {
                        "horizontalOrigin": "CENTER",
                        "image": selected_stations?.includes(station?.groundStationId) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg',
                        "pixelOffset": {
                            "cartesian2": [
                                0,
                                0
                            ]
                        },
                        "show": true,
                        "verticalOrigin": "CENTER"
                    },
                    "label": {
                        "fillColor": {
                            "rgba": [
                                255,
                                255,
                                255,
                                255
                            ]
                        },
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",
                        "outlineColor": {
                            "rgba": [
                                0,
                                0,
                                0,
                                255
                            ]
                        },
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                15,
                                0
                            ]
                        },
                        "show": true,
                        "style": "FILL_AND_OUTLINE",
                        "verticalOrigin": "CENTER",
                        "text": selected_stations?.includes(station?.groundStationId) ? station?.groundStationName : '',
                    },
                    "name": station?.groundStationName,
                    "position": {
                        "cartographicDegrees": [
                            station?.properties?.longitude,
                            station?.properties.latitude,
                            station?.properties?.altitude ? Number(station?.properties?.altitude) : 0
                        ]
                    },
                    "id": station?.groundStationId
                }
            )
        })
        return gsPacketList
    }

    useEffect(() => {
        if (viewer === null) {
            let cesium_viewer = new Cesium.Viewer("scenariocesiumContainer", {
                requestRenderMode: true,
                shouldAnimate: true,
                animation: false, // Disable the default animation widget
                baseLayerPicker: false, // Disable the default base layer picker
                timeline: false, // Disable the timeline
                geocoder: false, // Disable the geocoder
                homeButton: false, // Disable the home button
                navigationHelpButton: false, // Disable the navigation help button
                sceneModePicker: false, // Disable the scene mode picker
                selectionIndicator: false, // Disable the selection indicator
                fullscreenButton: false,
                imageryProvider: imaginary,
            });

            if (imaginary) {
                cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
            }

            cesium_viewer.scene.morphTo2D(0);
            cesium_viewer.scene.globe.showGroundAtmosphere = true;
            cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
            cesium_viewer.clock.shouldAnimate = true;
            cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
            cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
            cesium_viewer.scene.highDynamicRange = false;

            resetPosition(cesium_viewer, 39000000);
            setViewer(cesium_viewer);
        }

        return () => {
            if (viewer) {
                try {
                    viewer.dataSources.removeAll();
                } catch (error) {
                    console.log(error);
                }
            }
        };
    }, [viewer]);

    useEffect(() => {
        if (viewer && ground_stations?.length > 0) {
            viewer.dataSources.removeAll()
            const dataSource = new Cesium.CzmlDataSource();
            viewer.dataSources.add(dataSource.load(
                [...getGroundStationPackets()]
            ));

            const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

            handler.setInputAction((movement) => {
                const pickedObject = viewer.scene.pick(movement.endPosition);
                if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                    document.body.style.cursor = 'pointer';
                    let entity = pickedObject.id;
                    viewer.selectedEntity = entity;
                    setHoveredGs(entity && entity?._id)
                } else {
                    setHoveredGs(null)
                    document.body.style.cursor = 'auto';
                }

            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            return () => {
                handler.destroy();
            };
        }
    }, [viewer, ground_stations, selected_stations])

    useEffect(() => {
        if (viewer && selectedEntity && Cesium.defined(selectedEntity) && selectFromList) {
            const dataSource = viewer.dataSources.get(0); // Assuming there is at least one data source
            const entity = dataSource?.entities.getById(selectedEntity);
            if (entity) {
                viewer.flyTo(entity, {
                    duration: 1.5,
                    offset: new Cesium.HeadingPitchRange(
                        Cesium.Math.toRadians(0),
                        Cesium.Math.toRadians(-45),
                        3500000
                    )
                });
            }
        } else {
            resetPosition(viewer, 39000000);
            // setSelectedEntity(null);
        }
    }, [viewer, selectedEntity, selectFromList]);


    return (
        <Fragment>
            {hoveredGs && viewer && ground_stations?.map(station => {
                var latitude = Number(station?.properties?.latitude);
                var longitude = Number(station?.properties?.longitude);

                var cartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
                var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
                const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
                    viewer.scene,
                    cartesianPosition
                );

                let outside_window_x = (popupPosition.x + 610) > window.innerWidth;
                const top_position = station?.['properties']?.['freqbands']?.length === 3 ? 200 : station?.['properties']?.['freqbands']?.length === 2 ? 165 : 140
                let outside_window_y = popupPosition.y - top_position < 0;
                let top = outside_window_y ? `${((popupPosition.y - top_position) - (popupPosition.y - top_position))}px` : `${popupPosition.y - top_position}px`;
                let left = outside_window_x ?
                    outside_window_y ? `${popupPosition.x - 620}px` : `${(popupPosition.x + window.innerWidth) - (popupPosition.x + 610)}px` :
                    outside_window_y ? `${popupPosition.x + 20}px` : `${popupPosition.x + 10}px`;

                return (
                    <div className="popup-modal"
                        style={{
                            display: 'flex',
                            cursor: 'default',
                            left: left,
                            top: top,
                            transition: 'all 1s ease',
                            scale: station?.['groundStationId'] === hoveredGs ? '1' : '0',
                            opacity: station?.['groundStationId'] === hoveredGs ? '1' : '0',
                        }}
                    >
                        <div className='py-2 px-4 d-flex align-items-center w-100 justify-content-between'>
                            <div className='d-flex align-items-start flex-column w-55'>
                                <div className='d-flex align-items-center w-98'>
                                    <span className='fs-14 w-auto color-atmos text-truncate' title={station?.['groundStationName']}>
                                        {station?.['groundStationName']}
                                    </span>
                                    {(station?.['properties']?.['country'] && station?.['properties']?.['country'] !== '') && <span className='fs-14 w-auto text-truncate' title={station?.['properties']?.['country']}>
                                        {`, ${station?.['properties']?.['country']}`}
                                    </span>}
                                </div>
                                <span className='fs-12 color_history text-truncate'
                                    title={(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ? station?.['properties']?.['provider'] : 'Standalone'}>
                                    {(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ?
                                        station?.['properties']?.['provider']
                                        :
                                        'Standalone'
                                    }
                                </span>
                            </div>
                            <div className='d-flex align-items-center gap-2 fs-12'>
                                <div className='d-flex gap-1 align-items-center'>
                                    <span className='color_history'>Lat:</span>
                                    <span className='fs-13'>{station?.properties?.latitude}</span>
                                </div>
                                <div style={{ width: '0.5px', height: '20px', background: 'rgba(240,240,240,0.20)' }} />
                                <div className='d-flex gap-1 align-items-center'>
                                    <span className='color_history'>Lon:</span>
                                    <span className='fs-13'>{station?.properties?.longitude}</span>
                                </div>
                            </div>
                        </div>
                        <div className='modal_devider' />
                        <div className='w-100 d-flex align-items-center flex-column gap-2 px-4 py-3'>
                            <div className='d-flex align-items-center justify-content-between w-100'>
                                <span className='color_history fs-13 w-10'>Band</span>
                                <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Rx Freq.</span>
                                <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Tx Freq.</span>
                                <span className='color_history fs-13 w-15 align-items-center justify-content-center d-flex'>G/T (dB/K)</span>
                                <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>EIRP (dBW)</span>
                                <span className='color_history fs-13 w-15 align-items-center justify-content-start d-flex'>Polarization</span>
                            </div>
                            {station?.['properties']?.['freqbands']?.map((band: any, index) => {
                                return (
                                    <div className='d-flex align-items-center justify-content-between w-100' key={index}>
                                        <span className='color_history fs-12 w-10'>{band?.split('-')?.[0]}</span>
                                        <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>
                                            {station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['rxFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                        </span>
                                        <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>
                                            {station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['txFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                        </span>
                                        <span className='fs-12 w-15 align-items-center justify-content-center d-flex'>{station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] ? station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_GT`] : '-'}</span>
                                        <span className='fs-12 w-20 align-items-center justify-content-center d-flex'>{station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`] ? station?.['properties']?.[`${band === 'UHF' ? 'U' : band.split('-')[0]}_EIRP`] : '-'}</span>
                                        <span className='fs-12 w-15 align-items-center justify-content-start text-truncate' title={station?.['properties']?.[`polarization`]}>
                                            {station?.['properties']?.[`polarization`] ?
                                                station?.['properties']?.[`polarization`]
                                                : '-'
                                            }
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            <div id="scenariocesiumContainer" className="d-flex h-100 w-100" onClick={() => {
                setSelectedEntity(null)
                setHoveredGs(null)
            }} />
        </Fragment>
    );
}




export default GsVisualization
