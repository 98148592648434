import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import { v4 as uuidv4 } from "uuid";

import CommandCenterService from '../../../../../../service/CommandCenterService';
import useUrlParams from '../../../../hooks/useUrlParams';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import useScenario from '../../../../hooks/useScenario';
import './Console.scss'
import { SpinLoaderSvg } from '../Svgs';
import { getEnvVar } from '../../../../../../envUtils';
import { get_formated_time } from '../../../../utils';


const fetchEntries = async (sat_id, truetwin_id, scenario_id, cursor) => {
    let response_data = {
        data: [],
        cursor: '',
    };
    try {
        const response = await CommandCenterService.getAllCommandDetails(
            sat_id,
            truetwin_id,
            scenario_id,
            cursor,
            50
        );
        // Extract cursorId from the first entry in the response
        const cursorId = response.data?.[0]?.['cursorId'] || '';
        response_data = {
            data: response.data,
            cursor: cursorId,
        };
    } catch (error) {
        console.log(error);
    }

    return response_data;
};

function ConsoleOuputList({ height, latestDatacount, getCommandDetails, show_selected_output_details, selectedRow }) {
    const { sat_id, truetwin_id, scenario_id } = useUrlParams(); // Get parameters from the custom hook
    const { scenario } = useScenario()

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState(''); // Start with an empty cursor
    const listRef = useRef(null);
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);


    const truncate_rows = (index) => {
        const commandIndexs = selectedRow.commandIndexs
        if (commandIndexs.length > 1) {
            if (index === commandIndexs[0] || index === commandIndexs[commandIndexs.length - 1]) {
                return `${commandIndexs[0] === index ? 'request' : 'response'}`
            } else if (index > commandIndexs[0] && index < commandIndexs[commandIndexs.length - 1]) {
                return 'truncate__row'
            }
        }
        return ''
    }
    const loadMoreEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id) return; // Ensure parameters are available

        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, cursor, 50);
        // console.log('Entries being added:', data);

        setEntries(prevEntries => [...prevEntries, ...data]);
        setCursor(newCursor);  // Update the cursor with the new one from the API
        setLoading(false);
    };

    const loadLatestEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id) return; // Ensure parameters are available

        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, '', 50); // Reset the cursor to fetch the latest
        // console.log('Latest Entries:', data);

        setEntries(data);  // Replace the current entries with the latest ones
        setCursor(newCursor);  // Update the cursor with the new one from the API
        if (listRef.current) {
            listRef.current.scrollTo(0, 0); // Scroll to top
        }
        setLoading(false);
        setMessages([])
    };

    useEffect(() => {
        loadMoreEntries(); // Load initial entries
    }, []);

    useEffect(() => {
        const clearFormFields = () => {
            loadLatestEntries();
        };

        window.addEventListener('loadCurrentData', clearFormFields);
        return () => {
            window.removeEventListener('loadCurrentData', clearFormFields);
        };
    }, []);

    const handleScroll = useCallback(({ scrollOffset, scrollDirection }) => {
        if (scrollDirection === 'forward' && !loading) {
            const listElement = listRef.current;
            if (listElement) {
                const bottomReached =
                    scrollOffset + listElement.props.height >=
                    listElement.props.itemSize * entries.length;

                if (bottomReached) {
                    loadMoreEntries();
                }
            }
        }
    }, [entries, loading]);

    //web socket start//
    const create_socket = (simulationId, sessionId) => {
        try {
            return new WebSocket(`${getEnvVar('REACT_APP_WEBSOCKET_BACKEND_BASE_URL')}groundstation/websocketstatus?truetwinId=${truetwin_id}&sessionId=${sessionId}`);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        const token = sessionStorage.getItem("authentication");
        if (scenario?.['simulationId'] && token) {
            const sessionId = uuidv4();
            let ws = create_socket(scenario?.['simulationId'], sessionId)
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'authorization', token: `${token}` }));
                console.log('WebSocket Connected');
            };
            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                // const totalCount =
                setMessages((prevMessages) => [...prevMessages, message]);
            };

            ws.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
                let ws = create_socket(scenario?.['simulationId'], sessionId)
            };

            setSocket(ws);
            return () => {
                ws.close();
            };
        }
    }, [scenario]);
    //web socket end//


    const Row = useCallback(({ index, style }) => {
        const entry = entries[index];
        const commandIndexs = selectedRow.commandIndexs;
        const isSelected = selectedRow.selectedRow === index;
        const isHidden =
            commandIndexs.length > 1 &&
            index > commandIndexs[0] + 4 &&
            index < commandIndexs[commandIndexs.length - 1];

        const rowClass = `table__body__row ${isHidden ? 'd-none' : 'd-flex'}`;
        const buttonClass = `table__body__row__item ${truncate_rows(index)} ${isSelected ? 'selected' : ''}`;
        return (
            <div key={entry?.id} style={style}>
                <div className={rowClass}>
                    <button

                        onClick={() => {
                            getCommandDetails(entry);
                            show_selected_output_details(entry, entries);
                        }}
                        className={buttonClass}
                    >
                        {/* <div className='d-flex align-items-center justify-content-start' style={{ width: '15%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandUid} placement='top'>
                                    {entry?.CommandUid}
                                </CustomToolTip>
                            </span>
                        </div> */}
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '8%' }}>
                            <span className='truncate__text uppercase'>
                                <CustomToolTip title={entry?.type} placement='top'>
                                    {entry?.type}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '14%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={get_formated_time(entry?.CommandDetails?.timestamp)} placement='top'>
                                    {get_formated_time(entry?.CommandDetails?.timestamp)}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start position-relative' style={{ width: '7%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.saId} placement='top'>
                                    {entry?.CommandDetails?.saId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '9%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.daId} placement='top'>
                                    {entry?.CommandDetails?.daId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '8%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.frameLength} placement='top'>
                                    {entry?.CommandDetails?.frameLength}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '8%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={entry?.CommandDetails?.tctmId} placement='top'>
                                    {entry?.CommandDetails?.tctmId}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '16%' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title='' placement='top'>
                                    --
                                </CustomToolTip>
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }, [entries, selectedRow]);

    return (
        <div>
            {messages?.length > 0 &&
                <button className='button__load__more'
                    onClick={loadLatestEntries} disabled={loading}>
                    <SpinLoaderSvg className={loading ? 'spin__icon' : ''} />
                    <div className='button__load__more__tooltip'>Load latest entries</div>
                </button>
            }
            <List
                ref={listRef}
                height={height}
                itemCount={entries?.length}
                itemSize={35} // Adjusted height for posts
                width={'100%'}
                onScroll={handleScroll}
            >
                {Row}
            </List>
        </div>
    );
}

export default ConsoleOuputList;
