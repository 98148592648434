// @ts-nocheck
import { FC, Fragment, useEffect, useRef } from "react";
import Draggable from "react-draggable";

interface ModalProps {
    open: boolean;
    pinned: boolean;
    disabled: boolean;
    children: JSX.Element
    class_name?: string
}
export type DraggableEvent = React.MouseEvent<HTMLElement | SVGElement>
    | React.TouchEvent<HTMLElement | SVGElement>
    | MouseEvent
    | TouchEvent


export interface DraggableData {
    node: HTMLElement,
    x: number, y: number,
    deltaX: number, deltaY: number,
    lastX: number, lastY: number
}
const Modal: FC<ModalProps> = ({ open, children, class_name, pinned, disabled }) => {
    const draggingRef = useRef<boolean>(false);
    const positionRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 });

    const onDrag = (event: DraggableEvent, data: DraggableData) => {
        positionRef.current = { x: data.lastX, y: data.lastY };
        document.body.style.userSelect = 'none'
        draggingRef.current = true;
    };

    const onStop = () => {
        draggingRef.current = false;
        document.body.style.userSelect = 'auto'
    };

    useEffect(() => {
        let position = { x: 0, y: 0 }
        positionRef.current = position
    }, [open])

    return (
        <Fragment>
            {open && <Draggable position={positionRef.current} onStop={onStop} onDrag={onDrag} disabled={disabled}>
                <div className={`modal__container__wrapper ${open && 'active'} ${class_name}`}>
                    {children}
                </div>
            </Draggable>}
        </Fragment>
    )
}

export default Modal