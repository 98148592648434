
import { Fragment, useEffect, useState } from 'react';
import './CesiumContainer.css'
import * as Cesium from "cesium";
import useGroundStationData from '../../../../hooks/useGroundStationData';
import GroundStationCard from '../../../../custom_components/GroundStationCard';
import GroundTargetCard from '../../../../custom_components/GroundTargetCard'
import useCesium from '../../../../hooks/useCesium';

const CesiumContainer = () => {
    const { cesiumContainerRef, map_viewer, groundTargets } = useCesium()
    const [selectedStation, setSelectedStation] = useState<any | null>(null)
    const [selectedTarget, setSelectedTarget] = useState<any | null>(null)
    const { all_ground_stations } = useGroundStationData()

    const getGroundStationById = (ground_stations: any[], grounStationId: string) => {
        const hoveredGsId = ground_stations.find(station => station.groundStationId === grounStationId)
        return hoveredGsId;
    }

    useEffect(() => {
        if (!map_viewer || !all_ground_stations || !groundTargets) {
            return; // Exit early if map_viewer or all_ground_stations are not ready
        }
        const handler = new Cesium.ScreenSpaceEventHandler(map_viewer.scene.canvas);
        handler.setInputAction((movement: { endPosition: Cesium.Cartesian2; }) => {
            if (!map_viewer || !map_viewer.scene) return;
            const pickedObject = map_viewer.scene.pick(movement.endPosition);
            if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                let entity = pickedObject.id;
                all_ground_stations?.map((station) => {
                    if (entity?._id === station.groundStationId) {
                        const station = getGroundStationById(all_ground_stations, entity?._id);
                        setSelectedStation(station)
                    }
                })
                groundTargets?.map((target) => {
                    if (entity?._id === target?.groundTargetId) {
                        setSelectedTarget(target)
                    }
                })

                map_viewer.selectedEntity = entity;
            } else {
                setSelectedTarget(null)
                setSelectedStation(null)
                document.body.style.cursor = 'auto';
            }

        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        return () => {
            handler.destroy();
        };
    }, [map_viewer, all_ground_stations, groundTargets])



    return (
        <Fragment>
            <GroundStationCard station={selectedStation} viewer={map_viewer} />
            <GroundTargetCard target={selectedTarget} viewer={map_viewer} />
            <div ref={cesiumContainerRef} className="position-relative h-100 w-100" />
        </Fragment>
    )
}

export default CesiumContainer