import React, { useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import ModalDropdown from "../ModalComponents/ModalDropdown";
import ModalHeader from "../ModalComponents/ModalHeader";
import ModalFooter from "../ModalComponents/ModalFooter";
import InnerTableModal from "../ModalComponents/InnerTableModal";

const ThermalAnalysisModal = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const tableProps = { isModalVisible, setisModalVisible };
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [previousOption, setPreviousOption] = useState("");

  const [powerHiFiComponents, showPowerHiFiComponents] = useState({
    Analysis_Duration: true,
    External_Parameters: true,
    Satellite_Physical_Specs: true,
  });

  const satShapeOptions = [
    { label: "Cube", value: "Cube" },
    { label: "Cuboid", value: "Cuboid" },
  ];

  const [powerAnalysisSats, showPowerAnalysisSats] = useState({
    Mission_Sat_01: true,
  });

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  /* These are the values required for showing the table */

  const headerElements = [
    {
      title: "Face",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Surf Area",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
    {
      title: "Emissivity",
      image: require("../../ImageSvgs/ADC.svg").default,
    },
    {
      title: "Absorptivity",
      image: require("../../ImageSvgs/Navigation.svg").default,
    },
  ];

  const header = {
    title: "Thermal Analysis",
    img: require("../../ImageSvgs/Thermal-Analysis.svg").default,
  };

  let [surfaceAreaPlaceHolder, setSurfaceAreaPlaceHolder] = useState("200 Sq. cm");
  let [emissivityPlaceHolder, setEmissivityPlaceHolder] = useState("12.4 W/m.");
  let [absorptivityPlaceHolder, setAbsorptivityPlaceHolder] = useState("12.4 lt/mole cm.");

  const handleSelectedOption = (selectedOption) => {
    console.log("Selected Option => ", selectedOption);
  };

  const rowElements = [
    <input className="tableInputElement" value="" placeholder="Axis" />,
    <input className="tableInputElement" value={surfaceAreaPlaceHolder} onChange={(event) => setSurfaceAreaPlaceHolder(event.target.value)} placeholder="in sq. meters" />,
    <input className="tableInputElement" value={emissivityPlaceHolder} onChange={(event) => setEmissivityPlaceHolder(event.target.value)} placeholder="in %" />,
    <input className="tableInputElement" value={absorptivityPlaceHolder} onChange={(event) => setAbsorptivityPlaceHolder(event.target.value)} placeholder="in metres" />,
  ];

  /* End of values for the table */

  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}

      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal largerMainModal" : "mainModal largerMainModal"} id="thermalAnalysis">
            <ModalHeader props={props} parameterProps={parameterProps} header={header} />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <div className="modalCheckboxText modalGreenSubHeader ">Satellites</div>
              </div>

              <div className="modalrefOrbitDiv">
                {powerAnalysisSats.Mission_Sat_01 && (
                  <div className="modalrefOrbitsBtn">
                    <span>&lt;Mission_Sat_01&gt;</span>
                    <button
                      className="modalrefOrbitsCloseBtn"
                      onClick={() =>
                        showPowerAnalysisSats({
                          ...powerAnalysisSats,
                          Mission_Sat_01: false,
                        })
                      }
                    >
                      <img src={require("../../ImageSvgs/CloseButton.svg").default} />
                    </button>
                  </div>
                )}
              </div>

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showPowerHiFiComponents({
                      ...powerHiFiComponents,
                      Analysis_Duration: !powerHiFiComponents.Analysis_Duration,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={powerHiFiComponents.Analysis_Duration ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Analysis Duration</div>
              </div>

              {powerHiFiComponents.Analysis_Duration && (
                <div className="modalDiv">
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">Start Time</div>
                    <input className="bigModalHalfDivInput" style={{ width: "130px" }} placeholder="UTC"></input>
                  </div>
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">Total Duration</div>
                    <input className="bigModalHalfDivInput" placeholder="in hours"></input>
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showPowerHiFiComponents({
                      ...powerHiFiComponents,
                      External_Parameters: !powerHiFiComponents.External_Parameters,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={powerHiFiComponents.External_Parameters ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">External Parameters</div>
              </div>

              {powerHiFiComponents.External_Parameters && (
                <div className="modalDiv">
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">Solar irradiance</div>
                    <input className="bigModalHalfDivInput" placeholder="W/sq. m"></input>
                  </div>
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">Albedo</div>
                    <input className="bigModalHalfDivInput" placeholder="#"></input>
                  </div>
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">OBC</div>
                    <input className="bigModalHalfDivInput" placeholder="in UTC"></input>
                  </div>
                  <div className="bigModalHalfDiv">
                    <div className="bigModalHalfDivLabel">Internal Temperature</div>
                    <input className="bigModalHalfDivInput" placeholder="in deg C"></input>
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showPowerHiFiComponents({
                      ...powerHiFiComponents,
                      Satellite_Physical_Specs: !powerHiFiComponents.Satellite_Physical_Specs,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={powerHiFiComponents.Satellite_Physical_Specs ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellite Physical Specs</div>
              </div>

              {powerHiFiComponents.Satellite_Physical_Specs && (
                <>
                  <div className="modalDiv">
                    <div className="bigModalHalfDiv">
                      <div className="bigModalHalfDivLabel">Wall Thickness</div>
                      <input className="bigModalHalfDivInput" placeholder="in mm"></input>
                    </div>
                    <div className="bigModalHalfDiv">
                      <div className="bigModalHalfDivLabel">Satellite Shape</div>
                      <select className="modalSelect">
                        <option className="modalSelectOption" selected value="Cube">
                          Cube
                        </option>
                        <option className="modalSelectOption" value="Cuboid">
                          Cuboid
                        </option>
                      </select>
                    </div>
                  </div>
                  <InnerTableModal
                    props={props}
                    parameterProps={parameterProps}
                    tableBarValues={[]}
                    tableBarItemProps={[]}
                    headerElements={headerElements}
                    numOfRows={6}
                    rowElements={rowElements}
                    tableHeader="Power Source"
                  />
                </>
              )}
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("Thermal Analysis successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Power");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ThermalAnalysisModal;
