
interface ModalHeaderProps {
  close: () => void;
  toggle_body: () => void;
}

const AdditionalOrbitModalHeader: React.FC<ModalHeaderProps> = ({ close, toggle_body }) => {
  const header = {
    title: "Additional Orbital Details (Optional)",
    img: require("../../Images/ScenarioCreationIcon.svg").default,
  };

  return (
    <div className="modalHeader">
      <div className="modalHeaderImg">
        <img
          src={header.img}
          className="active-img"
        />
      </div>
      <span>{header.title}</span>
      <button
        className="modalCloseBtn"
        onClick={close}
      >
        <img src={require("../../Images/CloseButtonWhite.svg").default} />
      </button>
      <button
        className="modalCloseBtn modalMinimizeBtn"
        onClick={toggle_body}
      >
        <img src={require("../../Images/MinimizeButtonWhite.svg").default} />
      </button>
    </div>
  )
}

export default AdditionalOrbitModalHeader

