import React from "react";
import "./CommsTele.css";

function CommsCount({
  selectedCOMMSSubButton,
  configStep,
  commsCount,
  panels,
}) {
  const type = selectedCOMMSSubButton === "UPLINK" ? "UPLink" : "DownLink";



  return (
<>
  {selectedCOMMSSubButton !== "SYSTEM" && configStep === "Comms" && (
    <div className="row no-gutters m-auto">
      {Object.keys(commsCount)?.length>0 &&
        Object.keys(commsCount["UHFCounts"] || {})
          .filter(
            (key) =>
              ![
                "CommandCountPer",
                "RxMode",
                "PacketReceivedCountPer",
                "PacketRejectedChksumPer",
              ].includes(key)
          )
          .map((key) => {
            const value = commsCount["UHFCounts"][key];
            return (
              <div key={key} className="col-4 mb-1">
                <div className="commsCountWrapper">
                  <div className="commsCountValue col-9">
                    {value}
                    <br />
                    <div className="commsCountKey">{key==="PacketReceivedCount"?"Packet Received":key==="CommandCount"?"Command Count":"Packets Rejected"}</div>
                  </div>
                  {/* <div className="text-end col-3" style={{ padding: "5px" }}>
                    %
                  </div> */}
                </div>
              </div>
            );
          })}
    </div>
  )}
</>

  );
}

export default CommsCount;
