import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const DeorbitAnalysisGraph = ({ data }) => {
  console.log("data in graph", data);

  const chartRef = useRef(null);

  // Prepare series data
  const seriesData = data.height?.map((height, index) => ({
    x: data.time[index],
    y: height,
  }));

  // Calculate the midpoint timestamp
  const midpointIndex = Math.floor(data.time.length / 2);
  const midpointTimestamp = data.time[midpointIndex];

  const initialZoom = {
    type: 'x',
    enabled: true,
    autoScaleYaxis: true,
    xaxis: {
      min: midpointTimestamp - (midpointTimestamp - data.time[0]) / 4,
      max: data.time[midpointIndex],
    },
  };

  const options = {
    chart: {
      type: 'area',
      height: 400,
      toolbar: {
        show: true,
      },
      events: {
        zoomed: handleZoom,
      },
      background: '#23272A', // Dark background color
    },
    xaxis: {
      type: 'numeric',
      categories: data.time.map((time) => {
        const date = new Date(time);
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        });
      }),
      title: {
        text: 'Time',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif',
          color: '#FFFFFF', // White text color
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          colors: '#B0BEC5', // Light text color
        },
      },
      initialZoom: initialZoom,
      categories: [data.time[0], data.time[Math.floor(data.time.length / 2)], data.time[data.time.length - 1]],
      tickAmount: 3,
    },
    yaxis: {
      show: false,
      title: {
        text: 'Altitude (km)',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif',
          color: '#FFFFFF', // White text color
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          colors: '#B0BEC5', // Light text color
        },
      },
      tickAmount: 2,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#9f7de1'], // Green line color
    },
    fill: {
      colors: ['#9f7de1'], // Green area fill color
      opacity: 0.8,
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: (value) => value.toFixed(2) + ' km',
        style: {
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
        },
      },
    },
  };

  function handleZoom(event, chartContext, config) {
    // You can handle the zoom event here if needed
    console.log('Zoom event:', event);
  }

  return (
    <Chart
      options={options}
      series={[{ data: seriesData }]}
      type="area"
      height={400}
      className="mt-3"
      ref={chartRef}
    />
  );
};

export default DeorbitAnalysisGraph;
