import React from 'react'
import ScenarioTimeline from './ScenarioTimeline';
import ScenarioOrbitNew from './ScenarioOrbitNew.js';
import { useSearchParams } from 'react-router-dom';

interface VisualizationWrapperProps {
  active_schedule_modal: boolean;
  scenario_start_time: any;
  scenario_duration: any;
  simulation_start_time: any;
  simulation_end_time: any;
  scenario_step?: string;
  open_schedule_modal: () => void;
  schedule_data: any[];
  duration: any;
  set_simulation_time: (data: any) => void;
  scenario_response_data?: object;
  set_loading: (data: boolean) => void;
  chooseGs: (gs: any) => void;
  all_ground_stations: any[];
  selectedSimulationTask: any;
  scenarioData: any;
  setCurrentGlobeType: (data: string) => void;
  setSelectedGS: (data: any) => void;
  groundStationCheck: any;
}

const VisualizationWrapper: React.FC<VisualizationWrapperProps> = ({
  scenario_step, open_schedule_modal, scenario_duration, scenario_start_time, schedule_data,
  simulation_start_time, simulation_end_time, duration, scenario_response_data, set_simulation_time,
  set_loading, chooseGs, all_ground_stations, selectedSimulationTask, scenarioData, active_schedule_modal,
  setCurrentGlobeType, setSelectedGS, groundStationCheck }) => {

  const [searchParams] = useSearchParams();
  const scenario_scheduled = searchParams.get('scheduled');

  const OrbitContainer: React.CSSProperties = {
    height: (scenario_scheduled && scenario_step === 'Schedule') ? 'calc(100% - 200px)' : '100%',
    width: '100%',
    display: 'flex',
  }

  const TimelineContainer: React.CSSProperties = {
    height: (scenario_scheduled && scenario_step === 'Schedule') ? '200px' : '0px',
    width: '100%',
    display: (scenario_scheduled && scenario_step === 'Schedule') ? 'flex' : 'none',
    position: 'absolute',
    bottom: 0,
    transition: 'all 0.3s ease-in-out',
  }

  return (
    <div className='d-flex w-100 h-100 flex-column'>
      <div style={OrbitContainer}>
        <ScenarioOrbitNew
          set_loading={set_loading} //loader
          chooseGs={chooseGs} // selected Gs from Modal
          all_ground_stations={all_ground_stations} // All gs from mission
          selectedSimulationTask={selectedSimulationTask} // selected Task from TAsk Dropdown
          scenarioData={scenarioData}
          scenario_response_data={scenario_response_data}
          setCurrentGlobeType={setCurrentGlobeType}
          setSelectedGS={setSelectedGS}
          groundStationCheck={groundStationCheck}
        />
      </div>
      <div style={TimelineContainer}>
        <ScenarioTimeline
          active_schedule_modal={active_schedule_modal}
          scenario_duration={scenario_duration}
          scenario_start_time={scenario_start_time}
          simulation_start_time={simulation_start_time}
          simulation_end_time={simulation_end_time}
          open_schedule_modal={open_schedule_modal}
          schedule_data={schedule_data}
          duration={duration}
          set_simulation_time={set_simulation_time}
        />
      </div>
    </div>
  )
}


export default VisualizationWrapper
