import React from 'react';
import axios from './Api'
import { v4 as uuidv4 } from "uuid";

    const defaultHeaders = {
        "Content-Type" : "*/*;application/json",
        //"Accept" : "*/*;application/json",
        "X-Request-ID": uuidv4()
    };

// export const BASE_APP_URL = `${window.location.protocol}//${window.location.hostname}:21000/api/`;
const gBackendHasBasicAuth = true

if (gBackendHasBasicAuth) {
    //let username = "saasuser";
    //let passwd = "spaceBar432!";
    //let credentials = btoa(username + ":" + passwd);
    //defaultHeaders["Authorization"] = "Basic " + credentials;

    let credentials = sessionStorage.getItem("authentication");
    defaultHeaders["Authorization"] = `Bearer ${credentials}`;
}

export default class ApiService extends React.Component {

    static async get(url, headers, data){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        headers = Object.assign({},defaultHeaders, headers);
        return await axios({
            method : "GET",
            url : url,
            headers : headers,
            data : data,
            timeout: 300000
        })

    }

    static async fetch(url, headers){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        headers = Object.assign({},defaultHeaders, headers);

        return await axios({
            method : "GET",
            url : url,
            headers : headers,
            timeout: 300000,
            // withCredentials : withCredentialsFlag
        });

    }

    static async post(url, body, headers){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        headers = Object.assign({},defaultHeaders, headers);

        return await axios({
            method : "POST",
            url : url,
            data : JSON.stringify(body),
            headers : headers,
            timeout: 300000,
        });
    }

    static async hi(url, body, headers){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        headers = Object.assign({},defaultHeaders, headers);

        
        return await axios({
            method : "POST",
            url : url,
            data : body,
            headers : headers,
            timeout: 300000,
        });
    }

    static async Login(url, body){

        let headers = {
            "Content-type":"application/x-www-form-urlencoded"
        }
        try {

            return await axios({
                method : "POST",
                url : url,
                data : body,
                headers : headers,
				timeout: 300000,
            });
        } catch (err){
            console.log(err);
        }
    }

    static async put(url, body){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        let headers = Object.assign({}, defaultHeaders);

        return await axios({
            method : "PUT",
            url : url,
            data : body,
            headers : headers,
            timeout: 300000
        })
        
    }

    static async delete(url, body){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        let headers = Object.assign({}, defaultHeaders);

        return await axios({
            method : "DELETE",
            url : url,
            headers : headers,
            timeout: 300000
        })
    }

    static async patch(url, body, headers){
        
        let credentials = sessionStorage.getItem("authentication");
        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        headers = Object.assign({}, defaultHeaders, headers);

        return await axios({
            method : "PATCH",
            url : url,
            data : body,
            headers : headers,
			timeout: 300000
        });
    }


    static async upload(url, body, headers){
        let credentials = sessionStorage.getItem("authentication");

        defaultHeaders["Authorization"] = `Bearer ${credentials}`;
        defaultHeaders["content-type"] = "multipart/form-data";
        
        headers = Object.assign({},defaultHeaders, headers);

        return await axios({

            method : "POST",
            url : url,
            data : body,
            headers : headers,
            timeout: 300000,
        });
    }

    render(){
        return (null);
    }
}