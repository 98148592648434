// @ts-nocheck

import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { containsSpecialChars } from '../../../../CommonComponents/CommonFunctions';

const CreateBusModel = ({type, setTemplateName, templateName, defaultTemplateName, close, handle_click}) => {

    
  return (
    <div className='admin-form'>
        <div className='admin-form-card'>
            <div className='w-100 d-flex justify-content-between align-items-center'>
                <span className='d-flex w-100 gap-2 align-items-center'>
                    <span className='color-text fs-20'>{type === 'Clone' ? 'Clone' : 'Create New'}</span>
                    <span className='form-heade-text' >{type === 'Clone' ? `${defaultTemplateName}` : ''}</span>
                </span>
                <i onClick={close} className="fe fe-x-circle me-1 fs-25" style={{ cursor: 'pointer' }}></i>
            </div>
            <Form className='d-flex flex-column gap-4'>
                <div className='d-flex gap-3 flex-column align-items-start w-100 '>
                    <span className='form-content-text'>{'New Bus Design Name * :'}</span>
                    <div className='d-flex gap-2 flex-column w-100'>
                        <Form.Control
                            className='w-90'
                            required
                            type="text"
                            placeholder="Enter bus design name"
                            value={templateName}
                            onKeyDown={(e)=>{
                                if(e.keyCode === 13){
                                    e.preventDefault();
                                    if(templateName?.length <= 30){
                                        handle_click()
                                    }
                                }
                            }}
                            onChange={(event) => {
                                let isNotValidName = containsSpecialChars(event.target.value)
                                if(isNotValidName){
                                    toast.error('Name cannot contain special characters',{toastId:'o'})
                                }else{
                                    if(event.target.value.length > 30){
                                        if(event.target.value.length < templateName?.length){
                                            setTemplateName(event.target.value)
                                        }else{
                                            toast.error('Name cannot be more than 30 characters',{toastId:'o'})
                                        }
                                    }else{
                                        setTemplateName(event.target.value);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                {/* {isNewTemplate && <div style={{ marginLeft: '30px' }}>
                    <FormControl margin="normal">
                        <FormLabel id="demo-row-radio-buttons-group-label"
                            sx={{
                                color: 'white',
                                marginTop: '0px',
                                marginBottom: '-5px',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        >Select Bus Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={busType}
                            onKeyDown={(e)=>{
                                if(e.keyCode === 13){
                                    e.preventDefault();
                                }
                                }}
                            onChange={(e) => { setBusType(e.target.value) }}
                        >
                            {['3U', '6U', '12U', '27U'].map(value => (
                                <FormControlLabel key={value} value={value} control={<Radio />} label={value}
                                    sx={{
                                        '.MuiButtonBase-root': {
                                            backgroundColor: 'transparent',
                                        },
                                        '&.Mui-checked': {
                                            backgroundColor: 'gray',
                                        },
                                    }} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </div>} */}
            </Form>
            
            <div className='w-100 d-flex align-content-end justify-content-center mt-5'>
                <Button
                    variant="primary"
                    type="button"
                    className="d-flex align-items-center justify-content-center btn btn-primary w-25 pt-1 pb-1 pe-4 ps-4 fs-14"
                    onClick={(e)=>{
                        e.preventDefault();
                        handle_click()
                    }}
                    >
                    Continue
                </Button>
            </div>
        </div>
    </div>
  )
}

export default CreateBusModel