
import { FC, useEffect, useState } from "react";
import Select from "react-select";
import GroundStationService from "../../../../service/GroundStationService";
import { toast } from "react-toastify";
import MissionService from "../../../../service/MissionService";
import { useParams } from "react-router-dom";

interface GSPAssociateModalProps {
    close: () => void;
    navigate_to_station_details: () => void;
    set_open_provider_config_modal: () => void;
    station: any;
    all_ground_stations: any[];
    gs_providers: any[];
}

const GSPAssociateModal: FC<GSPAssociateModalProps> = ({ close, station, all_ground_stations, gs_providers, set_open_provider_config_modal, navigate_to_station_details }) => {

    const params = useParams()
    const { mission_id } = params

    const [selectedProvider, setSelectedProvider] = useState<any>(null)
    const [allProviders, setAllProviders] = useState<any>([])


    const update_mission_ground_stations = (providers) => {
        let updated_mission_ground_stations: any = []
        providers?.map((provider: any) => {
            if (provider?.groundStations && provider?.groundStations?.length > 0) {
                updated_mission_ground_stations = updated_mission_ground_stations?.length > 0 ? [...updated_mission_ground_stations, ...provider?.groundStations] : [...provider?.groundStations];
            }
        })
        MissionService.updateMissionStations(mission_id, updated_mission_ground_stations)?.then((res) => {
            if (res?.data) {
                toast.success('Station updated successfully.')
                navigate_to_station_details()
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update station.')
        })
    }
    const get_provider_mqttConfiguredBands = (provider_stations: any) => {
        const filter_ground_stations = all_ground_stations?.filter((station: any) => provider_stations.includes(station?.['groundStationId']))

        let provider_mqttConfiguredBands: string[] = []
        filter_ground_stations?.map((station: any) => {
            if (station?.['properties']?.['freqbands']?.length > 0) {
                station?.['properties']?.['freqbands'].map((band: any) => {
                    if (!provider_mqttConfiguredBands?.includes(band)) {
                        provider_mqttConfiguredBands.push(band)
                    }
                })
            }

        })
        return provider_mqttConfiguredBands
    }

    const update_provider_details = () => {
        const provider_stations = (selectedProvider?.['groundStations'] && selectedProvider?.['groundStations']?.length > 0) ? [...selectedProvider?.['groundStations'], station?.['groundStationId']] : [station?.['groundStationId']]
        const provider_mqttConfiguredBands: string[] = get_provider_mqttConfiguredBands(provider_stations)
        const need_mqtt_configured = provider_mqttConfiguredBands?.length !== selectedProvider?.['mqttConfiguredBands']?.length
        let updated_provider = {
            ...selectedProvider,
            mqttConfiguredBands: selectedProvider?.['mqttConfiguredBands'] ? selectedProvider?.['mqttConfiguredBands'] : [],
            groundStations: provider_stations,
            nextConfigStep: (!selectedProvider?.['MQTTEndpoint'] || selectedProvider?.['MQTTEndpoint'] === '') ? 'Configure Provider' : (!selectedProvider?.['MQTTTopicList'] || selectedProvider?.['MQTTTopicList']?.length === 0 || need_mqtt_configured) ? 'Configure MQTT Topics' : '',
            status: ((!selectedProvider?.['MQTTEndpoint'] || selectedProvider?.['MQTTEndpoint'] === '') || (!selectedProvider?.['MQTTTopicList'] || selectedProvider?.['MQTTTopicList']?.length === 0 || need_mqtt_configured)) ? 'Not Configured' : 'Configured',
        }

        let filter_providers: any[] = gs_providers?.filter(item => item?.['uniqueId'] !== selectedProvider?.['uniqueId'])
        let providers_list: any = [...filter_providers, updated_provider]
        GroundStationService.updateGsProvider(selectedProvider?.['uniqueId'], updated_provider).then((res: any) => {
            if (res.data) {
                update_mission_ground_stations(providers_list)
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update provider.')
        })
    }
    const updat_ground_station = () => {
        let update_station = {
            ...station,
            properties: {
                ...station?.['properties'],
                provider: selectedProvider?.['providerName'],
            },
            status: 'Configured'
        }
        GroundStationService.updateGroundStations([update_station]).then((res) => {
            if (res.data) {
                update_provider_details()
            }
        }).catch((err) => {
            toast.error('Failed to update ground station.')
            console.error(err);
        })
    }


    useEffect(() => {
        if (gs_providers?.length > 0) {
            let provider_options = gs_providers?.map((provider: any) => {
                return {
                    value: provider,
                    label: provider?.['providerName']
                }
            })
            setAllProviders(provider_options)
        }
    }, [gs_providers])

    return (
        <div className="popup-model z-2">

            <div className='model-sm gap-3 p-0'>
                <div className='w-100 d-flex justify-content-between align-items-center px-5 pt-5 pb-3'>
                    <span className='form-heade-text fs-18' > Associate with Ground Station Provider</span>
                    <i onClick={close} className="fe fe-x-circle me-1 fs-25 cursor-pointer text-white"></i>
                </div>

                <div className="modal_devider" />

                {gs_providers?.length > 0 &&
                    <div className="w-100 d-flex p-5 ">
                        <Select
                            className="w-100"
                            required
                            classNamePrefix="Select"
                            options={allProviders}
                            placeholder="Select Ground Station Provider"
                            value={selectedProvider ? { label: selectedProvider?.['providerName'], value: selectedProvider } : null}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e: any) => {
                                setSelectedProvider(e.value)
                            }}
                        />
                    </div>
                }

                {gs_providers?.length === 0 &&
                    <div className="d-flex flex-column gap-3 px-5">
                        <span>There are no ground station provider to associate with your standalone ground station. </span>
                        <div className="gsp_badge_info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
                            </svg>
                            <span>Add ground station provider to associate a ground station.</span>
                        </div>
                    </div>
                }

                <div className="d-fle flex-column">
                    <div className="modal_devider" />
                    <div className="d-flex align-items-center justify-content-between p-5">
                        <button className="btn btn-outline-primary px-5" onClick={close}>
                            Cancel
                        </button>
                        <button className="btn btn-primary px-5"
                            disabled={gs_providers?.length > 0 && !selectedProvider}
                            onClick={() => {
                                if (selectedProvider && gs_providers?.length > 0) {
                                    updat_ground_station()
                                } else {
                                    set_open_provider_config_modal()
                                }
                            }}
                        >
                            {gs_providers?.length === 0 ? 'Add GSP' : 'Associate'}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GSPAssociateModal
