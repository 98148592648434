import { Fragment, useState } from "react"
import GsProviderConfigurationModal from "./GsProviderConfigurationModal"
import { ButtonGroup, Dropdown } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { navigateTo } from "../../../CommonComponents/CommonFunctions"
import GroundStationService from "../../../../service/GroundStationService"
import MissionService from "../../../../service/MissionService"
import Dialog from "../../../CommonComponents/Dialog"
interface GroundStationProviderProps {
    gs_providers: [];
    all_ground_stations: [];
    get_gs_providers: () => void;
    set_loading: (data) => void;
    get_all_ground_stations: () => void;
    open_provider_config_modal: any;
    set_open_provider_config_modal: (data) => void;
    show_btn_group: any;
    set_show_btn_group: (data) => void;
}

const GroundStationProvider: React.FC<GroundStationProviderProps> = ({ gs_providers, get_gs_providers, set_loading, get_all_ground_stations,
    open_provider_config_modal, set_open_provider_config_modal, all_ground_stations, show_btn_group, set_show_btn_group }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { mission_name, mission_id } = params
    const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);

    const update_mission_ground_stations = (providers: any[]) => {
        let updated_mission_ground_stations: any = []
        providers?.map((provider: any) => {
            if (provider?.groundStations?.length > 0) {
                updated_mission_ground_stations = updated_mission_ground_stations?.length > 0 ? [...updated_mission_ground_stations, ...provider?.groundStations] : [...provider?.groundStations]
            }
        })
        MissionService.updateMissionStations(mission_id, updated_mission_ground_stations)?.then((res) => {
            if (res?.data) {
                toast.success("Provider Deleted Successfully")
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update station.')
        })
    }

    const update_ground_station = (deleted_provider) => {
        let provider_associated_gs = all_ground_stations?.filter(station => (deleted_provider?.['groundStations'] && deleted_provider?.['groundStations']?.includes(station?.['groundStationId'])))
        let filter_providers = gs_providers?.filter(provider => provider?.['uniqueId'] !== deleted_provider?.['uniqueId'])
        let updated_provider_associated_gs: any = []
        provider_associated_gs?.map((station: any) => {
            if (station?.['owner-org'] !== "System") {
                let data = {
                    ...station,
                    properties: {
                        ...station?.['properties'],
                        provider: '',
                    },
                    status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Associate With Provider',
                }
                updated_provider_associated_gs = [...updated_provider_associated_gs, data]
            }
        })
        if (updated_provider_associated_gs?.length > 0) {
            GroundStationService.updateGroundStations(updated_provider_associated_gs)?.then((res) => {
                if (res?.data) {
                    update_mission_ground_stations(filter_providers)
                    get_all_ground_stations()
                }
            })?.catch((err) => {
                console.error(err);
                toast.error('Something went wrong while updating ground stations.')
            })
        } else {
            update_mission_ground_stations(filter_providers)
        }
    }

    const delete_provider = (provider: any) => {
        set_loading(true)
        GroundStationService.deleteGsProvider(provider?.['uniqueId'])?.then((res) => {
            if (res) {
                update_ground_station(provider)
                get_gs_providers()
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Something went wrong while deleting provider.')
            set_loading(false)
        })
    }

    const navigate_to_provider_details = (provider, tab_name) => {
        navigateTo(`${mission_name}/${mission_id}/station_provider/${provider?.['providerName']}/${provider?.['uniqueId']}/${tab_name}`, navigate)
    }

    const get_providers_stations = (provider) => {
        const stations = (provider?.['groundStations'] && provider?.['groundStations']?.length > 0) ? provider?.['groundStations'] : []
        return stations?.length === 0 ? 'Not Configured' : stations?.length
    }

    return (
        <Fragment>
            <div className="w-100 d-flex flex-column" onClick={() => set_show_btn_group('')}>
                <span className="fs-14 mb-3">Ground Station Providers</span>
                <div className="w-100 d-flex align-items-start flex-wrap gap-3">
                    {gs_providers?.length > 0 && gs_providers?.map((provider: any, index: number) => (
                        <div className="mission-card d-flex flex-column justify-content-between" key={index} onClick={() => navigate_to_provider_details(provider, 'Configuration')}>
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-center gap-1 w-90">
                                    {provider?.['providerName'] === 'LeafSpace' && <img src={require('../../../../assets/images/svgs/gs/leaf_space_provider.svg').default} height={30} width={30} alt='provider' />}
                                    {(provider?.['providerName'] !== 'LeafSpace' && provider?.['providerName'] !== 'Atlas') && <img src={require('../../../../assets/images/svgs/gs/custom_gs_provider.svg').default} height={30} width={30} alt='provider' />}
                                    <span className="fs-20 color-atmos text-truncate w-90" title={provider?.['providerName']}>{provider?.['providerName']}</span>
                                </div>
                                <ButtonGroup size="sm" className="flex-nowrap" onClick={(e) => e.stopPropagation()}>
                                    <div className="btn-list more-button">
                                        <Dropdown drop="end" show={show_btn_group === provider?.['uniqueId']}>
                                            <Dropdown.Toggle size="sm" variant="transparent" onClick={() => { set_show_btn_group(show_btn_group === provider?.['uniqueId'] ? '' : provider?.['uniqueId']) }}>
                                                <i className="fe fe-more-vertical fs-20"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="1"
                                                    onClick={() => {
                                                        set_open_provider_config_modal({
                                                            mode: 'Update',
                                                            type: provider?.['customProvider'] ? 'Custom' : 'Provider',
                                                            data: provider
                                                        })
                                                        set_show_btn_group('')
                                                    }}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="2"
                                                    onClick={() => {
                                                        setOpenDeleteModal(provider)
                                                        set_show_btn_group('')
                                                    }}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </ButtonGroup>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="w-95 text-truncate fs-14">{get_providers_stations(provider)}</span>
                                <span className="color_history fs-12">Ground Stations </span>
                            </div>
                            <div className="d-flex flex-column gap-3">
                                <span className="border-bottom"></span>
                                <div className="d-flex align-items-ceter justify-content-between">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span className="fs-12 color-history">{provider?.['status'] === 'Configured' ? 'Configuration Completed' : 'Configuration Pending'}</span>
                                    </div>
                                    <button className="p-0 ps-3 border-0 w-auto d-flex align-items-center fs-12 gap-1 bg-transparent color-atmos"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            set_show_btn_group('')
                                            if (provider?.['nextConfigStep'] === 'Add Ground Station' || provider?.['nextConfigStep'] === 'Configure Ground Station') {
                                                navigate_to_provider_details(provider, 'Station')
                                            } else if (provider?.['nextConfigStep'] === 'Configure MQTT Topics') {
                                                navigate_to_provider_details(provider, 'MQTT_Topic')
                                            } else if (provider?.['nextConfigStep'] === 'Configure Provider') {
                                                set_open_provider_config_modal({
                                                    mode: 'Update',
                                                    data: provider
                                                })
                                            } else {
                                                navigate_to_provider_details(provider, 'Configuration')
                                            }
                                        }}
                                    >
                                        {provider?.['nextConfigStep']}
                                        {provider?.['nextConfigStep'] !== '' && <i className="fe fe-chevron-right fs-16"></i>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="add_gs_provider"
                        onClick={() => {
                            set_show_btn_group('')
                            set_open_provider_config_modal({
                                mode: 'Create'
                            })
                        }}>
                        <div className="d-flex flex-column mt-2 align-items-center gap-2">
                            <i className="fe fe-plus fs-40 mb-1 align-self-center"></i>
                            <strong>Add GS Provider</strong>
                        </div>
                    </div>
                </div>
            </div>

            {open_provider_config_modal &&
                <GsProviderConfigurationModal
                    close={() => {
                        get_gs_providers()
                        set_open_provider_config_modal(false)
                    }}
                    openModal={open_provider_config_modal}
                    all_providers={gs_providers}
                    all_ground_stations={all_ground_stations}
                />
            }

            <Dialog
                onClick={(event) => {
                    if (event) {
                        delete_provider(openDeleteModal)
                    }
                    setOpenDeleteModal(false)
                }}
                openModel={openDeleteModal} comment={`Are you sure you want to delete this GS Provider ?`}
            />

        </Fragment>
    )
}

export default GroundStationProvider