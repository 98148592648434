import { useContext } from 'react';
import { ViewerContext } from '../providers/CesiumProvider';
import * as Cesium from "cesium";
import useTimeline from './useTimeline';

const useViewer = () => {
    const context = useContext(ViewerContext);
    const {
        set_marker,
        window_width,
        timeframe_details,
    } = useTimeline()
    if (context === undefined) {
        throw new Error('useViewer must be used within a ViewerProvider');
    }
    const { viewer, set_current_simulation_time } = context;
    const { frame_start_time, frame_duration } = timeframe_details

    const change_viewer_time = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        const per_sec_pixel = window_width / frame_duration;
        if (viewer) {
            const julianDate = Cesium.JulianDate.fromDate(new Date(newValue * 1000));
            viewer.clock.currentTime = julianDate;
            set_marker({
                epoch: newValue,
                position: (newValue - frame_start_time) * per_sec_pixel,
            });
        }

    };

    return {
        ...context,
        change_viewer_time
    }
};

export default useViewer;