import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";

const createGsProvider = (data) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/providers`, data)
}
const createandUpdateGsProvider = (data) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/providers-create`, data)
}

const updateGsProvider = (providerId, data) => {
    return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/providers?providerId=${providerId}`, data)
}

const deleteGsProvider = (providerId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/providers?providerId=${providerId}`)
}

const getGsProvider = (providerId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/providers?providerId=${providerId}`)
}

const getAllGsProviders = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/gs-providers?missionId=${missionId}`)
}


const getGroundStationsByMissionId = () => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation`);
}

const getGroundStationById=(gsId)=>{
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/gsObject/${gsId}`);
}

const createGroundStation = (name, body) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationName=${name}`, body);
}
const updateGroundStations = (body) => {
    return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/update`, body);
}

const deleteGroundStation = (gsId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationId=${gsId}`);
}
  

const GroundStationService = {
    createGsProvider,
    createandUpdateGsProvider,
    updateGsProvider,
    deleteGsProvider,
    getGsProvider,
    getAllGsProviders,
    getGroundStationsByMissionId,
    getGroundStationById,
    createGroundStation,
    updateGroundStations,
    deleteGroundStation
}

export default GroundStationService
