import React, { Fragment, useState } from 'react'

const SatSpaceObject = () => {

    const [expanded, setExpanded] = useState<any>([])

    const toggleExpanded = (name: string) => {
        if (expanded.includes(name)) {
            setExpanded(expanded.filter((item: string) => item !== name));
        } else {
            setExpanded([...expanded, name]);
        }
    }

    return (
        <Fragment>
            <button className='bg-transparent py-2 w-90 px-0'>Orbit Parameters</button>
            {/* <div className={`details`}>
                <div className='summary'>
                    <div className={`triangle__toggle__btn ${expanded?.includes('Payloads') ? 'expanded' : ''}`} onClick={() => {toggleExpanded('Payloads')}}/>
                   <div className='summary__object__name'>Payloads</div>
                </div>
            </div>
            <div className={`details`}>
                <div className='summary'>
                    <div className={`triangle__toggle__btn ${expanded?.includes('Edge') ? 'expanded' : ''}`} onClick={() => {toggleExpanded('Edge')}}/>
                   <div className='summary__object__name'>Edge</div>
                </div>
            </div>
            <div className={`details`}>
                <div className='summary'>
                    <div className={`triangle__toggle__btn`} onClick={() => {toggleExpanded('Bus')}}/>
                   <div className='summary__object__name'>Bus</div>
                </div>
                <div className='object__details__body__section'>
                    <div className={`details`}>
                        <div className='summary'>
                            <div className={`triangle__toggle__btn ${expanded?.includes('Communication') ? 'expanded' : ''}`} onClick={() => {toggleExpanded('Communication')}}/>
                            <div className='summary__object__name'>Communication</div>
                        </div>
                    </div>
                    <div className={`details`}>
                        <div className='summary'>
                            <div className={`triangle__toggle__btn ${expanded?.includes('EPS') ? 'expanded' : ''}`} onClick={() => {toggleExpanded('EPS')}}/>
                            <div className='summary__object__name'>EPS</div>
                        </div>
                    </div>
                    <div className={`details`}>
                        <div className='summary'>
                            <div className={`triangle__toggle__btn ${expanded?.includes('All Others') ? 'expanded' : ''}`} onClick={() => {toggleExpanded('All Others')}}/>
                            <div className='summary__object__name'>All Others</div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}

export default SatSpaceObject
