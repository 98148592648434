import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { Fragment, useEffect } from 'react'
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

interface OrbitalDetailsProps {
    orbit_details: any;
    set_scenario_orbit_details: (details: Object) => void;
    navigate_scenario: (details: string) => void;
    data_submited: string[];
    true_twin: Object;
    scenario_step?: string;
    loading: boolean | string;
}

const OrbitalDetails: React.FC<OrbitalDetailsProps> = ({ orbit_details, set_scenario_orbit_details,
    navigate_scenario, true_twin, scenario_step, data_submited, loading }) => {
    function isValidDate(date: any) {
        return date instanceof Date && !isNaN(date as unknown as number);
    }

    const handleScenarioDateTime = (dateTime) => {
        const date = new Date(dateTime);
        if (isValidDate(date)) {
            let year = date.getFullYear();
            if (year >= 2002 && year <= 2037) {
                set_scenario_orbit_details({
                    epoch: dateTime,
                })
            } else {
                toast.error('Year should be between 2002 and 2037', { toastId: "error" })
                set_scenario_orbit_details({
                    epoch: dayjs.utc(new Date()),
                })
            }
        } else {
            set_scenario_orbit_details({
                epoch: '',
            })
            setTimeout(() => {
                set_scenario_orbit_details({
                    epoch: dayjs.utc(new Date()),
                })
            }, 10);
        }
    }

    function getYears() {
        const years: number[] = [];
        for (let year = 2002; year <= 2037; year++) {
            years.push(year);
        }
        return years;
    }


    return (
        <div className={`${loading ? "disable__components" : ""}`}>
            <div className="modalBoxShadowDiv">
                <button
                    onClick={() => {
                        if (data_submited?.includes('Orbital_Details')) {
                            navigate_scenario("Orbital_Details",)
                        }
                    }}
                    className={`modalCheckboxDiv bg-transparent ${(!data_submited?.includes('Orbital_Details') && scenario_step !== 'Orbital_Details') ? "disabledBtn" : ''}`}
                >
                    <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
                    <div className={"modalDropDownIconBtn"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d={scenario_step === 'Orbital_Details' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                                stroke="#F0F0F0"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className={"modalCheckboxText modalWhiteSubHeader"}>
                        Orbital Details
                    </div>
                    {data_submited?.includes('Orbital_Details') && <img className="greenTickIcon" src={require("../Images/GreenTickIcon.svg").default} />}
                </button>
            </div>

            {scenario_step === 'Orbital_Details' && (
                <div className={`referenceOrbitDiv ${loading ? "disable__components" : ""}`}>
                    <form id="orbitalDetails">
                        <div className="px-3 d-flex flex-row gap-2 align-items-center justify-content-between">
                            <div className="modalAnalysisText">Epoch </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DateTimePicker className="w-70" ampm={false} format='YYYY-MM-DD HH:mm:ss'
                                    shouldDisableYear={(date) => {
                                        const current_year = dayjs(date).year();
                                        const yearList = getYears();
                                        return !yearList.includes(current_year);
                                    }}
                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                    value={dayjs.utc(orbit_details?.epoch)} onChange={handleScenarioDateTime} />
                            </LocalizationProvider>
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">Altitude </div>
                            <input
                                value={orbit_details?.altitude}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (!isNaN(+event.target.value) && event.target.value?.length <= 8) {
                                        set_scenario_orbit_details({
                                            altitude: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                        })
                                    }
                                }}
                                className="modalAnalysisInput"
                                placeholder="km"
                                type='number'
                                step='any'
                                required
                            />
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">Eccentricity </div>
                            <input
                                value={orbit_details?.eccentricity}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (+event.target.value >= 0 && +event.target.value <= 0.05 && !isNaN(+event.target.value)) {
                                        if (event.target.value?.length <= 8) {
                                            set_scenario_orbit_details({
                                                eccentricity: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                            })
                                        }
                                    } else {
                                        toast.error("Eccentricity must be between 0 and 0.05", { toastId: '0' })
                                    }
                                }}
                                className="modalAnalysisInput"
                                required
                                type='number'
                                step='any'
                                placeholder="degree"
                            />
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">Inclination </div>
                            <input
                                value={orbit_details?.inclination}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (+event.target.value >= 0 && +event.target.value <= 180 && !isNaN(+event.target.value)) {
                                        if (event.target.value?.length <= 8) {
                                            set_scenario_orbit_details({
                                                inclination: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                            })
                                        }
                                    } else {
                                        toast.error("Inclination must be between 0 and 180", { toastId: '0' })
                                    }
                                }}
                                className="modalAnalysisInput"
                                required
                                type='number'
                                step='any'
                                placeholder="degree"
                            />
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">Longitude of Ascending Node </div>
                            <input
                                value={orbit_details?.RAAN}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                        if (event.target.value?.length <= 8) {
                                            set_scenario_orbit_details({
                                                RAAN: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                            })
                                        }
                                    } else {
                                        toast.error("Longitude of Ascending Node must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="modalAnalysisInput"
                                required
                                type='number'
                                step='any'
                                placeholder="degree"
                            />
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">Argument of Periapsis </div>
                            <input
                                value={orbit_details?.AP}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                        if (event.target.value?.length <= 8) {
                                            set_scenario_orbit_details({
                                                AP: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                            })
                                        }
                                    } else {
                                        toast.error("Argument of Periapsis must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="modalAnalysisInput"
                                required
                                type='number'
                                step='any'
                                placeholder="degree"
                            />
                        </div>

                        <div className="modalAnalysisDiv modalAnalysisDivLong">
                            <div className="modalAnalysisText">True Anomaly </div>
                            <input
                                value={orbit_details?.TA}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                        if (event.target.value?.length <= 8) {
                                            set_scenario_orbit_details({
                                                TA: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                            })
                                        }
                                    } else {
                                        toast.error("True Anomaly must be between 0 and 360", { toastId: '0' })
                                    }
                                }}
                                className="modalAnalysisInput"
                                required
                                type='number'
                                step='any'
                                placeholder="degree"
                            />
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default OrbitalDetails
