import { ReactNode, createContext, useEffect, useState } from "react";
import { ScheduleData } from "../hooks/useOnBoardSchedule";
import { ContactStation } from "../hooks/useGroundContact";
import CommandCenterService from "../../../service/CommandCenterService";
import useScenario from "../hooks/useScenario";
import useUrlParams from "../hooks/useUrlParams";

interface ContactsContextType {
    selectedSchedule: {
        default_schedule: boolean;
        schedule_data: ScheduleData;
    };
    setSelectedSchedule: (data: {
        default_schedule: boolean;
        schedule_data: ScheduleData;
    }) => void;
    selectedContact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null;
    setSelectedContact: (data: {
        contact_type: string;
        contact_data: ContactStation,
    } | null) => void;
    contacts: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }
    setContacts: (data: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }) => void;
    contactData: ContactStation[];
    scheduleData: ScheduleData[];
    timelineScheduleData: ScheduleData[];
    scheduleDetails: {
        start_time: number,
        name: string,
    };
}

export const ContactsContext = createContext<ContactsContextType | undefined>(undefined);

interface ContactsProviderProps {
    children: ReactNode;
}
const ContactsProvider = ({ children }: ContactsProviderProps) => {
    const { scenario } = useScenario()
    const { scenario_id } = useUrlParams()
    const [selectedSchedule, setSelectedSchedule] = useState<{
        default_schedule: boolean;
        schedule_data: ScheduleData;
    }>({
        default_schedule: true,
        schedule_data: {} as ScheduleData,
    })

    const [selectedContact, setSelectedContact] = useState<
        {
            contact_type: string;
            contact_data: ContactStation,
        } | null
    >(null)
    const [contacts, setContacts] = useState<{
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }>({
        previous_contact: null,
        ongoing_contact: null,
        upcoming_contact: null,
        current_time: null,
    })

    const [contactData, setContactData] = useState<ContactStation[]>([])
    const [scheduleData, setScheduleData] = useState<ScheduleData[]>([])
    const [timelineScheduleData, setTimelineScheduleData] = useState<ScheduleData[]>([])
    const [scheduleDetails, setScheduleDetails] = useState<{
        start_time: number,
        name: string,
    }>({
        start_time: 0,
        name: '',
    })

    const get_timeline_onboard_schedule_data = () => {
        CommandCenterService.getOnBoardScheduleData(scenario?.['simulationId']).then((res) => {
            if (res?.data) {
                const Data = res.data
                setScheduleData(Data)
                setTimelineScheduleData(Data)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const get_onboard_schedule_data = () => {
        CommandCenterService.getSimulationStatus(scenario?.['simulationId']).then((res) => {
            if (res?.data) {
                const Data = res?.data?.['taskList']
                setScheduleDetails({
                    start_time: res?.data?.['simulation_start_epoch'],
                    name: res?.data?.['scheduleName'],
                })
                setScheduleData(Data)
                setTimelineScheduleData(Data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const get_gs_contact_details = () => {
        CommandCenterService.getGroundContacts(scenario_id).then((res) => {
            if (res?.['data']?.['groundStations']) {
                setContactData(res?.['data']?.['groundStations'])
            }
        }).catch(() => {
            console.error('Failed to get contact details.');
        })
    }

    useEffect(() => {
        if (scenario_id) {
            get_gs_contact_details()
        }
    }, [scenario_id])


    useEffect(() => {
        if (scenario?.['simulationId']) {
            // get_timeline_onboard_schedule_data()
            get_onboard_schedule_data()
        }
    }, [scenario])


    return (
        <ContactsContext.Provider
            value={{
                contacts,
                contactData,
                setContacts,
                scheduleData,
                scheduleDetails,
                timelineScheduleData,
                selectedContact,
                selectedSchedule,
                setSelectedSchedule,
                setSelectedContact,
            }}>
            {children}
        </ContactsContext.Provider>
    )
}

export default ContactsProvider