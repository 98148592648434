import React, { useState } from "react";
import "../Modals.css";
import styled from "styled-components";
import ModalCreationMessage from "../ModalCreationMessage";
import ModalHeader from "../ModalComponents/ModalHeader";
import ModalDropdown from "../ModalComponents/ModalDropdown";

const ADCSSelectionsModal = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [previousOption, setPreviousOption] = useState("");

  const handleSelectedOption = (selectedOption) => {};

  const [dataAnalysisItems, showDataAnalysisItems] = useState({
    Satellites: true,
    Initial_Conditions: true,
    AOI: true,
    Schedule: true,
    ADCS_Specs: true,
    Propulsion: true,
  });

  const [dataAnalysisSats, showDataAnalysisSats] = useState({
    Mission_Sat_01: true,
  });

  const [aoiItems, showAoiItems] = useState({
    AOI_01: true,
    AOI_02: true,
    AOI_03: true,
  });

  const controlModeOptions = [{ label: "Ideal", value: "Ideal" }];

  const header = {
    title: "ADCS Selection",
    img: require("../../ImageSvgs/ADCS-Selection.svg").default,
  };

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal" : "mainModal"} id="dataAnalysis">
            <ModalHeader props={props} parameterProps={parameterProps} header={header} />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Satellites: !dataAnalysisItems.Satellites,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Satellites ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellites</div>
              </div>

              {dataAnalysisItems.Satellites && (
                <div className="modalrefOrbitDiv">
                  {dataAnalysisSats.Mission_Sat_01 && (
                    <div className="modalrefOrbitsBtn">
                      <span>&lt;Mission_Sat_01&gt;</span>
                      <button
                        className="modalrefOrbitsCloseBtn"
                        onClick={() =>
                          showDataAnalysisSats({
                            ...dataAnalysisSats,
                            Mission_Sat_01: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Initial_Conditions: !dataAnalysisItems.Initial_Conditions,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Initial_Conditions ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Initial Conditions</div>
              </div>

              {dataAnalysisItems.Initial_Conditions && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Satellite Orientation</div>
                  </div>
                  <div className="modalAnalysisDiv modalFlexDiv">
                    <div className="modalThirdsDiv">
                      Yaw
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Pitch
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Roll
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                  </div>
                  <br/>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Inertia</div>
                  </div>
                  <div className="modalAnalysisDiv modalFlexDiv">
                    <div className="modalThirdsDiv">
                      Ixx
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Ixy
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Ixz
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                  </div>
                  <div className="modalAnalysisDiv modalFlexDiv">
                    <div className="modalThirdsDiv">
                      Iyx
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Iyy
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Iyz
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                  </div>
                  <div className="modalAnalysisDiv modalFlexDiv">
                    <div className="modalThirdsDiv">
                      Izx
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Izy
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      Izz
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                  </div>
                  <br/>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Angular Rate</div>
                  </div>
                  <div className="modalAnalysisDiv modalFlexDiv">
                    <div className="modalThirdsDiv">
                      &#8486;x
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      &#8486;y
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                    <div className="modalThirdsDiv">
                      &#8486;z
                      <input className="tableInputElement" value="" placeholder="#" />
                    </div>
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Simulation Time Period</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in mins"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Max agility</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"Degree per sec"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Req. De-tumbling time</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in mins"} style={{ width: "148px" }} />
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      AOI: !dataAnalysisItems.AOI,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.AOI ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Area of Interest</div>
              </div>

              {dataAnalysisItems.AOI && (
                <div className="modalrefOrbitDiv modalAoiBtnDiv">
                  <div className="modalrefOrbitsSpacer">
                    <div
                      className="modalrefOrbitsBtn modalAoiBtn"
                      style={{
                        display: aoiItems.AOI_01 ? "flex" : "none",
                      }}
                    >
                      <span> AOI_01 </span>
                      <button
                        className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                        onClick={() =>
                          showAoiItems({
                            ...aoiItems,
                            AOI_01: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>

                    <div
                      className="modalrefOrbitsBtn modalAoiBtn"
                      style={{
                        display: aoiItems.AOI_02 ? "flex" : "none",
                      }}
                    >
                      <span> AOI_02 </span>
                      <button
                        className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                        onClick={() =>
                          showAoiItems({
                            ...aoiItems,
                            AOI_02: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    className="modalrefOrbitsBtn modalAoiBtn"
                    style={{
                      display: aoiItems.AOI_03 ? "flex" : "none",
                    }}
                  >
                    <span> AOI_03 </span>
                    <button
                      className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                      onClick={() =>
                        showAoiItems({
                          ...aoiItems,
                          AOI_03: false,
                        })
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                        <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                        <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Schedule: !dataAnalysisItems.Schedule,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Schedule ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Schedule</div>
              </div>

              {dataAnalysisItems.Schedule && (
                <table className="inModalTable">
                  <thead className="tableModalRow">
                    <tr>
                      <th className="tableModalColumn tableModalHeaderText" style={{ width: "24%" }}>
                        <img className="tableModalHeaderIcon" src={require("../../ImageSvgs/DataFlow.svg").default} />
                        Time
                      </th>
                      <th className="tableModalColumn tableModalHeaderText inModalTableColumn" style={{ width: "42%" }}>
                        <img className="tableModalHeaderIcon" src={require("../../ImageSvgs/DataFlow.svg").default} />
                        Control Mode
                      </th>
                      <th className="tableModalColumn tableModalHeaderText inModalTableColumn" style={{ width: "33%" }}>
                        <img className="tableModalHeaderIcon" src={require("../../ImageSvgs/DataFlow.svg").default} />
                        Argument
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="inModalTableRow">
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="T+12mins" placeholder="in mins" />
                      </td>
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="Ideal" placeholder="in %" />
                      </td>
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="3W" placeholder="in W" />
                      </td>
                    </tr>
                    <tr className="inModalTableRow">
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="T+12mins" placeholder="in mins" />
                      </td>
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="Ideal" placeholder="in %" />
                      </td>
                      <td>
                        <input className="tableInputElement inModaltableInputElement" value="3W" placeholder="in W" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      ADCS_Specs: !dataAnalysisItems.ADCS_Specs,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.ADCS_Specs ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">ADCS Specs</div>
              </div>

              {dataAnalysisItems.ADCS_Specs && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Reaction Wheels</div>
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Max Torque</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Spin Axis</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Max Momentum</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in m"} style={{ width: "148px" }} />
                  </div>
                  <div className="addPanelDiv">
                    <button className="addTableModalPanelBtn">+ Add More</button>
                  </div>

                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Magnetorquers</div>
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Spin Axis</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Max Dipole Moment </div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="addPanelDiv">
                    <button className="addTableModalPanelBtn">+ Add More</button>
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Propulsion: !dataAnalysisItems.Propulsion,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Propulsion ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Propulsion</div>
              </div>

              {dataAnalysisItems.Propulsion && <div className="modalAnalysisDiv"></div>}
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("ADCS Selection successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Orbital");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ADCSSelectionsModal;
