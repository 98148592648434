import React, { useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import ModalHeader from "../ModalComponents/ModalHeader";
import ModalDropdown from "../ModalComponents/ModalDropdown";

const KeepInKeepOutModal = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [previousOption, setPreviousOption] = useState("");
  const [selectedTypeDropDownOption, setSelectedTypeDropDownOption] = useState("Criteria 1");
  const [selectedOrbitDropDownOption, setSelectedOrbitDropDownOption] = useState("Orbit");

  const [keepInKeepOutItems, showKeepInKeepOutItems] = useState({
    Satellites: true,
  });

  const [keepInKeepOutSats, showKeepInKeepOutSats] = useState({
    Mission_Sat_01: true,
  });

  const [sensorItems, showSensorItems] = useState({
    Sensor_01: true,
    Sensor_02: true,
  });

  const sensorViolationOptions = [
    {
      label: "Criteria 1",
      value: "Criteria 1",
    },
    {
      label: "Criteria 2",
      value: "Criteria 2",
    },
    {
      label: "Criteria 3",
      value: "Criteria 3",
    },
  ];

  const orbitOptions = [
    { label: "Orbit 1", value: "Orbit 1" },
    { label: "Orbit 2", value: "Orbit 2" },
  ];

  const handleSelectedOption = (selectedOption) => {
    setSelectedTypeDropDownOption(selectedOption.label);
  };

  const header = {
    title: "Keep-In & Keep-Out",
    img: require("../../ImageSvgs/Keep-In-Keep-Out.svg").default,
  };

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal" : "mainModal"} id="dataAnalysis">
            <ModalHeader props={props} parameterProps={parameterProps} header={header} />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showKeepInKeepOutItems({
                      ...keepInKeepOutItems,
                      Satellites: !keepInKeepOutItems.Satellites,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={keepInKeepOutItems.Satellites ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellites</div>
              </div>

              {keepInKeepOutItems.Satellites && (
                <>
                  <div className="modalrefOrbitDiv">
                    {keepInKeepOutSats.Mission_Sat_01 && (
                      <div className="modalrefOrbitsBtn">
                        <span>&lt;Mission_Sat_01&gt;</span>
                        <button
                          className="modalrefOrbitsCloseBtn"
                          onClick={() =>
                            showKeepInKeepOutSats({
                              ...keepInKeepOutSats,
                              Mission_Sat_01: false,
                            })
                          }
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                            <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                            <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="modalAnalysisDiv blockDisplay">
                    <div className="modalAnalysisText blockDisplay">Analysis Duration</div>

                    <select className="modalSelect blockDisplay">
                      <option className="modalSelectOption" selected value="Cube">
                        Hours
                      </option>
                      <option className="modalSelectOption" value="Cuboid">
                        Minutes
                      </option>
                      <option className="modalSelectOption" value="Cuboid">
                        Days
                      </option>
                    </select>
                    <input className="modalAnalysisInput blockDisplay" type="number" placeholder={"#"} style={{ width: "25px" }} />
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showSensorItems({
                      ...sensorItems,
                      Sensor_01: !sensorItems.Sensor_01,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={sensorItems.Sensor_01 ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Sensor 01</div>
              </div>

              {sensorItems.Sensor_01 && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Sensor FOV</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Direction</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Violation criteria </div>
                    <div style={{ width: "148px" }}>
                      <ModalDropdown options={sensorViolationOptions} isMulti={"false"} handleSelectedOption={handleSelectedOption} previousOption={previousOption} />
                    </div>
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showSensorItems({
                      ...sensorItems,
                      Sensor_02: !sensorItems.Sensor_02,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={sensorItems.Sensor_02 ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Sensor 02</div>
              </div>

              {sensorItems.Sensor_02 && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Sensor FOV</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Direction</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Violation criteria </div>
                    <div style={{ width: "148px" }}>
                      <ModalDropdown options={sensorViolationOptions} isMulti={"false"} handleSelectedOption={handleSelectedOption} previousOption={previousOption} />
                    </div>
                  </div>
                </>
              )}
              <div className="addPanelDiv">
                <button className="addTableModalPanelBtn">+ Add Sensor</button>
              </div>
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("Keep-In & Keep-Out successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Orbital");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default KeepInKeepOutModal;
