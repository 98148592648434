import React from 'react'
import { PayloadsList } from '../../../Stepper.constants';

const FirstStep = ({payloadCatalouge,  onSelectPayload, }) => {

  return (
    <div className="w-100 d-flex gap-3 justify-content-center mt-5">
        {PayloadsList.map((data) => {
            return (
            <div
                className={
                data.type === payloadCatalouge
                    ? "category-card-box  active"
                    : "category-card-box "
                }
                key={data.id}
                onClick={() => {
                    onSelectPayload(data);
                }}
            >
                <div className={"common-card-header"}>
                <img src={data?.['src']} alt={data?.['type']} />
                {data.type}
                </div>
                <div className="common-card-content">{data?.['text']}</div>
            </div>
            );
        })}
    </div>
  )
}

export default FirstStep