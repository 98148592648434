import { FC, Fragment, useEffect, useState } from 'react'
import { ButtonGroup, Dropdown } from "react-bootstrap"
import GroundStationForm from './GroundStationForm';
import GSPAssociateModal from './GSPAssociateModal';
import { useNavigate, useParams } from 'react-router-dom';
import { navigateTo } from '../../../CommonComponents/CommonFunctions';
import GroundStationService from '../../../../service/GroundStationService';
import { toast } from 'react-toastify';
import GsTypeModal from '../GSProviderDetails/Modals/GsTypeModal';
import Dialog from '../../../CommonComponents/Dialog';

interface GroundStationsProps {
    set_loading: (data) => void;
    get_all_ground_stations: () => void;
    set_open_provider_config_modal: (data) => void;
    stand_alone_gs: [];
    gs_providers: [];
    show_btn_group: any;
    set_show_btn_group: (data) => void;
    set_open_map_visualisation_modal: (data) => void;
}

const GroundStations: FC<GroundStationsProps> = ({ set_loading, stand_alone_gs, gs_providers, get_all_ground_stations, set_open_provider_config_modal, show_btn_group, set_show_btn_group, set_open_map_visualisation_modal }) => {

    const params = useParams();
    const navigate = useNavigate();
    const { mission_name, mission_id } = params
    const [openAssociateGSPModal, setOpenAssociateGSPModal] = useState<any>(false)
    const [open_gs_config_modal, set_open_gs_config_modal] = useState<any>(false)
    const [gsTypeSelectModal, setGSTypeSelectModal] = useState<any>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);

    const delete_standalone_gs = (station) => {
        GroundStationService.deleteGroundStation(station?.['groundStationId'])?.then((res) => {
            if (res) {
                toast.success("Ground Station Deleted Successfully")
                get_all_ground_stations()
            }
        }).catch((err) => {
            toast.error('Something went wrong while deleting ground station.')
        })
    }

    const navigate_to_station_details = (station) => {
        navigateTo(`${mission_name}/${mission_id}/ground_station/${station?.['groundStationName']}/${station?.['groundStationId']}`, navigate)
    }

    const create_ground_station = (data) => {
        const current_name = data?.['groundStationName'].includes('(') ? data?.['groundStationName'].split('(')[0] : data?.['groundStationName']
        const filter_gs_with_same_name = stand_alone_gs?.filter((gs: any) => gs?.['groundStationName'].toLowerCase().trim().includes(current_name.toLowerCase().trim()))
        const gs_name = `${current_name.trim()} ${filter_gs_with_same_name?.length > 1 ? `(copy ${filter_gs_with_same_name?.length})` : '(copy)'}`
        const updated_gs = {
            ...data,
            groundStationName: gs_name,
        }
        GroundStationService.createGroundStation(gs_name, updated_gs)?.then((res) => {
            if (res) {
                toast.success("Ground Station Created Successfully")
                get_all_ground_stations()
            }
        }).catch((err) => {
            toast.error('Something went wrong while creating ground station.')
        })
    }

    useEffect(() => {
        const close_btn_group = () => {
            set_show_btn_group('')
        }
        window.addEventListener('click', close_btn_group)
        return () => {
            window.removeEventListener('click', close_btn_group)
        }
    }, [])

    return (
        <Fragment>
            <div className="w-100 d-flex flex-column" onClick={() => { set_show_btn_group('') }}>
                <span className='fs-14 mb-3'>Standalone Ground Stations</span>
                <div className="w-100 d-flex align-items-start flex-wrap gap-3">
                    {stand_alone_gs?.length > 0 && stand_alone_gs?.map((station: any, index: number) => {
                        return (
                            <div className="mission-card d-flex flex-column justify-content-between" key={index} onClick={() => {
                                navigate_to_station_details(station)
                            }}>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className='d-flex align-items-center gap-1 w-90'>
                                        <img src={require('../../../../assets/images/svgs/gs/ground_station.svg').default} width={20} height={20} alt='mission' />
                                        <span className="fs-20 color-atmos text-truncate w-90" title={station?.['groundStationName']}>{station?.['groundStationName']}</span>
                                    </div>
                                    <ButtonGroup size="sm" className="flex-nowrap" onClick={(e) => { e.stopPropagation() }}>
                                        <div className="btn-list more-button">
                                            <Dropdown drop="end" show={show_btn_group === station?.['groundStationId']}>
                                                <Dropdown.Toggle size="sm" variant="transparent"
                                                    onClick={() => { set_show_btn_group(show_btn_group === station?.['groundStationId'] ? '' : station?.['groundStationId']) }}
                                                >
                                                    <i className="fe fe-more-vertical fs-20"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="0"
                                                        onClick={() => {
                                                            set_open_gs_config_modal(station)
                                                            set_show_btn_group('')
                                                        }}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item eventKey="1"
                                                        onClick={() => {
                                                            create_ground_station(station);
                                                            set_show_btn_group('')
                                                        }}>
                                                        Clone
                                                    </Dropdown.Item>
                                                    <Dropdown.Item eventKey="2"
                                                        onClick={() => {
                                                            setOpenDeleteModal(station)
                                                            set_show_btn_group('')
                                                        }}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </ButtonGroup>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className="d-flex flex-column w-65">
                                        <span className="w-95 text-truncate fs-14" title={station?.['properties']?.['location']}>
                                            {station?.['properties']?.['location'] ?
                                                station?.['properties']?.['location']
                                                : '--'}
                                        </span>
                                        <span className='color_history fs-12'>Location</span>
                                    </div>
                                    <div className="d-flex flex-column w-35">
                                        <div className="w-95 text-truncate d-flex gap-1 align-content-center fs-14">
                                            {station?.['properties']?.['freqbands'] && station?.['properties']?.['freqbands']?.map((band, index) => {
                                                const show_separator = index !== station?.['properties']?.['freqbands'].length - 1
                                                return (
                                                    <span title={band}>
                                                        {band?.split('-')[0] + `${show_separator ? ',' : ''}`}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                        <span className='color_history fs-12'>Frequency Bands </span>
                                    </div>
                                </div>
                                <div className="d-flex flex-column gap-3">
                                    <span className="border-bottom"></span>
                                    <div className="d-flex align-items-ceter justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <span className="fs-12 color-history"> {station?.['status'] === 'Configured' ? 'Configuration Completed' : 'Configuration Pending'}</span>
                                        </div>
                                        <button
                                            className="p-0 ps-3 border-0 w-auto d-flex align-items-center fs-12 gap-1 bg-transparent color-atmos"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                set_show_btn_group('')
                                                if (station?.['status'] === 'Configure') {
                                                    set_open_gs_config_modal(station)
                                                } else if (station?.['status'] === 'Associate With Provider') {
                                                    setOpenAssociateGSPModal(station)
                                                }
                                            }}
                                        >
                                            {station?.['status']}
                                            <i className="fe fe-chevron-right fs-16"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="add_gs_provider"
                        onClick={() => { setGSTypeSelectModal(true) }}>
                        <div className="d-flex flex-column mt-2 align-items-center gap-2">
                            <i className="fe fe-plus fs-40 mb-1 align-self-center"></i>
                            <strong>Add Ground Station</strong>
                        </div>
                    </div>
                </div>
            </div>

            {gsTypeSelectModal &&
                <GsTypeModal
                    close={() => { setGSTypeSelectModal(false) }}
                    set_preferance_type={(preferance) => {
                        if (preferance === 'Map') {
                            set_open_map_visualisation_modal(preferance)
                        } else {
                            set_open_gs_config_modal('Create')
                        }
                    }}
                />
            }

            {open_gs_config_modal &&
                <GroundStationForm
                    close={() => {
                        set_open_gs_config_modal(false)
                        get_all_ground_stations()
                    }}
                    data={open_gs_config_modal}
                    mode={open_gs_config_modal === 'Create' ? 'Create' : 'Update'}
                />
            }
            {openAssociateGSPModal &&
                <GSPAssociateModal
                    close={() => {
                        setOpenAssociateGSPModal(false)
                        get_all_ground_stations()
                    }}
                    station={openAssociateGSPModal}
                    all_ground_stations={stand_alone_gs}
                    gs_providers={gs_providers}
                    set_open_provider_config_modal={() => {
                        setOpenAssociateGSPModal(false)
                        set_open_provider_config_modal({
                            mode: 'Create'
                        })
                    }}
                    navigate_to_station_details={() => { navigate_to_station_details(openAssociateGSPModal) }}
                />
            }

            <Dialog
                onClick={(event) => {
                    if (event) {
                        delete_standalone_gs(openDeleteModal)
                    }
                    setOpenDeleteModal(false)
                }}
                openModel={openDeleteModal} comment={`Are you sure you want to delete this Ground Station ?`}
            />
        </Fragment>
    )
}

export default GroundStations
