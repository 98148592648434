import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

interface LoaderProps { }

const Loader: FC<LoaderProps> = () => (
  <div className='loader-page'>
    <CircularProgress color='inherit' />
  </div>
);

export default Loader;
