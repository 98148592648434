// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MissionStore } from '../../../store/MissionSlice';

const Power = ({enableBusSlider, selectedComp, setSelectedComp}) => {
    const [template, setTemplate] = useState<any>({});
    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);


    useEffect(() => {
        setTemplate(Satellite?.Template);
    },[]);

    return <div className="config-data-box h-100">
        <div className={`config-card ${selectedComp === 'EPS' && 'active'}`}
            onClick={() => {
                setSelectedComp('EPS')
                enableBusSlider(CloneTemplate?.['Power']?.['EPS'] ? CloneTemplate?.['Power']?.['EPS'] : template?.['assembly']?.[0]?.['power']?.['EPS']?.['atmos-data'],  'EPS', 'Power', template?.['assembly']?.[0]?.['power']?.['EPS']?.['user_data']?.['numUnits'])
            }}>
            <h5 className="c-color1">
                <span className="me-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                            fill="#9B72F2"/>
                    </svg>
                </span>
                EPS
            </h5>
            <div>
                {CloneTemplate?.['Power']?.['EPS'] ? CloneTemplate?.['Power']?.['EPS']?.['name'] : template?.['assembly']?.[0]?.['power']?.['EPS']?.['atmos-data']?.['name']}
            </div>
        </div>
        <div className={`config-card ${selectedComp === 'Battery' && 'active'}`}
            onClick={() => {
                setSelectedComp('Battery')
                enableBusSlider(CloneTemplate?.['Power']?.['Battery Pack'] ? CloneTemplate?.['Power']?.['Battery Pack'] : template?.['assembly']?.[0]?.['power']?.['Battery Pack']?.['atmos-data'],  'Battery Pack', 'Power', template?.['assembly']?.[0]?.['power']?.['Battery Pack']?.['user_data']?.['numUnits'])
            }}>
            <h5 className="c-color1">
                <span className="me-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                            fill="#9B72F2"/>
                    </svg>
                </span>
                Battery
            </h5>
            <div>
                {CloneTemplate?.['Power']?.['Battery Pack'] ? CloneTemplate?.['Power']?.['Battery Pack']?.['name'] : template?.['assembly']?.[0]?.['power']?.['Battery Pack']?.['atmos-data']?.['name']}
            </div>
        </div>
        <div className={`config-card ${selectedComp === 'Solar Panel' && 'active'}`} 
            onClick={() => {
                setSelectedComp('Solar Panel')
                enableBusSlider(CloneTemplate?.['Power']?.['Solar Panel'] ? CloneTemplate?.['Power']?.['Solar Panel'] : template?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['atmos-data'],  'Solar Panel', 'Power', (CloneTemplate?.['Power']?.['Solar PanelCount'] ? CloneTemplate?.['Power']?.['Solar PanelCount'] : template?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['user_data']?.['numUnits']))
            }}>
            <h5 className="c-color1">
                <span className="me-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                            fill="#9B72F2"/>
                    </svg>
                </span>
                Solar Panel
            </h5>
            <div>
                {CloneTemplate?.['Power']?.['Solar Panel'] ? CloneTemplate?.['Power']?.['Solar Panel']?.['name']+' ' : template?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['atmos-data']?.['name']}
                            {' '}({CloneTemplate?.['Power']?.['Solar PanelCount'] ? CloneTemplate?.['Power']?.['Solar PanelCount'] : template?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['user_data']?.['numUnits']})
            </div>
        </div>
    </div>;
};
export default Power;
