import React, { useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import ModalHeader from "../ModalComponents/ModalHeader";

const DataAnalysisModal = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [dataAnalysisItems, showDataAnalysisItems] = useState({
    Satellites: true,
    AOI: true,
    Imaging_Specs: true,
    Edge_Processing: true,
    Ground_Stations: true,
  });

  const [dataAnalysisSats, showDataAnalysisSats] = useState({
    Mission_Sat_01: true,
  });

  const [aoiItems, showAoiItems] = useState({
    AOI_01: true,
    AOI_02: true,
    AOI_03: true,
  });

  const [gsItems, showGsItems] = useState({
    GS_01: true,
    GS_02: true,
  });

  const header = {
    title: "Data Analysis",
    img: require("../../ImageSvgs/Data-Analysis.svg").default,
  };

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal" : "mainModal"} id="dataAnalysis">
            <ModalHeader props={props} parameterProps={parameterProps} header={header} />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Satellites: !dataAnalysisItems.Satellites,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Satellites ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Satellites</div>
              </div>

              {dataAnalysisItems.Satellites && (
                <div className="modalrefOrbitDiv">
                  {dataAnalysisSats.Mission_Sat_01 && (
                    <div className="modalrefOrbitsBtn">
                      <span>&lt;Mission_Sat_01&gt;</span>
                      <button
                        className="modalrefOrbitsCloseBtn"
                        onClick={() =>
                          showDataAnalysisSats({
                            ...dataAnalysisSats,
                            Mission_Sat_01: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      AOI: !dataAnalysisItems.AOI,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.AOI ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Area of Interest</div>
              </div>

              {dataAnalysisItems.AOI && (
                <div className="modalrefOrbitDiv modalAoiBtnDiv">
                  <div className="modalrefOrbitsSpacer">
                    <div
                      className="modalrefOrbitsBtn modalAoiBtn"
                      style={{
                        display: aoiItems.AOI_01 ? "flex" : "none",
                      }}
                    >
                      <span> AOI_01 </span>
                      <button
                        className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                        onClick={() =>
                          showAoiItems({
                            ...aoiItems,
                            AOI_01: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>

                    <div
                      className="modalrefOrbitsBtn modalAoiBtn"
                      style={{
                        display: aoiItems.AOI_02 ? "flex" : "none",
                      }}
                    >
                      <span> AOI_02 </span>
                      <button
                        className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                        onClick={() =>
                          showAoiItems({
                            ...aoiItems,
                            AOI_02: false,
                          })
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                          <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                          <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    className="modalrefOrbitsBtn modalAoiBtn"
                    style={{
                      display: aoiItems.AOI_03 ? "flex" : "none",
                    }}
                  >
                    <span> AOI_03 </span>
                    <button
                      className="modalrefOrbitsCloseBtn modalAoiCloseBtn"
                      onClick={() =>
                        showAoiItems({
                          ...aoiItems,
                          AOI_03: false,
                        })
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                        <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                        <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Imaging_Specs: !dataAnalysisItems.Imaging_Specs,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Imaging_Specs ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Imaging Specs</div>
              </div>

              {dataAnalysisItems.Imaging_Specs && (
                <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Pixels</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in px"}/>
                    X
                    <input className="modalAnalysisInput" type="number" placeholder={"in px"}/>
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">No. of bands</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">FPS</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Bits per Pixels</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"#"} style={{ width: "148px" }} />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">GSD</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in m"} style={{ width: "148px" }} />
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Edge_Processing: !dataAnalysisItems.Edge_Processing,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Edge_Processing ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Edge Processing</div>
              </div>

              {dataAnalysisItems.Edge_Processing && (
                <div className="modalAnalysisDiv">
                  <div className="modalAnalysisText">Compression</div>
                  <input className="modalAnalysisInput" type="number" placeholder={"in mbps"} style={{ width: "148px" }} />
                </div>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showDataAnalysisItems({
                      ...dataAnalysisItems,
                      Ground_Stations: !dataAnalysisItems.Ground_Stations,
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d={dataAnalysisItems.Ground_Stations ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">Ground Station</div>
              </div>

              {dataAnalysisItems.Ground_Stations && (
                <>
                  <div className="modalrefOrbitDiv">
                    {gsItems.GS_01 && (
                      <div className="modalrefOrbitsBtn">
                        <span>GS_01</span>
                        <button
                          className="modalrefOrbitsCloseBtn"
                          onClick={() =>
                            showGsItems({
                              ...gsItems,
                              GS_01: false,
                            })
                          }
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                            <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                            <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                          </svg>
                        </button>
                      </div>
                    )}
                    {gsItems.GS_02 && (
                      <div className="modalrefOrbitsBtn">
                        <span>GS_02</span>
                        <button
                          className="modalrefOrbitsCloseBtn"
                          onClick={() =>
                            showGsItems({
                              ...gsItems,
                              GS_02: false,
                            })
                          }
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                            <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                            <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Downlink Bandwidth</div>
                    <input className="modalAnalysisInput" type="number" placeholder={"in mbps"} style={{ width: "148px" }} />
                  </div>
                </>
              )}
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("Data Analysis successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Power");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DataAnalysisModal;
