import { Fragment } from 'react'
import Console from './console/Console'
import './Modal.scss'
import OnBoardschedule from './schedule/OnBoardschedule'
import GroundSchedule from './schedule/GroundSchedule'
import Telemetry from './telemetry/Telemetry'
import FileQueue from './files/FileQueue'

const ModalProvider = () => {
  return (
    <Fragment>
      <Console />
      <OnBoardschedule />
      <GroundSchedule />
      <Telemetry />
      <FileQueue />
    </Fragment>
  )
}

export default ModalProvider