const ModalHeader = ({ props, parameterProps, header }) => {
  return (
    <div className="modalHeader">
      <div className="modalHeaderImg">
        <img
          src={header.img}
          className=""
        />
      </div>
      <span>{header.title}</span>
      <button
        className="modalCloseBtn"
        onClick={() => {
          parameterProps.setisModalVisible(false);
          props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
        }}
      >
        <img src={require("../../ImageSvgs/CloseButtonWhite.svg").default} />
      </button>
      <button
        className="modalCloseBtn modalMinimizeBtn"
        onClick={() => {
          parameterProps.setisModalVisible(false);
          props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
        }}
      >
        <img src={require("../../ImageSvgs/MinimizeButtonWhite.svg").default} />
      </button>
    </div>
  );
};

export default ModalHeader;
