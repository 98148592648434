// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MissionStore } from '../../../store/MissionSlice';

const Comm = ({ enableBusSlider, selectedComp, setSelectedComp }) => {

    const [template, setTemplate] = useState<any>({});

    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);


    useEffect(() => {
        setTemplate(Satellite?.Template);
    }, []);

    return <div className="config-data-box h-100">

        <div className={`config-card ${selectedComp === 'S-Band Radio' && 'active'}`}
            onClick={() => {
                setSelectedComp('S-Band Radio')
                enableBusSlider(CloneTemplate['Comm']?.['S-Band Radio'] ? CloneTemplate['Comm']?.['S-Band Radio'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['S-band']?.['atmos-data'], 'S-Band Radio', 'Communication', template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['S-band']?.['user_data']?.['numUnits'])
            }}>
            <h5 className="c-color1">
                <span className="me-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                            fill="#9B72F2" />
                    </svg>
                </span>
                S-Band Radio
            </h5>
            <div>
                {CloneTemplate['Comm']?.['S-Band Radio'] ? CloneTemplate['Comm']?.['S-Band Radio']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['S-band']?.['atmos-data']?.['name']}
            </div>
        </div>

        <div className={`config-card ${selectedComp === 'S-Band Antenna' && 'active'}`}
            onClick={() => {
                setSelectedComp('S-Band Antenna')
                enableBusSlider(CloneTemplate['Comm']?.['S-Band Antenna'] ? CloneTemplate['Comm']?.['S-Band Antenna'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['S-band']?.['atmos-data'], 'S-Band Antenna', 'Communication', template?.['assembly']?.[0]?.['communication']?.['antennas']?.['S-band']?.['user_data']?.['numUnits'])
            }}>
            <h5 className="c-color1">
                <span className="me-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                            fill="#9B72F2" />
                    </svg>
                </span>
                S-Band Antenna
            </h5>
            <div>
                {CloneTemplate['Comm']?.['S-Band Antenna'] ? CloneTemplate['Comm']?.['S-Band Antenna']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['S-band']?.['atmos-data']?.['name']}
            </div>
        </div>

        {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band'] &&
            <div className={`config-card ${selectedComp === 'X-Band Radio' && 'active'}`}
                onClick={() => {
                    setSelectedComp('X-Band Radio')
                    enableBusSlider(CloneTemplate['Comm']?.['X-Band Radio'] ? CloneTemplate['Comm']?.['X-Band Radio'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['atmos-data'], 'X-Band Radio', 'Communication', template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    X-Band Radio ({template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['user_data']?.['numUnits']})
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['X-Band Radio'] ? CloneTemplate['Comm']?.['X-Band Radio']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['atmos-data']?.['name']}
                </div>
            </div>
        }

        {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['X-band'] &&
            <div className={`config-card ${selectedComp === 'X-Band Antenna' && 'active'}`}
                onClick={() => {
                    setSelectedComp('X-Band Antenna')
                    enableBusSlider(CloneTemplate['Comm']?.['X-Band Antenna'] ? CloneTemplate['Comm']['X-Band Antenna'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['X-band']?.['atmos-data'], 'X-Band Antenna', 'Communication', template?.['assembly']?.[0]?.['communication']?.['antennas']?.['X-band']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    X-Band Antenna
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['X-Band Antenna'] ? CloneTemplate['Comm']['X-Band Antenna']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['X-band']?.['atmos-data']?.['name']}
                </div>
            </div>
        }

        {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF'] &&
            <div className={`config-card ${selectedComp === 'UHF Radio' && 'active'}`}
                onClick={() => {
                    setSelectedComp('UHF Radio')
                    enableBusSlider(CloneTemplate['Comm']?.['UHF Radio'] ? CloneTemplate['Comm']?.['UHF Radio'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF']?.['atmos-data'], 'UHF Radio', 'Communication', template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    UHF Radio
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['UHF Radio'] ? CloneTemplate['Comm']?.['UHF']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF']?.['atmos-data']?.['name']}
                </div>
            </div>
        }

        {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF'] &&
            <div className={`config-card ${selectedComp === 'UHF Antenna' && 'active'}`}
                onClick={() => {
                    setSelectedComp('UHF Antenna')
                    enableBusSlider(CloneTemplate['Comm']?.['UHF Antenna'] ? CloneTemplate['Comm']['UHF Antenna'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF']?.['atmos-data'], 'UHF Antenna', 'Communication', template?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    UHF Antenna
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['UHF Antenna'] ? CloneTemplate['Comm']['UHF Antenna']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF']?.['atmos-data']?.['name']}
                </div>
            </div>
        }

        {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS'] &&
            <div className={`config-card ${selectedComp === 'AIS Radio' && 'active'}`}
                onClick={() => {
                    setSelectedComp('AIS Radio')
                    enableBusSlider(CloneTemplate['Comm']?.['AIS Radio'] ? CloneTemplate['Comm']?.['AIS Radio'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS']?.['atmos-data'], 'AIS Radio', 'Communication', template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    AIS Radio
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['AIS Radio'] ? CloneTemplate['Comm']?.['AIS Radio']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS']?.['atmos-data']?.['name']}
                </div>
            </div>
        }

        {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['AIS'] &&
            <div className={`config-card ${selectedComp === 'AIS Antenna' && 'active'}`}

                onClick={() => {
                    setSelectedComp('AIS Antenna')
                    enableBusSlider(CloneTemplate['Comm']?.['AIS Antenna'] ? CloneTemplate['Comm']['AIS Antenna'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['AIS']?.['atmos-data'], 'UHF Antenna', 'Communication', template?.['assembly']?.[0]?.['communication']?.['antennas']?.['AIS']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2" />
                        </svg>
                    </span>
                    AIS Antenna
                </h5>
                <div>
                    {CloneTemplate['Comm']?.['AIS Antenna'] ? CloneTemplate['Comm']['AIS Antenna']?.['name'] : template?.['assembly']?.[0]?.['communication']?.['antennas']?.['AIS']?.['atmos-data']?.['name']}
                </div>
            </div>
        }
    </div>;
};
export default Comm;
