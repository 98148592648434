// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MissionStore} from '../../../store/MissionSlice';

const PyloadServer = ({enableBusSlider, selectedComp, setSelectedComp,selectedComp_UId,setSelectedComp_UId}) => {

    const [template, setTemplate] = useState<any>({});
    const [getPayloads, setPayloads] = useState<any[]>([]);
    const [getEdges, setEdges] = useState<any[]>([]);

    const isCloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isCloneTemplate);
    const isNewTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isNewTemplate);
    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);

    useEffect(() => {
        setTemplate(Satellite?.Template);
        setPayloads(Satellite?.Payloads);
        setEdges(Satellite?.Edges);
    },[]);

    return <div className="config-data-box h-100">
            <div className={`config-card ${selectedComp_UId === 'Payload OBC' && 'active'}`} 
                onClick={() => {
                    setSelectedComp('Payload OBC')
                    setSelectedComp_UId('Payload OBC')
                    enableBusSlider(CloneTemplate?.['Payload']?.['Payload OBC'] ? CloneTemplate?.['Payload']?.['Payload OBC'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload OBC']?.['atmos-data'],  'Payload OBC', 'Payload Server', template?.['assembly']?.[0]?.['payloadServer']?.['Payload OBC']?.['user_data']?.['numUnits'])
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 16.5V6.5H2V16.5H9ZM9 4.5V1.5C9 1.23478 9.10536 0.98043 9.29289 0.792893C9.48043 0.605357 9.73478 0.5 10 0.5H19C19.2652 0.5 19.5196 0.605357 19.7071 0.792893C19.8946 0.98043 20 1.23478 20 1.5V17.5C20 17.7652 19.8946 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H1C0.734784 18.5 0.48043 18.3946 0.292893 18.2071C0.105357 18.0196 0 17.7652 0 17.5V5.5C0 5.23478 0.105357 4.98043 0.292893 4.79289C0.48043 4.60536 0.734784 4.5 1 4.5H9ZM11 2.5V16.5H18V2.5H11ZM3 13.5H8V15.5H3V13.5ZM12 13.5H17V15.5H12V13.5ZM12 10.5H17V12.5H12V10.5ZM12 7.5H17V9.5H12V7.5ZM3 10.5H8V12.5H3V10.5Z"
                                fill="#36F097"/>
                        </svg>
                    </span>
                    Payload OBC
                </h5>
                <div>
                    {CloneTemplate?.['Payload']?.['Payload OBC'] ? CloneTemplate?.['Payload']?.['Payload OBC']?.['name'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload OBC']?.['atmos-data']?.['name']}
                </div>
            </div>

            <div className={`config-card ${selectedComp_UId === 'Payload SSD' && 'active'}`} 
                onClick={() => {
                    setSelectedComp('Payload SSD')
                    setSelectedComp_UId('Payload SSD')
                    enableBusSlider(CloneTemplate?.['Payload']?.['Payload SSD'] ? CloneTemplate?.['Payload']?.['Payload SSD'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['atmos-data'],  'Payload SSD', 'Payload Server', (CloneTemplate?.['Payload']?.['Payload SSDCount'] ? CloneTemplate?.['Payload']?.['Payload SSDCount'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['user_data']?.['numUnits']))
                }}>
                <h5 className="c-color1">
                    <span className="me-2">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.8685 13.5589H13.6056V3.85297H3.8685V13.5589ZM10.3599 15.1765H7.1142V16.7941H5.49135V15.1765H3.05708C2.84187 15.1765 2.63548 15.0913 2.48331 14.9396C2.33114 14.7879 2.24565 14.5822 2.24565 14.3677V11.9412H0.622803V10.3236H2.24565V7.08827H0.622803V5.47062H2.24565V3.04415C2.24565 2.82963 2.33114 2.62391 2.48331 2.47222C2.63548 2.32054 2.84187 2.23532 3.05708 2.23532H5.49135V0.617676H7.1142V2.23532H10.3599V0.617676H11.9827V2.23532H14.417C14.6322 2.23532 14.8386 2.32054 14.9908 2.47222C15.143 2.62391 15.2284 2.82963 15.2284 3.04415V5.47062H16.8513V7.08827H15.2284V10.3236H16.8513V11.9412H15.2284V14.3677C15.2284 14.5822 15.143 14.7879 14.9908 14.9396C14.8386 15.0913 14.6322 15.1765 14.417 15.1765H11.9827V16.7941H10.3599V15.1765ZM5.49135 5.47062H11.9827V11.9412H5.49135V5.47062Z"
                                fill="#9B72F2"/>
                        </svg>
                    </span>
                    Payload SSD
                </h5>
                <div>
                    {CloneTemplate?.['Payload']?.['Payload SSD'] ? CloneTemplate?.['Payload']?.['Payload SSD']?.['name'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['atmos-data']?.['name']}
                    {' '}({CloneTemplate?.['Payload']?.['Payload SSDCount'] ? CloneTemplate?.['Payload']?.['Payload SSDCount'] : template?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['user_data']?.['numUnits']})
                </div>
            </div>

            {!isCloneTemplate && getPayloads?.map((data,i) => {
                const uniqueKey = `${data?.['user_data']?.['payload_name']}-${i}`;
                return <div className={`config-card ${selectedComp_UId ===  uniqueKey && 'active'}`} 
                    onClick={() => {
                        const payload = {
                            ...data?.['atmos-data'],
                            name: data?.['user_data']?.['payload_name']
                        }
                        setSelectedComp(data?.['user_data']?.['payload_name'])
                        setSelectedComp_UId(uniqueKey)
                        enableBusSlider(payload,  'Payload', 'Payload Server',0)
                    }} key={i}>
                    <h5 className="c-color1">
                        <span className="me-2">
                            <img src={data["atmos-data"].src}/>
                        </span>
                        {data['user_data']?.['payload_name']}
                    </h5>
                    <div>
                        {data['atmos-data'].name}
                    </div>
                </div>
            })}
            
            {!isCloneTemplate && getEdges?.length > 0 && getEdges?.map((data,i) => {
                const uniqueKey = `${data?.['edge-device']?.['atmos-data']?.['name']}-${i}`;
                const uniquekey1 = `${data?.['edge-ssd']?.['atmos-data']?.['name']}-${i}`;
                return (
                    <>
                        <div className={`config-card ${selectedComp_UId === uniqueKey && 'active'}`} 
                            onClick={() => {
                                setSelectedComp(data?.['edge-device']?.['atmos-data']?.['name'])
                                setSelectedComp_UId(uniqueKey)
                                enableBusSlider(data?.['edge-device']?.['atmos-data'],  'Edge Device', 'Edge Device',0)
                            }}>
                            <h5 className="c-color1">
                                Edge 
                            </h5>
                            <div>
                                {data?.['edge-device']?.['atmos-data']?.['name']}
                            </div>
                        </div> 
                        <div className={`config-card ${selectedComp_UId === uniquekey1 && 'active'}`} 
                            onClick={() => {
                                setSelectedComp(data?.['edge-ssd']?.['atmos-data']?.['name'])
                                setSelectedComp_UId(uniquekey1)
                                enableBusSlider(data?.['edge-ssd']?.['atmos-data'],  'Edge SSD', 'Edge SSD',0)
                            }} key={i}>
                            <h5 className="c-color1">
                                Edge SSD ({data?.['edge-ssd']?.['user_data']?.['numUnits']})
                            </h5>
                            <div>
                                {data?.['edge-ssd']?.['atmos-data']?.['name']}
                            </div>
                        </div>
                    </ >
                )
            })}
    </div>;
};
export default PyloadServer;
