import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from "./RadialSeparators";
import './CircularProgressbar.css';
import { Fragment } from "react";
import { calculateProgressTime, calculateTimePercentage } from "../../components/footer/utils";

interface CircularProgressbarProps {
    simulation_start_time: number;
    simulation_end_time: number;
    simulation_current_time: number;
}

const CircularProgressbar: React.FC<CircularProgressbarProps> = ({ simulation_start_time, simulation_end_time, simulation_current_time }) => {

    return (
        <div className='circular__progress__container'>
            {(simulation_current_time && simulation_start_time) &&
                <Fragment>
                    <div className='circular__progress'>
                        <CircularProgressbarWithChildren
                            value={simulation_current_time}
                            minValue={simulation_start_time}
                            maxValue={simulation_end_time}
                            text={`${calculateTimePercentage(simulation_start_time, simulation_end_time, simulation_current_time)}%`}
                            strokeWidth={15}
                            styles={buildStyles({
                                strokeLinecap: "butt",
                                pathTransitionDuration: 0.5,
                                textColor: '#F0F0F0',
                                pathTransition: "none",
                                trailColor: "rgba(240, 240, 240, 0.2)",
                                pathColor: `rgba(204, 245, 78, 1)`,
                            })}
                        >
                            {RadialSeparators({
                                count: 25,
                                style: {
                                    background: "#000",
                                    width: "2px",
                                    height: `${15}%`
                                }
                            })}
                        </CircularProgressbarWithChildren>
                    </div>
                    <span>{calculateProgressTime(simulation_start_time, simulation_current_time)}</span>
                </Fragment>
            }
        </div>
    )
}

export default CircularProgressbar