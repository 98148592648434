import { createSlice } from '@reduxjs/toolkit';

export class MissionStore {
    Mission = {};
    missionEdit = false;
    satelliteEdit = false;
    satelliteClone = false;
    Missions = [];
    Satellites: [];
    Satellite = {
        name: '',
        missionId: '',
        Payloads: [],
        Edges: [],
        Template: {},
        satellite_Id: '',
    };

    payloadIndex = 1;
    addNewPayload = false;
    isPayloadSlider = false;
    payloadSliderData = {};
    filterPayloadDeviceList = [];
    isEditPayload = false;
    EditPayloadData = {};
    isEditEdgeData = false;
    EditEdgeData = {};
    isEditTemplateData = false;
    EditTemplateData = {};
    satDetails = {
        Configaration: {},
        Payloads: [],
        Edges: [],
        TrueTwins: [],
        Template: {},
        Sat_Id: '',
        Sat_name: '',
        Sat_desc: '',
    };
    route = {
        to: '',
        from: ''
    };
    

    remoteAppStatus:{
        status: 0,
        truetwin_id: '',
    };
    userData = {};
    propulsion = 'Yes';
    isPayloadSteps = false;
    Edges = {};
    isEdgeSelected = false;
    isEdgePreview = false;
    isNewPayloadCatelog = false;
    isTemplateSelected = false;
    isTemplateData = false;
    isNewTemplate = false;
    isCloneTemplate = false;
    Template = {};
    CloneTemplate = {
        Core: {},
        Payload: {},
        Comm: {},
        Power: {},
        Other: {},
    };
    moveToBuild = false;
    stepperStartIdx = 1;
    currentStep = 1;
    updateReviewData = false;
    stepSec = false;
    one = false;
    payloadEdit = {
        goToPayloadStepOne: false,
        data: null,
        goToPreviewStep: false,
    };
    edgeEdit = {
        goToEdge: false,
        data: null,
        goToEdgeSlider: false,
        goToEdgePreview: false,
    };
    four = false;
    busSlider = {
        busSliderSet: false,
        data: {},
        type: '',
        subSystem: '',
        numUnits: 0,
    };
    bus = {
        isBus: true,
        isPayloadServer: false,
        isComm: false,
        isPower: false,
    };
    projects = [];
}

export const MissionSlice = createSlice({
    name: 'missionSlice',
    initialState: {
        Mission : {},
        missionEdit: false,
        satelliteEdit: false,
        satelliteClone: false,
        Missions: [],
        Satellites: [],
        Satellite: {
            name: '',
            missionId: '',
            Payloads: [],
            Edges: [],
            Template: {},
            satellite_Id: '',
        },

        payloadIndex: 1,
        isPayloadSlider: false,
        addNewPayload: false,
        payloadSliderData: {},
        filterPayloadDeviceList: [],
        isEditPayload: false,
        EditPayloadData: {},
        isEdgeSelected: false,
        isEditEdgeData: false,
        EditEdgeData: {},
        isEditTemplateData: false,
        EditTemplateData:{},
        satDetails: {
            Configaration: {},
            Payloads: [],
            Edges: [],
            TrueTwins: [],
            Template: {},
            Sat_Id: '',
            Sat_name: '',
            Sat_desc: '',
        },

        route: {
            to: window.location.pathname,
            from: window.location.pathname,
        },
        


        remoteAppStatus: {
            status: 0,
            truetwin_id: '',
        },
        userData: {},
        propulsion: 'Yes',
        isPayloadSteps: false,
        Edges: {},
        Template: {},
        CloneTemplate: {
            Core: {},
            Payload: {},
            Comm: {},
            Power: {},
            Other: {},
        },
        isEdgePreview: false,
        isNewPayloadCatelog: false,
        isTemplateSelected: false,
        isTemplateData:false,
        isNewTemplate:false,
        isCloneTemplate:false,
        moveToBuild: false,
        stepperStartIdx: 1,
        currentStep: 1,
        updateReviewData: false,
        stepSec: false,
        one: false,
        payloadEdit: {
            goToPayloadStepOne: false,
            data: null,
            goToPreviewStep: false,
        },
        edgeEdit: {
            goToEdge: false,
            data: null,
            goToEdgeSlider: false,
            goToEdgePreview: false,
        },
        bus: {
            isBus: true,
            isPayloadServer: false,
            isComm: false,
            isPower: false,
        },
        four: false,
        busSlider: {
            busSliderSet: false,
            data: {},
            type: '',
            subSystem: '',
            numUnits: 0,
        },
        projects: [],
    },
    reducers: {
        setMission: (state, action) => {
            state.Mission = action.payload;
        },

        setMissionEdit: (state, action) => {
            state.missionEdit = action.payload;
        },
        setMissions: (state, action) => {
            state.Missions = action.payload;
        },

        setSatellite: (state, action) => {
            state.Satellite.name = action.payload?.['name'];
            state.Satellite.missionId = action.payload?.['missionId'];
            state.Satellite.Payloads = action.payload?.['Payloads'];
            state.Satellite.Edges = action.payload?.['Edges'];
            state.Satellite.Template = action.payload?.['Template'];
            state.Satellite.satellite_Id = action.payload?.['satellite_Id'];
        },

        setSatellites: (state, action) => {
            state.Satellites = action.payload;
        },

        setSatelliteEdit: (state, action) => {
            state.satelliteEdit = action.payload;
        },
        setSatelliteClone: (state, action) => {
            state.satelliteClone = action.payload;
        },
        resetSatellite: (state, action) => {
            state.Satellite.name = '';
            state.Satellite.missionId = '';
            state.Satellite.Payloads = [];
            state.Satellite.Edges = [];
            state.Satellite.Template = {};
            state.Satellite.satellite_Id = '';
        },



        setPayloadIndex: (state,action) => {
            state.payloadIndex = action.payload;
        },
        setAddNewPayload: (state, action) => {
            state.addNewPayload = action.payload;
        },
        setIsPayloadEdit: (state, action) => {
            state.isEditPayload = action.payload;
        },
        setIsEdgeEdit: (state, action) => {
            state.isEditEdgeData = action.payload;
        },
        setIsTemplateEdit: (state, action) => {
            state.isEditTemplateData = action.payload;
        },
        setFilterPayloadDeviceList: (state, action) => {
            state.filterPayloadDeviceList = action.payload;
        },
        setPayloadSliderTrue: (state) => {
            state.isPayloadSlider = true;
        },
        setPayloadSliderFalse: (state) => {
            state.isPayloadSlider = false;
        },
        
        setEditPayloadData: (state, action) => {
            state.EditPayloadData = action.payload;
        },
        setEditEdgeData: (state, action) => {
            state.EditEdgeData = action.payload;
        },
        setEdge: (state, action) => {
            state.Edges = action.payload;
        },
        setEdgeSelected: (state, action) => {
            state.isEdgeSelected = action.payload;
        },
        setEditTemplateData: (state, action) => {
            state.EditTemplateData = action.payload;
        },
        setSatDetails: (state, action) => {
            if(action.payload){
                state.satDetails.Configaration = action.payload?.['Configaration'];
                state.satDetails.Payloads = action.payload?.['Payloads'];
                state.satDetails.Edges = action.payload?.['Edges'];
                state.satDetails.TrueTwins = action.payload?.['TrueTwins'];
                state.satDetails.Template = action.payload?.['Template'];
                state.satDetails.Sat_Id = action.payload?.['Sat_Id'];
                state.satDetails.Sat_name = action.payload?.['Sat_name'];
                state.satDetails.Sat_desc = action.payload?.['Sat_desc'];
            }
        },
        reSetSatDetails: (state) => {
            state.satDetails.Configaration = {};
            state.satDetails.Payloads = [];
            state.satDetails.Edges = [];
            state.satDetails.TrueTwins = [];
            state.satDetails.Template = {};
            state.satDetails.Sat_Id = '';
            state.satDetails.Sat_name = '';
            state.satDetails.Sat_desc = '';
        },

        setRoute: (state, action) => {
            state.route.to = action.payload?.['to'];
            state.route.from = action.payload?.['from'];
        },

        setPayloadsStepsToTrue: (state) => {
            state.isPayloadSteps = true;
        },
        setPayloadsStepsToFalse: (state) => {
            state.isPayloadSteps = false;
        },
        
        setRemoteAppStatus: (state, action) => {
            state.remoteAppStatus.status = action.payload?.['status'];
            state.remoteAppStatus.truetwin_id = action.payload?.['truetwin_id']
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        setPropulsionData: (state, action) => {
            if(action.payload){
                state.propulsion = action.payload
            } else {
                state.propulsion = 'Yes'
            }
        },
        
        setEdgeIsPreview: (state) => {
            state.isEdgePreview = true;
        },
        setEdgeNotPreview: (state) => {
            state.isEdgePreview = false;
        },
        setIsNewPayloadCatelog: (state,action) => {
            state.isNewPayloadCatelog = action.payload;
        },
        setShowTemplateDataFalse: (state) => {
            state.isTemplateData = false
        },
        setShowTemplateDataTrue: (state) => {
            state.isTemplateData = true
        },
        setIsNewTemplateFalse: (state) => {
            state.isNewTemplate = false
        },
        setIsNewTemplateTrue: (state) => {
            state.isNewTemplate = true
        },
        setIsCloneTemplateFalse: (state) => {
            state.isCloneTemplate = false
        },
        setIsCloneTemplateTrue: (state) => {
            state.isCloneTemplate = true
        },
        setTemplate: (state, action) => {
            if (action.payload) {
                state.Template = action.payload;
            } else {
                state.Template = {};
            }
        },
        setCloneTemplate: (state, action) => {
            if (action.payload) {
                if(action.payload['Core']){
                    state.CloneTemplate.Core = { ...state.CloneTemplate.Core,...action.payload['Core']};
                }
                else if(action.payload['Payload Server']){
                    state.CloneTemplate.Payload = { ...state.CloneTemplate.Payload,...action.payload['Payload Server']};
                }
                else if(action.payload['Communication']){
                    state.CloneTemplate.Comm = { ...state.CloneTemplate.Comm,...action.payload['Communication']};
                }
                else if(action.payload['Power']){
                    state.CloneTemplate.Power = { ...state.CloneTemplate.Power,...action.payload['Power']};
                }else if(action.payload['Other']){
                    state.CloneTemplate.Other = action.payload['Other'];
                }else{
                    state.CloneTemplate.Core = {}
                    state.CloneTemplate.Payload = {}
                    state.CloneTemplate.Comm = {}
                    state.CloneTemplate.Power = {}
                    state.CloneTemplate.Other = {}
                }

            } else {
                state.CloneTemplate = {
                    Core: {},
                    Payload: {},
                    Comm: {},
                    Power: {},
                    Other: {},
                };
            }
        },
        setShowTemplateSlideToTrue: (state) => {
            state.isTemplateSelected = true;
        },
        setShowTemplateSlideToFalse: (state) => {
            state.isTemplateSelected = false;
        },
        setMoveToBuild: (state) => {
            state.moveToBuild = true;
        },
        setMoveToBuildFalse: (state) => {
            state.moveToBuild = false;
        },
        setStepperStartIdx: (state, action) => {
            state.stepperStartIdx = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setUpdateReviewData: (state) => {
            state.updateReviewData = true;
        },
        setUpdateReviewDataFalse: (state) => {
            state.updateReviewData = false;
        },
        setStepToSec: (state) => {
            state.stepSec = true;
        },
        setStepToSecFalse: (state) => {
            state.stepSec = false;
        },
        /* Edit Mission Steps starts here */
        goToStepOne: (state, action) => {
            state.one = action.payload;
        },
        goToStepTwo: (state, action) => {
            state.payloadEdit.goToPayloadStepOne = action.payload.goToPayloadStepOne;
            state.payloadEdit.data = action.payload.data;
            state.payloadEdit.goToPreviewStep = action.payload.goToPreviewStep;
        },
        goToStepThird: (state, action) => {
            state.edgeEdit.goToEdge = action.payload.goToEdge;
            state.edgeEdit.data = action.payload.data;
            state.edgeEdit.goToEdgeSlider = action.payload.goToEdgeSlider;
            state.edgeEdit.goToEdgePreview = action.payload.goToEdgePreview;
        },
        goToStepFour: (state, action) => {
            state.four = action.payload;
        },
        setBusSlider: (state, action) => {
            state.busSlider.busSliderSet = action.payload.busSliderSet;
            state.busSlider.data = action.payload.data;
            state.busSlider.type = action.payload.type;
            state.busSlider.subSystem = action.payload.subSystem;
            state.busSlider.numUnits = action.payload.numUnits;
        },
        /* Edit Mission Steps ends here */
        busConfig: (state, action) => {
            state.bus = action.payload;
        },

        setProjectsData: (state, action) => {
            state.projects = action.payload;
        }
    },
});

export const {
    setMission,
    setMissionEdit,
    setSatelliteEdit,
    setSatelliteClone,
    setMissions,
    setSatellite,
    resetSatellite,
    setPayloadIndex,
    setFilterPayloadDeviceList,
    setPayloadSliderFalse,
    setPayloadSliderTrue,
    setIsNewPayloadCatelog,
    setAddNewPayload,
    setIsPayloadEdit,
    setEditPayloadData,
    setIsEdgeEdit,
    setEdgeSelected,
    setEditEdgeData,
    setIsTemplateEdit,
    setEditTemplateData,
    setSatellites,
    setRoute,


    setPayloadsStepsToTrue,
    setPayloadsStepsToFalse,
    setSatDetails,
    reSetSatDetails,
    setRemoteAppStatus,
    setUserData,
    setPropulsionData,
    setShowTemplateDataFalse,
    setShowTemplateDataTrue,
    setShowTemplateSlideToTrue,
    setShowTemplateSlideToFalse,
    setIsNewTemplateFalse,
    setIsNewTemplateTrue,
    setIsCloneTemplateFalse,
    setIsCloneTemplateTrue,
    setEdge,
    setEdgeIsPreview,
    setEdgeNotPreview,
    setTemplate,
    setCloneTemplate,
    setMoveToBuild,
    setMoveToBuildFalse,
    setStepperStartIdx,
    setUpdateReviewData,
    setUpdateReviewDataFalse,
    setStepToSec,
    setStepToSecFalse,
    goToStepOne,
    goToStepTwo,
    goToStepThird,
    goToStepFour,
    setBusSlider,
    busConfig,
    setCurrentStep,
    setProjectsData
} = MissionSlice.actions;

export default MissionSlice.reducer;
