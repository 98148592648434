// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MissionService from '../../../service/MissionService';
import { CircularProgress } from '@mui/material';
import { Accordion } from 'react-bootstrap';
import TrueTwinConfig from '../TrueTwins/TrueTwinConfig';
import TrueTwins from './TrueTwins';
import { toast } from 'react-toastify';
import { MissionStore } from '../../../store/MissionSlice';
import Loader from '../../../Layouts/Loader/Loader';

const Simulate = () => {

  const params = useParams();
  const { mission_name, mission_id } = params
  
  const [showBtnGroup, setShowBtnGroup] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [satellites, setSatellites] = useState([]);
  const [openCreateTrueTwin, setOpenCreateTrueTwin] = React.useState({
    isCreateTrueTwin: false,
    sat_id: ''
  });
  const userData = useSelector(
    (state: { mission: MissionStore }) => state.mission.userData
  );
  
  const get_satellites = () => {
    MissionService.get_satellites_by_mission_id(mission_id)?.then((res) => {
      if(res?.data){
        setSatellites(res?.data)
      }
      setLoading(false)
    }).catch((err) => {
        console.error(err);
        setLoading(false)
    })
  } 

  
  useEffect(() => {
    if(mission_id){
      get_satellites()
    }
  },[mission_id])

  

  return (
    <div className='p-7 h-100 w-100' onClick={()=>{setShowBtnGroup('')}}>
        {loading &&  <Loader/>}
        
        <div className="np-acc">
          {satellites?.length > 0 && satellites?.map((sat,i) =>  {
            return(
              <Accordion defaultActiveKey={(sat?.['trueTwinIds'] && sat?.['trueTwinIds']?.length > 0) ? i : ''} key={i}>
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                    <div className='d-flex w-100 align-items-center justify-content-between'>
                      <div className="d-flex align-items-center gap-2">
                          <span className='color-atmos' title={sat?.['name']}>{sat?.['name']?.length > 20 ? sat?.['name'].substring(0, 20)+" ..." : sat?.['name']}</span>
                          {(sat?.['draftMode'] && sat?.['draftMode'] === 'Yes') && <span className='color-atmos fs-14 font-italic'>(Configuration pending)</span>}
                      </div>  
                      <div className=' d-flex gap-2 align-items-center justify-content-center cm-button me-5'
                        onClick={(e)=>{
                          e.stopPropagation()
                          setShowBtnGroup('')
                          if(sat?.['draftMode'] && sat?.['draftMode'] === 'Yes'){
                            toast.error('Satellite configuration is incomplete, configure it before creating True Twin',{toastId:'o'})
                          }else{
                            if(sat?.['trueTwinIds'] && (userData?.['feature-allowed']?.['ttMaxLimit'] ===  sat?.['trueTwinIds']?.length)){
                              toast.warning('You have reached the maximum number of TrueTwins .',{toastId:'o'})
                            }else{
                                setOpenCreateTrueTwin({
                                    isCreateTrueTwin: true,
                                    sat_id: sat?.['uniqueId']
                                })
                            }
                          }
                          
                        }}
                      >
                        <i className="fe fe-plus-circle fs-16 fw-light"></i>
                        <span className="fs-12">Add TrueTwin</span>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                      <TrueTwins sat_Id={sat?.['uniqueId']} mission_name={mission_name} mission_id={mission_id} 
                      sat_name={sat?.['name']} openCreateTrueTwin={openCreateTrueTwin} setLoading={setLoading} 
                      get_satellites={get_satellites} setShowBtnGroup={setShowBtnGroup} showBtnGroup={showBtnGroup}/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          })}
        </div>

      {openCreateTrueTwin.isCreateTrueTwin && <TrueTwinConfig mission_name={mission_name} mission_id={mission_id} sat_id={openCreateTrueTwin.sat_id} close={() => setOpenCreateTrueTwin({isCreateTrueTwin: false, sat_id: ''})} />}
    
    </div>
  )
}

export default Simulate

