import React, { Fragment, useEffect, useState } from 'react'
import ModalDropdown from './ModalDropdown';
import { Form } from 'react-bootstrap';
import { useScroll } from '@react-three/drei';

interface ScenarioGroundStationProps {
  all_ground_stations: any[];
  ground_stations: any[];
  set_scenario_ground_stations: (stations: any) => void;
  navigate_scenario: (data: string) => void;
  data_submited: string[];
  true_twin: Object;
  scenario_step?: string;
  setChooseGs: (data: any) => void;
  setSelectedGS: (data: any) => void;
  selectedGS: string;
  loading: boolean | string;
  currentGlobeType: string;
  setGroundStationCheck: (data: any) => void
}

const ScenarioGroundStation: React.FC<ScenarioGroundStationProps> = ({ all_ground_stations, ground_stations, set_scenario_ground_stations, navigate_scenario, true_twin, scenario_step, data_submited, setChooseGs, setGroundStationCheck, loading, currentGlobeType, selectedGS, setSelectedGS }) => {

  return (
    <div className={`${loading ? "disable__components" : ""}`}>
      <div className="modalBoxShadowDiv">
        <button
          onClick={() => {
            if (data_submited?.includes('Ground_Station')) {
              navigate_scenario('Ground_Station')
            }
          }}
          className={`modalCheckboxDiv bg-transparent ${(!data_submited?.includes('Ground_Station') && scenario_step !== 'Ground_Station') ? "disabledBtn" : ''}`}>
          <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
          <div className={"modalDropDownIconBtn"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d={scenario_step === 'Ground_Station' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                stroke="#F0F0F0"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={"modalCheckboxText modalWhiteSubHeader"}>Ground Stations</div>
          {data_submited?.includes('Ground_Station') && <img className="greenTickIcon" src={require("../Images/GreenTickIcon.svg").default} />}
        </button>
      </div>

      {scenario_step === 'Ground_Station' && (
        <div style={{ margin: "12px" }} className='d-flex flex-column gap-3'>
          {all_ground_stations?.map(gs => (
            <div className='d-flex align-items-center justify-content-between ps-5' key={gs?.['groundStationId']}>
              <div className='d-flex gap-3'>
                <Form.Check
                  type="checkbox"
                  className="h-5 cursor-pointer"
                  checked={ground_stations?.includes(gs?.["groundStationId"])}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={() => {
                    if (ground_stations?.includes(gs?.["groundStationId"])) {
                      set_scenario_ground_stations(ground_stations.filter(id => id !== gs?.["groundStationId"]))
                      setGroundStationCheck({
                        groundStation: gs,
                        isChecked: false
                      })
                    } else {
                      set_scenario_ground_stations([...ground_stations, gs?.["groundStationId"]])
                      setGroundStationCheck({
                        groundStation: gs,
                        isChecked: true
                      })
                    }
                  }}
                />
                <div title={gs?.['groundStationName']} >{gs?.['groundStationName']?.length > 15 ? gs?.['groundStationName']?.substring(0, 15) + '...' : gs?.['groundStationName']}</div>
              </div>
              {<button className={`btn bg-transparent border-0 p-0 cursor-pointer ${currentGlobeType === '3D' ? 'disabled' : ''}`} onClick={() => {
                setChooseGs(gs)
                setSelectedGS(gs?.['groundStationId'])
              }}>
                <i className='fe fe-eye' style={{ color: (selectedGS === gs?.['groundStationId']) ? '#CCF54E' : '' }} />
              </button>}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ScenarioGroundStation
