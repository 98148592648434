import { Fragment, useEffect, useState } from 'react';
import './Contact.scss'
import { filter_options, gs_inner_options, task_inner_options } from './utils';
import ContactAlert from './ContactAlert';
import ContactHeader from './ContactHeader';
import GroundContactDetails from './GroundContactDetails';
import OnboardContactDetails from './OnboardContactDetails';
import GroundAndOnboardContact from './GroundAndOnboardContact';
import useScenario from '../../../../hooks/useScenario';
import useTimeline from '../../../../hooks/useTimeline';
import { MinimizeSvg } from './Svgs';
import Settings from '../../../../custom_components/Settings';
import useUrlParams from '../../../../hooks/useUrlParams';

const Contact = () => {

  const { scenario } = useScenario();
  const { selected_contact, set_selected_contact } = useTimeline()
  const { dashboard } = useUrlParams()
  const [active, setActive] = useState(true);

  const [selectedFilter, setSelectedFilter] = useState<{ option: string, value: any[] }>({
    option: 'ground_station',
    value: []
  });

  const [openSelector, setOpenSelector] = useState('');
  const [filterOptions, setFilterOptions] = useState<any[]>([filter_options[0]]);
  const [filterContent, setFilterContent] = useState<any[]>(gs_inner_options);


  const select_filter_contact_option = (option: any) => {
    if (option.value === 'ground_station') {
      setFilterContent(gs_inner_options);
    } else {
      setFilterContent(task_inner_options);
    }
  }

  const select_inner_option = (option: any) => {
    const selected_filter_options = selectedFilter.value.filter((o: any) => o.value !== option.value);
    setSelectedFilter({
      option: selectedFilter.option,
      value: selectedFilter.value.includes(option.value) ? selected_filter_options : [...selectedFilter.value, option.value]
    });
  }

  const select_contact_option = (contact: any) => {
    set_selected_contact(contact);
    if (contact.value === 'ground_contact') {
      setFilterOptions([filter_options[0]])
      setSelectedFilter({
        option: 'ground_station',
        value: []
      })
      setFilterContent(gs_inner_options);
    } else if (contact.value === 'on_board_schedule') {
      setFilterOptions([filter_options[1]])
      setSelectedFilter({
        option: 'task',
        value: []
      })
      setFilterContent(task_inner_options);
    } else {
      setFilterOptions(filter_options)
      setSelectedFilter({
        option: '',
        value: []
      })
    }
    setOpenSelector('');
  }

  useEffect(() => {
    const onClick = () => {
      setOpenSelector('');
      if (selected_contact.value === 'ground_&_on_board') {
        setSelectedFilter({
          option: '',
          value: []
        });
      }
    }
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick)
  }, [])

  const disabled_contact_section = dashboard === 'quick-deploy' ? 'disabled' : active ? 'active' : ''

  return (
    <div className={`contact__center__container__wrapper ${disabled_contact_section}`}>
      <button className='container__minimization__button'
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <MinimizeSvg />
      </button>
      <div className='contact__center__container'>

        {/* // Alert Section use for operate only */}
        <ContactAlert />
        {/* // Alert Section use for operate only */}
        <Settings />

        <ContactHeader
          openSelector={openSelector}
          selected_contact={selected_contact}
          selectedFilter={selectedFilter}
          filterOptions={filterOptions}
          filterContent={filterContent}
          setSelectedFilter={(data: any) => setSelectedFilter(data)}
          setOpenSelector={(data: any) => setOpenSelector(data)}
          select_contact_option={(data: any) => {
            select_contact_option(data)
          }}
          select_inner_option={(data: any) => select_inner_option(data)}
          select_filter_contact_option={(data: any) => select_filter_contact_option(data)}
        />

        <div className='contact__center__container__body'>
          <Fragment>
            {selected_contact.value === 'ground_contact' &&
              <GroundContactDetails
                simulation_start_time={scenario?.['simulationStartTime']}
                simulation_end_time={scenario?.['simulationStartTime'] + (Number(scenario?.['simulationDuration']) * 60)}
              />}

            {selected_contact.value === 'on_board_schedule' &&
              <OnboardContactDetails
                simulation_start_time={scenario?.['simulationStartTime']}
                simulation_end_time={scenario?.['simulationStartTime'] + (Number(scenario?.['simulationDuration']) * 60)}
              />}

            {selected_contact.value === 'ground_&_on_board' &&
              <GroundAndOnboardContact
                simulation_start_time={scenario?.['simulationStartTime']}
                simulation_end_time={scenario?.['simulationStartTime'] + (Number(scenario?.['simulationDuration']) * 60)}
              />}
          </Fragment>
        </div>
      </div>
    </div>
  )
}

export default Contact