import {
    goToStepThird,
    goToStepTwo,
    resetSatellite,
    setAddNewPayload,
    setBusSlider, setCloneTemplate,
    setEdgeNotPreview,
    setEdgeSelected,
    setEditEdgeData,
    setEditPayloadData,
    setEditTemplateData,
    setIsCloneTemplateFalse,
    setIsEdgeEdit,
    setIsNewPayloadCatelog,
    setIsNewTemplateFalse, setIsPayloadEdit, setIsTemplateEdit, setMissionEdit,
    setPayloadIndex,
    setPayloadSliderFalse,
    setPayloadsStepsToFalse, setSatDetails, setSatelliteClone, setSatelliteEdit, setShowTemplateDataFalse,
    setShowTemplateSlideToFalse,
    setTemplate,
    setUpdateReviewDataFalse,
} from '../../store/MissionSlice';
import { clearBusUsage, setBusPercentage, setBusUsage, setEdgePowerUsage, setEdgeSizeUsage, setEdgeWeightUsage, setMaxUsage, 
    setPayloadPowerUsage, setPayloadSizeUsage, setPayloadUsagePercentage, setPayloadWeightUsage, setEdgeUsagePercentage, setTotalAopCalculateUsage, setTotalPayloadAOPUsage, setTotalEdgeAOPUsage} from '../../store/UsageSlice';
import CommandCenterService from '../../service/CommandCenterService';

export const truncateNumber = (number, decimalPlaces) =>{
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
}

export const eraseMissionData = (dispatch) => {
    setTotalAopCalculateUsage(0)
    dispatch(setPayloadIndex(1))
    dispatch(setMissionEdit(false))
    dispatch(setAddNewPayload(false))
    dispatch(setIsTemplateEdit(false));
    dispatch(setEditTemplateData({}));
    dispatch(setEditEdgeData({}));
    dispatch(setIsEdgeEdit(false));
    dispatch(setIsPayloadEdit(false));
    dispatch(setEditPayloadData({}));
    dispatch(resetSatellite());
    dispatch(setEdgeSelected(false));
    dispatch(setPayloadSizeUsage(0));
    dispatch(setPayloadWeightUsage(0));
    dispatch(setPayloadPowerUsage(0));
    dispatch(setEdgeSizeUsage(0));
    dispatch(setEdgeWeightUsage(0));
    dispatch(setEdgePowerUsage(0));
    dispatch(setIsNewPayloadCatelog(false));
    dispatch(setSatelliteEdit(false));
    dispatch(setSatelliteClone(false));
    dispatch(setUpdateReviewDataFalse());
    dispatch(setPayloadsStepsToFalse());
    dispatch(setShowTemplateSlideToFalse());
    dispatch(goToStepTwo({ goToPayloadStepOne: false, data: null, goTopreviewStep: false }));
    dispatch(goToStepThird({ goToEdge: false, data: null, goToEdgeSlider: false, goToEdgePreview: false }));
    dispatch(setEdgeNotPreview());
    dispatch(setPayloadSliderFalse());
    dispatch(setTemplate({}));
    dispatch(setShowTemplateDataFalse())
    dispatch(setIsNewTemplateFalse())
    dispatch(setIsCloneTemplateFalse())
    dispatch(clearBusUsage());
    dispatch(setCloneTemplate({}))
    dispatch(setBusSlider({
        busSliderSet: false,
        data: {},
        type: '',
        subSystem: '',
    }))
    dispatch(
        setPayloadUsagePercentage({
            size: 0,
            weight: 0,
            power: 0,
        })
    );
    dispatch(
        setEdgeUsagePercentage({
            size: 0,
            weight: 0,
            power: 0,
        })
    );
    dispatch(
        setBusPercentage({
            size: 0,
            weight: 0,
            power: 0,
        })
    );
    dispatch(
        setBusUsage({
            busSizeUsage: 0,
            busWeightUsage: 0,
            busPowerUsage: 0,
        })
    );

    dispatch(
        setMaxUsage({
            maxSizeUsage: 0,
            maxWeightUsage: 0,
            maxPowerUsage: 0,
        })
    );
    dispatch(
        setSatDetails({
          Configaration: {},
          Payloads: [],
          Edges: [],
          Template: {},
          TrueTwins: [],
          Sat_Id: "",
          Sat_name: "",
          Sat_desc: "",
        })
    );
}


export const calculateUsagePercentage = (allparams) => {
    let {temp,templateDataAvailable,dispatch,powerUsage,edgePowerUsage,edgeSizeUsage,edgeWeightUsage,sizeUsage,weightUsage} = allparams
    if (templateDataAvailable) {
        let payload_sizeCent = 0;
        let payload_weightCent = 0;
        let payload_powerCent = 0;
        
        let edge_sizeCent = 0;
        let edge_weightCent = 0;
        let edge_powerCent = 0;
        
        let bus_sizeCent = 0;
        let bus_weightCent = 0;
        let bus_powerCent = 0;

        let busSize = temp?.["template-attribute"]?.["bus-size"];
        let busWeight = temp?.["template-attribute"]?.["bus-mass"];
        let busPower = temp?.["template-attribute"]?.["bus-power"];
        let maxSize = temp?.["template-attribute"]?.["sizeInUnits"];
        let maxWeight = temp?.["template-attribute"]?.["maxWeightInKg"];
        let maxPower = temp?.["template-attribute"]?.["battery-capacity"];

        dispatch(
            setBusUsage({
                busSizeUsage: busSize,
                busWeightUsage: busWeight,
                busPowerUsage: busPower,
            })
        );

        dispatch(
            setMaxUsage({
                maxSizeUsage: maxSize,
                maxWeightUsage: maxWeight,
                maxPowerUsage: maxPower,
            })
        );

        payload_sizeCent = (sizeUsage * 100) / (maxSize );
        payload_sizeCent = parseFloat(truncateNumber(payload_sizeCent, 0));
        payload_weightCent = (weightUsage * 100) / (maxWeight );
        payload_weightCent = parseFloat(truncateNumber(payload_weightCent, 0));
        payload_powerCent = ((powerUsage) * 100) / (maxPower );
        payload_powerCent = parseFloat(truncateNumber(payload_powerCent, 0));
        dispatch(
            setPayloadUsagePercentage({
                size: payload_sizeCent,
                weight: payload_weightCent,
                power: payload_powerCent,
            })
        );
        
        edge_sizeCent = (edgeSizeUsage * 100) / (maxSize );
        edge_sizeCent = parseFloat(truncateNumber(edge_sizeCent, 0));
        edge_weightCent = (edgeWeightUsage * 100) / (maxWeight );
        edge_weightCent = parseFloat(truncateNumber(edge_weightCent, 0));
        edge_powerCent = ((edgePowerUsage) * 100) / (maxPower );
        edge_powerCent = parseFloat(truncateNumber(edge_powerCent, 0));
        dispatch(
            setEdgeUsagePercentage({
                size: edge_sizeCent,
                weight: edge_weightCent,
                power: edge_powerCent,
            })
        );

        bus_sizeCent = (busSize * 100) / temp?.["template-attribute"]?.["sizeInUnits"];
        bus_sizeCent = parseFloat(truncateNumber(bus_sizeCent, 0));
        bus_weightCent =
            (busWeight * 100) / temp?.["template-attribute"]?.["maxWeightInKg"];
        bus_weightCent = parseFloat(truncateNumber(bus_weightCent, 0));
        bus_powerCent =
            (busPower * 100) / temp?.["template-attribute"]?.["battery-capacity"];
        bus_powerCent = parseFloat(truncateNumber(bus_powerCent, 0));
        dispatch(
            setBusPercentage({
                size: bus_sizeCent,
                weight: bus_weightCent,
                power: bus_powerCent,
            })
        );
    }
};

export const calculate_Total_AOP_Usage = (payloads, edges, dispatch) => {
    let total_aop = 0;
    let total_payload_aop = 0;
    let total_edge_aop = 0;
    payloads?.map((v) => {
        total_payload_aop = total_payload_aop + Number(v["user_data"]?.['aop']);
      return total_payload_aop;
    })
    edges?.map((v) => {
        total_edge_aop = total_edge_aop + Number(v?.['edge-device']?.["user_data"]?.['total_aop']);
      return total_edge_aop;
    })
    total_aop = total_payload_aop + total_edge_aop
    dispatch(setTotalPayloadAOPUsage(truncateNumber(total_payload_aop, 2)));
    dispatch(setTotalEdgeAOPUsage(truncateNumber(total_edge_aop, 2)));
    dispatch(setTotalAopCalculateUsage(truncateNumber(total_aop, 2)));
}

export const calculatePayloadUsage = (allPayloads,dispatch) => {
    let size = 0;
    let power = 0;
    let weight = 0;
    allPayloads?.map((v) => {
      size = size + Number(v["atmos-data"]?.["product-cookie"]?.["Size"]);
      power = power + Number(v["user_data"]?.["peak_solar_power"]);
      weight = weight + Number(v["atmos-data"]?.["weight_in_gms"]);
      return 0;
    });
    weight = weight / 1000;
    dispatch(setPayloadSizeUsage(size));
    dispatch(setPayloadPowerUsage(power));
    dispatch(setPayloadWeightUsage(weight));
    dispatch(setEdgePowerUsage(0));
    dispatch(setEdgeSizeUsage(0));
    dispatch(setEdgeWeightUsage(0));
  };

export const calculateEdgeUsage = (edges,dispatch) => {
    let edgePower = 0
    let edgeSize = 0
    let edgeWeight = 0
    edges?.map(item => {
        edgePower = edgePower + Number(item?.['edge-device']?.['user_data']?.['peak_solar_power'])
        // edgeSize = edgeSize + Number(item?.['edge-device']?.['atmos-data']?.["product-cookie"]?.["Size"]) + (Number(item?.['edge-ssd']?.['atmos-data']?.["product-cookie"]?.["Size"]) * Number(item?.['edge-ssd']?.['user_data']?.["numUnits"]))
        edgeSize = edgeSize + Number(item?.['edge-device']?.['atmos-data']?.["product-cookie"]?.["Size"])
        edgeWeight = edgeWeight + Number(item?.['edge-device']?.['atmos-data']?.['weight_in_gms']) + (Number(item?.['edge-ssd']?.['atmos-data']?.['weight_in_gms']) * Number(item?.['edge-ssd']?.['user_data']?.["numUnits"]))
        return 0
    })
    edgeWeight = edgeWeight / 1000;
    
    dispatch(setEdgePowerUsage(Number(truncateNumber(edgePower, 2))));
    dispatch(setEdgeSizeUsage(Number(truncateNumber(edgeSize, 2))));
    dispatch(setEdgeWeightUsage(Number(truncateNumber(edgeWeight, 2))));
}

export const removeTruetwin = (trueTwinId, callFun) => {
    CommandCenterService.removeTrueTwin(trueTwinId)?.then((res) => {
        if (res){
            callFun()
        }
    }).catch(err => {
        console.error(err)
    })
}
export const removeGStation = (item,trueTwinId,callFun) => {
    CommandCenterService.removeGroundStation(item?.['groundStationId'])?.then(res => {
        if (res) {
            removeTruetwin(trueTwinId,callFun)
        }
    }).catch(err => {
        console.error(err)
        removeTruetwin(trueTwinId,callFun)
    })
}

export const removeDeployTrueTwin = (item, callFun) => {
    let trueTwinId = item?.['trueTwinId']
    if(item?.['Category'] === 'TrueTwin'){
        CommandCenterService.disconnectGroundStation(item?.['groundStationId'],trueTwinId)?.then(res => {
            if (res.data.status === "Disconnected") {
                removeGStation(item,trueTwinId,callFun)
            }else{
                removeGStation(item,trueTwinId,callFun)
            }
        }).catch(err => {
            console.error(err)
            removeGStation(item,trueTwinId,callFun)
        })
    }else{
        removeTruetwin(trueTwinId,callFun)
    }
    
}


export const navigateTo = (path, navigate) => {
    navigate(`${process.env.PUBLIC_URL}/${path}`)
}

export function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*+\=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
}

export const uuidv4 = () =>{
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


export const containsWhitespace = (str) =>{
    if(str && !str.trim()){
        return true
    }else{
        return false
    }
    
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function getFileTypeFromFileName(fileName) {
    // Create a mapping of common file extensions to MIME types
    const fileExtensionToTypeMap = {
      txt: "text/plain",
      js: "application/javascript",
      json: "application/json",
      ts: "application/typescript",
      image: {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        bmp: "image/bmp",
      },
      video: {
        mp4: "video/mp4",
        avi: "video/x-msvideo",
        mkv: "video/x-matroska",
      },
      pdf: "application/pdf",
      zip: "application/zip",
      log: "text/plain",
      bmp: "image/bmp",
    };
  
    // Extract the file extension from the given file name
    const fileExtension = fileName.split(".").pop().toLowerCase();
  
    // Look up the MIME type based on the file extension
    const fileType = fileExtensionToTypeMap[fileExtension];
  
    if (fileType) {
      if (typeof fileType === "object") {
        // If it's an object, look up the specific image or video type
        return fileType[fileExtension];
      } else {
        // Otherwise, return the found MIME type
        return fileType;
      }
    } else {
      // Return a default type if the extension is not recognized
      return "application/octet-stream"; // Generic binary data
    }
  }


  export function downloadFile(data, fileName) {
    var contentType = getFileTypeFromFileName(fileName);
    var binaryData = atob(data);
    var arrayBuffer = new ArrayBuffer(binaryData.length);
    var byteArray = new Uint8Array(arrayBuffer);
    for (var i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }
    var blob = new Blob([arrayBuffer], { type: contentType });

    // Create an object URL for the blob
    var url = URL.createObjectURL(blob);

    // Create an anchor element for the download
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  }

  export function validateIPv4(ip) {
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    return ipv4Regex.test(ip);
  }

export const getYearLists = (length) => {
    const current = new Date().getFullYear();
    return Array.from({length: length}, (_, i) => current + i);
}

export const getNextDays = (dateTimeString) => {
    const dates = new Set();
    for(let i = 0; i < 48; i++) {
        const nextDateTime = new Date(dateTimeString);
        nextDateTime.setTime(nextDateTime.getTime() + (i * 60 * 60 * 1000));
        const dateOnly = nextDateTime.getDate();
        dates.add(dateOnly);
    }
    return [...dates];
}

export const getRandomFourDigits = () => {
    return Math.floor(1000 + Math.random() * 9000);
}