import React, { createContext, useState, ReactNode } from 'react';
import { FrameDetails, MarkerDetails, SelectOption } from '../components/center/hud/contact/utils';
import { get_formated_time } from '../utils';
import { get_current_timeframe } from '../hooks/utils';


interface TimelineContextType {
    loading: boolean;
    timelist: { date: string, time: string, epoch: number }[];
    window_width: number;
    selected_timeframe: SelectOption;
    selected_contact: SelectOption;
    marker: MarkerDetails;
    timeframe_details: FrameDetails;
    set_loading: (loading: boolean) => void;
    set_timelist: (list: { date: string, time: string, epoch: number }[]) => void;
    set_window_width: (width: number) => void;
    set_selected_contact: (option: SelectOption) => void;
    set_marker: (marker: MarkerDetails) => void;
    set_timeframe_details: (details: FrameDetails) => void;
    select_timeframe: (option: SelectOption) => void;
    get_time_list: (time: number) => void;
    change_frame: (side: string, simulation_start_time: number, simulation_end_time: number) => void;
    set_cesium_time: (time: number, simulation_start_time: number, simulation_end_time: number) => void
}

export const TimelineContext = createContext<TimelineContextType | undefined>(undefined);

interface TimelineProviderProps {
    children: ReactNode;
}

const TimelineProvider = ({ children }: TimelineProviderProps) => {
    const [loading, set_loading] = useState<boolean>(true);
    const [window_width, set_window_width] = useState<number>(window.innerWidth);
    const [selected_contact, set_selected_contact] = useState<SelectOption>({
        label: 'GROUND & ONBOARD',
        value: 'ground_&_on_board'
    });
    const [selected_timeframe, set_selected_timeframe] = useState<SelectOption>({
        label: '30 Min',
        value: 30,
    });
    const [marker, set_marker] = useState<MarkerDetails>({
        epoch: 0,
        position: 0
    });
    const [timeframe_details, set_timeframe_details] = useState<FrameDetails>({
        frame_start_time: 0,
        frame_end_time: 0,
        frame_duration: 1800,
        fream_step: 0,
        manual_change: false
    });

    const [timelist, set_timelist] = useState<{ date: string, time: string, epoch: number }[]>([]);

    const select_timeframe = (option: SelectOption) => {
        set_selected_timeframe(option);
        set_timeframe_details({
            ...timeframe_details,
            frame_duration: option.value as number * 60,
        })
    }
    const roundup_time = (start_time: number) => {
        // Convert epoch time to Date object
        const date = new Date(start_time * 1000);
        // Get minutes and round to nearest 30-minute interval
        const minutes = date.getMinutes();
        const roundedMinutes = 30 * Math.floor(minutes / 30);

        // Set rounded minutes and reset seconds and milliseconds
        date.setMinutes(roundedMinutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return Math.floor(date.getTime() / 1000);
    }

    const get_time_list = (epoch_start_time) => {
        const rounded_start_time = roundup_time(epoch_start_time);
        const timeframe_duration = timeframe_details.frame_duration;
        if (rounded_start_time) {
            const epoch_end_time = rounded_start_time + timeframe_duration;
            const epoch_center_time = ((rounded_start_time! + epoch_end_time) / 2);

            const start_time = {
                date: `${get_formated_time(rounded_start_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(rounded_start_time!)?.split('T')?.[1]}`,
                epoch: rounded_start_time!
            }
            const end_time = {
                date: `${get_formated_time(epoch_end_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(epoch_end_time)!.split('T')?.[1]}`,
                epoch: epoch_end_time!
            }
            const center_time = {
                date: `${get_formated_time(epoch_center_time)?.split('T')?.[0]}T`,
                time: `${get_formated_time(epoch_center_time)!.split('T')?.[1]}`,
                epoch: epoch_center_time!
            }
            const time_list: { date: string, time: string, epoch: number }[] = [start_time, center_time, end_time];
            set_timelist(time_list);
            return time_list;
        }
    }

    const set_frame_start_time = (start_time: number, manual_change: boolean | string) => {
        const timeframe_duration = timeframe_details.frame_duration;
        set_timeframe_details((prev) => ({
            ...prev,
            frame_start_time: start_time,
            frame_end_time: start_time + timeframe_duration,
            manual_change: manual_change
        }))
    }

    const change_frame = (side: string, simulation_start_time: number, simulation_end_time: number) => {

        const frame_start_time = timeframe_details.frame_start_time;
        const timeframe_duration = timeframe_details.frame_duration;

        if (side === 'left') {
            const new_start_time = frame_start_time - timeframe_duration
            const timelist = get_time_list(new_start_time);
            if (timelist) {
                const marker_in_frame = marker.epoch >= timelist[0]?.epoch && marker.epoch <= timelist[3]?.epoch ? false : 'left'
                set_frame_start_time(new_start_time, marker_in_frame)
            }
        } else {
            const new_start_time = frame_start_time + timeframe_duration
            const timelist = get_time_list(new_start_time);
            if (timelist) {
                const marker_in_frame = marker.epoch >= timelist[0]?.epoch && marker.epoch <= timelist[3]?.epoch ? false : 'right'
                set_frame_start_time(new_start_time, marker_in_frame)
            }
        }
    }


    const set_cesium_time = (time: number, simulation_start_time: number, simulation_end_time: number) => {
        const current_time = time / 1000
        const frame_change_manually = timeframe_details.manual_change
        const timeframe_duration = timeframe_details.frame_duration;
        const per_sec_pixel = window_width / timeframe_duration
        const start_time = roundup_time(simulation_start_time)
        const current_time_frame = get_current_timeframe(current_time, start_time, timeframe_duration);
        if (current_time_frame?.length > 0) {
            if (!frame_change_manually) {
                get_time_list(current_time_frame?.[0]);
                set_frame_start_time(current_time_frame?.[0], false)
            }
            set_marker(() => ({
                epoch: current_time,
                position: ((current_time - current_time_frame?.[0]) * per_sec_pixel)
            }))
        }
        set_loading(false)
    }

    return (
        <TimelineContext.Provider
            value={{
                loading,
                marker,
                timelist,
                set_loading,
                set_timelist,
                set_marker,
                window_width,
                set_window_width,
                select_timeframe,
                selected_contact,
                timeframe_details,
                selected_timeframe,
                set_selected_contact,
                set_timeframe_details,
                get_time_list,
                change_frame,
                set_cesium_time,
            }}>
            {children}
        </TimelineContext.Provider>
    )
}

export default TimelineProvider