import React, { useEffect, useState } from "react";
import "./CommsTele.css";

function CommsConfig({ selectedCOMMSSubButton, configStep, commsCount }) {
    const [mergedArray, setMergedArray] = useState([]);

    useEffect(() => {    
        if (commsCount?.["UHFConfig"] && Object.keys(commsCount?.["UHFConfig"])?.length > 0) {
            const downLinkArray = Object.keys(
                commsCount?.["UHFConfig"]?.["UHFDownLinkConfig"]
            ).map((key) => ({
                key,
                value: commsCount?.["UHFConfig"]?.["UHFDownLinkConfig"][key],
            }));
            const upLinkArray = Object.keys(
                commsCount?.["UHFConfig"]?.["UHFUpLinkConfig"]
            ).map((key) => ({
                key,
                value: commsCount?.["UHFConfig"]?.["UHFUpLinkConfig"][key],
            }));

            const upLinkMap = new Map(
                upLinkArray.map((item) => [item.key, item.value])
            );
            var mergedArray = downLinkArray.map((item) => ({
                key: item.key,
                value1: item.value,
                value2: upLinkMap.get(item.key),
            }));
            setMergedArray(mergedArray);
        }
    }, [commsCount]);

    const getColorForValue = (key, value) => {
        if (
            (key === "FECEnable" ||
                key === "CRCEnable" ||
                key === "WhiteningEnable") &&
            value === 1
        ) {
            return "#CCF54E";
        } else if (
            (key === "FECEnable" ||
                key === "CRCEnable" ||
                key === "WhiteningEnable") &&
            value === 0
        ) {
            return "#FF3333";
        } else {
            return "#fff";
        }
    };

    const getValueLabel = (key, value) => {
        if (key === "FECEnable" && value === 0) {
            return "Disabled";
        } else if (key === "FECEnable" && value === 1) {
            return "Enabled";
        } else if (key === "CRCEnable" && value === 0) {
            return "Disabled";
        } else if (key === "CRCEnable" && value === 1) {
            return "Enabled";
        } else if (key === "WhiteningEnable" && value === 0) {
            return "Disabled";
        } else if (key === "WhiteningEnable" && value === 1) {
            return "Enabled";
        } else {
            return value;
        }
    };
    return (
        <>
            
            {selectedCOMMSSubButton !== "SYSTEM" && configStep === "Comms" && (
                <div className="container commsConfigMainWrapper ">
                    <table className="table">
                        <thead>
                            <tr className="remove-bottom-border-config-th">
                                <th scope="col">Configurations</th>
                                <th scope="col">uplink</th>
                                <th scope="col">downlink</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(commsCount)?.length>0&&mergedArray?.map((item) => (
                                <tr className="remove-bottom-border-config" key={item.key}>
                                    <td>{item.key}</td>
                                    <td
                                        style={{ color: getColorForValue(item.key, item.value1) }}
                                    >
                                        {getValueLabel(item.key, item.value1)}
                                    </td>
                                    <td
                                        style={{ color: getColorForValue(item.key, item.value2) }}
                                    >
                                        {getValueLabel(item.key, item.value2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
           <div className="container commsConfigMainWrapper">
                    <table className="table">
                        <thead>
                            <tr className="remove-bottom-border-config-th">
                                <th scope="col">Power</th>
                                <th scope="col">{""}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(commsCount)?.length>0&&Object.entries(commsCount?.UHFConfig?.power || {})?.map(([key, value]) => (
                                <tr className="remove-bottom-border-config" key={key}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </>
    );
}

export default CommsConfig;
