import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";


const useModals = () => {
    const navigate = useNavigate();
    const [modalsDetails, setModalsDetails] = useState({})

    useEffect(() => {
        if (!sessionStorage.getItem('modals')) {
            sessionStorage.setItem('modals', JSON.stringify(modalsDetails))
        } else {
            const modals = JSON.parse(sessionStorage.getItem('modals') || '{}')
            setModalsDetails(modals)
        }
    }, [])

    const update_url = (modals: any) => {
        const new_search_params = new URLSearchParams()
        if (Object.keys(modals).length === 0) {
            setModalsDetails(modals)
            sessionStorage.setItem('modals', JSON.stringify(modals))
            navigate(window.location.pathname)
        } else {
            const last_object = Object.keys(modals)[Object.keys(modals).length - 1]
            const state = modals[last_object].pinned ? 'pinned' : 'open'
            const updated_modals = {
                ...modals,
                [last_object]: {
                    ...modals[last_object],
                    current_modal: last_object
                }
            }
            setModalsDetails(updated_modals)
            sessionStorage.setItem('modals', JSON.stringify(updated_modals))
            new_search_params.append(last_object, state)
            navigate(window.location.pathname + '?' + new URLSearchParams(new_search_params).toString())
        }
    }

    const handleUpdateUrl = (modal: string, action: string) => {
        let modals = JSON.parse(sessionStorage.getItem('modals') || '{}');
        let updated_modals = { ...modals }
        if (action === 'close') {
            delete updated_modals[modal];
            updated_modals = Object.keys(updated_modals).length === 0 ? {} : updated_modals;
            update_url(updated_modals)
        } else {
            if (Object.keys(updated_modals).length === 0) {
                updated_modals = {
                    [modal]: {
                        open: action === 'close' ? false : true,
                        pinned: action === 'pinned' ? true : false,
                    },
                }
                update_url(updated_modals)
            } else {
                delete updated_modals[modal];
                Object.keys(updated_modals).map((key) => {
                    if (updated_modals[key].pinned) {
                        updated_modals = {
                            ...updated_modals,
                            [key]: {
                                open: updated_modals[key].pinned ? true : false,
                                pinned: updated_modals[key].pinned ? true : false,
                            },
                        }
                    } else {
                        delete updated_modals[key];
                    }
                })
                updated_modals = {
                    ...updated_modals,
                    [modal]: {
                        open: true,
                        pinned: action === 'pinned' ? true : false,
                    },
                }
                update_url(updated_modals)
            }

        }

    };

    const getModalDetails = (modal: string) => {
        let modal_details = JSON.parse(sessionStorage.getItem('modals') || '{}');
        if (modal_details[modal]) {
            return modal_details[modal]
        } else {
            return {
                open: false,
                pinned: false,
            }
        }
    }

    return {
        handleUpdateUrl,
        getModalDetails,
        modalsDetails,
    }
}

export default useModals