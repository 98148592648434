import { useContext } from 'react'
import { TimelineContext } from '../providers/TimelineProvider';

const useTimeline = () => {
    const context = useContext(TimelineContext);
    if (context === undefined) {
        throw new Error('useTimeline must be used within a TimelineProvider');
    }
    return context;
}

export default useTimeline