
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

export const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 15,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
        backgroundColor: '#CCF54E',
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(12px)',
        backgroundColor: '#CCF54E',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#CCF54E',
        '& + .MuiSwitch-track': {
          opacity: 0.7,
          backgroundColor: 'rgba(255,255,255,0.4)',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 11,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 0.7,
      backgroundColor: 'rgba(255,255,255,0.4)',
      boxSizing: 'border-box',
    },
  }));