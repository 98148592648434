import React, { useState } from "react";
import "./TimelineEventCell.css";
import MissionModelingService from "../../MissionModelingService";
import { toast } from "react-toastify";
import ModalCreationMessage from "../ModalCreationMessage";
import { useParams } from "react-router-dom";

const TimelineEventCell = ({ props, parameterProps }) => {
  const params = useParams()
  const {
    mission_name,
    mission_id
  } = params;

  let geofenceSwath, geofenceTimeframe, geofenceRevisits;

  const [message, setMessage] = useState("");
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const[aoiname,setAOIname]=useState("")

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };


  const postAreaOfInterest = () => {
    const boundingBoxCoordinateList: string[][] = [];
    props.boundingBoxCoordinates?.map((item) => {
      boundingBoxCoordinateList.push(item);
    });

    MissionModelingService.getAllAreaOfInterest(mission_id)
      .then((result) => {
        if(result?.data){
            let areaOfInterestName = "aoi_01"
            if(result?.data?.length > 0){
              let newEntryNum = parseInt(result?.data[result?.data?.length-1]?.name?.slice(4, result?.data[result?.data?.length-1]?.name?.length));
              let areaOfInterest = newEntryNum < 9 ? "aoi_0":"aoi_";
              areaOfInterestName = areaOfInterest+(newEntryNum + 1)
            }

            let body = {
              name: aoiname?aoiname:"aoi_01",
              coordinates: boundingBoxCoordinateList,
              // coverageTimeframe: parameterProps.timeframe,
              // revisits: parameterProps.revisits,
              // swath : parameterProps.swath,
              type: "geofence",
            };

            MissionModelingService.postAreaOfInterest(body, mission_id)
              .then((res) => {
                  props.setModalProps((previousState) => ({
                    ...previousState,
                      isTimelineEventCellVisible: false,
                      modalComponent: props.modalComponent
                }))
                props.setSidebarProps({
                  isSidebarVisible: props.isSidebarVisible,
                  sidebarComponent: props.sidebarComponent,
                  isComponentUpdated: !props.isComponentUpdated,
                });
                showSuccessMessage("Area Of Interest Posted SuccessFully")
                props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                // props.setSidebarProps({isSidebarVisible: true})
              })
              .catch((err) => {
                console.log(err);
              });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    
  };
  return (
    <>
     {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {props.isTimelineEventCellVisible && (
        <div
          className={
            props.isSidebarVisible
              ? "timelineEventCell sidebarOpenTimeline"
              : "timelineEventCell"
          }
        >
          <div className="timelineEventHeader">
            <div className="timelineEventHeaderTitle">
              <span>Geofence</span>
            </div>
            <button
              className="timelineEventHeaderCloseBtn"
              onClick={() =>
                  props.setModalProps((previousState) => ({
                      ...previousState,
                        isTimelineEventCellVisible: false,
                        modalComponent: props.modalComponent
                  }))
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.19998 10.8L10.8 1.19995"
                  stroke="#F30000"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                />
                <path
                  d="M1.2 1.19995L10.8 10.8"
                  stroke="#F30000"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              className="timelineEventHeaderDoneBtn"
              onClick={() =>
                postAreaOfInterest()
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
              >
                <path
                  d="M1.19995 5.22868L4.39995 8.42868L10.8 1.57153"
                  stroke="#CCF54E"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="timelineEventContent">
            <div className="timelineEventContentText">
              <span>AOI Name</span>
            </div>
            <div className="timelineEventContentInput">
              <input
                value={geofenceSwath}
                onChange={(event) => {
                  // parameterProps.setboundingBoxParameters({
                  //   swath: event.target.value,
                  //   timeframe: parameterProps.timeframe,
                  //   revisits: parameterProps.revisits,
                  // });
                 setAOIname(event.target.value)
                }}
                className="timelineEventInput"
                type="text"
                placeholder=""
              />
            </div>
          </div>
          {/* Temporary commenting as it may need in future */}
          {/* <div className="timelineEventContent">
            <div className="timelineEventContentText">
              <span>Swath</span>
            </div>
            <div className="timelineEventContentInput">
              <input
                value={geofenceSwath}
                onChange={(event) => {
                  parameterProps.setboundingBoxParameters({
                    swath: event.target.value,
                    timeframe: parameterProps.timeframe,
                    revisits: parameterProps.revisits,
                  });
                }}
                className="timelineEventInput"
                type="number"
                placeholder="km"
              />
            </div>
          </div>
          <div className="timelineEventContent">
            <div className="timelineEventContentText">
              <span>Coverage Timeframe</span>
            </div>
            <div className="timelineEventContentInput">
              <input
                value={geofenceTimeframe}
                onChange={(event) => {
                  parameterProps.setboundingBoxParameters({
                    swath: parameterProps.swath,
                    timeframe: event.target.value,
                    revisits: parameterProps.revisits,
                  });
                }}
                className="timelineEventInput"
                type="number"
                placeholder="in hours"
              />
            </div>
          </div>
          <div className="timelineEventContent">
            <div className="timelineEventContentText">
              <span>Revisits per day</span>
            </div>
            <div className="timelineEventContentInput">
              <input
                value={geofenceRevisits}
                onChange={(event) => {
                  parameterProps.setboundingBoxParameters({
                    swath: parameterProps.swath,
                    timeframe: parameterProps.timeframe,
                    revisits: event.target.value,
                  });
                }}
                className="timelineEventInput"
                type="number"
                placeholder="#"
              />
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};
export default TimelineEventCell;