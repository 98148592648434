/* eslint-disable */
import React, { useState } from 'react';
import './EclipseReport.css';
import EclipseAnalysisGraph from './EclipseAnalysisGraph';

const Report = ({ report }) => (
  <div className="report-container-eclipse">
    {report?.map((item) => {
      switch (item.type) {
        case 'Spaceobject':
          return renderSpaceobject(item);
        case 'Plane':
          return renderPlane(item);
        case 'Satellite':
          return renderSatellite(item, item.type);
        default:
          return null;
      }
    })}
  </div>
);

const renderSpaceobject = (spaceobject) => (
  <div key={spaceobject.spaceobjectId} className="spaceobject-container">
    <h5>{spaceobject.spaceobjectName}</h5>
    {spaceobject.planes.map((plane) => renderPlane(plane))}
  </div>
);

const renderPlane = (plane) => (
  <div key={plane.planeId} className="plane-container">
    <div className="plane-header position-relative">
      <span className='position-absolute'>{plane.planeName}</span>
      {renderSatelliteDropdown(plane.satellites)}
    </div>
  </div>
);

const renderSatelliteDropdown = (satellites) => {
  const [selectedSatellite, setSelectedSatellite] = useState(satellites[0]);

  const handleSatelliteChange = (event) => {
    const selectedSatelliteId = event.target.value;
    const selectedSatellite = satellites.find((sat) => sat.satelliteId === selectedSatelliteId);
    setSelectedSatellite(selectedSatellite);
  };

  const showSatelliteTitle = satellites.length === 0;

  return (
    <div className=''>
      <div className="satellite-dropdown-container">
        {showSatelliteTitle && <label>Select Satellite:</label>}
        <select
          className="satellite-dropdown-eclipse"
          onChange={handleSatelliteChange}
          value={selectedSatellite?.satelliteId || ''}
        >
          {satellites.map((sat) => (
            <option key={sat.satelliteId} value={sat.satelliteId}>
              {sat.satelliteName}
            </option>
          ))}
        </select>
        {selectedSatellite && renderSatellite(selectedSatellite, satellites)}
      </div>
    </div>
  );
};

const renderSatellite = (satellite, reportType) => {
  console.log('Rendering satellite:', satellite);

  return (
    <>
    <div key={satellite.satelliteId} className="satellite-container">
      {reportType === 'Satellite' && (
        <h4>{satellite.satelliteName}</h4>
      )}
      <div className="overview-container mb-5">
        {/* <h1>{satellite?.data}</h1> */}
        
        <div className="overview-list">
          <div className="reportDataValues">
            <div className="reportDataValuesLabel">Avg Eclipse Duration</div>
            <div className="reportDataValuesDivNum">{satellite?.overview?.avgEclipseDuration?satellite?.overview?.avgEclipseDuration:"-"} mins</div>
            <div className="reportDataValuesLabel">Max Eclipse Duration</div>
            <div className="reportDataValuesDivNum">{satellite?.overview?.maxEclipseDuration?satellite?.overview?.maxEclipseDuration:"-"} mins</div>
            {/* <div className="reportDataValuesLabel">Eclipse Fraction</div>
            <div className="reportDataValuesDivNum">{satellite?.overview?.eclipseFraction?satellite?.overview?.eclipseFraction:"-"}</div> */}
            <div className="reportDataValuesLabel">No of Eclipse</div>
            <div className="reportDataValuesDivNum">{satellite?.overview?.noOfEclipse?satellite?.overview?.noOfEclipse:"-"}</div>
          </div>
        </div>
        <EclipseAnalysisGraph data={{ height: satellite?.data, time: satellite?.timeline }}/>
      </div>
    </div>
    </>
  );
};

export default Report;
