//@ts-nocheck
import { iteratee } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as SvgCancleIcon } from '../../../../../assets/images/svgs/cancle.svg'
import { ReactComponent as SvgPreviewIcon } from '../../../../../assets/images/svgs/previews.svg'
import './ADCSGrafana.css'
import Modal from 'react-bootstrap/Modal';
import AdcsPowerGrafanaPanel from './AdcsPowerGrafanaPanel';
import AdcsMeasurenmentGrafanaPanel from './AdcsMeasurenmentGrafanaPanel';
import { useParams } from 'react-router-dom';
import MissionService from '../../../../../service/MissionService';
import AdcsStateGrafanaPanels from './AdcsStateGrafanaPanels';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
     <div className='modal-style'>
        <div className='d-flex justify-content-between mt-4 ms-6 me-6'>
            <span> {props.currentState}</span>
            <SvgCancleIcon className='cursor-pointer' onClick={props.onHide}/>
        </div>

        {props.currentMode === "error" && props?.currentModeData?.objectList?.map((item, index) => (
            <div key={index} className={index <4 ? 'm-4 history-latest-container' : 'm-4 history-oldest-container'}>
                <div>
                    <span className='ms-4 history-main-text'> {item?.Component_Name} </span>
                    <div className='d-flex justify-content-between ms-4 me-4 mb-2'>
                        <span className='history-sub-text'> {item?.Timestamp} </span>
                        <span className={ index <4 ? item?.ErrorStatus !== "Resolved" ? 'history-error-text' : 'history-success-text' : 'history-normal-text'}>  {item?.ErrorStatus} </span>
                    </div>
                </div>
            </div>
        ))}

        {props.currentMode === "enabled" && props?.currentModeData?.objectList?.map((item, index) => (
            <div key={index} className={'m-4 history-latest-container'}>
                <div>
                    <span className='ms-4 history-main-text'> {item?.Component_Name} </span>
                    <div className='d-flex justify-content-between ms-4 me-4 mb-2'>
                        <span className='history-sub-text'> {item?.Timestamp} </span>
                        <span className={item?.Status !== "Enabled" ? 'history-error-text' : 'history-success-text'}>  {item?.Status} </span>
                    </div>
                </div>
            </div>
        ))}

        {props.currentMode === "control" && props?.currentModeData?.objectList?.map((item, index) => (
            <div key={index} className={props?.currentModeData?.['Current_mode'] === item?.Component_Name ? 'm-4 history-latest-container' : 'm-4 history-oldest-container'}>
                <div>
                    <span className='ms-4 history-main-text'> {item?.Component_Name} </span>
                    <div className='d-flex justify-content-between ms-4 me-4 mb-2'>
                        <span className='history-sub-text'> {item?.Timestamp} </span>
                        <span className={props?.currentModeData?.['Current_mode'] === item?.Component_Name ? item?.Status !== "Enabled" ? 'history-error-text' : 'history-success-text' : 'history-normal-text'}>  {item?.Status} </span>
                    </div>
                </div>
            </div>
        ))}

        {props.currentMode === "estimation" && props?.currentModeData?.objectList?.map((item, index) => (
            <div key={index} className={props?.currentModeData?.['Current_mode'] === item?.Component_Name ? 'm-4 history-latest-container' : 'm-4 history-oldest-container'}>
                <div>
                    <span className='ms-4 history-main-text'> {item?.Component_Name} </span>
                    <div className='d-flex justify-content-between ms-4 me-4 mb-2'>
                        <span className='history-sub-text'> {item?.Timestamp} </span>
                        <span className={props?.currentModeData?.['Current_mode'] === item?.Component_Name ? item?.Status !== "Enabled" ? 'history-error-text' : 'history-success-text' : 'history-normal-text'}>  {item?.Status} </span>
                    </div>
                </div>
            </div>
        ))}

     </div>
    </Modal>
  );
}

const AdcsGrafanaPanels = ({ adcs_pannels, selectedADCSButton, handleGrafanaIframePanel, validation, setCurrentMeasurenmentSubButton,currentComponent,setCurrentComponent }) => {

    const params = useParams();
    const {
        scenario_id, truetwin_id
    } = params;

    const [runMode, setRunMode] = useState("ON")
    const [modalShow, setModalShow] = useState(false);
    const [currentMode, setCurrentMode] = useState();
    const [state , setState] = useState();
    const [key, setKey] = useState(1);

    const [control_mode, setControlMode] = useState()
    const [estimation_mode, setEstimationMode] = useState()

    //Run Mode 
    const run_mode = {
       "Status" : "ON" 
    }
    
    //Error History
    const error_history = {
        "Error_History" : [
            {
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Signal Communications",
                "ErrorStatus": "Resolved"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:40 UTC",
                "Component_name": "Signal Communications",
                "ErrorStatus": "Error"
            },
            {
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Nadir Sensor Range",
                "ErrorStatus": "Resolved"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:40 UTC",
                "Component_name": "Camera 1 Sensor Busy",
                "ErrorStatus": "Error"
            },
            {
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Camera 2 Sensor Detection",
                "ErrorStatus": "Resolved"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:40 UTC",
                "Component_name": "Wheel Speed Range",
                "ErrorStatus": "Error"
            },
            {
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Star tracker",
                "ErrorStatus": "Resolved"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:40 UTC",
                "Component_name": "Camera 2 SRAM Overcurrent Detected",
                "ErrorStatus": "Error"
            }
       ]
    }

    // Enabled State
    const enabled_state =  {
        "Enabled_state" : [
            { 
                    "Timestamp" : "2003-10-12 11:20:45 UTC",
                    "Component_name": "Control signal",
                    "Status": "Enabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Control motor",
                "Status": "Disabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Sensor 1",
                "Status": "Enabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Sensor 2",
                "Status": "Disabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Wheel 1",
                "Status": "Enabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Wheel 2",
                "Status": "Disabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Wheel 3",
                "Status": "Enabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "Motor Drive",
                "Status": "Disabled"
            },
            { 
                "Timestamp" : "2003-10-12 11:20:45 UTC",
                "Component_name": "GPS Receiver",
                "Status": "Disabled"
            }
        ]
    }

    useEffect(() => {
        const fetchData = () => {
            MissionService.getControlMode(scenario_id)
                .then((res) => {
                    setControlMode(res?.data);
                })
                .catch((err) => {
                    console.log(err);
                });
    
            MissionService.getEstimationMode(scenario_id)
                .then((res) => {
                    setEstimationMode(res?.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        setKey(key+1)

        return () => clearInterval(intervalId);
    }, []);
    

    const handleOnClick = (mode) => {
        setCurrentMode(mode)
        setModalShow(true)
    }

    return (
        <Fragment>

            {selectedADCSButton === "State" &&
             <Fragment>
                <div className='d-flex w-100'>
                
                    {control_mode?.Current_mode && <div className='run-mode-box mt-2 w-10'>
                        <span className='run-mode'> Run Mode </span><br/>
                        <span className='run-status'> {runMode} </span>
                    </div>}
                    {control_mode?.Current_mode && <div className='estimation-box mt-2 w-40'>
                        <div className='d-flex column justify-content-between'>
                            <div className='run-mode'> Control Mode </div>
                            <SvgPreviewIcon className='cursor-pointer' onClick={()=>{
                                handleOnClick("control")
                                setState("CONTROL MODE")
                            }}/>
                        </div>
                        <span> {control_mode?.Current_mode} </span> 
                    </div>}

                    {estimation_mode?.Current_mode && <div className='estimation-box mt-2 w-50'>
                        <div className='d-flex column justify-content-between'>
                            <div className='run-mode'> Estimation Mode </div>
                            <SvgPreviewIcon className='cursor-pointer' onClick={()=>{
                                handleOnClick("estimation")
                                setState("ESTIMATION MODE")
                            }}/>
                        </div>
                        <span>  {estimation_mode?.Current_mode} </span> 
                    </div>}
                </div>

                <div className='d-flex mt-2'>

                    {/* <div className='error-box'>
                        <div className='d-flex column justify-content-between m-3'> 
                                <span>Error</span>
                                <SvgPreviewIcon className='cursor-pointer' onClick={()=>{
                                handleOnClick("error")
                                setState("ERROR HISTORY")
                            }}/>
                        </div>
                        <div className='error-box-container'>
                            {error_history.Error_History.map((item) => (
                                <div className='d-flex error-box-component ms-2 mb-2' >
                                    <span className={item.ErrorStatus === "Resolved" ?'innner-box-yellow-text' : "innner-box-gray-text"}>{item.Component_name}</span>
                                    <div className={item.ErrorStatus === "Resolved" ? 'circle-yellow' : 'circle-gray'}></div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='error-box ms-2'>
                        <div className='d-flex column justify-content-between m-3'> 
                                <span>Enabled Status</span>
                                <SvgPreviewIcon className='cursor-pointer' onClick={()=>{
                                handleOnClick("enabled")
                                setState("ENABLED STATE")
                            }}/>
                        </div>
                        <div className='error-box-container ms-2'>
                            {enabled_state.Enabled_state.map((item) => (
                                <div className='d-flex error-box-component ms-2 mb-2' >
                                    <span className={item.Status === "Enabled" ?'innner-box-green-text' : 'innner-box-gray-text'}>{item.Component_name}</span>
                                    <div className={item.Status === "Enabled" ? 'circle-green' : 'circle-gray'}></div>
                                </div>
                            ))}
                        </div>
                    </div> */}

                </div>

            </Fragment>}

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                currentModeData = {currentMode === "error" ? error_history : currentMode === "enabled" ? enabled_state : currentMode === "control" ? control_mode : estimation_mode}
                currentMode = {currentMode}
                currentState = {state}
            />

            {selectedADCSButton === "State" &&
               <AdcsStateGrafanaPanels
                        adcs_pannels= {adcs_pannels}
                        selectedADCSButton={selectedADCSButton}
                        handleGrafanaIframePanel={handleGrafanaIframePanel}
                        validation={validation}
               />
            }
            {selectedADCSButton === "Power" &&
              <AdcsPowerGrafanaPanel
                        adcs_pannels= {adcs_pannels}
                        selectedADCSButton={selectedADCSButton}
                        handleGrafanaIframePanel={handleGrafanaIframePanel}
                        validation={validation}
                />
            }
            {selectedADCSButton === "Measurements" &&
               <AdcsMeasurenmentGrafanaPanel
                        adcs_pannels= {adcs_pannels}
                        selectedADCSButton={selectedADCSButton}
                        handleGrafanaIframePanel={handleGrafanaIframePanel}
                        validation={validation}
                        setCurrentMeasurenmentSubButton={setCurrentMeasurenmentSubButton}
                        currentComponent={currentComponent}
                        setCurrentComponent={setCurrentComponent}
               />
            }
        </Fragment>
    )
}

export default AdcsGrafanaPanels