import React, { useState } from "react";

const ModalCreationMessage = ({ props, message }) => {
  return (
    <div className={
        props.isSideReportBarVisible
          ? "modalCreationMessage sideReportbarOpen"
          : "modalCreationMessage"
      }>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.4375 10.9375L7.8125 15.3125L16.5625 5.9375"
          stroke="#CCF54E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {message}
    </div>
  );
};

export default ModalCreationMessage;
