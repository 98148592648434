// @ts-nocheck
import React, { useState, useRef } from "react";
import styled from "styled-components";
import "./SidebarReport.css";

import CoverageAnalysisReport from "./ReportComponents/OrbitalReportComponents/CoverageAnalysisReport";
import EclipseAnalysisReport from "./ReportComponents/OrbitalReportComponents/EclipseAnalysisReport";
import DeOrbitingAnalysisReport from "./ReportComponents/OrbitalReportComponents/DeOrbitingAnalysisReport";
import GroundStationAccessReport from "./ReportComponents/GroundStationAccessReport";
import PowerAnalysisHiFiReport from "./ReportComponents/PowerThermalReportComponents/PowerAnalysisHiFiReport";
import PowerAnalysisLoFiReport from "./ReportComponents/PowerThermalReportComponents/PowerAnalysisLoFiReport";
import ThermalAnalysisReport from "./ReportComponents/PowerThermalReportComponents/ThermalAnalysisReport";
import DataAnalysisReport from "./ReportComponents/DataAnalysisReport";
import KeepInKeepOutReport from "./ReportComponents/KeepInKeepOutReport";
import ADCSSelectionReport from "./ReportComponents/ADCSSelectionsReport";

import ImageAssets from "../ImageAssets";

const SidebarNav = styled.nav`
  background: #0f0f0f;
  width: 369px;
  height: 90vh;
  justify-content: center;
  position: fixed;
  top: 10vh;
  right: ${({ sidebarReport }) => (sidebarReport ? "0" : "-369px")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarReportNavBar = styled.nav`
  background: #0f0f0f;
  width: 48px;
  height: auto;
  justify-content: center;
  position: fixed;
  top: 10vh;
  right: ${({ sidebarReport }) => (sidebarReport ? "369px" : "0")};
  transition: 350ms;
  z-index: 10;
  padding: 4px;
  border-radius: 8px 0px 0px 8px;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarReport = ({ props }) => {
  const [sidebarPin, setSidebarPin] = useState(false);
  let [isCurrentlyActive, setIsCurrentlyActive] = useState("Report");

  const showSidebarReport = (item) => {
    props.setSideReportBarProps({ isSideReportBarVisible: true });
    setIsCurrentlyActive(item);
    if (sidebarPin === true) {
      props.setSideReportBarProps({ isSideReportBarVisible: true });
    } else if (sidebarPin === false && item === "Outside") {
      props.setSideReportBarProps({ isSideReportBarVisible: false });
    }
  };

  const showSidebarPin = () => {
    setSidebarPin(!sidebarPin);
    if (sidebarPin === true) {
      props.setSideReportBarProps({ isSideReportBarVisible: true });
    }
  };

  return (
    <div>
      <div id="backgroundOverlay" onClick={() => showSidebarReport("Outside")}></div>
      <SidebarReportNavBar sidebarReport={props.isSideReportBarVisible}>
        <button
          className={isCurrentlyActive === "Report" ? "activeReportBtn sidebarReportNavBtn" : "sidebarReportNavBtn"}
          onClick={() => {
            showSidebarReport("Report");
            props.setSideReportBarProps({ isSideReportBarVisible: !props.isSideReportBarVisible });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            {isCurrentlyActive === "Report"
              ? ImageAssets("#CCF54E").find((object) => object.Report !== undefined)?.Report
              : ImageAssets("#F0F0F0").find((object) => object.Report !== undefined)?.Report}
          </svg>
        </button>

        {/* <div className="reportComponentSeparator"></div> */}

        {/* <button className={isCurrentlyActive === "Satellite" ? "activeReportBtn sidebarReportNavBtn" : "sidebarReportNavBtn"} onClick={() => showSidebarReport("Satellite")}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            {isCurrentlyActive === "Satellite"
              ? ImageAssets("#CCF54E").find((object) => object.Satellite !== undefined)?.Satellite
              : ImageAssets("#F0F0F0").find((object) => object.Satellite !== undefined)?.Satellite}
          </svg>
        </button>
        <button
          className={isCurrentlyActive === "Ground Station" ? "activeReportBtn sidebarReportNavBtn" : "sidebarReportNavBtn"}
          onClick={() => showSidebarReport("Ground Station")}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
            {isCurrentlyActive === "Ground Station"
              ? ImageAssets("#CCF54E").find((object) => object.Ground_Station_1 !== undefined)?.Ground_Station_1
              : ImageAssets("#F0F0F0").find((object) => object.Ground_Station_1 !== undefined)?.Ground_Station_1}
          </svg>
        </button>
        <button className={isCurrentlyActive === "Containers" ? "activeReportBtn sidebarReportNavBtn" : "sidebarReportNavBtn"} onClick={() => showSidebarReport("Containers")}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            {isCurrentlyActive === "Containers"
              ? ImageAssets("#CCF54E").find((object) => object.Containers !== undefined)?.Containers
              : ImageAssets("#F0F0F0").find((object) => object.Containers !== undefined)?.Containers}
          </svg>
        </button> */}
      </SidebarReportNavBar>

      <SidebarNav sidebarReport={props.isSideReportBarVisible}>
        <SidebarWrap>
          {props.isSideReportBarVisible && (
            <div className="sidebarReportTitle">
              <button className="sidebarCloseBtn sidebarReportCloseBtn" onClick={showSidebarPin}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M15.6397 5.02833L10.9712 0.360588C10.8569 0.246269 10.7212 0.155585 10.5719 0.0937147C10.4225 0.0318445 10.2625 0 10.1008 0C9.93917 0 9.7791 0.0318445 9.62976 0.0937147C9.48041 0.155585 9.34472 0.246269 9.23043 0.360588L5.105 4.4991C4.28499 4.24217 2.41266 3.93217 0.458788 5.50988C0.324114 5.61817 0.213754 5.75361 0.134892 5.90738C0.0560308 6.06115 0.0104355 6.22981 0.00107464 6.40237C-0.00828626 6.57493 0.0187969 6.74752 0.0805612 6.90892C0.142326 7.07032 0.237387 7.2169 0.359556 7.33913L4.07652 11.0546L0.794946 14.3338C0.679473 14.4493 0.614601 14.6059 0.614601 14.7692C0.614601 14.9325 0.679473 15.0891 0.794946 15.2046C0.910418 15.3201 1.06703 15.385 1.23034 15.385C1.39364 15.385 1.55025 15.3201 1.66572 15.2046L4.94499 11.923L8.65965 15.6377C8.77385 15.7524 8.90954 15.8434 9.05896 15.9056C9.20838 15.9677 9.36859 15.9998 9.53043 16H9.61735C9.7928 15.9878 9.96358 15.9381 10.1181 15.854C10.2726 15.77 10.4072 15.6537 10.5127 15.5131C12.0235 13.5054 11.8781 11.873 11.5274 10.8976L15.6405 6.76912C15.7548 6.65478 15.8454 6.51905 15.9072 6.36968C15.969 6.2203 16.0008 6.06022 16.0007 5.89857C16.0006 5.73692 15.9687 5.57686 15.9068 5.42754C15.8448 5.27823 15.7541 5.14258 15.6397 5.02833ZM14.769 5.89911L10.3635 10.3192C10.2725 10.4105 10.2125 10.5281 10.192 10.6553C10.1714 10.7826 10.1913 10.9131 10.2489 11.0284C10.9766 12.4846 10.1104 13.9969 9.53043 14.7684L1.23034 6.46758C2.15958 5.71834 3.04882 5.52065 3.72883 5.52065C4.16031 5.51476 4.58824 5.5994 4.98499 5.76911C5.10074 5.82704 5.23181 5.847 5.35956 5.82614C5.4873 5.80529 5.60522 5.74469 5.69654 5.65296L10.1012 1.2306L14.769 5.89834V5.89911Z"
                    fill={sidebarPin ? "#CCF54E" : "#F0F0F0"}
                  />
                </svg>
              </button>
              Reports
            </div>
          )}
          <div className="sidebarMainComponents">
            <div className="sidebarReportComponent">
              <button
                className={props.sidebarReportComponent === "Orbital" ? "activesidebarReportComponent sidebarReportComponentBtn" : "sidebarReportComponentBtn"}
                onClick={() => {
                  props.setShowSidebarReportComponent("Orbital");
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  {props.sidebarReportComponent === "Orbital"
                    ? ImageAssets("#CCF54E").find((object) => object.Orbital !== undefined)?.Orbital
                    : ImageAssets("#F0F0F0").find((object) => object.Orbital !== undefined)?.Orbital}
                </svg>
              </button>
            </div>

            <div className="sidebarReportComponent">
              <button
                className={props.sidebarReportComponent === "Power" ? "activesidebarReportComponent sidebarReportComponentBtn" : "sidebarReportComponentBtn"}
                onClick={() => {
                  props.setShowSidebarReportComponent("Power");
                }}
              >
                <img src={require("../ImageSvgs/PowerThermal.svg").default} className={props.sidebarReportComponent === "Power" ? "active-img" : ""} />
              </button>
            </div>

            <div className="sidebarReportComponent">
              <button
                className={props.sidebarReportComponent === "Ground Station" ? "activesidebarReportComponent sidebarReportComponentBtn" : "sidebarReportComponentBtn"}
                onClick={() => {
                  props.setShowSidebarReportComponent("Ground Station");
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                  {props.sidebarReportComponent === "Ground Station"
                    ? ImageAssets("#CCF54E").find((object) => object.Ground_Station_1 !== undefined)?.Ground_Station_1
                    : ImageAssets("#F0F0F0").find((object) => object.Ground_Station_1 !== undefined)?.Ground_Station_1}
                </svg>
              </button>
            </div>

            <div className="sidebarReportComponent">
              <button
                className={props.sidebarReportComponent === "Area of Interest" ? "activesidebarReportComponent sidebarReportComponentBtn" : "sidebarReportComponentBtn"}
                onClick={() => {
                  props.setShowSidebarReportComponent("Area of Interest");
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  {props.sidebarReportComponent === "Area of Interest"
                    ? ImageAssets("#CCF54E").find((object) => object.Area_Of_Interest !== undefined)?.Area_Of_Interest
                    : ImageAssets("#F0F0F0").find((object) => object.Area_Of_Interest !== undefined)?.Area_Of_Interest}
                </svg>
              </button>
            </div>
          </div>

          {props.sidebarReportComponent === "Orbital" && props.bottomBarReport === "Coverage Analysis" && <CoverageAnalysisReport props={props} />}
          {props.sidebarReportComponent === "Orbital" && props.bottomBarReport === "Eclipse Analysis" && <EclipseAnalysisReport props={props} />}
          {props.sidebarReportComponent === "Orbital" && props.bottomBarReport === "De-Orbiting Analysis" && <DeOrbitingAnalysisReport props={props} />}

          {props.sidebarReportComponent === "Ground Station" && <GroundStationAccessReport props={props} />}

          {props.sidebarReportComponent === "Power" && props.bottombarComponent === "Power Analysis" && <PowerAnalysisLoFiReport props={props} />}
          {props.sidebarReportComponent === "Power" && props.bottombarComponent === "Power Analysis - Hi-Fi" && <PowerAnalysisHiFiReport props={props} />}
          {props.sidebarReportComponent === "Power" && props.bottombarComponent === "Thermal Analysis" && <ThermalAnalysisReport props={props} />}
          {props.sidebarReportComponent === "Power" && props.bottombarComponent === "Data Analysis" && <DataAnalysisReport props={props} />}

          {props.sidebarReportComponent === "Orbital" && props.bottombarComponent === "Keep-In & Keep-Out" && <KeepInKeepOutReport props={props} />}
          {props.sidebarReportComponent === "Orbital" && props.bottombarComponent === "ADCS Selections" && <ADCSSelectionReport props={props} />}

        </SidebarWrap>
      </SidebarNav>
    </div>
  );
};

export default SidebarReport;
