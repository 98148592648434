// @ts-nocheck
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CatalogueService from '../../service/CatalogueService';
import { MissionStore } from '../../store/MissionSlice';

const SaveTemplate = ({ hover, navigateToTemplate, setLoading, bus_name }) => {

    const isNewTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isNewTemplate);
    const isCloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isCloneTemplate);
    const userData = useSelector((state: { mission: MissionStore }) => state.mission.userData);
    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const propulsion = useSelector((state: { mission: MissionStore }) => state.mission.propulsion);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);
    const [open, setOpen] = React.useState(false);
    // const [templateName, setTemplateName] = React.useState<any>('');
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const saveTemplateData = () => {
        setLoading(true)
        if (Satellite?.Template && (isCloneTemplate || isNewTemplate)) {
            // const template = Satellite?.Template;
            // @ts-ignore
            const d = Satellite?.Template;

            const updatedAssembly = [
                {
                    'communication': {
                        'antennas': {
                            'S-band': {
                                'atmos-data': CloneTemplate['Comm']?.['S-Band Antenna'] ? CloneTemplate['Comm']['S-Band Antenna'] : d?.['assembly']?.[0]?.['communication']?.['antennas']?.['S-band']?.['atmos-data'],
                            },
                            'X-band': {
                                'atmos-data': CloneTemplate['Comm']?.['X-Band Antenna'] ? CloneTemplate['Comm']['X-Band Antenna'] : d?.['assembly']?.[0]?.['communication']?.['antennas']?.['X-band']?.['atmos-data'],
                            },
                            'UHF': {
                                'atmos-data': CloneTemplate['Comm']?.['UHF Antenna'] ? CloneTemplate['Comm']['UHF Antenna'] : d?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF']?.['atmos-data'],
                            },
                            // 'AIS': {
                            //     'atmos-data': CloneTemplate['Comm']?.['AIS Antenna'] ? CloneTemplate['Comm']['AIS Antenna'] : d?.['assembly'][0]['communication']['antennas']['AIS']['atmos-data'],
                            // }
                        },
                        'comm-devices': {
                            'S-band': {
                                'atmos-data': CloneTemplate['Comm']?.['S-Band Radio'] ? CloneTemplate['Comm']['S-Band Radio'] : d?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['S-band']?.['atmos-data'],
                            },
                            'X-band': {
                                'atmos-data': CloneTemplate['Comm']?.['X-Band Radio'] ? CloneTemplate['Comm']['X-Band Radio'] : d?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['atmos-data'],
                            },
                            'UHF': {
                                'atmos-data': CloneTemplate['Comm']?.['UHF Radio'] ? CloneTemplate['Comm']['UHF Radio'] : d?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF']?.['atmos-data'],
                            },
                            // 'AIS': {
                            //     'atmos-data': CloneTemplate['Comm']?.['AIS Radio'] ? CloneTemplate['Comm']['AIS Radio'] : d?.['assembly'][0]['communication']['comm-devices']['AIS']['atmos-data'],
                            // },
                        }
                    },
                    'core': {
                        'ADCS': {
                            'Horizon Sensor': {
                                'atmos-data': (CloneTemplate?.['Core']?.['ADCS']?.['Horizon Sensor'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Discrete')) ? CloneTemplate?.['Core']['ADCS']['Horizon Sensor'] : d?.['assembly']?.[0]?.['core']?.['ADCS']?.['Horizon Sensor']?.['atmos-data'],
                                'user_data': d?.['assembly'][0]['core']['ADCS']['Horizon Sensor']['user_data']
                            },
                            'Star Sensor': {
                                'atmos-data': (CloneTemplate?.['Core']?.['ADCS']?.['Star Sensor'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Discrete')) ? CloneTemplate?.['Core']['ADCS']['Star Sensor'] : d?.['assembly']?.[0]?.['core']?.['ADCS']?.['Star Sensor']?.['atmos-data'],
                                'user_data': d?.['assembly'][0]['core']['ADCS']['Star Sensor']['user_data']
                            },
                            'Sun-n-Nadir Sensor': {
                                'atmos-data': (CloneTemplate?.['Core']?.['ADCS']?.['Sun-n-Nadir Sensor'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Discrete')) ? CloneTemplate?.['Core']['ADCS']['Sun-n-Nadir Sensor'] : d?.['assembly']?.[0]?.['core']?.['ADCS']?.['Sun-n-Nadir Sensor']?.['atmos-data'],
                                'user_data': d?.['assembly'][0]['core']['ADCS']['Sun-n-Nadir Sensor']['user_data']
                            },
                            'Torquer': {
                                'atmos-data': (CloneTemplate?.['Core']?.['ADCS']?.['Torquer'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Discrete')) ? CloneTemplate?.['Core']['ADCS']['Torquer'] : d?.['assembly']?.[0]?.['core']?.['ADCS']?.['Torquer']?.['atmos-data'],
                                'user_data': d?.['assembly'][0]['core']['ADCS']['Torquer']['user_data']
                            },
                            'Wheels': {
                                'atmos-data': (CloneTemplate?.['Core']?.['ADCS']?.['Wheels'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Discrete')) ? CloneTemplate?.['Core']['ADCS']['Wheels'] : d?.['assembly']?.[0]?.['core']?.['ADCS']?.['Wheels']?.['atmos-data'],
                                'user_data': d?.['assembly'][0]['core']['ADCS']['Wheels']['user_data']
                            },
                        },
                        'ADCS Software': d?.['assembly'][0]['core']['ADCS Software'],
                        'Bus OBC': {
                            'atmos-data': CloneTemplate?.['Core']?.['Bus OBC'] ? CloneTemplate?.['Core']['Bus OBC'] : d?.['assembly']?.[0]?.['core']?.['Bus OBC']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['core']['Bus OBC']['user_data']
                        },
                        'Bus OS': {
                            'atmos-data': CloneTemplate?.['Core']?.['Bus OS'] ? CloneTemplate?.['Core']['Bus OS'] : d?.['assembly']?.[0]?.['core']?.['Bus OS']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['core']['Bus OS']['user_data']
                        },
                        'GPS': {
                            'atmos-data': CloneTemplate?.['Core']?.['GPS'] ? CloneTemplate?.['Core']['GPS'] : d?.['assembly']?.[0]?.['core']?.['GPS']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['core']['GPS']['user_data']
                        },
                        // 'Heater': {
                        //     'atmos-data': CloneTemplate?.['Core']?.['Heater'] ? CloneTemplate?.['Core']['Heater'] : d?.['assembly'][0]['core']['Heater']['atmos-data'],
                        //     'user_data': {
                        //         'numUnits': CloneTemplate?.['Core']?.['HeaterCount'] ? CloneTemplate?.['Core']['HeaterCount'] : d?.['assembly'][0]['core']['Heater']['user_data']?.['numUnits'],
                        //         'userOption': d?.['assembly'][0]['core']['Heater']['user_data']?.['userOption']
                        //     }
                        // },
                        // 'IMU': {
                        //     'Accelerometer': {
                        //         'atmos-data': (CloneTemplate?.['Core']?.['IMU']?.['Accelerometer'] && (CloneTemplate?.['Core']?.['imuType'] === 'Discrete')) ? CloneTemplate?.['Core']['IMU']['Accelerometer'] : d?.['assembly'][0]['core']['IMU']['Accelerometer']['atmos-data'],
                        //         'user_data': d?.['assembly'][0]['core']['IMU']['Accelerometer']['user_data']
                        //     },
                        //     'Gyro': {
                        //         'atmos-data': (CloneTemplate?.['Core']?.['IMU']?.['Gyro'] && (CloneTemplate?.['Core']?.['imuType'] === 'Discrete')) ? CloneTemplate?.['Core']['IMU']['Gyro'] : d?.['assembly'][0]['core']['IMU']['Gyro']['atmos-data'],
                        //         'user_data': d?.['assembly'][0]['core']['IMU']['Gyro']['user_data']
                        //     },
                        //     'Magnetometer': {
                        //         'atmos-data': (CloneTemplate?.['Core']?.['IMU']?.['Magnetometer'] && (CloneTemplate?.['Core']?.['imuType'] === 'Discrete')) ? CloneTemplate?.['Core']['IMU']['Magnetometer'] : d?.['assembly'][0]['core']['IMU']['Magnetometer']['atmos-data'],
                        //         'user_data': d?.['assembly'][0]['core']['IMU']['Magnetometer']['user_data']
                        //     },
                        // },
                        'Temperature Sensor': {
                            'atmos-data': CloneTemplate?.['Core']?.['Temperature Sensor'] ? CloneTemplate?.['Core']['Temperature Sensor'] : d?.['assembly']?.[0]?.['core']?.['Temperature Sensor']?.['atmos-data'],
                            'user_data': {
                                'numUnits': CloneTemplate?.['Core']?.['Temperature SensorCount'] ? CloneTemplate?.['Core']['Temperature SensorCount'] : d?.['assembly']?.[0]?.['core']?.['Temperature Sensor']?.['user_data']?.['numUnits'],
                                'userOption': d?.['assembly'][0]['core']['Temperature Sensor']['user_data']?.['userOption']
                            }
                        },

                        'Thruster': {
                            'atmos-data': CloneTemplate?.['Core']?.['Thruster'] ? CloneTemplate?.['Core']['Thruster'] : d?.['assembly']?.[0]?.['core']?.['Thruster']?.['atmos-data'],
                            'user_data': {
                                'numUnits': CloneTemplate?.['Core']?.['ThrusterCount'] ? CloneTemplate?.['Core']['ThrusterCount'] : d?.['assembly']?.[0]?.['core']?.['Thruster']?.['user_data']?.['numUnits'],
                                'userOption': d?.['assembly'][0]?.['core']?.['Thruster']?.['user_data']?.['userOption']
                            }
                        },
                        // 'iIMU': {
                        //     'atmos-data': (CloneTemplate?.['Core']?.['iIMU'] && (CloneTemplate?.['Core']?.['imuType'] === 'Integrated')) ? CloneTemplate?.['Core']['iIMU'] : d?.['assembly'][0]['core']['iIMU']['atmos-data'],
                        //     'user_data': d?.['assembly'][0]['core']['iIMU']['user_data']
                        // },
                    },
                    'iADCS': {
                        'atmos-data': (CloneTemplate?.['Core']?.['iADCS'] && (CloneTemplate?.['Core']?.['adcsType'] === 'Integrated')) ? CloneTemplate?.['Core']['iADCS'] : d?.['assembly']?.[0]?.['iADCS']?.['atmos-data'],
                        'user_data': d?.['assembly'][0]['iADCS']['user_data']
                    },
                    'payloadServer': {
                        'Payload OBC': {
                            'atmos-data': CloneTemplate?.['Payload']?.['Payload OBC'] ? CloneTemplate?.['Payload']['Payload OBC'] : d?.['assembly']?.[0]?.['payloadServer']?.['Payload OBC']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['payloadServer']['Payload OBC']['user_data']
                        },
                        'Payload SSD': {
                            'atmos-data': CloneTemplate?.['Payload']?.['Payload SSD'] ? CloneTemplate?.['Payload']['Payload SSD'] : d?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['atmos-data'],
                            'user_data': {
                                'numUnits': CloneTemplate?.['Payload']?.['Payload SSDCount'] ? CloneTemplate?.['Payload']['Payload SSDCount'] : d?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['user_data']?.['numUnits'],
                                'userOption': d?.['assembly'][0]['payloadServer']['Payload SSD']['user_data']?.['userOption']
                            }
                        },
                    },
                    'power': {
                        'Battery Pack': {
                            'atmos-data': CloneTemplate?.['Power']?.['Battery Pack'] ? CloneTemplate?.['Power']['Battery Pack'] : d?.['assembly']?.[0]?.['power']?.['Battery Pack']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['power']['Battery Pack']['user_data']
                        },
                        'EPS': {
                            'atmos-data': CloneTemplate?.['Power']?.['EPS'] ? CloneTemplate?.['Power']['EPS'] : d?.['assembly']?.[0]?.['power']?.['EPS']?.['atmos-data'],
                            'user_data': d?.['assembly'][0]['power']['EPS']['user_data']
                        },
                        'MPTT': d?.['assembly'][0]['MPTT'],
                        'Solar Panel': {
                            'atmos-data': CloneTemplate?.['Power']?.['Solar Panel'] ? CloneTemplate?.['Power']['Solar Panel'] : d?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['atmos-data'],
                            'user_data': {
                                'numUnits': CloneTemplate?.['Power']?.['Solar PanelCount'] ? CloneTemplate?.['Power']['Solar PanelCount'] : d?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['user_data']?.['numUnits'],
                                'userOption': d?.['assembly'][0]['power']['Solar Panel']['user_data']?.['userOption']
                            }
                        },
                    },
                    'version': d?.['assembly']?.[0]?.['version']
                }
            ]
            const batteryCapacity = (d?.['template-attribute']?.['battery-capacity'] / d?.['template-attribute']?.['NumSolarPanel']) * (CloneTemplate?.['Power']?.['Solar PanelCount'] ? CloneTemplate?.['Power']?.['Solar PanelCount'] : d?.['template-attribute']?.['NumSolarPanel'])

            const updatedTemplateAttribute = {
                ...d?.['template-attribute'],
                'ADCS': (CloneTemplate?.['Core']?.['adcsType'] ? ((CloneTemplate?.['Core']?.['adcsType'] === 'Integrated') ? 'iADCS' : 'ADCS') : d?.['template-attribute']?.['ADCS']),
                'IMU': (CloneTemplate?.['Core']['imuType'] ? ((CloneTemplate?.['Core']['imuType'] === 'Integrated') ? "iIMU" : 'IMU') : d?.['template-attribute']?.['IMU']),
                'NumSolarPanel': (CloneTemplate?.['Power']?.['Solar PanelCount'] ? CloneTemplate?.['Power']?.['Solar PanelCount'] : d?.['template-attribute']?.['NumSolarPanel']),
                'battery-capacity': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['battery-capacity'] : batteryCapacity,
                'bus-mass': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['bus-mass'] : d?.['template-attribute']?.['bus-mass'],
                'bus-obc': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['bus-obc'] : d?.['template-attribute']?.['bus-obc'],
                'bus-power': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['bus-power'] : d?.['template-attribute']?.['bus-power'],
                'bus-size': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['bus-size'] : d?.['template-attribute']?.['bus-size'],
                'bus-type': isNewTemplate ? CloneTemplate?.['Other']?.['busType'] : d?.['template-attribute']['bus-type'],
                'maxWeightInKg': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['maxWeightInKg'] : d?.['template-attribute']?.['maxWeightInKg'],
                'propulsion': isNewTemplate ? propulsion : d?.['template-attribute']['propulsion'],
                'sizeInUnits': isNewTemplate ? CloneTemplate?.['Other']?.['template-attribute']?.['sizeInUnits'] : d?.['template-attribute']?.['sizeInUnits'],
                'sizeInXYZ': d?.['template-attribute']?.['sizeInXYZ'] ? d?.['template-attribute']?.['sizeInXYZ'] : '',
                'type': d?.['template-attribute']?.['type']
            }

            if(updatedTemplateAttribute?.['bus-type'] === '6U'){
                delete updatedAssembly[0]?.['core']?.['Thruster']
            }

            const data = {
                assembly: updatedAssembly,
                'build_info': d?.['build_info'],
                'container-project': d?.['container-project'],
                'latest-version-id': d?.['latest-version-id'],
                'name': bus_name,
                'template-attribute': updatedTemplateAttribute,
                'owner-org': userData?.['owner_org'], 
            };

            const resp = CatalogueService.postTemplate(data)
            resp?.then(result => {
                // console.log('Template Post successful')
                toast.success("Modified Bus Design saved successfully")
                // localStorage.setItem("template", JSON.stringify(data));
                // let path = `${process.env.PUBLIC_URL}/add_mission`;
                // navigate(path);
                // let updatedsat = {
                //     ...Satellite,
                //     Template: {
                //         ...Satellite.Template,
                //         uniqueId: result.data
                //     }
                // }
                // console.log("Satellite All Data => ", updatedsat)
                // console.log("Satellite tempId => ", result.data)
                // dispatch(setSatellite(updatedsat))
                // update_satellite(result.data)
                // dispatch(setIsCloneTemplateFalse())
                navigateToTemplate()
                setLoading(false)
            }).catch(err => {
                console.log('Failure in saving modified Bus Design', err.message)
                setLoading(false)
            })

        }
        // dispatch(setCloneTemplate({}))
    }

    return (
        <>

            {/* <Mui_Dialog open={open} onClose={() => {

            }} maxWidth={'sm'} fullWidth={true}
                PaperProps={{
                    style: {
                    }
                }}
            >
                <DialogTitle>Bus Design</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Save modified Bus Design as
                    </DialogContentText>
                    <TextField
                        inputProps={{
                            style: { textAlign: 'center', height: 10, display: 'flex' },
                        }}
                        sx={{
                            ".css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root": {
                                color: "white",
                            },
                        }}
                        InputProps={{
                            sx: {
                                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid gray",
                                },
                                "&:hover": {
                                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                        border: "2px solid gray",
                                    },
                                },
                            },
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => { setTemplateName(e.target.value) }}
                        margin="normal"
                        id="name"
                        label=""
                        type="text"
                        fullWidth
                        variant="outlined"
                        color='success'
                    />
                </DialogContent>


                <DialogActions className='gap-2 px-5 pb-5'>
                    <Mui_Button variant='contained' className='btn btn-primary' onClick={() => {
                        setOpen(false)
                        setTemplateName('')
                        // dispatch(setIsNewTemplateFalse())
                        // dispatch(setIsCloneTemplateFalse())
                    }}>No</Mui_Button>
                    <Mui_Button variant='contained' className='btn btn-primary' onClick={saveTemplateData}>Yes</Mui_Button>
                </DialogActions>
            </Mui_Dialog> */}

            {/* {!open && (isNewTemplate || isCloneTemplate) && */}
            {(isNewTemplate || isCloneTemplate) &&
                <Button
                    variant={hover ? "primary" : "outline-primary"}
                    type="button"
                    className={`d-flex align-items-center btn ${hover ? 'btn-primary' : 'btn-outline-primary'} pt-1 pb-1 pe-5 ps-4 fs-14`}
                    onClick={saveTemplateData}
                >
                    Save Bus Design
                    <i className="fe fe-chevron-right ms-1 fs-16"></i>
                </Button>
            }
        </>
    )
}

export default SaveTemplate