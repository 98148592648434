export function getEnvVar(varName: string): string {
    const defaults: { [key: string]: string } = {
        REACT_APP_ENVIRONMENT: "DEVELOPMENT",
        REACT_APP_PRIVATE_KEY: "DEV_KEY",
        REACT_APP_BACKEND_BASE_URL: "http://localhost:21000/api/",
        REACT_APP_KEYCLOAK_URL: "http://20.70.1.251:23000/",
        REACT_APP_KEYCLOAK_REALM: "ATMOS",
        REACT_APP_KEYCLOAK_RESOURCE_CLIENT: "ATMOS-RESOURCE-SERVER"
    };

    return window._env_ && window._env_[varName] ? window._env_[varName] : defaults[varName];
}
