//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import Sidebar from "./Sidebar/Sidebar";
import "./MissionModeling.css";
import SidebarReport from "./SideReportBar/SidebarReport";
import BottomBar from "./Bottombar/Bottombar";
import Modals from "./Modals/Modals";
import MissionOrbit from "./OrbitVisualisation/MissionOrbit";
import MapWithBoundingBox from "./Modals/BoundingBox/BoundingBox";
import Lottie from "react-lottie-player";
import * as animationData from '../MissionModeling/MMLoader.json'

const MissionModeling = () => {
  const [loading, setLoading] = useState(false);
  const [loadingOrbitData, setLoadingOrbitData] = useState(false);
  const [key, setKey] = useState(0);

  const [sidebarProps, setSidebarProps] = useState({
    isSidebarVisible: false,
    sidebarComponent: "Satellite",
    isComponentUpdated: false,
  });

  const [sideReportBarProps, setSideReportBarProps] = useState({
    isSideReportBarVisible: false,
    isEclipseComponentUpdate: false,
    isDeorbitComponrntUpdate: false,
    isCoverageAnalysisComponentUpdate: false,
  });

  let [isTopBarCurrentlyActive, setIsTopBarCurrentlyActive] = useState("Orbital");
  const [sidebarReportComponent, setShowSidebarReportComponent] = useState("Orbital");
  const [isSatelliteCreated, setIsSatelliteCreated] = useState(false)

  const [bottombarProps, setBottombarProps] = useState({
    bottombarComponent: "",
    componentId: "",
    componentData: "",
  });

  const [bottomBarReport, setBottomBarReport] = useState("")

  const [modalProps, setModalProps] = useState({
    modalComponent: bottombarProps.bottombarComponent,
    isTimelineEventCellVisible: false,
    boundingBoxCoordinates: [],
  });

  const [visualisationProps, setVisualisationProps] = useState<any>({});

  const [names, setNames] = useState({
    groundStationLatestName: "",
    areaOfInterestLatestName: "",
    planeLatestName: "",
    spaceObjectLatestName: "",
  });


  const [orbitDataList, setOrbitDataList] = useState({
    aoiList : null,
    gsList : null,
    satelliteList : null,
    coverageAnalysisSatList : null
  })

  const [isCAUpdate, setIsCVUpdate] = useState(false)
  const [isCoverageAnalysisUpdate,setIsCoverageAnalysisUpdate] = useState(false)
  const [isShowMultipleOrbit, setIsShowMultipleOrbit] = useState(false)
  const [selectedCVAoi, setSelectedCVAoi] = useState()
  
  const props = {
    ...sidebarProps,
    ...sideReportBarProps,
    ...bottombarProps,
    ...modalProps,
    ...visualisationProps,
    ...names,
    ...orbitDataList,
    isSatelliteCreated,
    isTopBarCurrentlyActive,
    sidebarReportComponent,
    bottomBarReport,
    loadingOrbitData,
    isCAUpdate,
    isCoverageAnalysisUpdate,
    isShowMultipleOrbit,
    selectedCVAoi,
        setSidebarProps,
    setSideReportBarProps,
    setBottombarProps,
    setModalProps,
    setVisualisationProps,
    setIsTopBarCurrentlyActive,
    setNames,
    setShowSidebarReportComponent,
    setOrbitDataList,
    setBottomBarReport,
    setIsSatelliteCreated,
    setLoadingOrbitData,
    setIsCVUpdate,
    setIsCoverageAnalysisUpdate,
    setIsShowMultipleOrbit,
    setSelectedCVAoi
  };

  return (
    <>
    {loadingOrbitData && 
      <div className="loader-page orbit-creating-text-container">
        <div><Lottie
                    loop
                    animationData={animationData}
                    play
                    style={{margin:"auto", width: 500, height: 370, marginTop:"10%" }}
                  />
                  </div>
                  <div>Preparing orbit...</div>
      </div>       
    }
    <div className="position-relative h-100 w-100">
      {bottombarProps.bottombarComponent !== 'Area of Interest' ? (
        <MissionOrbit
          polygonCoordinate={modalProps.boundingBoxCoordinates}
          selectedCompo={visualisationProps.selectedComponent}
          selectedCompoId={visualisationProps.selectedComponentId}
          props={props}
        />
      ) : (
        <>
          <MapWithBoundingBox props={props} />
          <div className="geofenceModalContentBox">
            <button
              className={props.bottombarComponent === "Area of Interest" ? "geofenceModalContentBoxBtn" : "geofenceModalContentBoxBtn disabledBtn"}
              onClick={() =>
                props.setModalProps({
                  isTimelineEventCellVisible: true,
                  modalComponent: props.modalComponent,
                })
              }
            >
              <img src={require("./ImageSvgs/Circle-draw.svg").default} />
            </button>
          </div>
        </>
      )}

      {loading && (
        <Box className="loader-page">
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div className="d-flex gap-3">
        <Sidebar  props={props} />
        <SidebarReport props={props} />
        <BottomBar props={props} />
        <Modals props={props} />
      </div>
    </div>
    </>
  );
};

export default MissionModeling;
