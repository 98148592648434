import { GridViewSvg, ListViewSvg } from "./svgs/Svgs"

export const Monitor_Btn_List = [
    { label: 'EPS', value: 'EPS' },
    { label: 'ADCS', value: 'ADCS' },
    // { label: 'Communication', value: 'Communication' },  
    { label: 'OBC', value: 'OBC' },
    { label: 'Payload', value: 'Payload' },
]

export const Monitor_view = [
    {
        title: 'List',
        icon: <ListViewSvg />,
    },
    {
        title: 'Grid',
        icon: <GridViewSvg />,
    },
]
