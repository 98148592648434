import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";
// import { BASE_APP_URL } from "./ApiService";

const getCatalogue = (type, component) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue?subsystemType=${type}&catalogueType=${component}`);
};
const addCatalogue = (body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue`, body);
};

const deleteCatalogue = (catalogueId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue/${catalogueId}`,);
};

const getAllTemplates = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue/template`);
};

const postTemplate = (template) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue/template`, template);
};
const deleteTemplate = (templateId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue/template/${templateId}`,);
};

const getTemplate = (templateId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}catalogue/template/${templateId}`);
};

const getGlobalGroundStations=()=>{
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation`);
}

const getGlobalGroundStationsById=(gsId)=>{
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/${gsId}`);
}

const createGroundStation = (name, body) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationName=${name}`, body);
}

const deleteGroundStation = (gsId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationId=${gsId}`);
}

const CatalogueService = {
    getCatalogue,
    addCatalogue,
    deleteCatalogue,
    getAllTemplates,
    postTemplate,
    deleteTemplate,
    getTemplate,
};

export default CatalogueService;
