import React, { useDebugValue, useEffect, useState, useRef } from "react";
import moment from "moment";
import "../ReportComponents.css";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import MissionModelingService from "../../../MissionModelingService";
import MapAnalysisInputs from "../MapAnalysisInputs";

const ThermalAnalysisReport = ({ props }) => {
  const [powerAnalysisItems, setPowerAnalysisItems] = useState({
    Analysis_Inputs: false,
    Output: true,
  });

  const spaceCraftObject = [
    {
      title: "SpaceObject_01",
      planes: [
        { title: "Plane_01", satellites: [{ title: "Mission_Sat_01" }, { title: "Mission_Sat_02" }, { title: "Mission_Sat_03" }] },
        { title: "Plane_02", satellites: [{ title: "Mission_Sat_04" }] },
      ],
      startTime: "2023-10-10T08:12:36Z",
      duration: "10 hours",
    },
  ];

  const [overviewValues, setOverviewValues] = useState([
    { label: "Thermal Shock", value: "+10 C/min" },
    { label: "Thermal Stress ", value: ".N/m sq" },
    { label: "Internal heat 12U", value: "33.51 J" },
  ]);

  const [busValues, setBusValues] = useState([
    { label: "Thermal Shock", value: "+10 C/min" },
    { label: "Thermal Stress ", value: ".N/m sq" },
    { label: "Internal heat 12U", value: "33.51 J" },
  ]);

  const [solarPanelValues, setSolarPanelValues] = useState([
    { label: "Thermal Shock", value: "+10 C/min" },
    { label: "Thermal Stress ", value: ".N/m sq" },
    { label: "Internal heat 12U", value: "33.51 J" },
  ]);

  const powerOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  const labels = ["S", "M", "T", "W", "T", "F", "S"];

  const PowerProfileOutputDiv = ({ label, value }) => (
    <>
      <div className="reportDataBoxText">{label}</div>
      <div className="reportDataBoxData">{value}</div>
    </>
  );

  const powerProfileData = {
    labels,
    datasets: [
      {
        fill: true,
        label: "ADCS",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "rgba(119, 152, 24)",
        backgroundColor: "rgba(119, 152, 24, 0.20)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="reportDiv">
      <div className="reportTitleDiv">
        <div className="reportTitle">Thermal Analysis</div>
        <div className="reportDate">{moment(new Date).format('DD - MM - HH - YYYY')}</div>
      </div>
      <div className="modalCheckboxDiv reportCheckBoxDiv">
        <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
        <button
          className="modalDropDownIconBtn"
          onClick={() =>
            setPowerAnalysisItems({
              Analysis_Inputs: !powerAnalysisItems.Analysis_Inputs,
              Output: powerAnalysisItems.Output,
            })
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d={powerAnalysisItems.Analysis_Inputs ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
              stroke="#F0F0F0"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="modalCheckboxText modalWhiteSubHeader">Analysis Inputs</div>
      </div>

      {powerAnalysisItems.Analysis_Inputs && <MapAnalysisInputs spaceCraftObject={spaceCraftObject} />}

      <div className="modalCheckboxDiv reportCheckBoxDiv">
        <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
        <button
          className="modalDropDownIconBtn"
          onClick={() =>
            setPowerAnalysisItems({
              Analysis_Inputs: powerAnalysisItems.Analysis_Inputs,
              Output: !powerAnalysisItems.Output,
            })
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d={powerAnalysisItems.Output ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
              stroke="#F0F0F0"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="modalCheckboxText modalWhiteSubHeader">Output</div>
        <button className="reportCommentBtn">
          <img src={require("../../../ImageSvgs/Comment-Button.svg").default} />
        </button>
        <button className="reportCommentBtn reportTagBtn">
          <img src={require("../../../ImageSvgs/Tag-Button.svg").default} />
        </button>
      </div>

      {powerAnalysisItems.Output && (
        <>
          <div className="modalCheckboxDiv reportCheckBoxDiv">
            <div className="reportConfigHeader white analysisPlaneHeader">Heating</div>
            <div className="selectBorderDiv">
              <select
                name="reportOutputTime"
                id="powerDropdown"
                className={"reportConfigDropdown reportAnalysisDropdown"}
                onChange={(event) => {
                  event.target.value != "" ? (event.target.style.color = "#CCF54E") : (event.target.style.color = "#F2A93B");
                }}
              >
                <option selected hidden value="">
                  Solar Panel
                </option>
                <option value="Panel 1">Panel 1</option>
                <option value="Panel 2">Panel 2</option>
                <option value="Panel 3">Panel 3</option>
              </select>
            </div>
          </div>

          <Line className="reportBarGraph planeBarGraph" options={powerOptions} data={powerProfileData} />
          <div className="reportConfigHeader white analysisPlaneHeader" style={{marginTop: "12px"}}>Overview</div>
          <div className="reportDataBox reportDataBoxHeight">{overviewValues.map(PowerProfileOutputDiv)}</div>
          <div className="reportConfigHeader white analysisPlaneHeader" style={{marginTop: "12px"}}>Bus</div>
          <div className="reportDataBox reportDataBoxHeight">{busValues.map(PowerProfileOutputDiv)}</div>
          <div className="reportConfigHeader white analysisPlaneHeader" style={{marginTop: "12px"}}>Solar Panel</div>
          <div className="reportDataBox reportDataBoxHeight">{solarPanelValues.map(PowerProfileOutputDiv)}</div>
        </>
      )}
    </div>
  );
};

export default ThermalAnalysisReport;
