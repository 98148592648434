import React, { useState } from "react";

const MapAnalysisInputs = (spaceCraftObject) => {
  const spaceCraftObjects = [
    {
      title: "SpaceObject_01",
      planes: [
        { title: "Plane_01", satellites: [{ title: "Mission_Sat_01" }, { title: "Mission_Sat_02" }, { title: "Mission_Sat_03" }] },
        { title: "Plane_02", satellites: [{ title: "Mission_Sat_04" }] },
      ],
      startTime: "2023-10-10T08:12:36Z",
      duration: "10 hours",
    },
    {
      title: "SpaceObject_02",
      planes: [
        { title: "Plane_11", satellites: [{ title: "Mission_Sat_11" }, { title: "Mission_Sat_12" }, { title: "Mission_Sat_13" }] },
        { title: "Plane_12", satellites: [{ title: "Mission_Sat_14" }] },
      ],
      startTime: "2023-8-9T07:19:72Z",
      duration: "20 hours",
    },
  ];

  return (
    <div className="mapAnalysisInputsDiv">
      {spaceCraftObjects?.map((spaceCraftItem) => {
        return (
          <div className="reportAnalysisItem">
            <div className="reportAnalysisIcon">
              <img src={require("../../ImageSvgs/Satellite-icon-small.svg").default} />
            </div>
            <div className="reportAnalysisItemName">&lt; {spaceCraftItem.title} &gt;</div>
            {spaceCraftItem?.planes.map((planesItem) => {
              return (
                <div className="reportAnalysisItem planeItem">
                  <div className="reportAnalysisIcon">
                    <img src={require("../../ImageSvgs/Planes-icon.svg").default} />
                  </div>
                  <div className="reportAnalysisItemName">&lt; {planesItem.title} &gt;</div>
                  {planesItem?.satellites.map((satellitesItem) => {
                    return (
                      <div className="reportAnalysisItem satelliteItem">
                        <div className="reportAnalysisIcon">
                          <img src={require("../../ImageSvgs/Satellite-icon-small.svg").default} />
                        </div>
                        <div className="reportAnalysisItemName">&lt; {satellitesItem.title} &gt;</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className="reportAnalysisIcon">
              <img src={require("../../ImageSvgs/Orbital-details-icon.svg").default} />
            </div>
            <div className="reportAnalysisItemName">Start Time: {spaceCraftItem.startTime}</div>
            <div className="reportAnalysisIcon">
              <img src={require("../../ImageSvgs/Orbital-details-icon.svg").default} />
            </div>
            <div className="reportAnalysisItemName  reportAnalysisItemSeparator">Duration: {spaceCraftItem.duration}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MapAnalysisInputs;
