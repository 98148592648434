import React, { useState } from "react";
import AreaOfInterestModal from "./OrbitalModals/AreaOfInterestModal";
import CoverageAnalysis from "./OrbitalModals/CoverageAnalysis";
import SatelliteCreationModal from "./OrbitalModals/SatelliteCreationModal";
import GroundStationModal from "./OrbitalModals/GroundStationModal";
import EclipseAnalysis from "./OrbitalModals/EclipseAnalysisModal";
import DeOrbitingAnalysis from "./OrbitalModals/DeOrbitingAnalysisModal";
import GroundStationAccessAnalysis from "./OrbitalModals/GroundStationAccessAnalysisModal";
import PowerAnalysisModal from "./PowerThermalModals/PowerAnalysisModal";
import PowerAnalysisModalHiFi from "./PowerThermalModals/PowerAnalysisModalHiFi";
import ThermalAnalysisModal from "./PowerThermalModals/ThermalAnalysisModal";
import DataAnalysisModal from "./PowerThermalModals/DataAnalysisModal";
import KeepInKeepOutModal from "./GNCModals/KeepInKeepOutModal";
import ADCSSelectionsModal from "./GNCModals/ADCSSelections";
import { v4 as uuidv4 } from "uuid";
import MissionModelingService from "../MissionModelingService";

const Modals = ({ props }) => {
  const [boundingBoxParameters, setboundingBoxParameters] = useState({
    swath: 0,
    timeframe: 0,
    revisits: 0,
  });

  const [orbitalParameters, setOrbitalParameters] = useState({
    epoch: [],
    altitude: ["460"],
    eccentricity: ["0"],
    SMA: ["460"],
    inclination: ["67"],
    LAN: [],
    AOP: [],
    TA: [],
  });

  const [groundStationParameters, setGroundStationParameters] = useState({
    Latitude: ["460"],
    Longitude: ["460"],
    Height: ["2"],
  });

  const [eclipseParameters, setEclipseParameters] = useState({
    Start_Time: "",
    Duration: "",
  });

  const [deOrbitingParameters, setDeOrbitingParameters] = useState({
    Satellite_Mass: "",
    Surface_area: "",
    Area_Drag_coefficient: "",
    Geo_Magnetic_Index: "",
    Solar_Flux: "",
  })

  const [isTableModalVisible, setIsTableModalVisible] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(true);

  const parameterProps = {
    ...boundingBoxParameters,
    ...orbitalParameters,
    ...groundStationParameters,
    ...eclipseParameters,
    ...deOrbitingParameters,
    isTableModalVisible,
    isModalVisible,
    setboundingBoxParameters,
    setOrbitalParameters,
    setGroundStationParameters,
    setEclipseParameters,
    setDeOrbitingParameters,
    setIsTableModalVisible,
    setisModalVisible,
  };

  const postPLane = () => {
    let data = {
      planeId: uuidv4(),
      name: "new_plane",
      type: "single",
      satellites: [
        {
          satId: uuidv4(),
          satName: "Satellite 1",
          orbitParameters: {
            altitude: parameterProps?.["altitude"],
            semiMajorAxis: 90.0,
            eccentricity: parameterProps?.["eccentricity"],
            inclination: 90.0,
            RAAN: 0.0,
            AOP: 0.0,
            TA: 0.0,
            epoch: 1695021339.0,
          },
        },
        {
          satId: uuidv4(),
          satName: "Satellite 2",
          orbitParameters: {
            altitude: 500.0,
            semiMajorAxis: 90.0,
            eccentricity: 0.0,
            inclination: 90.0,
            RAAN: 0.0,
            AOP: 0.0,
            TA: 0.0,
            epoch: 1695021339.0,
          },
        },
      ],
    };
    MissionModelingService.postPlane(data)
      .then((res) => {
        console.log("post succcesss", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <>
      {props.bottombarComponent === "Satellite" && <SatelliteCreationModal props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Ground Station" && <GroundStationModal props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Area of Interest" && <AreaOfInterestModal props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Coverage Analysis" && <CoverageAnalysis props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Eclipse Analysis" && <EclipseAnalysis props={props} />}
      {props.bottombarComponent === "De-Orbiting Analysis" && <DeOrbitingAnalysis props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Ground Station Access Analysis" && <GroundStationAccessAnalysis props={props} parameterProps={parameterProps} />}

      {props.bottombarComponent === "Power Analysis" && <PowerAnalysisModal props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Power Analysis - Hi-Fi" && <PowerAnalysisModalHiFi props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "Thermal Analysis" && <ThermalAnalysisModal props={props} parameterProps={parameterProps} />}

      {props.bottombarComponent === "Data Analysis" && <DataAnalysisModal props={props} parameterProps={parameterProps} />}

      {props.bottombarComponent === "Keep-In & Keep-Out" && <KeepInKeepOutModal props={props} parameterProps={parameterProps} />}
      {props.bottombarComponent === "ADCS Selections" && <ADCSSelectionsModal props={props} parameterProps={parameterProps} />}
    </>
  );
};

export default Modals;
