import React, { useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import TableModal from "../ModalComponents/TableModal";
import ModalDropdown from "../ModalComponents/ModalDropdown";
import ModalHeader from "../ModalComponents/ModalHeader";
import ModalFooter from "../ModalComponents/ModalFooter";

const PowerAnalysisModal = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const tableProps = {isModalVisible, setisModalVisible};
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [previousOption, setPreviousOption] = useState("")

  const [powerAnalysisSats, showPowerAnalysisSats] = useState({
    Mission_Sat_01: true,
  });

  const [busComponents, showBusComponents] = useState({
    ADCS: true,
    X_Band: true,
    S_Band: true,
  });

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  /* These are the values required for showing the table */

  const TableBarValues = [
    {
      label: "Bus Component",
      id: "busComponent",
      imgPath: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      label: "Payload",
      id: "payload",
      imgPath: require("../../ImageSvgs/PowerThermal.svg").default,
    },
    {
      label: "Edge",
      id: "edge",
      imgPath: require("../../ImageSvgs/PowerThermal.svg").default,
    },
  ];

  const [tableBarItem, setTableBarItem] = useState(TableBarValues[0].label);
  const TableBarItemProps = { tableBarItem, setTableBarItem };

  const BusHeaderElements = [
    {
      title: "Component",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Mode",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
    {
      title: "Power Usage",
      image: require("../../ImageSvgs/ADC.svg").default,
    },
    {
      title: "Up-Time",
      image: require("../../ImageSvgs/Navigation.svg").default,
    },
  ];

  const PayloadHeaderElements = [
    {
      title: "Payload",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Mode",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
    {
      title: "Power Usage",
      image: require("../../ImageSvgs/ADC.svg").default,
    },
    {
      title: "Up-Time",
      image: require("../../ImageSvgs/Navigation.svg").default,
    },
  ];

  const EdgeHeaderElements = [
    {
      title: "Edge Device",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Mode",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
    {
      title: "Power Usage",
      image: require("../../ImageSvgs/ADC.svg").default,
    },
    {
      title: "Up-Time",
      image: require("../../ImageSvgs/Navigation.svg").default,
    },
  ];

  const busComponentOptions = [
    { label: "ADCS", value: "ADCS" },
    { label: "X-Band", value: "X-Band" },
    { label: "S-Band", value: "S-Band" },
  ];

  const payloadComponentOptions = [{ label: "Imaging", value: "Imaging" }];

  const edgeComponentOptions = [{ label: "Edge SSD", value: "Edge SSD" }];

  const modeOptions = [
    { label: "Regular", value: "Regular" },
    { label: "Ideal", value: "Ideal" },
    { label: "Max", value: "Max" },
    { label: "Run-Time", value: "Run-Time" },
    { label: "Secondary", value: "Secondary" },
    { label: "Back-up", value: "Back-up" },
  ];

  let [inputFieldPlaceHolder, setInputFieldPlaceHolder] = useState("120 W");

  const handleSelectedOption = (selectedOption) => {
    console.log("Selected Option => ", selectedOption)
  }

  const BusComponentsRowElements = [
    <ModalDropdown options={busComponentOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <ModalDropdown options={modeOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <input
      className="tableInputElement"
      value={inputFieldPlaceHolder}
      onChange={(event) => setInputFieldPlaceHolder(event.target.value)}
      placeholder="in Watts"
    />,
    <input className="tableInputElement" value="" placeholder="in sec" />,
  ];

  const PayloadRowElements = [
    <ModalDropdown options={payloadComponentOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <ModalDropdown options={modeOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <input
      className="tableInputElement"
      value={inputFieldPlaceHolder}
      onChange={(event) => setInputFieldPlaceHolder(event.target.value)}
      placeholder="in Watts"
    />,
    <input className="tableInputElement" value="" placeholder="in sec" />,
  ];

  const EdgeRowElements = [
    <ModalDropdown options={edgeComponentOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <ModalDropdown options={modeOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <input
      className="tableInputElement"
      value={inputFieldPlaceHolder}
      onChange={(event) => setInputFieldPlaceHolder(event.target.value)}
      placeholder="in Watts"
    />,
    <input className="tableInputElement" value="" placeholder="in sec" />,
  ];

  const headerElementsSelector = () => {
    if (tableBarItem === "Bus Component") {
      return BusHeaderElements;
    } else if (tableBarItem === "Payload") {
      return PayloadHeaderElements;
    } else if (tableBarItem === "Edge") {
      return EdgeHeaderElements;
    }
  };

  const rowElementsSelector = () => {
    if (tableBarItem === "Bus Component") {
      return BusComponentsRowElements;
    } else if (tableBarItem === "Payload") {
      return PayloadRowElements;
    } else if (tableBarItem === "Edge") {
      return EdgeRowElements;
    }
  };

  const numOfRowsSelector = () => {
    if (tableBarItem === "Bus Component") {
      return 6;
    } else if (tableBarItem === "Payload") {
      return 2;
    } else if (tableBarItem === "Edge") {
      return 2;
    }
  };

  /* End of values for the table */

  const header = {
    title: "Power Analysis - Low-Fi",
    img: require("../../ImageSvgs/PowerThermal.svg").default,
  };

  return (
    <>
      {parameterProps.isTableModalVisible && (
        <TableModal
          props={props}
          parameterProps={parameterProps}
          tableBarValues={TableBarValues}
          tableBarItemProps={TableBarItemProps}
          headerElements={headerElementsSelector()}
          numOfRows={numOfRowsSelector()}
          rowElements={rowElementsSelector()}
          tableHeader="Power Sink"
        />
      )}

      {isSuccessMsgVisible && (
        <ModalCreationMessage props={props} message={message} />
      )}
      {isModalVisible && (
        <>
          <div
            className={
              props.isSidebarVisible
                ? "mainModal sidebarOpenModal"
                : "mainModal"
            }
            id="coverageAnalysis"
          >
            <ModalHeader
              props={props}
              parameterProps={parameterProps}
              header={header}
            />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <div className="modalCheckboxText modalGreenSubHeader ">
                  Satellites
                </div>
              </div>

              <div className="modalrefOrbitDiv">
                {powerAnalysisSats.Mission_Sat_01 && (
                  <div className="modalrefOrbitsBtn">
                    <span>&lt;Mission_Sat_01&gt;</span>
                    <button
                      className="modalrefOrbitsCloseBtn"
                      onClick={() =>
                        showPowerAnalysisSats({
                          ...powerAnalysisSats,
                          Mission_Sat_01: false,
                        })
                      }
                    >
                      <img
                        src={require("../../ImageSvgs/CloseButton.svg").default}
                      />
                    </button>
                  </div>
                )}
              </div>

              <div className="modalCheckboxDiv">
                <div className="modalCheckboxText modalGreenSubHeader">
                  Power Sink
                </div>
                <button
                  className="tableModalOpenModal"
                  onClick={() => parameterProps.setIsTableModalVisible(true)}
                >
                  <img
                    src={require("../../ImageSvgs/OpenNewBox.svg").default}
                  />
                </button>
              </div>

              <div className="modalWhiteSubHeader modalSubHeaderDiv">
                Bus Components
              </div>

              <div
                className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                style={{ margin: "12px" }}
              >
                <div className="modalrefOrbitsSpacer">
                  <div
                    className="modalrefOrbitsBtn"
                    style={{
                      display: busComponents.ADCS ? "flex" : "none",
                    }}
                  >
                    <span>ADCS</span>
                    <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                      <img
                        src={require("../../ImageSvgs/eye-outline.svg").default}
                      />
                    </button>
                    <button
                      className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                      onClick={() =>
                        showBusComponents({
                          ...busComponents,
                          ADCS: false,
                        })
                      }
                    >
                      <img
                        src={require("../../ImageSvgs/CloseButton.svg").default}
                      />
                    </button>
                  </div>
                  <div
                    className="modalrefOrbitsBtn"
                    style={{
                      display: busComponents.X_Band ? "flex" : "none",
                    }}
                  >
                    <span>X-Band</span>
                    <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                      <img
                        src={require("../../ImageSvgs/eye-outline.svg").default}
                      />
                    </button>
                    <button
                      className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                      onClick={() =>
                        showBusComponents({
                          ...busComponents,
                          X_Band: false,
                        })
                      }
                    >
                      <img
                        src={require("../../ImageSvgs/CloseButton.svg").default}
                      />
                    </button>
                  </div>
                </div>
                <div
                  className="modalrefOrbitsBtn"
                  style={{
                    display: busComponents.S_Band ? "flex" : "none",
                  }}
                >
                  <span>S-Band</span>
                  <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                    <img
                      src={require("../../ImageSvgs/eye-outline.svg").default}
                    />
                  </button>
                  <button
                    className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                    onClick={() =>
                      showBusComponents({
                        ...busComponents,
                        S_Band: false,
                      })
                    }
                  >
                    <img
                      src={require("../../ImageSvgs/CloseButton.svg").default}
                    />
                  </button>
                </div>
              </div>
              <div className="modalWhiteSubHeader modalSubHeaderDiv">
                Payloads
              </div>
              <div
                className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                style={{ margin: "12px" }}
              >
                Add Payloads
              </div>
              <div className="modalWhiteSubHeader modalSubHeaderDiv">Edge</div>
              <div
                className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                style={{ margin: "12px" }}
              >
                Add Edge devices
              </div>
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("Power Analysis - Low-Fi Analysis successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Power");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PowerAnalysisModal;
