
export function calculateFrameTimes(simulation_start_time: number, simulation_end_time: number, frame_duration: number): number[][] {
    const frameTimes: number[][] = [];
    let currentTime = simulation_start_time;
    const endTime = simulation_end_time;
    const timeframe_duration = frame_duration;
    while (currentTime < endTime) {
        let frameEnd = currentTime + timeframe_duration;
        if (frameEnd > endTime) {
            frameEnd = endTime;
        }
        frameTimes.push([currentTime, frameEnd]);
        currentTime = frameEnd;
    }

    return frameTimes;
}

export const get_current_timeframe = (current_time, start_time: number, duration: number) => {
    // const current_timeframe = timeframe_list.find(timeframe => {
    //     return current_time >= timeframe[0] && current_time <= timeframe[1]
    // })
    // return current_timeframe
    let slotNumber = Math.floor((current_time - start_time) / duration);
    // Calculate the start and end of the current time slot
    let slot_start = start_time + slotNumber * duration;
    let slot_end = slot_start + duration;

    return [slot_start, slot_end];
}
