import React, { createContext, useState, ReactNode, useEffect } from 'react';
import * as Cesium from "cesium";
import useScenario from '../hooks/useScenario';
import useTimeline from '../hooks/useTimeline';
import useTrueTwin from '../hooks/useTrueTwin';

interface ViewerContextType {
    viewer: Cesium.Viewer | null;
    setViewer: (data: any) => void;
    current_simulation_time: number;
    set_current_simulation_time: (data: number) => void;
}

export const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

interface CesiumProviderProps {
    children: ReactNode;
}

const CesiumProvider = ({ children }: CesiumProviderProps) => {
    const { scenario } = useScenario()
    const { get_truetwin_status } = useTrueTwin()
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null)
    const [current_simulation_time, set_current_simulation_time] = useState<number>(0)
    const { window_width, timeframe_details, set_cesium_time } = useTimeline()
    const { frame_start_time, frame_duration } = timeframe_details

    useEffect(() => {
        if (viewer && scenario?.['simulationStartTime'], frame_start_time, frame_duration) {
            const simulation_start_time = scenario?.['simulationStartTime']
            const simulation_duration = scenario?.['simulationDuration'] * 60
            const simulation_end_time = (simulation_start_time + simulation_duration) * 1000
            if (simulation_end_time) {
                const Interval = setInterval(() => {
                    const clock = viewer?.clock;
                    if (clock) {
                        const currentTime = clock?.currentTime;
                        if (currentTime) {
                            const epochTime = Cesium.JulianDate.toDate(currentTime).getTime();
                            set_cesium_time(epochTime, simulation_start_time, (simulation_end_time / 1000))
                            if (epochTime >= simulation_end_time) {
                                clearInterval(Interval);
                                set_current_simulation_time(simulation_end_time)
                                get_truetwin_status()
                            } else {
                                set_current_simulation_time(epochTime)
                            }
                        }
                    }
                    // Code to run every second goes here
                }, 1000);
                return () => {
                    clearInterval(Interval);
                };
            }
        }
    }, [viewer, scenario, window_width, frame_duration, frame_start_time]);

    return (
        <ViewerContext.Provider value={{ viewer, setViewer, current_simulation_time, set_current_simulation_time }}>
            {children}
        </ViewerContext.Provider>
    )
}

export default CesiumProvider