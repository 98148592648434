
import CustomSelect from '../../../../custom_components/CustomSelect'

const TaskForm = () => {
    return (
        <div className="left__panel__body__form__section__container">
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Task Type</span>
                <div className='input__box__container'>
                    <CustomSelect options={[]} value={null} onChange={() => { }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Files</span>
                <div className='input__box__container'>
                    <CustomSelect options={[]} value={null} onChange={() => { }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Destination Path</span>
                <div className='input__box__container'>
                    <input className='input__box' value={''} placeholder='Destination path' onChange={() => { }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
        </div>
    )
}

export default TaskForm