import Tooltip from '@mui/material/Tooltip';
interface CustomToolTipProps {
    children: React.ReactNode;
    title: string;
    placement?: 'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'right-start' | 'right-end';
    key?: any;
    disabled?: boolean;
    className?: string;
}

const CustomToolTip: React.FC<CustomToolTipProps> = ({ children, title, placement, key, disabled = false, className }) => {

    return (
        <Tooltip key={key} title={title} placement={placement}
            componentsProps={{
                tooltip: {
                    sx: {
                        display: disabled ? 'none' : 'flex',
                        color: "#d8d8d8",
                        backgroundColor: "#19191A",
                        padding: '6px 12px',
                        fontSize: '12px',
                        borderRadius: '4px',
                        border: '0.5px solid rgba(240, 240, 240, 0.20)',
                        blur: '15%',
                        cursor: 'default',
                    }
                }
            }}>
            <span className={className}>{children}</span>
        </Tooltip>
    )
}

export default CustomToolTip