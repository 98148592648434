import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const EclipseAnalysisGraph = ({ data }) => {
  console.log("data in graph", data);

  const chartRef = useRef(null);

  // Prepare series data
  const seriesData = data.height?.map((height, index) => ({
    x: data.time[index] * 1000, // Convert to milliseconds for datetime
    y: height,
  }));

  // Calculate the midpoint timestamp
  const midpointIndex = Math.floor(data.time.length / 2);
  const midpointTimestamp = data.time[midpointIndex];

  const initialZoom = {
    type: 'x',
    enabled: true,
    autoScaleYaxis: true,
    xaxis: {
      min: midpointTimestamp - (midpointTimestamp - data.time[0]) / 4,
      max: data.time[midpointIndex],
    },
  };

  const options = {
    chart: {
      type: 'line',
      height: 400,
      toolbar: {
        show: true,
      },
      events: {
        zoomed: handleZoom,
      },
      background: '#2E2E33', // Set the background color here
    },
    xaxis: {
      type: 'datetime', // Change type to 'datetime' for UTC time
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
          });
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          colors: '#666',
        },
      },
      title: {
        text: 'Time',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
      },
      tooltip: {
        enabled: true,
        formatter: function (value) {
          const date = new Date(value);
          return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
          });
        },
      },
      initialZoom: initialZoom,
    },
    yaxis: {
      show: false,
      title: {
        text: 'Height',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          colors: '#666',
        },
      },
      tickAmount: 2,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#9f7de1'],
    },
    tooltip: {
      x: {
        show: false, // Disable tooltip for the x-axis
      },
      y: {
        formatter: (value) => value.toFixed(2),
        style: {
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
        },
      },
      theme: 'dark',
    },
  };

  function handleZoom(event, chartContext, config) {
    // You can handle the zoom event here if needed
    console.log('Zoom event:', event);
  }

  return (
    <Chart
      options={options}
      series={[{ data: seriesData }]}
      type="line"
      height={400}
      className="mt-3"
      ref={chartRef}
    />
  );
};

export default EclipseAnalysisGraph;
