import { Fragment } from "react";
import PlaneSpaceObject from "./PlaneSpaceObject";
import ConstellationSpaceObject from "./ConstellationSpaceObject";

interface SpaceObjectContainerProps {
  PlaneObject: any;
  setVisualisationProperties: (name:string, id: string) => void;
  openModal: (selectedItem: any) => void;
  props: any;
}

const SpaceObjectContainer: React.FC<SpaceObjectContainerProps> = ({ PlaneObject, setVisualisationProperties, openModal, props }) => {


  return (
    <Fragment>
      {PlaneObject?.['type']  === 'Plane' && 
        <PlaneSpaceObject
          PlaneObject={PlaneObject}
          setVisualisationProperties={(name, id)=>{setVisualisationProperties(name,id)}}
          openModal={(data)=>{openModal(data)}}
          props={props}
        />
      }
      {PlaneObject?.['type']  === "Constellation" && 
        <ConstellationSpaceObject
          PlaneObject={PlaneObject}
          setVisualisationProperties={(name, id)=>{setVisualisationProperties(name,id)}}
          openModal={(data)=>{openModal(data)}}
          props={props}
        />
      }
    </Fragment>
  );
};

export default SpaceObjectContainer;
