import React, { useState } from "react";

const Upperbar = ({props}) => {

  const UpperBarValues = [
    {
      label: "Orbital",
      id: "orbital",
      imgPath: require("../ImageSvgs/Orbital.svg").default,
    },
    {
      label: "Power & Thermal",
      id: "power",
      imgPath: require("../ImageSvgs/PowerThermal.svg").default,
    },
    {
      label: "Data Flow",
      id: "dataFlow",
      imgPath: require("../ImageSvgs/DataFlow.svg").default,
    },
    {
      label: "ADC",
      id: "adc",
      imgPath: require("../ImageSvgs/ADC.svg").default,
    },
    {
      label: "Navigation",
      id: "navigation",
      imgPath: require("../ImageSvgs/Navigation.svg").default,
    },
    {
      label: "View",
      id: "view",
      imgPath: require("../ImageSvgs/View.svg").default,
    },
  ];

  const RenderUpperBarButton = ({ label, id, imgPath }) => (
    <button
    className={
      props.isTopBarCurrentlyActive === label
        ? "activeBottomBarBtn upperBarElement"
        : "upperBarElement"
    }
    id= {id}
    onClick={() => props.setIsTopBarCurrentlyActive(label)}
  >
    <img
        src={imgPath}
        className={props.isTopBarCurrentlyActive === label ? "active-upperbar-img" : ""}
      />
    <span>{label}</span>
  </button>
  );

  return (
    <div className="upperBar">
      {UpperBarValues.map(RenderUpperBarButton)}
    </div>
  );
};

export default Upperbar;
