import './CustomLoader.css'

interface LoaderProps {
    loading: boolean
}


const CustomLoader: React.FC<LoaderProps> = ({ loading }) => {
    const style = {
        height: '100%',
        width: '100%',
        display: loading ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
    }

    return (
        <div style={style}>
            <div className="unique_loader" />
        </div>
    )
}

export default CustomLoader