import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";
import axios from "axios"

const getToken = async (token) => {
    const newToken = await axios.post(`${getEnvVar('REACT_APP_KEYCLOAK_URL')}`+`realms/${getEnvVar('REACT_APP_KEYCLOAK_REALM')}/protocol/openid-connect/token`, new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
        audience: getEnvVar('REACT_APP_KEYCLOAK_RESOURCE_CLIENT') ? getEnvVar('REACT_APP_KEYCLOAK_RESOURCE_CLIENT') : ""
    }), { headers: {
        "Authorization": "Bearer " + token,
        'Content-Type': 'application/x-www-form-urlencoded'
    }});
    return newToken
}

const getAllTenantsUsers = () => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant`);
}
const createTenantsUsers = (tenantData) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant`,tenantData);
}
const updateTenantsUsers = (tenantId,tenantData) => {
    return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant/${tenantId}`,tenantData);
}

const getTenantsByUser = () => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant`);
}
const get_User_By_Tenant = (tenantId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant/${tenantId}`);
}
const deleteTenantsUsers = (tenantId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/tenant/${tenantId}`);
}

const createNewUser = (userData) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/keycloak`,userData);
}

const updateUser = (userId, userData) => {
    return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/keycloak/${userId}`, userData);
}

const getUsersList = () => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/keycloakUsers`);
}
const deletetUser = (userId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/keycloak/${userId}`);
}

const getUserRolesList = () => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/roles`);
}

const sendVerificationMail = (userId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/keycloak/mail/verify/${userId}`);
}


const verifyMail = (mail) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}user/verifyemail?email=${mail}`);
}

const UserService = {
    getToken,
    getTenantsByUser,
    getAllTenantsUsers,
    createTenantsUsers,
    updateTenantsUsers,
    get_User_By_Tenant,
    deleteTenantsUsers,
    createNewUser,
    getUsersList,
    deletetUser,
    getUserRolesList,
    updateUser,
    sendVerificationMail,
    verifyMail
}

export default UserService;
