const Lowerbar = ({ props }) => {
  const showModalContent = (component) => {
    props.setBottombarProps({ 
      bottombarComponent: component,
      componentId: "",
      componentData : "" });
  };

  const OrbitalLowerBarValues = [
    {
      label: "Satellite",
      id: "satelliteLowerbar",
      tooltipContent: "Satellite",
      imgPath: require("../ImageSvgs/Satellite.svg").default,
    },
    {
      label: "Ground Station",
      id: "groundStationLowerbar",
      tooltipContent: "Ground Station",
      imgPath: require("../ImageSvgs/GroundStation.svg").default,
    },
    {
      label: "Area of Interest",
      id: "aoiLowerbar",
      tooltipContent: "Area of Interest",
      imgPath: require("../ImageSvgs/AOI.svg").default,
    },
    {
      label: "Coverage Analysis",
      id: "coverageAnalysisLowerbar",
      tooltipContent: "Coverage Analysis",
      imgPath: require("../ImageSvgs/CoverageAnalysis.svg").default,
    },
    {
      label: "Eclipse Analysis",
      id: "eclipseAnalysisLowerbar",
      tooltipContent: "Eclipse Analysis",
      imgPath: require("../ImageSvgs/EclipseAnalysis.svg").default,
    },
    {
      label: "De-Orbiting Analysis",
      id: "deorbitingAnalysisLowerbar",
      tooltipContent: "De-Orbiting Analysis",
      imgPath: require("../ImageSvgs/DeOrbitingAnalysis.svg").default,
    },
    // {
    //   label: "Ground Station Access Analysis",
    //   id: "groundStationAccessAnalysisLowerbar",
    //   tooltipContent: "Ground Station Access Analysis",
    //   imgPath: require("../ImageSvgs/GroundStationAccessAnalysis.svg").default,
    // },
  ];

  const PowerThermalLowerBarValues = [
    {
      label: "Power Analysis",
      id: "powerAnalysisLowFiLowerbar",
      tooltipContent: "Power Analysis - Low-Fi",
      imgPath: require("../ImageSvgs/PowerThermal.svg").default,
    },
    {
      label: "Power Analysis - Hi-Fi",
      id: "powerAnalysisHiFiLowerbar",
      tooltipContent: "Power Analysis - Hi-Fi",
      imgPath: require("../ImageSvgs/PowerThermal.svg").default,
    },
    {
      label: "Thermal Analysis",
      id: "thermalAnalysisLowerbar",
      tooltipContent: "Thermal Analysis",
      imgPath: require("../ImageSvgs/Thermal-Analysis.svg").default,
    },
    
  ];

  const DataFlowLowerBarValues = [
    {
      label: "Data Analysis",
      id: "dataAnalysisLowerbar",
      tooltipContent: "Data Analysis",
      imgPath: require("../ImageSvgs/Data-Analysis.svg").default,
    },
  ];

  const ADCLowerBarValues = [
    {
      label: "Keep-In & Keep-Out",
      id: "keepInKeepOutLowerbar",
      tooltipContent: "Keep-In & Keep-Out",
      imgPath: require("../ImageSvgs/Keep-In-Keep-Out.svg").default,
    },
    {
      label: "ADCS Selections",
      id: "adcsSelectionsLowerbar",
      tooltipContent: "ADCS Selections",
      imgPath: require("../ImageSvgs/ADCS-Selection.svg").default,
    },
  ];

  const RenderLowerBarButton = ({ label, id, tooltipContent, imgPath }) => (
    <button
      className={
        props.bottombarComponent === label
          ? "activeTopBarBtn bottomBarBtn tooltipDiv"
          : "bottomBarBtn tooltipDiv"
      }
      id={id}
      onClick={() => {
        showModalContent(label);
        props.setVisualisationProps(label);
        props.setSidebarProps({
        sidebarComponent: props.sidebarComponent,
        isSidebarVisible: props.isSidebarVisible,
        isComponentUpdated: false,
      });
      if(label==="Coverage Analysis" || label === "Eclipse Analysis" || label === "De-Orbiting Analysis")
      props.setBottomBarReport(label)
        if(label === "Coverage Analysis"){
          props.setIsShowMultipleOrbit(true)
        }else{
          props.setIsShowMultipleOrbit(false)
        }
    }}
    >
      <span className="tooltipContent">{tooltipContent}</span>
      <img
        src={imgPath}
        className={props.bottombarComponent === label ? "active-img" : ""}
      />
    </button>
  );

  return (
    <>
      <div className="bottomBar">
        <div className="bottomBarBtnDiv">
          {props.isTopBarCurrentlyActive === "Orbital" &&
            OrbitalLowerBarValues.map(RenderLowerBarButton)}
          {props.isTopBarCurrentlyActive === "Power & Thermal" &&
            PowerThermalLowerBarValues.map(RenderLowerBarButton)}
          {props.isTopBarCurrentlyActive === "Data Flow" &&
            DataFlowLowerBarValues.map(RenderLowerBarButton)}  
          {props.isTopBarCurrentlyActive === "ADC" &&
            ADCLowerBarValues.map(RenderLowerBarButton)}    
        </div>
      </div>
    </>
  );
};

export default Lowerbar;
