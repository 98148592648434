import { getEnvVar } from '../envUtils.ts';
import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
 url: getEnvVar('REACT_APP_KEYCLOAK_URL'),
 realm: "ATMOS",
 clientId: "ATMOS-UI-CLIENT",
});

export default keycloak;
