export const MaximizeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <path d="M0.500244 4.27669V7.5H2.11437H3.72851M4.63013 0.5H7.49969V3.4954" stroke="#F0F0F0" stroke-opacity="0.6" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const MinimizeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M8.58967 1.375V5.41033H12.625M1.375 8.875H4.96196V12.625" stroke="#F0F0F0" stroke-opacity="0.6" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
