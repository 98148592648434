import { FC } from "react"
import * as Cesium from "cesium"
import './GroundStationCard.css'

interface GsProps {
    station: any;
    viewer: any
}

const GroundStationCard: FC<GsProps> = ({ station, viewer }) => {

    if (viewer && station) {
        var latitude = Number(station?.properties?.latitude);
        var longitude = Number(station?.properties?.longitude);

        var cartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
        var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesianPosition
        );

        let outside_window_x = (popupPosition.x + 610) > window.innerWidth;
        const top_position = station?.['properties']?.['freqbands']?.length === 3 ? 200 : station?.['properties']?.['freqbands']?.length === 2 ? 165 : 140
        let outside_window_y = popupPosition.y - top_position < 0;
        let top = outside_window_y ? `${((popupPosition.y - top_position) - (popupPosition.y - top_position))}px` : `${popupPosition.y - top_position}px`;
        let left = outside_window_x ?
            outside_window_y ? `${popupPosition.x - 620}px` : `${(popupPosition.x + window.innerWidth) - (popupPosition.x + 610)}px` :
            outside_window_y ? `${popupPosition.x + 20}px` : `${popupPosition.x + 10}px`;

        return (
            <div className="popup-modal"
                style={{
                    display: station ? 'flex' : 'none',
                    cursor: 'default',
                    left: left,
                    top: top,
                    transition: 'all 1s ease',
                    zIndex: 9999,
                }}
            >
                <div className='py-2 px-4 d-flex align-items-center w-100 justify-content-between'>
                    <div className='d-flex align-items-start flex-column w-55'>
                        <div className='d-flex align-items-center w-98'>
                            <span className='fs-14 w-auto color-atmos text-truncate' title={station?.['groundStationName']}>
                                {station?.['groundStationName']}
                            </span>
                            {(station?.['properties']?.['country'] && station?.['properties']?.['country'] !== '') && <span className='fs-14 w-auto text-truncate color-text' title={station?.['properties']?.['country']}>
                                {`, ${station?.['properties']?.['country']}`}
                            </span>}
                        </div>
                        <span className='fs-12 color_history text-truncate'
                            title={(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ? station?.['properties']?.['provider'] : 'Standalone'}>
                            {(station?.['properties']?.['provider'] && station?.['properties']?.['provider'] !== '') ?
                                station?.['properties']?.['provider']
                                :
                                'Standalone'
                            }
                        </span>
                    </div>
                    <div className='d-flex align-items-center gap-2 fs-12'>
                        <div className='d-flex gap-1 align-items-center'>
                            <span className='color_history'>Lat:</span>
                            <span className='fs-13 color-text'>{station?.properties?.latitude}</span>
                        </div>
                        <div style={{ width: '0.5px', height: '20px', background: 'rgba(240,240,240,0.20)' }} />
                        <div className='d-flex gap-1 align-items-center'>
                            <span className='color_history'>Lon:</span>
                            <span className='fs-13 color-text'>{station?.properties?.longitude}</span>
                        </div>
                    </div>
                </div>
                <div className='modal_devider' />
                <div className='w-100 d-flex align-items-center flex-column gap-2 px-4 py-3'>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <span className='color_history fs-13 w-10'>Band</span>
                        <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Rx Freq.</span>
                        <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>Tx Freq.</span>
                        <span className='color_history fs-13 w-15 align-items-center justify-content-center d-flex'>G/T (dB/K)</span>
                        <span className='color_history fs-13 w-20 align-items-center justify-content-center d-flex'>EIRP (dBW)</span>
                        <span className='color_history fs-13 w-15 align-items-center justify-content-start d-flex'>Polarization</span>
                    </div>
                    {station?.['properties']?.['freqbands']?.map((band: any, index) => {
                        return (
                            <div className='d-flex align-items-center justify-content-between w-100' key={index}>
                                <span className='color_history fs-12 w-10'>{band?.split('-')?.[0]}</span>
                                <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>
                                    {station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['rxFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['rxFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                </span>
                                <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>
                                    {station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min'] ? `${station?.['properties']?.['txFreq']?.[`${band?.split('-')[0]}freq`]?.['min']} - ${station?.['properties']?.['txFreq'][`${band?.split('-')[0]}freq`]?.['max']}` : '-'}
                                </span>
                                <span className='fs-12 w-15 align-items-center justify-content-center d-flex color-text'>{station?.['properties']?.[`${band?.split('-')[0]}_GT`] ? station?.['properties']?.[`${band?.split('-')[0]}_GT`] : '-'}</span>
                                <span className='fs-12 w-20 align-items-center justify-content-center d-flex color-text'>{station?.['properties']?.[`${band?.split('-')[0]}_EIRP`] ? station?.['properties']?.[`${band?.split('-')[0]}_EIRP`] : '-'}</span>
                                <span className='fs-12 w-15 align-items-center justify-content-start text-truncate color-text' title={station?.['properties']?.[`polarization`]}>
                                    {station?.['properties']?.[`polarization`] ?
                                        station?.['properties']?.[`polarization`]
                                        : '-'
                                    }
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    return null
}

export default GroundStationCard