// @ts-nocheck
import React, { useEffect, useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import MissionModelingService from "../../MissionModelingService";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
interface Plane {
  planeId: number;
  name: string;
  aoiId: number;
}
const CoverageAnalysis = ({ props, parameterProps }) => {
  const params = useParams()
  const {
    mission_name,
    mission_id
  } = params;
  const [isModalVisible, setisModalVisible] = useState(true);
  const [isSelectedModalComponent, setSelectedModalComponent] = useState("Imaging");
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [refOrbit, showRefOrbit] = useState(true);
  const [message, setMessage] = useState("");
  const [planes, setPlanes] = useState([]);
  const [planeId, setPlaneId] = useState<Plane[]>([]);
  const [aoi, setAllAoi] = useState<Plane[]>([]);
  const [aoiId, setAllAoiId] = useState<Plane[]>([]);
  const [Gs, setAllGs] = useState<Plane[]>([]);
  const [eclipsePlanes, setEclipsePlanes] = useState<any>([]);
  const [eclipseItems, showEclipseItems] = useState({
    Planes: true,
    Analysis_Timeframe: true,
  });
  const [showItems, setShowItems] = useState({
    Satellites: true,
    Ground_Stations: true,
    Analysis_Timeframe: true,
  });
  const [selectedGs, setSelectedGs] = useState<any[]>([]);
  const [payloadSwath, setPayloadSwath]=useState()
  const [revisits,setRevisits]=useState("1")
  const [coverageTimeframe, setCoverageTimeframe] = useState();
  const [loading, setLoading] = useState(false)


  // Function to remove a plane by its ID
  const removePlaneById = (item) => {
    const updatedPlanes = planes?.filter((plane) => plane !== item);
    setPlanes(updatedPlanes);
  };

  // Function to remove a aoi by its ID
  const removeAoiById = (aoiId: number) => {
    const updatedAoi = aoi?.filter((aoi) => aoi.aoiId !== aoiId);
    setAllAoi(updatedAoi);
    var remainingAoiIds = updatedAoi?.map((aoi) => aoi.aoiId);

    console.log("remainingAoiIds", remainingAoiIds);
    setAllAoiId(remainingAoiIds);
  };

  const [aoiButtons, hideAoiButtons] = useState({
    Constellations: false,
    AOI_01: true,
    AOI_02: true,
    Point_Target: true,
  });

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      // props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
      props.setBottomBarReport("Coverage Analysis")
    }, 2000);
  };

  const getAllPlanes = () => {
    MissionModelingService.getAllPlanes(mission_id)
      .then((res) => {
        setPlanes(res?.["data"]);
        var remainingPlaneIds = res?.["data"]?.map((plane) => plane.planeId);
        setPlaneId(remainingPlaneIds);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllAreaOfInterest = () => {
    MissionModelingService.getAllAreaOfInterest(mission_id)
      .then((res) => {
        console.log("Response ar => ", res);
        setAllAoi(res?.["data"]);
        var remainingAoiIds = res?.["data"]?.map((aoi) => aoi.aoiId);
        setAllAoiId(remainingAoiIds);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllGroundStation = () => {
    MissionModelingService.getAllGroundStation(mission_id)
      .then((res) => {
        setAllGs(res?.["data"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllPlanes();
    getAllAreaOfInterest();
    getAllGroundStation();
  }, []);

  const postCoverageAnalysis = () => {
    setLoading(true)

    let planeIds = [];
    planes?.map((item) => {
      planeIds.push(item?.["spaceobjectId"]);
    });

    let spaceobjects:any = []
    eclipsePlanes?.map((item: any) => {
      spaceobjects.push({objectId: item?.['objectId'], objectType: item?.['objectType']})
    })
 console.log("slectedgs", selectedGs,coverageTimeframe,revisits,payloadSwath);


 let data = {
  aoiobjects: selectedGs.map(item => ({
    aoiId: item.aoiId,
    aoiProperties: {
      coverageTimeframe: parseFloat(coverageTimeframe),  
      revisits: parseFloat(revisits),           
      swath: parseFloat(payloadSwath)              
    }
  })),
  spaceobjects: spaceobjects,
  constellation: false,
};

    MissionModelingService.postCoverageAnalysis(data, mission_id)
      .then((res) => {
        showSuccessMessage("Coverage Analysis successful");
        props.setSideReportBarProps({ isSideReportBarVisible: true, isEclipseComponentUpdate: !props.isEclipseComponentUpdate});
        // props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
        setLoading(false)
        setisModalVisible(false);
        props.setSideReportBarProps({isSideReportBarVisible: true, isCoverageAnalysisComponentUpdate: !props.isCoverageAnalysisComponentUpdate});                  
        props.setIsCVUpdate(!props.isCVUpdate)
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false)
      });
  };
  useEffect(() => {
    if(props.selectedComponent === "Area of Interest"){
      const data = {
        aoiId: props?.selectedComponentId,   
        groundstationName: props?.selectedComponentName,   
      };
      let alreadyHas = selectedGs?.some(item => JSON.stringify(item) === JSON.stringify(data))
      
      if(!alreadyHas){
        setSelectedGs([data])
        // setSelectedGs([...selectedGs, data]);  for now commented because we are considering only one AOI
      }
    }else if(props.selectedComponent === 'Satellite' || props.selectedComponent === 'Plane' || props.selectedComponent === 'Spaceobject'){
      const currentEclipseObject = {
        objectId: props?.selectedComponentId,
        name: props?.selectedComponentName,
        objectType: props?.selectedComponent,
      };

      let alreadyHas = eclipsePlanes?.some(item => JSON.stringify(item?.['objectId']) === JSON.stringify(currentEclipseObject?.['objectId']))
      if(!alreadyHas){
        setEclipsePlanes([...eclipsePlanes, currentEclipseObject]);
      }
    }
  }, [props.selectedComponentId]);

  const removeEclipsePlanes = (item) => {
    const updatedPlanes = eclipsePlanes?.filter((eclipsePlanes) => eclipsePlanes?.['objectId'] !== item?.['objectId']);
    setEclipsePlanes(updatedPlanes);
  };
  const removeGroundStations = (item) => {
    const updatedPlanes = selectedGs?.filter((gs) => gs?.['objectId'] !== item?.['objectId']);
    setSelectedGs(updatedPlanes);
  };
  return (
    <>
      {isSuccessMsgVisible && <ModalCreationMessage props={props} message={message} />}
      {isModalVisible && (
        <>
          <div className={props.isSidebarVisible ? "mainModal sidebarOpenModal" : "mainModal"} id="coverageAnalysis">
            <div className="modalHeader">
              <div className="modalHeaderImg">
                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="20px" viewBox="0 0 23 22" fill="none">
                  <path
                    d="M10.6422 16.2396C13.5358 16.2396 15.8816 13.8939 15.8816 11.0003C15.8816 8.10667 13.5358 5.76093 10.6422 5.76093C7.74857 5.76093 5.40283 8.10667 5.40283 11.0003C5.40283 13.8939 7.74857 16.2396 10.6422 16.2396Z"
                    fill="#F0F0F0"
                  />
                  <path
                    d="M0.365723 11.0002C0.365723 5.32933 4.9732 0.750183 10.6423 0.750183C15.2492 0.750183 19.2564 3.80343 20.5104 8.19214L20.6014 8.51082L19.8699 8.53742L19.8173 8.35771C18.6247 4.2873 14.9182 1.46295 10.6423 1.46295C5.38145 1.46295 1.10508 5.73932 1.10508 11.0002C1.10508 16.261 5.38145 20.5374 10.6423 20.5374V21.0374L10.6157 21.0374V21.2502C4.97276 21.2502 0.365723 16.6706 0.365723 11.0002Z"
                    fill="#F0F0F0"
                  />
                  <circle cx="20.6343" cy="11.0002" r="2" fill="#F0F0F0" />
                </svg>
              </div>
              <span>Coverage Analysis</span>
              <button
                className="modalCloseBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                <img src={require("../../ImageSvgs/CloseButtonWhite.svg").default} />
              </button>
              <button
                className="modalCloseBtn modalMinimizeBtn"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                <img src={require("../../ImageSvgs/MinimizeButtonWhite.svg").default} />
              </button>
            </div>

            {/* <div className="modalComponentBox">
              <div className="modalComponentItem modalTwoComponentItem">
                <div className="modalComponentBtnBox">
                  <button
                    className={isSelectedModalComponent === "Imaging" ? "activeModalComponentBtn modalComponentBtn modalTwoComponentBtn" : "modalComponentBtn modalTwoComponentBtn"}
                    onClick={() => {
                      setSelectedModalComponent("Imaging");
                    }}
                  >
                    <img src={require("../../ImageSvgs/Earth-Icon.svg").default} className={isSelectedModalComponent === "Imaging" ? "active-img" : ""} />
                  </button>
                </div>
                <div className="modalComponentTxt">Imaging</div>
              </div>

              <div className="modalComponentItem modalTwoComponentItem">
                <div className="modalComponentBtnBox">
                  <button
                    className={
                      isSelectedModalComponent === "Communication" ? "activeModalComponentBtn modalComponentBtn modalTwoComponentBtn" : "modalComponentBtn modalTwoComponentBtn"
                    }
                    onClick={() => {
                      setSelectedModalComponent("Communication");
                    }}
                  >
                    <img src={require("../../ImageSvgs/Ground-Station-Comm.svg").default} className={isSelectedModalComponent === "Communication" ? "active-img" : ""} />
                  </button>
                </div>
                <div className="modalComponentTxt">Communication</div>
              </div>
            </div> */}

            <div className="modalScrollableSection">
              <div className="modalSubHeader modalGreenSubHeader">Space Objects</div>

              {eclipseItems.Planes && (
                <div className="modalrefOrbitDiv">
                  <div className="modalrefOrbitsScrollSpacer">
                  {eclipsePlanes?.map((item) => {
                      return (
                        <div key={item?.['objectId']} className="modalrefOrbitsBtn orbitsBtnSpacer">
                          <span>{item?.["name"]}</span>
                          <button
                            className="modalrefOrbitsCloseBtn"
                            onClick={() => {
                              removeEclipsePlanes(item);
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                              <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                              <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="modalCheckboxDiv" style={{display:"none"}}>
                <button
                  onClick={() => {
                    hideAoiButtons({
                      ...aoiButtons,
                      Constellations: !aoiButtons.Constellations,
                    });
                  }}
                  className="modalCheckBoxBtn"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#F0F0F0" />
                    {aoiButtons.Constellations && <path d="M4 8L6.5 10.5L13 4" stroke="#F0F0F0" strokeLinecap="round" strokeLinejoin="round" />}
                  </svg>
                </button>
                <div className="modalCheckboxText">Analyse for Constellation</div>
              </div>

                <div className="modalSubHeader modalGreenSubHeader">Area of Interest</div>

             {showItems.Ground_Stations && (
                <div className="modalrefOrbitDiv mb-5">
                    {selectedGs?.length > 0 && selectedGs?.map(gs => {
                      return (
                        <div className="modalrefOrbitsSpacer">
                          <div key={gs?.['objectId']}
                            className="modalrefOrbitsBtn modalGroundStationBlueBtn"
                          >
                            <span>{gs?.['groundstationName']}</span>
                            <button
                              className="modalrefOrbitsCloseBtn modalGroundStationBlueBtn"
                              onClick={() =>
                                removeGroundStations(gs)
                              }
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                                <path d="M1.2002 10.8L10.8002 1.19995" stroke="#0A0A0A" strokeLinecap="round" />
                                <path d="M1.20029 1.19995L10.8003 10.8" stroke="#0A0A0A" strokeLinecap="round" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )})
                    }
                </div>
              )}
{
                !aoi?.map((item) => (
                  <>
                    <div className="modalCheckboxDiv">
                      <button
                        onClick={() => {
                          // hideAoiButtons({
                          //   ...aoiButtons,
                          //   AOI_01: !aoiButtons.AOI_01,
                          // });
                          removeAoiById(item?.aoiId);
                        }}
                        className="modalCheckBoxBtn"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#36F097" />
                          {aoiButtons.AOI_01 && <path d="M4 8L6.5 10.5L13 4" stroke="#36F097" strokeLinecap="round" strokeLinejoin="round" />}
                        </svg>
                      </button>
                      <div className="modalCheckboxText modalGreenSubHeader">&lt; {item?.["name"]} &gt;</div>
                    </div>
                    {aoiButtons.AOI_01 && (
                      <>
                        <div className="modalAnalysisDiv">
                          <div className="modalAnalysisText">Payload Swath</div>
                          <input
                            value={parameterProps.swath}
                            onChange={(event) => {
                              parameterProps.setboundingBoxParameters({
                                swath: event.target.value,
                                timeframe: parameterProps.timeframe,
                                revisits: parameterProps.revisits,
                              });
                            }}
                            className="modalAnalysisInput"
                            type="number"
                            placeholder="km"
                          />
                        </div>
                        <div className="modalAnalysisDiv">
                          <div className="modalAnalysisText">Coverage Timeframe</div>
                          <input
                            value={item?.["coverageTimeframe"] ? item?.["coverageTimeframe"] : parameterProps.timeframe}
                            onChange={(event) => {
                              parameterProps.setboundingBoxParameters({
                                swath: parameterProps.swath,
                                timeframe: event.target.value,
                                revisits: parameterProps.revisits,
                              });
                            }}
                            className="modalAnalysisInput"
                            type="number"
                            placeholder="in hours"
                          />
                        </div>
                        <div className="modalAnalysisDiv"><hr className="thinHorizontalLine"/> Or <hr className="thinHorizontalLine"/></div>
                        <div className="modalAnalysisDiv">
                          <div className="modalAnalysisText">Revisits</div>
                          <input
                            value={item?.["revisits"] ? item?.["revisits"] : parameterProps.revisits}
                            onChange={(event) => {
                              parameterProps.setboundingBoxParameters({
                                swath: parameterProps.swath,
                                timeframe: parameterProps.timeframe,
                                revisits: event.target.value,
                              });
                            }}
                            className="modalAnalysisInput"
                            type="number"
                            placeholder="#"
                          />
                        </div>
                      </>
                    )}
                  </>
                ))
              }
              <div className="modalSubHeader modalGreenSubHeader">Expected Requirement</div>
              <>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Payload Swath (km)</div>
                    <input
                      // value={parameterProps.Satellite_Mass}
                      onChange={(event) => {
                      
                        setPayloadSwath(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in km"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv">
                    <div className="modalAnalysisText">Coverage Timeframe (hr)</div>
                    <input
                      // value={parameterProps.Surface_area}
                      onChange={(event) => {
                      
                        setCoverageTimeframe(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"in hours"}
                      style={{ width: "148px" }}
                    />
                  </div>
                  <div className="modalAnalysisDiv" style={{display:"none"}}>
                    <div className="modalAnalysisText">Revisits per day</div>
                    <input
                      // value={parameterProps.Area_Drag_coefficient}
                      onChange={(event) => {
                       
                        setRevisits(event.target.value);
                      }}
                      className="modalAnalysisInput"
                      type="number"
                      placeholder={"#"}
                      style={{ width: "148px" }}
                    />
                  </div>

                </>

            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  postCoverageAnalysis();
                  // props.setShowSidebarReportComponent("Orbital");
          

                }}
              > {!loading ?"Analysis":"Analyzing..."}&nbsp;
              {loading && 
               <CircularProgress size={10} className="text-white" />
              }
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CoverageAnalysis;
