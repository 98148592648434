import { useEffect, useState } from "react"
import CommandCenterService from "../../../service/CommandCenterService"
import useUrlParams from "./useUrlParams"
import useError from "./useError"

type Orbit = {
    documentMetadata: any,
    groundStation: any,
    areaOfInterest: any,
    groundTarget: any,
    satToGsConnection: any,
    satToGTConnection: any,
    satellite: any
}

const useOrbit = () => {
    const [orbitData, setOrbitData] = useState<Orbit | null>(null)
    const { dashboard, scenario_id, truetwin_id } = useUrlParams()
    const { setError } = useError()

    const getOrbitDetails = () => {
        const propagation = dashboard === 'summary' ? 'summary' : 'dashboard'
        CommandCenterService.getOribtData(scenario_id, truetwin_id, propagation).then(res => {
            if (res?.data) {
                setOrbitData(res?.data)
            }
        }).catch(err => {
            const error_msg = err?.message
            const error_code = error_msg === 'Network Error' ? error_msg : '500'
            // setError(error_code)
        })
    }

    useEffect(() => {
        if (dashboard && scenario_id && truetwin_id) {
            getOrbitDetails()
        }
    }, [dashboard, scenario_id, truetwin_id])

    return { orbitData }
}

export default useOrbit