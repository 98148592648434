import { getEnvVar } from '../../envUtils.ts';
import ApiService from "../../service/ApiService";

const getAllPlanes = (missionId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/spaceobject/all?missionid=${missionId}&scenarioid=1`);
};

const postAreaOfInterest = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/areaofinterest?missionid=${missionId}&scenarioid=1`, body);
};

const deleteAreaOfInterest = (aoiId, missionId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/areaofinterest?missionid=${missionId}&scenarioid=1&aoiid=${aoiId}`);
}

const getAllAreaOfInterest = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/areaofinterest/all?missionid=${missionId}&scenarioid=1`);
};

const postCoverageAnalysis = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/coverage?missionid=${missionId}&scenarioid=1`, body);
}
const postPlane = (body, missionId) => {
    return ApiService.hi(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/spaceobject?missionid=${missionId}&scenarioid=1`, body);
};

const getAllGroundStation=(missionId)=>{
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/groundstation/all?missionid=${missionId}&scenarioid=1`);
}
const getAllGlobalGroundStation=(missionId)=>{
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/groundstation/global?missionid=${missionId}&scenarioid=1`);
}
const postGroundStation = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/groundstation?missionid=${missionId}&scenarioid=1`, body);
};

const getAreaOfInterestReport = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/report/coverage?missionid=${missionId}&scenarioid=1`);
}

const postEclipseAnalysisData = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/eclipse?missionid=${missionId}&scenarioid=1`, body);
}

const postDeorbitAnalysisData = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/deorbit?missionid=${missionId}&scenarioid=1`, body);
}

const postGSAnalysisData = (body, missionId) => {
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/analysis/groundstation?missionid=${missionId}&scenarioid=1`, body);
}

const getEclipseAnalysisData = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/report/eclipse?missionid=${missionId}&scenarioid=1`);
}


const getDeorbitAnalysis = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/report/deorbit?missionid=${missionId}&scenarioid=1`);
}

const getGroundStationAccessAnalyis = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/mission-simulation/reports/groundstation?missionid=${missionId}&scenarioid=1`);
}

const getAllOrbitData = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/spaceobject/orbitpath/all?missionid=${missionId}&scenarioid=1`)
}

const deleteSpaceObject = (spaceObjId, missionId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/spaceobject?missionid=${missionId}&scenarioid=1&spaceobjectid=${spaceObjId}`);
}

const deleteGroundStation = (groundStationId, missionId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/groundstation?missionid=${missionId}&scenarioid=1&groundstationid=${groundStationId}`);
}
const getCoverageAnalysisOrbitList = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}/mission-simulation/areaofinterest/orbitpath?missionid=${missionId}&scenarioid=1`)
}

const MissionModelingService = {
    getAllPlanes,
    postAreaOfInterest,
    deleteAreaOfInterest,
    getAllAreaOfInterest,
    postCoverageAnalysis,
    postPlane,
    getAreaOfInterestReport,
    getAllGroundStation,
    postGroundStation,
    postEclipseAnalysisData,
    postDeorbitAnalysisData,
    getEclipseAnalysisData,
    getAllGlobalGroundStation,
    getDeorbitAnalysis,
    getGroundStationAccessAnalyis,
    postGSAnalysisData,
    getAllOrbitData,
    deleteSpaceObject,
    deleteGroundStation,
    getCoverageAnalysisOrbitList
};

export default MissionModelingService;
