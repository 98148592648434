import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import TimelineEventCell from "../TimelineEventCell/TimelineEventCell";
// import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "./BoundingBox.css";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const MapWithBoundingBox = ({ props, parameterProps, setBoundingBoxVisibility, setboundingBoxParameters }) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const draw = useRef(null);
  const [boundingBox, setBoundingBox] = useState(null);

  useEffect(() => {
    mapboxgl.accessToken =    "pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw";
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [0, 0], // Default center
      zoom: 1.8, // Default zoom level
    });
    map.current.on("load", () => {
      // Initialize Mapbox Draw
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon : props?.bottombarComponent === "Area of Interest" ? true : false,
          trash: props?.bottombarComponent === "Area of Interest" ? true : false,
          point: false,
        },
      });
      map.current.addControl(draw.current, "top-left"); // Add controls to the top-left corner
      // Event handler when a bounding box and point is created
      map.current.on("draw.create", (event) => {
        const feature = event.features[0];
        if (feature.geometry.type === "Point") {
          // Handle the point drawing here
          const coordinates = feature.geometry.coordinates;
          console.log("Point Coordinates (Lat, Long):", coordinates);
          setBoundingBox(coordinates);
        } else if (feature.geometry.type === "Polygon") {
          // Handle the bounding box here
          const coordinates = feature.geometry.coordinates[0];
          setBoundingBox(coordinates);
          props.setModalProps({
            isTimelineEventCellVisible: true,
            modalComponent: props.modalComponent,
            boundingBoxCoordinates: coordinates,
          });
        }
      });
    });
    return () => {
      map.current.remove();
    };
  }, [props?.bottombarComponent]);

  const showSidebar = () => {
    props.setSidebarProps({
      isSidebarVisible: true,
      sidebarComponent: props.bottombarComponent,
      isComponentUpdated: true,
    });
  };
  return (
    <>
      {/* {props.isTimelineEventCellVisible && (
        <TimelineEventCell props={props} parameterProps={parameterProps} />
      )} */}
      <div
      // className={
      //   props.isSidebarVisible
      //     ? "boundingBoxWrapper sidebarOpen"
      //     : "boundingBoxWrapper"
      // }
      >
        <div className="w-100 bondingBoxWrapper position-absolute" ref={mapContainerRef} style={{ height: "100vh" }} />
        <button
          className="bbFooter"
          onClick={() => {
            setboundingBoxParameters(boundingBox);
            setBoundingBoxVisibility(false);
            showSidebar();
            props.setModalProps({
              isTimelineEventCellVisible: true,
              modalComponent: props.modalComponent,
              boundingBoxCoordinates: boundingBox,
            });
          }}
          style={{display:"none"}}
        >
          Preview
        </button>
      </div>
    </>
  );
};
export default MapWithBoundingBox;