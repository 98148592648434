import { ElementRef, useEffect, useRef, useState } from 'react'
import './Documentation.css'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

const DOCUMENTS = [
    {
        side_item: 'Introduction',
        body: {
            heading: 'Introduction',
            content: (
                <div className='document__content'>
                    Welcome to the Antaris Cloud Platform! We are excited to support you in your mission and hope that you have a 
                    great experience with us.This User Guide supports those directly working within the Antaris Cloud Platform (ACP) to 
                    develop and operate satellites. Please use this document to learn about core platform concepts and workflows.If you 
                    have any questions or comments, please contact the Antaris support team via email at 
                    <a href='mailto:support@antaris.space.'>support@antaris.space.</a>,
                </div>
            ),
            
        }
    },{
        side_item: 'Platform Access',
        body: {
            heading: 'Platform Access',
            content: (
                <div className='document__content'>
                    Access to ACP is limited to parties authorized directly by Antaris, Inc. Individuals that are associated with  
                    an active customer or partner of Antaris should work with the appropriate ACP Tenant Admin to provision a new user account.
                    <br/>
                    For those with existing user accounts, simply navigate to <a href='https://app.antaris.cloud'>https://app.antaris.cloud</a>
                    in a web browser and provide individual credentials to log in. After logging in, users will be able to access to a shared Tenant. 
                    <br/>
                    If you are having trouble logging in, you can click on “Forgot Password?” at the ACP login page to trigger a password reset. 
                    You should contact the support team <a href='mailto:support@antaris.space'>support@antaris.space</a> for further assistance, if needed. 
                    <br/>
                    Those not associated with active 
                    customers can contact the Antaris sales team at <a href='mailto:sales@antaris.space'>sales@antaris.space</a> to discuss licensing. 
                </div>
                
            ),
            
        }
    },{
        side_item: 'Quickstart',
        body: {
            heading: 'Quickstart',
            content: (
                <div className='document__content'>
                    This section quickly drives a user through the key workflows in ACP. This describes how a user with limited experience can
                    quickly orient themselves within ACP. 
                    <br/>
                    After establishing an ACP user account, the first task is to define a Mission. This input is used to inform later design choices 
                    and satellite operations:
                     <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    If you are not prompted to set up your Mission, it is likely another member of your team has already done so. You can always 
                    edit the mission from the DESIGN dashboard if updates are required. Next, create a Satellite based on the necessary spaceobject design 
                    requirements. During this process, you will define satellite payloads, add edge compute resources, then choose an appropriate Bus Design. 
                    Decisions need not be final before walking through this process, as a design can be modified later: 
                     <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    After creating a Satellite, proceed to deploy it with TrueTwin™. Create a TrueTwin instance using the “Create TrueTwin” action on 
                    the Satellite card in the DESIGN dashboard. Be sure to enable the “Quick Deploy” option, which causes ACP to automatically launch the new TrueTwin into your mission's orbit:
                     <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    Once the deployment process completes, follow the “Quick Deploy” link on the TrueTwin card Note the “Download Remote Config” action, which can be used later to 
                    remotely connect a payload application to the TrueTwin instance: 
                    <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    At this point, you now have a fully functional TrueTwin satellite. Familiarize yourself with the Satellite Console and the Telemetry interface, and review the satellite's orbital trajectory: 
                    <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    When finished, simply use the “Retire” action in the bottom right hand corner of the screen to tear down the running TrueTwin satellite. 
                    Now that you have completed this Quickstart, you are encouraged to explore the rest of this guide and continue your journey with the Antaris Cloud Platform! 
                </div>
            )
            
            
        }
    },{
        side_item: 'Glossary',
        body: {
            heading: 'Glossary',
            content: (
                <div className='document__content'>
                    Below is a general description of key terms used in the context of ACP. Use this as a reference while reading through other sections of the User Guide. 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>ACP:</span> an acronym meaning “Antaris Cloud Platform”</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Tenant:</span> an isolated space within ACP, typically scoped to a real world organization</div>
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>User:</span> an auth context used to represent a real person within ACP, scoped to a Tenant</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Mission:</span> a specific set of objectives for a satellite, including system scope and operational goals</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Ground Station:</span> an earth-based communications node that facilitates satellite operation</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Satellite:</span> a space vehicle that can be designed, tested, and operated within ACP</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Bus:</span> the set of satellite system components that support foundational operations, such as guidance, navigation, and power management</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Bus Design:</span> a template object in ACP representing a complete Bus and its individual components</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Payload:</span> a mission-specific device installed in a satellite along with its control software</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>SatOS™:</span> onboard software responsible for orchestrating satellite activities (i.e. the “operating system” of the satellite)</div>
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>TrueTwin™:</span> technology that supports virtual satellite testing and simulation, as well as operator training</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Task:</span> a meaningful unit of work that a satellite may execute</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Task Plan:</span> a reusable template of satellite tasks, along with requirements such as geography and repetition</div> 
                    <div className='d-flex align-items-center gap-2'><span className='fs-18'>Scenario:</span> a virtual sandbox constructed to test a satellite in a fully simulated space environment</div> 
                </div>
            )
            
            
        }
    },{
        side_item: {
            heading: 'Satellite Design',
            sub_headings: ['Creating a Satellite', 'Configuring Payloads', 'Configuring Edge Devices', 'Choosing a Bus Design']
        },
        body: {
            heading: 'Satellite Design',
            content: (
                <div className='document__content'>
                    This section describes the core satellite design process, which focuses primarily on satellite system architecture. 
                        Operational configuration and planning are addressed elsewhere. 
                </div>
            )
            
            ,
            sub_headings: [
                {
                    heading: 'Creating a Satellite',
                    content: (
                        <div className='document__content'>
                            rom the DESIGN dashboard, simply click on the Create Satellite action. Give the Satellite a unique name and proceed on to the next step: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Unique Satellites may be created for many reasons, and users should feel free to use them to explore alternate design decisions. 
                            Each operational space vehicle in a constellation is represented by a unique Satellite object
                        </div>
                    )
                    
                    
                    
                },{
                    heading: 'Configuring Payloads',
                    content: (
                        <div className='document__content'>
                            A satellite is defined primarily by the set of payloads intended to operate within it. This is critical input, 
                            as it defines the “load” to be supported by core satellite systems. <br/> 
                            
                            Iterate through the “Add Payload” process once for 
                            each desired payload. Components that support core system functions such as RF communication or power management are 
                            included in the satellite bus, which will come later in this process. 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Selection of a payload category and subcategory allows ACP to provide more contextual filters that help identify the most appropriate Payload Device from the catalog. <br/>
                            
                            The “Average Active Duration per Orbit” field helps calculate the power demand during a representative orbit. Similarly, 
                            the “Average Data Generated per Orbit” informs onboard data buffering and downlink bandwidth requirements. If an appropriate Payload Device does not yet exist in the catalog, it may be defined using the “Add Payload Device” action: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            By the end of this process, a summary of the load to be supported by the satellite will be presented in the form of “Size”, “Mass” and “Power” meters: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            These requirements feed directly into the upcoming Bus Design selection process. 
                        </div>
                    )
                    
                    
                },{
                    heading: 'Configuring Edge Devices',
                    content: (
                        <div className='document__content'>
                            Payloads may access a nominal amount of onboard CPU, memory, and persistent storage. Satellites may optionally include Edge Devices to provide additional compute capacity to resident payloads. 
                            Edge Devices commonly contain GPUs or other hardware accelerators for specialized data processing. <br/> 
                            
                            To configure an Edge Device, choose from the supported components, indicate an average amount of time per orbit it will be active, and choose a quantity of SSDs to attach to it: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                        </div>
                    )
                },{
                    heading: 'Choosing a Bus Design',
                    content: (
                        <div className='document__content'>
                            When arriving at this stage, ACP will have gathered enough input about the satellite such that an appropriate Bus Design can be recommended:
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            As soon as you click on a Bus Design at this stage of the workflow, the load meters at the top will indicate how utilized the chosen Bus will be in each dimension. This feedback can be used to help understand how “full” the bus will be. <br/>
                            After making your selection, click “Save & Continue” and the Satellite is now ready to be used!
                        </div>
                    )
                    
                    
                },
            ]
        }
    },{
        side_item: {
            heading: 'Payload Development',
            sub_headings: ['Defining Payload Sequences', 'Create TrueTwin', 'Testing Payload Sequences']
        },
        body: {
            heading: 'Payload Development',
            content: (
                <div className='document__content'>
                    Prior to physical satellite integration, application software must be developed to connect the SatOS™ control services to each payload. This development process utilizes TrueTwin within ACP along with the
                    <a href='https://antaris-inc.github.io/SatOS-Payload-SDK/index.html'>SatOS Payload SDK</a>. It is important that application developers review the 
                    <a href='https://antaris-inc.github.io/SatOS-Payload-SDK/developer-guide.html'>SatOS Payload Developer Guide</a>,  as it describes the technical architecture and interfaces used to conduct this development activity. <br/>
                    The following diagram represents the high-level relationship between TrueTwin in ACP and the elements operating outside of ACP: 
                    <div className='doc__img__container'>
                        <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                    </div>
                    The remainder of this section focuses on the ACP portion of the payload development process described above. It is vital that developers review the 
                    <a href='https://antaris-inc.github.io/SatOS-Payload-SDK/developer-guide.html'>SatOS Payload Developer Guide </a> before continuing. 
                </div>
            )
            
            
            ,
            sub_headings: [
                {
                    heading: 'Defining Payload Sequences',
                    content: (
                        <div className='document__content'>
                            Each Payload object contains a unique set of Payload Sequences. These sequences represent individual requests that can be handled by Payload Application software. A sequence typically maps to a logical mode of operation: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Payload Sequences may also receive parameters. The “Default Sequence Params” field is used to set the default value sent to Payload Applications when executing a sequence. These parameters may be overridden later, as needed. <br/> 
                            The “Power Requirement” represents an estimated power value that will be observed while handling the sequence. This helps in initial power modeling of the full satellite system and will be improved once representative values can be measured. 
                        </div>
                    )
                },{
                    heading: 'Create TrueTwin',
                    content: (
                        <div className='document__content'>
                            To test a Payload Sequence, begin by deploying a TrueTwin instance for the target Satellite. Truetwin can be configured to provide device paths, IP addresses, GPIO pin numbers if your payload is using such interfaces. These inputs are
                        </div>
                    )
                },{
                    heading: 'Testing Payload Sequences',
                    content: (
                        <div className='document__content'>
                            To test a Payload Sequence, begin by deploying a TrueTwin instance for the target Satellite. Be sure to configure the required payload as “remote” and enable the “Quick Deploy” option. 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            After the TrueTwin has been deployed, download the payload's remote config and use the SatOS Payload SDK to run the necessary application. The SDK will automate connecting the payload application to the TrueTwin instance in ACP. <br/> 
                            After the payload application is up and running, open the TrueTwin dashboard by clicking on the “Quick Deploy” link in the card: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            In the Console, choose the appropriate Payload and the Payload Sequence to test. Default parameters will be set, but they may be overridden. The “Duration” represents a maximum amount of time granted to the payload application to handle the sequence before timing out. After deciding on this input, click “Submit”: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            The sequence will be encoded using an appropriate telecommand and sent to the TrueTwin instance for processing. If the request is accepted by the TrueTwin, the telecommand will be acknowledged and logged in the output pane. The sequence will be forwarded by the TrueTwin to the connected payload application for processing. <br/> 
                            Note that the remote payload application may be restarted while the TrueTwin is running, which can help to quickly develop application software. 
                        </div>
                    )
                }
            ]
        }
    },{
        side_item: {
            heading: 'Ground Communications',
            sub_headings: ['Configure Ground Stations', 'Configure Ground Links']
        },
        body: {
            heading: 'Ground Communications',
            content: (
                <div className='document__content'>
                    Within ACP, an operator may configure both the network of ground stations used for a mission as well as the radios employed onboard their satellites. ACP will automatically schedule ground contacts for satellites operating on the platform, including both onboard scheduling as well as API-based scheduling via ground station provider APIs. 
                </div>
            ),
            sub_headings: [
                {
                    heading: 'Configure Ground Stations',
                    content: (
                        <div className='document__content'>
                            A mission's ground station network plays a critical role in facilitating communication between satellites and ground-based services. Identify which ground stations will support a mission through the “Ground Stations” element of the DESIGN dashboard: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Manage the set of ground stations used in a mission with the “Add Ground Stations” action. Select individual ground stations that will be active or deselect them if they will not support the mission: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Add a custom ground station via the “Add New” action, or clone and modify an existing ground station via the icon in each row to extend the built-in options. 
                        </div>
                    )
                    
                },{
                    heading: 'Configure Ground Links',
                    content: (
                        <div className='document__content'>
                            Ground Links are defined within the scope of a Satellite object. This informs ACP how radios should be used. A Satellite should always contain at least a “TT&C” and a “Teledata” Ground Link since these are the two most common methods of satellite communication. To define a Ground Link, simply choose an appropriate radio and save the configuration: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            It is wise to choose a backup radio for each Ground Link so that ACP can automate fallback in the event that a primary radio cannot be used. This can happen due to onboard or terrestrial failures, or simply due to ground station scheduling congestion.
                        </div>
                    )
                    
                }
            ]
        }
    },{
        side_item: {
            heading: 'Task Planning',
            sub_headings: ['Managing Task Plans', 'Defining Payload Tasks']
        },
        body: {
            heading: 'Task Planning',
            content: (
                <div className='document__content'>
                    Operational scheduling of a satellite begins with a Task Plan, which contains a prioritized set of Tasks. Each Task describes some desired activity with optional repetition and geographic requirements. Task Plans are considered generally reusable and tend to represent the “concept of operations” (ConOps) of a given satellite. <br/> 
                    A Task Schedule is generated from a Task Plan for a desired period of time, which spans anywhere from a single orbit up to a few days. The operational state of the satellite (e.g. power level, physical location, previous activity, etc) is also considered during the scheduling process. The schedule will contain an optimized set of Tasks that will be executed onboard at specific times. <br/> 
                    Once generated, a Task Schedule can be deployed to a target satellite during a ground station contact. Task Schedules can also be used within a Scenario to simulate mission operations, which is discussed later in this document. 
                </div>
            ),
            sub_headings: [
                {
                    heading: 'Managing Task Plans',
                    content: (
                        <div className='document__content'>
                            Add, modify, or delete Task Plans through the “Task Plan” element of the Satellite Dashboard: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            It is common to define multiple Task Plans for each Satellite, typically modeling the nominal operations as well as one or more alternate plans. <br/> 
                            Add new Tasks to a Task Plan using the “Create Task” button: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            Edit or delete tasks with the action buttons available in each row. Be sure to click “Save Task Plan” after any changes are made. <br/> 
                            Tasks within a plan are explicitly prioritized based on their relative order. Drag tasks up or down within the table to set this priority, which will inform the scheduler if any conflicts arise during the scheduling process.
                        </div>
                    ) 
                    
                },{
                    heading: 'Defining Payload Tasks',
                    content: (
                        <div className='document__content'>
                            A “Payload Task”, which is just a specific type of Task, identifies a Payload and a single sequence to execute along with some additional scheduling requirements:  
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            The minimum duration field defines the minimal amount of time required for the scheduler to consider the Task for execution. An optional maximum duration will prevent scheduling of the Task longer than the indicated amount of time. <br/> 
                            Several controls are available to influence when a Payload Task may be executed: 
                            <div className='doc__img__container'>
                                <img src='https://www.geospatialworld.net/wp-content/uploads/2023/03/Screen-Shot-2020-04-28-at-10.48.37-AM.png' alt='' />
                            </div>
                            <ul>
                                The Geo-Trigger values are described below: 
                                <li>Use None when the task has no ground-based requirements. </li>
                                <li>Use the Target Track option to require the satellite point towards a specific location on the ground during Task execution. Locations are defined using WGS84 latitude and longitude (decimal degrees) along with an altitude (km). The minimum 
                                    elevation will limit which ground passes are considered, ensuring that Tasks are only scheduled when the satellite maintains the required elevation above the horizon as measured from the target location. </li>
                                <li>The Geofence option limits Task execution to time periods when the satellite passes through some geographic area. A bounding 
                                    box is used to define the area, represented by two WGS84 coordinates (the upper left and lower right points of the box). </li>
                            </ul>
                            <ul>
                                In certain cases, an “Attitude Control Mode” must be selected:  
                                <li>The Ground Track mode causes the satellite to maintain a stable orientation with respect to the earth. This can be useful for payloads that scan the ground such as IoT receivers or push-broom imaging sensors. An optional Euler angle offset 
                                    may be provided (i.e. a custom “roll”, “pitch” and “yaw”), which is defined with respect to the satellite orbital frame. </li>
                                <li>The Sun Track mode allows the satellite to maintain a solar-oriented attitude during the Task. This can be useful for Tasks that are not sensitive to earth-relative orientation. </li>
                            </ul>
        
                            Use the “Frequency” controls to define the preferred number of times the Task should be scheduled within a given period of time

                        </div>
                    )
                    
                    
                }
            ]
        }
    },
]



const Documentation = () => {

    const resizingRef = useRef(false)
    const sidebarRef = useRef<ElementRef<'aside'>>(null)
    const navbarRef = useRef<ElementRef<'div'>>(null)
    const navigate = useNavigate();
    const { search } = useLocation()
    const url = new URLSearchParams(search);

    const [sectionItem, setSectionItem] = useState<any>('');
    const [currentSection, setCurrentSection] = useState<any>('');

    const mouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()

        resizingRef.current = true
        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('mouseup', mouseUp)

    }
    const mouseMove = (event: MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        if (!resizingRef.current) return

        let new_width = event.clientX
        if(new_width > 500) new_width = 500
        if(new_width < 300) new_width = 300
        if(sidebarRef.current && navbarRef.current) {
            sidebarRef.current.style.width = `${new_width}px`
            navbarRef.current.style.setProperty('left',`${new_width}px`)
            navbarRef.current.style.setProperty('width', `calc(100% - ${new_width}px)`);
        }
    }

    const mouseUp = () => {
        resizingRef.current = false
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('mouseup', mouseUp)
    }

    const navigate_section = (section: string, item?: string) => {
        const params = {
        section: section,
        };
        const options = {
        pathname: window.location.pathname,
        search: item ?  `?${createSearchParams(params)}${'&'+createSearchParams({item: item})}` : `?${createSearchParams(params)}`,
        };
        navigate(options, { replace: true });
    }

    
    useEffect(()=>{
        setCurrentSection(url.get('section'));
        setSectionItem(url.get('item'));
    },[url,sectionItem,currentSection]);

    return (
        <div className='documentation_container'>
            <aside ref={sidebarRef} className='documentation_sidebar'>
                <h1>Content</h1>
                <div className='documentation__sidebar__container'>
                    {DOCUMENTS.map((item) => {
                        if(typeof(item?.side_item) === 'string'){
                            return (
                                <span className={currentSection === item?.side_item ? 'active' : ''} onClick={() => navigate_section(`${item?.side_item}`)}>{item?.side_item}</span>
                            )
                        }else if(typeof(item?.side_item) === 'object'){
                            return (
                                <details open>
                                    <summary className={currentSection === item?.side_item?.['heading'] ? 'active' : ''}>{item?.side_item?.['heading']}</summary>
                                    <div style={{width: `calc(100% - 20px)`}}>
                                        {item?.side_item?.['sub_headings'].map((sub_heading) => {
                                            return (
                                                <span onClick={() => navigate_section(`${item?.side_item?.['heading']}`,`${sub_heading}`)} className={sectionItem === sub_heading ? 'active' : ''}>{sub_heading}</span>
                                            )
                                        })}
                                    </div>
                                </details>
                            )
                        }
                    })}
                </div>
                <div onMouseDown={mouseDown} className='sidebar__resize' />
            </aside>
            <main ref={navbarRef}>
                {DOCUMENTS.map((doc) => {
                    if(Object.values(doc?.body).includes(currentSection)){
                        return (
                            <section id={doc?.body?.['heading']}>
                                <h3 className='mb-1'>{doc?.body?.['heading']}</h3>
                                <div className='pe-9 ps-5 w-100'>
                                    <span>{doc?.body?.['content']}</span>
                                </div>
                                {doc?.body?.['sub_headings'] && doc?.body?.['sub_headings'].map((sub_heading) => {
                                    if(sub_heading?.['heading'] === sectionItem){
                                        return (
                                            <div className='py-5' id={sub_heading?.['heading']}>
                                                <h1 className='mb-1'>{sub_heading?.['heading']}</h1>
                                                <div className='pe-9 ps-5 w-100'>
                                                    <span>{sub_heading?.['content']}</span>
                                                </div>
                                            </div>
                                                
                                        )
                                    }
                                })}
                            </section>
                        )
                    }
                })}
            </main>
        </div>
    )
}

export default Documentation