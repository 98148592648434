import React, { Fragment } from 'react'
import CustomToolTip from '../../../../../../CommonComponents/CustomToolTip'
import { ADCSSvg, DataFlowSvg, OnboardScheduleSvg, PinnedSvg, PowerSvg } from './svgs/Svgs'
import { useSearchParams } from 'react-router-dom';

interface ModalDetails {
    open: boolean;
    pinned: boolean;
    current_modal: boolean | string;
    expanded: boolean | string;
}
interface Props {
    open_modal: (modal: string) => void;
    close_modal: (modal: string) => void;
    pinned_modal: (modal: string) => void;
    modals_details: {
        'power': ModalDetails;
        'data_flow': ModalDetails;
        'adcs': ModalDetails;
        'schedule': ModalDetails;
    };
    show_schedule_notification: boolean;
    prediction_data: boolean;
    show_pridiction_btn: boolean;
}

const RightHeaderSection: React.FC<Props> = ({ open_modal, close_modal, pinned_modal, modals_details, show_schedule_notification, prediction_data, show_pridiction_btn }) => {

    const [searchParams] = useSearchParams();
    const scenario_scheduled = searchParams.get('scheduled');

    const toggle_modal = (modal: string) => {
        if (modals_details[modal].open) {
            close_modal(modal)
        } else {
            open_modal(modal)
        }
    }

    return (
        <Fragment>
            <div className={`right__button__container`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Schedule' placement='bottom'>
                    <button className={`right__section__button single__btn ${(modals_details['schedule'].open || show_schedule_notification) && 'active'} ${!scenario_scheduled && 'disabled'}`}
                        onClick={() => { toggle_modal('schedule') }}>
                        <OnboardScheduleSvg />
                        <PinnedSvg showPinned={modals_details['schedule'].pinned} />
                    </button>
                </CustomToolTip>
            </div>
            {show_pridiction_btn &&
                <div className='right__button__container' onClick={(e: any) => { e.stopPropagation() }}>
                    <CustomToolTip title='Power' placement='bottom'>
                        <button className={`right__section__button left__btn ${modals_details['power'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('power') }}>
                            <PowerSvg />
                            <PinnedSvg showPinned={modals_details['power'].pinned} />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='ADCS' placement='bottom'>
                        <button className={`right__section__button center__btn ${modals_details['adcs'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('adcs') }}>
                            <ADCSSvg />
                            <PinnedSvg showPinned={modals_details['adcs'].pinned} />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='Data Flow' placement='bottom'>
                        <button className={`right__section__button right__btn ${modals_details['data_flow'].open && 'active'} ${!prediction_data && 'disabled'}`}
                            onClick={() => { toggle_modal('data_flow') }}>
                            <DataFlowSvg />
                            <PinnedSvg showPinned={modals_details['data_flow'].pinned} />
                        </button>
                    </CustomToolTip>
                </div>
            }
        </Fragment>
    )
}

export default RightHeaderSection