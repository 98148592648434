//@ts-nocheck
import { Fragment, useEffect, useState } from "react";
import CustomSelect, { Option } from "../../../../custom_components/CustomSelect";
import useUrlParams from "../../../../hooks/useUrlParams";
import { v4 as uuidv4 } from "uuid";
import {
    Form
} from 'react-bootstrap';
import './Console.scss'
import { toast } from "react-toastify";


interface BusFormProps {
    busComponentOptions: any[],
    fetchBusCommands: (components: string) => void;
    busCommandOptions: any[];
}
const BusForm: React.FC<BusFormProps> = ({ busComponentOptions, fetchBusCommands, busCommandOptions }) => {
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id } = useUrlParams()
    const [selectedBusComponent, setSelectedBusComponent] = useState<Option | null>({ value: 'All', label: 'All' });
    const [selectedTelecommand, setSelectedTelecommand] = useState<Option | null>(null);
    const [previewTelecommandData, setPreviewTeleCommandData] = useState<any>({});
    const [isValid, setIsValid] = useState<boolean>(true);


    useEffect(() => {
        sessionStorage.setItem('currentForm', 'bus');
        window.dispatchEvent(new Event('storageUpdate'));
    }, []);

    const setBusFormData = (data) => {
        const formData = [{
            "TruetwinId": truetwin_id,
            "LinkId": "",
            "scenarioId": scenario_id,
            "Timestamp": null,
            "CommandUid": uuidv4(),
            "Type": "completeJson",
            "ScheduleId": "default",
            "TaskId": "default",
            "sequenceData": data?.sequenceData
        }];
        sessionStorage.setItem('formData', JSON.stringify(formData));
        return formData;
    }
    useEffect(() => {
        // session storage is synchronized with state
        sessionStorage.setItem('selectedBusComponent', JSON.stringify(selectedBusComponent));
        sessionStorage.setItem('selectedTelecommand', JSON.stringify(selectedTelecommand));
        sessionStorage.setItem('previewTelecommandData', JSON.stringify(previewTelecommandData));
        window.dispatchEvent(new Event('storageUpdate'));
    }, [selectedBusComponent, selectedTelecommand, previewTelecommandData]);

    useEffect(() => {
        const clearFormFields = () => {
            setSelectedTelecommand(null);
            setPreviewTeleCommandData({});
            sessionStorage.setItem('currentForm', 'bus');
        };

        window.addEventListener('clearFormFields', clearFormFields);
        return () => {
            window.removeEventListener('clearFormFields', clearFormFields);
        };
    }, []);

    useEffect(() => {
        validateJSON(previewTelecommandData);
    }, [previewTelecommandData]);

    const validateJSON = (data: any) => {
        try {
            if (typeof data === 'string') {
                JSON.parse(data);
            } else if (typeof data === 'object') {
                JSON.stringify(data); // Try stringifying if it's an object
            }
            setIsValid(true);
            sessionStorage.setItem('isVaildJson', "true");
            toast.dismiss('json-error'); // Clear previous error if valid
        } catch (e: any) {
            setIsValid(false);
            toast.error('Invalid JSON', { toastId: 'json-error' });
            sessionStorage.setItem('isVaildJson', "false");
        }
    };

    const handleJsonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        let newValueObject;
        try {
            newValueObject = JSON.parse(newValue);
            setBusFormData(newValueObject)
        } catch (error) {
            // Handle the invalid exception here
            console.error('Error parsing JSON:', error);
            newValueObject = null; // or any other appropriate handling
        }
        setPreviewTeleCommandData(newValue);
    };

    return (
        <Fragment>
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Bus Components</span>
                <div className='input__box__container'>
                    <CustomSelect options={busComponentOptions} value={selectedBusComponent} onChange={(data) => {
                        setSelectedBusComponent(data)
                        sessionStorage.setItem('selectedBusComponent', JSON.stringify(data));
                        fetchBusCommands(data?.value)
                        setSelectedTelecommand(null)
                        setPreviewTeleCommandData({})
                        window.dispatchEvent(new Event('storage'));
                    }} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Telecommands</span>
                <div className='input__box__container'>
                    <CustomSelect options={busCommandOptions} value={selectedTelecommand} onChange={(data) => {
                        setSelectedTelecommand(data)
                        sessionStorage.setItem('selectedTelecommand', JSON.stringify(data));
                        const Teledata = {
                            sequenceData: data.value.sequenceData,
                            commandName: data.value.name,
                            commandType: 'BUS'
                        }
                        setPreviewTeleCommandData(Teledata)
                        sessionStorage.setItem('previewTelecommandData', JSON.stringify(Teledata));
                        setBusFormData(Teledata)
                        window.dispatchEvent(new Event('storage'));
                    }} />
                    <span className='input__feedback'></span>
                </div>
            </div>

            <Form.Control
                className="input__preview__container bg-transparent"
                type="text"
                as="textarea"
                value={previewTelecommandData ? typeof previewTelecommandData !== 'string' ? JSON.stringify(previewTelecommandData, null, 4) : previewTelecommandData : ''}
                rows={17}
                style={{ resize: 'none' }}
                onChange={handleJsonChange}
            />
            {!isValid && <code style={{ color: 'red' }}>Invalid JSON</code>}
        </Fragment>
    )
}

export default BusForm