import "./Bottombar.css";
import Upperbar from "./Upperbar";
import Lowerbar from "./Lowerbar";

const BottomBar = ({ props }) => {
  return (
    <div className="bottomNavBar">
      <Upperbar props={props} />
      <Lowerbar props={props} />
    </div>
  );
};

export default BottomBar;
