
export const GridViewSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2216 10.0431C14.6701 10.0429 15.1021 10.2172 15.4309 10.531C15.7598 10.8447 15.9613 11.2748 15.9949 11.735L15.9993 11.8721V14.6709C15.9995 15.1324 15.8301 15.5768 15.5251 15.9152C15.2202 16.2536 14.8022 16.4608 14.3549 16.4954L14.2216 16.5H10.666C10.2175 16.5001 9.78551 16.3259 9.45664 16.0121C9.12777 15.6983 8.92632 15.2683 8.89268 14.8081L8.88824 14.6709V11.8721C8.88809 11.4107 9.05749 10.9662 9.36246 10.6279C9.66743 10.2895 10.0854 10.0823 10.5327 10.0476L10.666 10.0431H14.2216ZM5.33333 10.0431C5.80483 10.0431 6.25701 10.2358 6.59041 10.5788C6.92381 10.9218 7.11111 11.387 7.11111 11.8721V14.6709C7.11111 15.156 6.92381 15.6213 6.59041 15.9643C6.25701 16.3073 5.80483 16.5 5.33333 16.5H1.77778C1.30628 16.5 0.854097 16.3073 0.520699 15.9643C0.187301 15.6213 0 15.156 0 14.6709V11.8721C0 11.387 0.187301 10.9218 0.520699 10.5788C0.854097 10.2358 1.30628 10.0431 1.77778 10.0431H5.33333Z" fill="#F0F0F0" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2216 0.500036C14.6701 0.49989 15.1021 0.674169 15.4309 0.987938C15.7598 1.30171 15.9613 1.73178 15.9949 2.19193L15.9993 2.32911V5.12789C15.9995 5.58934 15.8301 6.0338 15.5251 6.37216C15.2202 6.71053 14.8022 6.91779 14.3549 6.95239L14.2216 6.95697H10.666C10.2175 6.95711 9.78551 6.78283 9.45664 6.46906C9.12777 6.15529 8.92632 5.72523 8.89268 5.26507L8.88824 5.12789V2.32911C8.88809 1.86766 9.05749 1.4232 9.36246 1.08484C9.66743 0.746477 10.0854 0.539218 10.5327 0.504609L10.666 0.500036H14.2216ZM5.33333 0.500035C5.80483 0.500035 6.25701 0.692741 6.59041 1.03576C6.92381 1.37878 7.11111 1.84401 7.11111 2.32911V5.12789C7.11111 5.61299 6.92381 6.07822 6.59041 6.42124C6.25701 6.76426 5.80483 6.95697 5.33333 6.95697H1.77778C1.30628 6.95697 0.854097 6.76426 0.520699 6.42124C0.187301 6.07822 0 5.61299 0 5.12789V2.32911C0 1.84401 0.187301 1.37878 0.520699 1.03576C0.854097 0.692741 1.30628 0.500035 1.77778 0.500035H5.33333Z" fill="#F0F0F0" />
    </svg>
)

export const ListViewSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path d="M15.4796 1.0375C15.1462 0.693343 14.694 0.5 14.2225 0.5H1.77876C1.30726 0.5 0.855076 0.693343 0.521678 1.0375C0.18828 1.38165 0.000977516 1.84842 0.000977516 2.33513L0.000976562 5.37455C0.000976562 5.86126 0.188279 6.32803 0.521677 6.67218C0.855075 7.01633 1.30726 7.20968 1.77875 7.20968H14.2225C14.694 7.20968 15.1462 7.01633 15.4796 6.67218C15.813 6.32803 16.0003 5.86126 16.0003 5.37455L16.0003 2.33513C16.0003 1.84842 15.813 1.38165 15.4796 1.0375Z" fill="#F0F0F0" />
        <path d="M15.4796 1.0375C15.1462 0.693343 14.694 0.5 14.2225 0.5H1.77876C1.30726 0.5 0.855076 0.693343 0.521678 1.0375C0.18828 1.38165 0.000977516 1.84842 0.000977516 2.33513L0.000976562 5.37455C0.000976562 5.86126 0.188279 6.32803 0.521677 6.67218C0.855075 7.01633 1.30726 7.20968 1.77875 7.20968H14.2225C14.694 7.20968 15.1462 7.01633 15.4796 6.67218C15.813 6.32803 16.0003 5.86126 16.0003 5.37455L16.0003 2.33513C16.0003 1.84842 15.813 1.38165 15.4796 1.0375Z" fill="#F0F0F0" />
        <path d="M15.4796 10.5786C15.1462 10.2344 14.694 10.0411 14.2225 10.0411H1.77876C1.30726 10.0411 0.855076 10.2344 0.521678 10.5786C0.18828 10.9227 0.000977516 11.3895 0.000977516 11.8762V14.6649C0.000977516 15.1516 0.18828 15.6184 0.521678 15.9625C0.855076 16.3067 1.30726 16.5 1.77876 16.5H14.2225C14.694 16.5 15.1462 16.3067 15.4796 15.9625C15.813 15.6184 16.0003 15.1516 16.0003 14.6649V11.8762C16.0003 11.3895 15.813 10.9227 15.4796 10.5786Z" fill="#F0F0F0" />
    </svg>
)

export const SearchSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
        <path d="M13 12.5L10.1667 9.66168M11.7368 5.86842C11.7368 7.29221 11.1712 8.65769 10.1645 9.66447C9.15769 10.6712 7.79221 11.2368 6.36842 11.2368C4.94463 11.2368 3.57915 10.6712 2.57237 9.66447C1.5656 8.65769 1 7.29221 1 5.86842C1 4.44463 1.5656 3.07915 2.57237 2.07237C3.57915 1.0656 4.94463 0.5 6.36842 0.5C7.79221 0.5 9.15769 1.0656 10.1645 2.07237C11.1712 3.07915 11.7368 4.44463 11.7368 5.86842Z" stroke="#4F4F4F" stroke-linecap="round" />
    </svg>
)