// @ts-nocheck
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MissionStore, setShowTemplateSlideToFalse } from '../../../../../store/MissionSlice';

const TemplateSlider = () => {
    const dispatch = useDispatch();
    const template = useSelector((state: { mission: MissionStore }) => state.mission.Template);

    return <div className="right-slider-box">
        <div className="slider-header">
            {/* <i className="bi-arrow-left fs-25 me-2" onClick={() => dispatch(setTemplate(null))}></i> */}
            <h4 className="mb-0">
                {template?.['name']}
            </h4>
        </div>
        <div className="slider-content">
            <div className="common-card-box">
                <div className="common-card-header">
                    Bus
                </div>
                <div className="common-card-content">
                    <div className="d-flex flex-column">
                        <span><span className="temp-label">BUS OBC </span>: {template?.['assembly']?.[0]?.['core']?.['Bus OBC']?.['atmos-data']['name']}</span>
                        <span><span className="temp-label">BUS OS </span>: {template?.['assembly']?.[0]?.['core']?.['Bus OS']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label">ADCS </span>: {((template?.['template-attribute']?.['ADCS'] === 'iADCS') ?
                            template?.['assembly']?.[0]?.['iADCS']?.['atmos-data']?.['name'] : 'Discrete')}</span>
                        {/* <span><span className="temp-label">ADCS Software </span>: {template['assembly'][0]['core']['ADCS Software']['atmos-data']['name']}</span> */}
                        {/* {<span><span className="temp-label">IMU </span>: {((template?.['template-attribute']?.['IMU'] === 'iIMU') ?
                            template?.['assembly']?.[0]?.['core']?.['iIMU']?.['atmos-data']?.['name'] : 'Discrete')}</span>} */}
                        {<span><span className="temp-label">IMU </span>: {'Discrete'}</span>}
                        <span><span className="temp-label">GPS </span>: {template?.['assembly']?.[0]?.['core']?.['GPS']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label">Temperature sensor </span>: {template?.['assembly']?.[0]?.['core']?.['Temperature Sensor']?.['atmos-data']?.['name']}</span>
                        {/* <span><span className="temp-label">Heater </span>: {template['assembly'][0]['core']['Heater']['atmos-data']['name']}</span> */}
                        {(template?.['assembly']?.[0]?.['core']?.['Thruster']) ? <span><span className="temp-label">Thruster </span>: {((template?.['template-attribute']?.['propulsion'] === 'Yes') ?
                            template?.['assembly']?.[0]?.['core']?.['Thruster']?.['atmos-data']?.['name'] : 'No')}</span> : ""}
                    </div>
                </div>
            </div>
            <div className="common-card-box">
                <div className="common-card-header">
                    Payload Server
                </div>
                <div className="common-card-content">
                    <div className="d-flex flex-column">
                        <span><span className="temp-label"> Payload OBC </span>: {template?.['assembly']?.[0]?.['payloadServer']?.['Payload OBC']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label"> Payload SSD </span>: {template?.['assembly']?.[0]?.['payloadServer']?.['Payload SSD']?.['atmos-data']?.['name']}</span>
                    </div>
                </div>
            </div>
            <div className="common-card-box">
                <div className="common-card-header">
                    Communication Devices
                </div>
                <div className="common-card-content">
                    <div className="d-flex flex-column">
                        <span><span className="temp-label"> S-Band Radio </span>: {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['S-band']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label"> S-Band Antenna </span>: {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['S-band']?.['atmos-data']?.['name']}</span>
                        {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band'] &&
                            <span><span className="temp-label"> X-Band Radio </span>: {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['X-band']?.['atmos-data']?.['name']}</span>
                        }
                        {template?.['assembly']?.[0]['communication']?.['antennas']?.['X-band'] &&
                            <span><span className="temp-label"> X-Band Antenna  </span>: {template?.['assembly']?.[0]['communication']?.['antennas']?.['X-band']?.['atmos-data']?.['name']}</span>
                        }
                        {
                            template['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF'] &&
                            <span><span className="temp-label"> UHF Radio </span>: {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['UHF']?.['atmos-data']?.['name']}</span>
                        }
                        {
                            template['assembly']?.[0]?.['communication']?.['antennas']?.['UHF'] &&
                            <span><span className="temp-label"> UHF Antenna </span>: {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['UHF']?.['atmos-data']?.['name']}</span>
                        }
                        {
                            template['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS'] &&
                            <span><span className="temp-label"> AIS Radio </span>: {template?.['assembly']?.[0]?.['communication']?.['comm-devices']?.['AIS']?.['atmos-data']?.['name']}</span>
                        }
                        {
                            template['assembly']?.[0]?.['communication']?.['antennas']?.['AIS'] &&
                            <span><span className="temp-label"> AIS Antenna  </span>: {template?.['assembly']?.[0]?.['communication']?.['antennas']?.['AIS']?.['atmos-data']?.['name']}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="common-card-box">
                <div className="common-card-header">
                    Power
                </div>
                <div className="common-card-content">
                    <div className="d-flex flex-column">
                        <span><span className="temp-label"> EPS unit </span>: {template?.['assembly']?.[0]?.['power']?.['EPS']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label"> Battery Pack </span>: {template?.['assembly']?.[0]?.['power']?.['Battery Pack']?.['atmos-data']?.['name']}</span>
                        <span><span className="temp-label"> Solar Panel unit </span>: {template?.['assembly']?.[0]?.['power']?.['Solar Panel']?.['atmos-data']?.['name'] + ' '}
                            ({template?.['template-attribute']?.['NumSolarPanel']})</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="slider-footer">
            <Button
                variant="primary"
                type="button"
                className="btn btn-primary pt-1 pb-1 pe-7 ps-7 fs-14"
                onClick={() => dispatch(setShowTemplateSlideToFalse())}
            >
                Close
            </Button>
        </div>
    </div>;
};
export default TemplateSlider;
