import { useState } from "react";
import ModalFooter from "./ModalFooter";

const InnerTableModal = ({ props, parameterProps, tableBarValues, tableBarItemProps, headerElements, numOfRows, rowElements, tableHeader }) => {
  const RenderTableBarButton = ({ label, id, imgPath }) => (
    <button
      className={tableBarItemProps.tableBarItem === label ? "activeBottomBarBtn upperBarElement" : "upperBarElement"}
      style={{ padding: "6px 12px" }}
      id={id}
      onClick={() => tableBarItemProps.setTableBarItem(label)}
    >
      <img src={imgPath} className={tableBarItemProps.tableBarItem === label ? "active-upperbar-img" : ""} />
      <span>{label}</span>
    </button>
  );

  const RenderHeader = ({ title, image }) => (
    <th className="tableModalColumn tableModalHeaderText">
      <img className="tableModalHeaderIcon" src={image} />
      {title}
    </th>
  );

  const RenderTableElement = (element) => (
    <td className="tableModalColumn">
      <div className="tableModalElement">{element}</div>
    </td>
  );

  const RenderTableRow = () => <tr className="tableModalRow">{rowElements.map(RenderTableElement)}</tr>;

  const rows = [{}];
  const CreateRowsArray = (numOfRows) => {
    for (let i = 0; i < numOfRows - 1; i++) {
      rows.push(true);
    }
  };
  CreateRowsArray(numOfRows);
  let tableWidth = headerElements.length * 140 + 50;

  return (
    <>
      <div className={props.isSidebarVisible ? "innerTableModalDiv" : "innerTableModalDiv"} style={{ width: "100%" }}>
        <table className="tableModalTable">
          <thead className="tableModalRow">
            <tr>{headerElements.map(RenderHeader)}</tr>
          </thead>
          <tbody>{rows.map(RenderTableRow)}</tbody>
        </table>
      </div>
    </>
  );
};

export default InnerTableModal;
