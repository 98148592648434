import './Hud.css'
import Contact from '../hud/contact/Contact'
import CesiumContainer from './cesium/CesiumContainer';

const Hud = () => {

    return (
        <div className='hud__container'>
            <CesiumContainer />
            <Contact />
        </div>
    )
}

export default Hud