import React from 'react'
import { NavigationButton } from '../components/center/planning/utils'
import useUrlParams from '../hooks/useUrlParams'
import useUrlNavigation from '../hooks/useUrlNavigation'
import { NoDataSvg, NoFileSvg } from './Svgs'
import './EmptyPage.scss'

interface Props {
    content: string;
    modal_view?: boolean;
    content_type?: string;
}

const EmptyPage = ({ content, modal_view = false, content_type = 'contact' }: Props) => {
    const { tab_name } = useUrlParams()
    const { navigate_to } = useUrlNavigation()

    return (
        <div className='empty_ground__contact__container'>
            {(!modal_view && content_type === 'contact') &&
                <div className='ground__container__header__container'>
                    <div className='ground__container__header__left__container'>
                        {NavigationButton.map((btn) => (
                            <button className={`button ${tab_name === btn.name && 'active'}`}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation()
                                    navigate_to(btn.name)
                                }}>{btn.label}</button>
                        ))}
                    </div>
                </div>
            }
            <div className='empty__contact__section'>
                {content_type === 'contact' ? <NoDataSvg /> : <NoFileSvg />}
                <span className='empty__contact__section__content'>{content}</span>
            </div>
        </div>
    )
}

export default EmptyPage