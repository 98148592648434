//@ts-nocheck
import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { containsSpecialChars } from "../../../../../CommonComponents/CommonFunctions";
import GroundStationService from "../../../../../../service/GroundStationService";
import { useParams } from "react-router-dom";

interface CreateGSModalProps {
    close: () => void;
    open: any;
    all_providers: any[];
    provider_details: any;
    loading: any;
    setLoading: (data) => void;
    select_ground_station: (station, provider_name) => void;
}


const CreateGSModal: FC<CreateGSModalProps> = ({ close, open, all_providers, loading, setLoading, provider_details, select_ground_station }) => {

    const params = useParams()
    const { mission_id } = params

    const [defaultProviderList, setDefaultProviderList] = useState<any>([
        {
            value: 'ATLAS',
            label: 'ATLAS'
        },
        {
            value: 'Leaf Space',
            label: 'Leaf Space'
        },
    ])
    const [selectOtherProvider, setSelectOtherProvider] = useState<any>(false)
    const [groundStationDetails, setGroundStationDetails] = useState<any>({
        groundStationName: '',
        properties: {
            country: '',
            location: '',
            latitude: '',
            longitude: '',
            freqbands: [],
            provider: '',
        },
        status: 'Configure',
    })

    const handle_close = () => {
        setGroundStationDetails({
            groundStationName: '',
            properties: {
                country: '',
                location: '',
                latitude: '',
                longitude: '',
                freqbands: [],
                provider: '',
            },
            status: 'Configure',
        })
        setSelectOtherProvider(false)
        close()
    }

    useEffect(() => {
        if (all_providers?.length > 0 && !provider_details) {
            let updatedArray: any = []
            all_providers?.map((provider) => {
                let new_object = {
                    value: provider?.['providerName'],
                    label: provider?.['providerName']
                }
                const filteredArray = defaultProviderList.filter(item => item.value !== new_object.value);
                updatedArray = filteredArray.concat(new_object);
            })
            updatedArray = [
                ...updatedArray,
                {
                    value: 'Other',
                    label: 'Other'
                }
            ]
            setDefaultProviderList(updatedArray)
        } else if (provider_details) {
            setDefaultProviderList([
                {
                    value: provider_details?.['providerName'],
                    label: provider_details?.['providerName']
                }
            ])
            setGroundStationDetails((prev) => ({
                ...prev,
                properties: {
                    ...prev?.['properties'],
                    provider: provider_details?.['providerName']
                }
            }))
        }
    }, [all_providers, provider_details])

    const check_validation = () => {
        if (containsSpecialChars(groundStationDetails?.['groundStationName'])) {
            toast.error('Name cannot contain special characters', { toastId: 'gs_name' })
            return false
        }
        else if (groundStationDetails?.['groundStationName'] === '') {
            toast.error('Please enter ground station name', { toastId: 'gs_name' })
            return false
        }
        else if (groundStationDetails?.['properties']?.['latitude'] === '') {
            toast.error('Please enter latitude', { toastId: 'gs_lat' })
            return false
        }
        else if (groundStationDetails?.['properties']?.['longitude'] === '') {
            toast.error('Please enter longitude', { toastId: 'gs_long' })
            return false
        }
        else if (selectOtherProvider && groundStationDetails?.['properties']?.['provider'] === '') {
            return false
        }
        else if (groundStationDetails?.['properties']?.['freqbands']?.length === 0) {
            toast.error('Please select atleast one frequency', { toastId: 'gs_long' })
            return false
        } else {
            return true
        }
    }

    const fetch_ground_station_location = () => {
        let gs_name = groundStationDetails?.['groundStationName']?.trimEnd()
        let body = {
            ...groundStationDetails,
            groundStationName: gs_name,
            missionId: mission_id,
            properties: {
                ...groundStationDetails?.['properties'],
                provider: groundStationDetails?.['properties']?.['provider']?.trimEnd(),
                location: '',
                country: ''
            },
        }
        GroundStationService.createGroundStation(gs_name, body).then((res) => {
            if (res?.data) {
                const station = res?.data
                const provider = res?.data?.['properties']?.['provider']
                let provider_name = (provider && provider !== '') ? provider : 'Standalone'
                select_ground_station(station, provider_name)
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        }).finally(() => {
            handle_close()
            toast.success('Ground Station created successfully', { toastId: 'gs' })
        })
    }

    const creae_ground_station = () => {
        let validation = check_validation()
        if (validation) {
            setLoading(true)
            fetch_ground_station_location()
        }
    }

    return (
        <div className={`gs_create_modal_container ${open ? 'active' : ''}`}>
            <div className='w-100 px-3 py-2 d-flex align-items-center justify-content-between'>
                <div className='d-flex gap-2 align-items-center'>
                    <img src={require('../../../../../../assets/images/svgs/gs/ground_station.svg').default} width='20px' height='20px' alt='mission' />
                    <span className='fs-16 mt-1'>Add New Ground Station</span>
                </div>
                <button disabled={loading} className='p-0 border-0 bg-transparent' onClick={handle_close}>
                    <i className='fe fe-x fs-20 color-text'></i>
                </button>
            </div>
            <div className={`gs_create_modal_container_body ${loading && 'opacity-50'}`}>
                <div className="d-flex flex-column gap-3">
                    <span className="fs-16 color-atmos-secondary"> Basic Details</span>
                    <div className={`w-100 d-flex align-items-center justify-content-between`}>
                        <span>Name *</span>
                        <Form.Control disabled={loading} type="text" className="w-70" placeholder="Enter Ground Station Name" value={groundStationDetails?.groundStationName}
                            onChange={(event) => {
                                event.preventDefault();
                                let isNotValidName = containsSpecialChars(event.target.value)
                                if (isNotValidName) {
                                    toast.error('Ground station name cannot contain special characters.', { toastId: 'o' })
                                } else {
                                    if (event.target.value.length > 20) {
                                        toast.error('Ground station name cannot be greater than 20 characters. ', { toastId: 'o' })
                                    } else {
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            groundStationName: event.target.value.trimStart()
                                        })
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span>Latitude *</span>
                        <Form.Control disabled={loading} type="number" className="w-70" placeholder="Enter latitude (degree)" value={groundStationDetails?.properties?.latitude}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13 || e.keyCode === 69) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(event: any) => {
                                if (event.target.value.toString().length <= 8) {
                                    if (!isNaN(event.target.value) && Number(event.target.value) <= 90 && Number(event.target.value) >= -90) {
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                latitude: event.target.value
                                            }
                                        })
                                    } else {
                                        toast.error('Latitude must be between -90 and 90.', { toastId: 'o' })
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span>Longitude *</span>
                        <Form.Control disabled={loading} type="number" className="w-70" placeholder="Enter longitude (degree)" value={groundStationDetails?.properties?.longitude}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13 || e.keyCode === 69) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(event: any) => {
                                event.preventDefault();
                                if (!isNaN(event.target.value) && event.target.value.toString().length <= 8) {
                                    if (Number(event.target.value) <= 180 && Number(event.target.value) >= -180) {
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                longitude: event.target.value
                                            }
                                        })
                                    } else {
                                        toast.error('Longitude must be between -180 and 180.', { toastId: 'o' })
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <span>Provider {selectOtherProvider && '*'}</span>
                        {!selectOtherProvider &&
                            <Select
                                isDisabled={loading}
                                className="w-70"
                                menuPlacement="top"
                                classNamePrefix="Select"
                                placeholder="Select Provider"
                                value={groundStationDetails?.properties?.provider === '' ? null : { label: groundStationDetails?.properties?.provider, value: groundStationDetails?.properties?.provider }}
                                options={defaultProviderList}
                                onChange={(event) => {
                                    if (event?.label === 'Other') {
                                        setSelectOtherProvider(true)
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                provider: ''
                                            },
                                        })
                                    } else {
                                        setSelectOtherProvider(false)
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                provider: event?.label
                                            },
                                            status: 'Configure'
                                        })
                                    }
                                }}
                            />
                        }
                        {selectOtherProvider &&
                            <div className='other_provider_input_container'>
                                <input type="text" placeholder='Enter Provider Name' value={groundStationDetails?.['properties']?.['provider']}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        if (!containsSpecialChars(e.target.value) && e.target.value?.length <= 30) {
                                            setGroundStationDetails({
                                                ...groundStationDetails,
                                                properties: {
                                                    ...groundStationDetails?.properties,
                                                    provider: e.target.value.trimStart()
                                                },
                                                status: 'Configure'
                                            })
                                        }
                                    }} />
                                <button className='other_provider_close_btn' onClick={() => {
                                    setSelectOtherProvider(false)
                                    setGroundStationDetails({
                                        ...groundStationDetails,
                                        properties: {
                                            ...groundStationDetails?.properties,
                                            provider: ''
                                        },
                                    })
                                }}>
                                    <i className='fe fe-x fs-20 color_history' />
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column gap-2 mt-2">
                    <span className="fs-16 color-atmos-secondary">Frequency Bands *</span>
                    <div className="d-flex flex-wrap gap-3">
                        {["S-band", "X-band", "UHF"].map((band: any) => (
                            <div className='d-flex gap-2 align-items-center' key={band}>
                                <button disabled={loading} className={`p-0 border-0 bg-transparent`} onClick={() => {
                                    if (groundStationDetails?.properties?.freqbands?.includes(band)) {
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                freqbands: groundStationDetails?.properties?.freqbands?.filter((item) => item !== band)
                                            }
                                        })
                                    } else {
                                        setGroundStationDetails({
                                            ...groundStationDetails,
                                            properties: {
                                                ...groundStationDetails?.properties,
                                                freqbands: groundStationDetails?.properties?.freqbands?.length > 0 ? [...groundStationDetails?.properties?.freqbands, band] : [band]
                                            }
                                        })
                                    }
                                }}>
                                    {groundStationDetails?.properties?.freqbands?.includes(band) && <img src={require('../../../../../../assets/images/svgs/gs/checked_box.svg').default} width='16px' height='16px' alt='mission' />}
                                    {!groundStationDetails?.properties?.freqbands?.includes(band) && <img src={require('../../../../../../assets/images/svgs/gs/uncheck_box.svg').default} width='16px' height='16px' alt='mission' />}
                                </button>
                                <span className='fs-14'>{band}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="gs_create_modal_container_footer">
                <div className='px-3 py-2 gs_container_footer'>
                    <button disabled={loading} className='btn btn-outline-primary' onClick={handle_close}>{'Close'}</button>
                    <button disabled={loading} className='btn btn-primary' onClick={creae_ground_station}>Add</button>
                </div>
            </div>
        </div>
    )
}

export default CreateGSModal
