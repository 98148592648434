
const ongoingSvg = () => (
    <svg className="spin__svg" xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
        <path d="M0.484375 9.45312H7.51562M0.835938 8.08293H7.16406M0.835938 8.08293C0.835938 6.38028 2.25254 5 4 5M0.835938 8.08293V9.45312H7.16406V8.08293M7.16406 8.08293C7.16406 6.38028 5.74746 5 4 5M0.835938 1.91707H7.16406M0.835938 1.91707C0.835937 3.61972 2.25254 5 4 5M0.835938 1.91707L0.835937 0.546875L7.16406 0.546876L7.16406 1.91707M7.16406 1.91707C7.16406 3.61972 5.74746 5 4 5M0.484375 0.546875H7.51562" stroke="#F0F0F0" strokeOpacity="0.8" strokeWidth="0.75" strokeLinecap="round" />
    </svg>
)
const notConfirmSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
        <path d="M1.33337 4.22206L4.44448 7.33317L10.6667 0.666504" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
const confirmSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
        <path d="M1.33337 4.22206L4.44448 7.33317L10.6667 0.666504" stroke="#CCF54E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
const upcomingSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M10.1965 3.32058C9.60677 2.39679 8.72872 1.6934 7.69851 1.3195C6.6683 0.945602 5.54349 0.922088 4.49856 1.25261C3.45362 1.58313 2.54694 2.24921 1.91914 3.14754C1.29134 4.04588 0.977507 5.12627 1.02631 6.22115C1.07512 7.31603 1.48384 8.36421 2.18908 9.20312C2.89432 10.042 3.85667 10.6248 4.92687 10.861C5.99708 11.0973 7.11533 10.9738 8.10818 10.5097C9.10104 10.0456 9.91302 9.26686 10.4182 8.29427" stroke="#D9D9D9" strokeWidth="0.875" strokeLinecap="round" />
        <path d="M11.0577 2.10845L10.562 3.95842L8.60098 3.43298" stroke="#D9D9D9" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.00293 3.18848V6.34508L7.32876 7.80007" stroke="#D9D9D9" strokeWidth="0.875" strokeLinecap="round" />
    </svg>
)
const failedSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M1 1L5 5M9 9L5 5M5 5L9 1M5 5L1 9" stroke="#C91818" strokeLinecap="round" />
    </svg>
)

export const InfoSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0ZM8 1.116C6.17425 1.116 4.42328 1.84128 3.13228 3.13228C1.84128 4.42328 1.116 6.17425 1.116 8C1.116 9.82575 1.84128 11.5767 3.13228 12.8677C4.42328 14.1587 6.17425 14.884 8 14.884C9.82575 14.884 11.5767 14.1587 12.8677 12.8677C14.1587 11.5767 14.884 9.82575 14.884 8C14.884 6.17425 14.1587 4.42328 12.8677 3.13228C11.5767 1.84128 9.82575 1.116 8 1.116ZM7.884 5.768C8.192 5.768 8.4416 6.0184 8.4416 6.3264V12.3728C8.43613 12.5171 8.37495 12.6537 8.27091 12.7539C8.16686 12.8541 8.02804 12.9101 7.8836 12.9101C7.73916 12.9101 7.60034 12.8541 7.4963 12.7539C7.39225 12.6537 7.33107 12.5171 7.3256 12.3728V6.3256C7.3256 6.0176 7.5752 5.768 7.884 5.768ZM7.9064 3.536C8.10372 3.536 8.29296 3.61439 8.43249 3.75391C8.57201 3.89344 8.6504 4.08268 8.6504 4.28C8.6504 4.47732 8.57201 4.66656 8.43249 4.80609C8.29296 4.94561 8.10372 5.024 7.9064 5.024C7.70908 5.024 7.51984 4.94561 7.38031 4.80609C7.24079 4.66656 7.1624 4.47732 7.1624 4.28C7.1624 4.08268 7.24079 3.89344 7.38031 3.75391C7.51984 3.61439 7.70908 3.536 7.9064 3.536Z" fill="#E3E3E3" fill-opacity="0.4" />
    </svg>
)

export const ButtonSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
        <path d="M6.88238 7.17631L5.01067 5.70567L6.88238 4.23506M6.88238 7.17631L11 10.4116L11 0.99982L6.88238 4.23506M6.88238 7.17631V10.4116L1 5.70572L6.88238 0.99982V4.23506" stroke="#F0F0F0" stroke-width="0.588238" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const MinimizeSvg = () => (
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 7.271L5.82004 4.91647C5.06502 4.25312 3.93498 4.25312 3.17996 4.91647L0.5 7.271" stroke="white" stroke-width="0.711111" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.5 3.7583L5.82004 1.40377C5.06502 0.740426 3.93498 0.740426 3.17996 1.40377L0.5 3.7583" stroke="white" stroke-width="0.711111" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const event_status_state = {
    'ongoing': {
        svg: ongoingSvg(),
        state: 'Ongoing'
    },
    'not_confirm': {
        svg: notConfirmSvg(),
        state: 'Not Confirmed'
    },
    'confirm': {
        svg: confirmSvg(),
        state: 'Confirmed'
    },
    'completed': {
        svg: confirmSvg(),
        state: 'Completed'
    },
    'upcoming': {
        svg: upcomingSvg(),
        state: 'Upcoming',
    },
    'failed': {
        svg: failedSvg(),
        state: 'Failed',
    },
}
