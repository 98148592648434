import { ReactNode, createContext, useState } from "react";
import ErrorPage from "../custom_components/ErrorPage";

interface ErrorContextType {
    error: string | boolean;
    setError: (error: string | boolean) => void;
}

export const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

interface ErrorProviderProps {
    children: ReactNode;
}
const ErrorProvider = ({ children }: ErrorProviderProps) => {
    const [error, setError] = useState<string | boolean>(false);

    return (
        <ErrorContext.Provider value={{ error, setError }}>
            {error ? <ErrorPage error_code={error} /> : children}
        </ErrorContext.Provider>
    )
}

export default ErrorProvider