import useUrlParams from '../../../../hooks/useUrlParams'

import { useEffect, useState } from 'react'
import { ScheduleSvg, SearchSvg } from '../Svgs'

interface ExecuteButtonProps {
    payload: any;
    postData: (payload: any) => void;
}

const FormSubmit: React.FC<ExecuteButtonProps> = ({ payload, postData }) => {
    const { dashboard } = useUrlParams()
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [isExecuteEnabled, setIsExecuteEnabled] = useState<boolean>(false);


    const checkSelections = () => {
        const currentForm = sessionStorage.getItem('currentForm');
        const selectedBusComponent = sessionStorage.getItem('selectedBusComponent');
        const selectedTelecommand = sessionStorage.getItem('selectedTelecommand');
        const selectedPayload = sessionStorage.getItem('selectedPayload');
        const selectedSequences = sessionStorage.getItem('selectedSequences');
        const seqParams = sessionStorage.getItem('seqParams');
        const seqPowerReq = sessionStorage.getItem('seqPowerReq');
        const seqDuration = sessionStorage.getItem('seqDuration');
        const value = sessionStorage.getItem('value');
        const isValidJson = sessionStorage.getItem('isVaildJson');
        const busComponent = selectedBusComponent ? JSON.parse(selectedBusComponent) : null;
        const telecommand = selectedTelecommand ? JSON.parse(selectedTelecommand) : null;
        const payload = selectedPayload ? JSON.parse(selectedPayload) : null;
        const sequences = selectedSequences ? JSON.parse(selectedSequences) : null;
        const params = seqParams !== null ? seqParams : '';
        const power = seqPowerReq !== null ? seqPowerReq : '';
        const duration = seqDuration !== null ? seqDuration : '';

        if (currentForm === 'bus') {
            setIsExecuteEnabled(busComponent !== null && telecommand !== null && isValidJson === "true");
        } else if (currentForm === 'payload') {
            if (sequences?.label === 'Other Sequences') {
                setIsExecuteEnabled(payload !== null && sequences !== null && duration !== '' && value !== null);
            } else {
                setIsExecuteEnabled(payload !== null && sequences !== null && duration !== '');
            }
        }
    };

    useEffect(() => {
        checkSelections();
        const handleStorageUpdate = () => {
            checkSelections();
        };

        window.addEventListener('storageUpdate', handleStorageUpdate);
        return () => {
            window.removeEventListener('storageUpdate', handleStorageUpdate);
        };
    }, []);

    useEffect(() => {
        checkSelections()
    }, [sessionStorage.getItem('isVaildJson')])

    useEffect(() => {
        const close_modal = () => {
            setOpenModal(false)
        }
        window.addEventListener('click', close_modal)
        return () => {
            window.removeEventListener('click', close_modal)
        }
    }, [])

    const handleExecute = () => {
        const formData = JSON.parse(sessionStorage.getItem('formData') || '{}');
        postData(formData);
        sessionStorage.removeItem('selectedBusComponent');
        sessionStorage.removeItem('selectedTelecommand');
        sessionStorage.removeItem('selectedPayload');
        sessionStorage.removeItem('selectedSequences');
        sessionStorage.removeItem('seqParams');
        sessionStorage.removeItem('seqPowerReq');
        sessionStorage.removeItem('seqDuration');
        sessionStorage.removeItem('formData');
        sessionStorage.setItem('currentForm', '');
        const clearEvent = new Event('clearFormFields');
        window.dispatchEvent(clearEvent);
        setIsExecuteEnabled(false);
        window.dispatchEvent(new Event('storageUpdate'));
    };
    return (
        <div className='form__submit__section__container'>
            <div className={`contact__gs__list__container ${dashboard === 'operate' && 'show_container'}`}>
                <div className='badge'>Multiple ground station in contacts </div>
                <button className='gs__details__container selected'>
                    <div className='d-flex flex-column w-70 align-items-start'>
                        <span className='gs__details__label'>GS TITLE, Location</span>
                        <span className='gs__details__other__info'>GS TITLE, Location</span>
                    </div>
                    <div className='d-flex flex-column w-25 align-items-end'>
                        <span className='gs__details__other__info'>01:10</span>
                        <span className='gs__details__other__info'>S-band</span>
                    </div>
                </button>
                <button className='gs__details__container'>
                    <div className='d-flex flex-column w-70 align-items-start'>
                        <span className='gs__details__label'>GS TITLE, Location</span>
                        <span className='gs__details__other__info'>GS TITLE, Location</span>
                    </div>
                    <div className='d-flex flex-column w-25 align-items-end'>
                        <span className='gs__details__other__info'>01:10</span>
                        <span className='gs__details__other__info'>S-band</span>
                    </div>
                </button>
            </div>

            <div className='form__submit__btn__container'>
                <button className={`execute__btn`}
                    onClick={isExecuteEnabled ? handleExecute : undefined}
                    disabled={!isExecuteEnabled || dashboard === 'summary'}> Execute now</button>
                <button className={`schedule__btn ${dashboard === 'operate' && 'show_btn small'}`} onClick={(event: any) => {
                    event.stopPropagation();
                    setOpenModal(!openModal)
                }}>
                    <span>Schedule</span>
                    <ScheduleSvg />
                    <div className={`overlay__modal ${openModal && 'active'}`}>
                        <div className='modal__container'>
                            <div className='modal__header'>
                                <div className='search__icon'>
                                    <SearchSvg />
                                </div>
                                <input />
                            </div>
                            <div className='modal__body'>

                            </div>
                            <div className='modal__footer'>
                                <button className='modal__footer__btn'>Any Contact</button>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default FormSubmit