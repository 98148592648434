// @ts-nocheck
import { useState } from 'react';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export function ToggleCheckButtonsGroup({ toggleButtonsList, name, selectedCheck, selectedUnCheck,}) {

    const [isChecked, setIsChecked] = useState(false);

    const onSelectCheck = (event, data) => {
        setIsChecked(!isChecked);
        if (event.target.checked) {
            selectedCheck(data);
        } else {
            selectedUnCheck(data);
        }
    };

    return (
        <ToggleButtonGroup type="checkbox" name={name}>
            {toggleButtonsList.map((data) => {
                return <ToggleButton className="me-2" id={'toggle-check1' + `${data}`} checked={isChecked} 
                    variant="outline-primary"
                    type={'checkbox'}
                    onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            e.preventDefault();
                        }
                      }}
                    onChange={(event) => onSelectCheck(event, data)}
                    value={data} key={data + Math.random()}>{data}</ToggleButton>
            })}
        </ToggleButtonGroup>
    );
}

export function ToogleRadioButtonsGroup(props: { toggleButtonsList: any[], name: string, selectedRadios, defaultValue, value }) {
    const handleOnChange = (event) => {
        if (event.target.checked) {
            props.selectedRadios(event.target.value);
        }
    };
    return (
        <div>
        <ToggleButtonGroup type="radio" name={props.name} defaultValue={props.defaultValue}
                              value={props.value}>
        {props.toggleButtonsList.map((data,i) => {
            return <ToggleButton className='d-flex align-items-center justify-content-center' id={`radio-${data  + Math.random()}`} style={{minWidth:'120px', maxWidth: '150px', display: 'flex' , flexWrap: 'wrap'}} variant="outline-primary" value={data}
            onKeyDown={(e)=>{
                if(e.keyCode === 13){
                    e.preventDefault();
                }
              }}
            onChange={handleOnChange}
            key={i}>
                {data}
            </ToggleButton>
        })}
    </ToggleButtonGroup>
    </div>
    )
}


export function ToggleCustomCheckButtonsGroup({ toggleButtonsList, selectedCheck, selectedUnCheck, selectedBands}) {


    const onSelectCheck = (event, data) => {
        if (event.target.checked) {
            selectedCheck(data);
        } else {
            selectedUnCheck(data);
        }
    };


    return (
        <div className='w-100 d-flex gap-2'>
            {toggleButtonsList.map((data,i) => (
                <Button
                    key={i}
                    variant={selectedBands.includes(data) ? "primary" : "outline-danger"}
                    type="button"
                    className={`d-flex align-items-center justify-content-center btn-payload btn ${selectedBands.includes(data) ? 'btn-primary' : 'btn-outline-primary'} pt-1 pb-1 pe-4 ps-3 fs-14`}
                    onClick={(e) => {onSelectCheck(e,data)}}
                >
                {data}
                </Button>
            ))}
        </div>
    );
}