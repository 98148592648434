import { useEffect, useState } from "react"
import useScenario from "./useScenario"

const useMonitor = () => {
    const { loading, scenario } = useScenario()

    const [selected_panel, set_selected_panel] = useState<string>('EPS')
    const [monitor_view_type, set_monitor_view_type] = useState<string>('Grid')
    const [grafana_data, set_grafana_data] = useState<any>({})
    const [panels, set_panels] = useState<any | null>(null)


    useEffect(() => {
        if (scenario?.['grafanaData']) {
            const grafana_data = scenario?.['grafanaData']?.['Urls']
            set_grafana_data(grafana_data)
            set_panels(grafana_data?.['EPS'])
            set_selected_panel('EPS')
            change_monitor_view_type('Grid')
        }
    }, [scenario])

    const change_selected_panel = (panel: string) => {
        if (panel === 'Communication') {
            set_panels(grafana_data?.['Comms'])
            set_selected_panel(panel)
            return
        } else if (panel === 'Payload') {
            set_panels(grafana_data?.['PAYLOAD']?.['PayloadApp'])
            set_selected_panel(panel)
            return
        } else {
            set_panels(grafana_data?.[panel])
            set_selected_panel(panel)
            return
        }
    }

    const change_monitor_view_type = (view: string) => {
        set_monitor_view_type(view)
    }

    return {
        panels,
        loading,
        selected_panel,
        change_selected_panel,
        monitor_view_type,
        change_monitor_view_type
    }
}

export default useMonitor