import React, { useState } from "react";
import "../Modals.css";
import ModalCreationMessage from "../ModalCreationMessage";
import TableModal from "../ModalComponents/TableModal";
import ModalDropdown from "../ModalComponents/ModalDropdown";
import ModalHeader from "../ModalComponents/ModalHeader";
import ModalFooter from "../ModalComponents/ModalFooter";

const PowerAnalysisModalHiFi = ({ props, parameterProps }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const tableProps = {isModalVisible, setisModalVisible};
  const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [powerHiFiComponents, showPowerHiFiComponents] = useState({
    Power_Sink: false,
    Power_Source: true,
  });

  const [powerAnalysisSats, showPowerAnalysisSats] = useState({
    Mission_Sat_01: true,
  });

  const [busComponents, showBusComponents] = useState({
    ADCS: true,
    X_Band: true,
    S_Band: true,
  });

  const [solarPanels, showSolarPanels] = useState({
    Solar_Panel_X786: true,
  });

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setIsSuccessMsgVisible(true);
    setTimeout(() => {
      setIsSuccessMsgVisible(false);
      props.setBottombarProps({ bottombarComponent: "", componentId: "", componentData: "" });
    }, 2000);
  };

  /* These are the values required for showing the table */

  const TableBarValues = [
    {
      label: "Solar Panels",
      id: "solarPanels",
      imgPath: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      label: "Battery",
      id: "battery",
      imgPath: require("../../ImageSvgs/PowerThermal.svg").default,
    },
  ];

  const [tableBarItem, setTableBarItem] = useState(TableBarValues[0].label);
  const TableBarItemProps = { tableBarItem, setTableBarItem };

  const SolarPanelHeaderElements = [
    {
      title: "Component",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Area",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
    {
      title: "Efficiency",
      image: require("../../ImageSvgs/ADC.svg").default,
    },
    {
      title: "Normal Vector",
      image: require("../../ImageSvgs/Navigation.svg").default,
    },
  ];

  const BatteryHeaderElements = [
    {
      title: "Component",
      image: require("../../ImageSvgs/Orbital.svg").default,
    },
    {
      title: "Capacity",
      image: require("../../ImageSvgs/DataFlow.svg").default,
    },
  ];

  const SolarPanelOptions = [
    { label: "Panel_01", value: "Panel_01" },
    { label: "Panel_02", value: "Panel_02" },
    { label: "Panel_03", value: "Panel_03" },
  ];

  const BatteryOptions = [
    { label: "Battery_01", value: "Battery_01" },
    { label: "Battery_02", value: "Battery_02" },
    { label: "Battery_03", value: "Battery_03" },
  ];

  const header = {
    title: "Power Analysis - Hi-Fi",
    img: require("../../ImageSvgs/PowerThermal.svg").default,
  };

  let [inputFieldPlaceHolder, setInputFieldPlaceHolder] = useState("12");

  const handleSelectedOption = (selectedOption) => {
    console.log("Selected Option => ", selectedOption)
  }

  const [previousOption, setPreviousOption] = useState("")

  const SolarPowerRowElements = [
    <ModalDropdown options={SolarPanelOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <input
      className="tableInputElement"
      value={inputFieldPlaceHolder}
      onChange={(event) => setInputFieldPlaceHolder(event.target.value)}
      placeholder="in sq. meters"
    />,
    <input className="tableInputElement" value="" placeholder="in %" />,
    <input className="tableInputElement" value="" placeholder="in metres" />,
  ];

  const BatteryRowElements = [
    <ModalDropdown options={BatteryOptions} isMulti={"false"} handleSelectedOption = {handleSelectedOption} previousOption = {previousOption}/>,
    <input
      className="tableInputElement"
      value={inputFieldPlaceHolder}
      onChange={(event) => setInputFieldPlaceHolder(event.target.value)}
      placeholder="in Watts"
    />,
  ];

  const headerElementsSelector = () => {
    if(tableBarItem === "Solar Panels"){
      return SolarPanelHeaderElements;
    }
    else if(tableBarItem === "Battery"){
      return BatteryHeaderElements;
    }
  }

  const rowElementsSelector = () => {
    if(tableBarItem === "Solar Panels"){
      return SolarPowerRowElements;
    }
    else if(tableBarItem === "Battery"){
      return BatteryRowElements;
    }
  }

  /* End of values for the table */

  return (
    <>
      {tableBarItem && tableProps.isModalVisible && parameterProps.isTableModalVisible && (
        <TableModal
          props={props}
          parameterProps={parameterProps}
          tableBarValues={TableBarValues}
          tableBarItemProps={TableBarItemProps}
          headerElements={headerElementsSelector()}
          numOfRows={4}
          rowElements={rowElementsSelector()}
          tableHeader="Power Source"
        />
      )}

      {isSuccessMsgVisible && (
        <ModalCreationMessage props={props} message={message} />
      )}

      {isModalVisible && (
        <>
          <div
            className={
              props.isSidebarVisible
                ? "mainModal sidebarOpenModal"
                : "mainModal"
            }
            id="coverageAnalysis"
          >
            <ModalHeader
              props={props}
              parameterProps={parameterProps}
              header={header}
            />

            <div className="modalScrollableSection">
              <div className="modalCheckboxDiv">
                <div className="modalCheckboxText modalGreenSubHeader ">
                  Satellites
                </div>
              </div>

              <div className="modalrefOrbitDiv">
                {powerAnalysisSats.Mission_Sat_01 && (
                  <div className="modalrefOrbitsBtn">
                    <span>&lt;Mission_Sat_01&gt;</span>
                    <button
                      className="modalrefOrbitsCloseBtn"
                      onClick={() =>
                        showPowerAnalysisSats({
                          ...powerAnalysisSats,
                          Mission_Sat_01: false,
                        })
                      }
                    >
                      <img
                        src={require("../../ImageSvgs/CloseButton.svg").default}
                      />
                    </button>
                  </div>
                )}
              </div>

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showPowerHiFiComponents({
                      ...powerHiFiComponents,
                      Power_Sink: !powerHiFiComponents.Power_Sink,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d={
                        powerHiFiComponents.Power_Sink
                          ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344"
                          : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"
                      }
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">
                  Power Sink
                </div>
              </div>

              {powerHiFiComponents.Power_Sink && (
                <>
                  <div className="modalWhiteSubHeader modalSubHeaderDiv">
                    Bus Components
                  </div>

                  <div
                    className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                    style={{ margin: "12px" }}
                  >
                    <div className="modalrefOrbitsSpacer">
                      <div
                        className="modalrefOrbitsBtn"
                        style={{
                          display: busComponents.ADCS ? "flex" : "none",
                        }}
                      >
                        <span>ADCS</span>
                        <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                          <img
                            src={
                              require("../../ImageSvgs/eye-outline.svg").default
                            }
                          />
                        </button>
                        <button
                          className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                          onClick={() =>
                            showBusComponents({
                              ...busComponents,
                              ADCS: false,
                            })
                          }
                        >
                          <img
                            src={
                              require("../../ImageSvgs/CloseButton.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div
                        className="modalrefOrbitsBtn"
                        style={{
                          display: busComponents.X_Band ? "flex" : "none",
                        }}
                      >
                        <span>X-Band</span>
                        <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                          <img
                            src={
                              require("../../ImageSvgs/eye-outline.svg").default
                            }
                          />
                        </button>
                        <button
                          className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                          onClick={() =>
                            showBusComponents({
                              ...busComponents,
                              X_Band: false,
                            })
                          }
                        >
                          <img
                            src={
                              require("../../ImageSvgs/CloseButton.svg").default
                            }
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      className="modalrefOrbitsBtn"
                      style={{
                        display: busComponents.S_Band ? "flex" : "none",
                      }}
                    >
                      <span>S-Band</span>
                      <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                        <img
                          src={
                            require("../../ImageSvgs/eye-outline.svg").default
                          }
                        />
                      </button>
                      <button
                        className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                        onClick={() =>
                          showBusComponents({
                            ...busComponents,
                            S_Band: false,
                          })
                        }
                      >
                        <img
                          src={
                            require("../../ImageSvgs/CloseButton.svg").default
                          }
                        />
                      </button>
                    </div>
                  </div>
                  <div className="modalWhiteSubHeader modalSubHeaderDiv">
                    Payloads
                  </div>
                  <div
                    className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                    style={{ margin: "12px" }}
                  >
                    Add Payloads
                  </div>
                  <div className="modalWhiteSubHeader modalSubHeaderDiv">
                    Edge
                  </div>
                  <div
                    className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                    style={{ margin: "12px" }}
                  >
                    Add Edge devices
                  </div>
                </>
              )}

              <div className="modalCheckboxDiv">
                <button
                  className="modalDropDownIconBtn"
                  onClick={() =>
                    showPowerHiFiComponents({
                      ...powerHiFiComponents,
                      Power_Source: !powerHiFiComponents.Power_Source,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d={
                        powerHiFiComponents.Power_Source
                          ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344"
                          : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"
                      }
                      stroke="#33E18F"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="modalCheckboxText modalGreenSubHeader modalCheckboxTextRight">
                  Power Source
                </div>
                <button
                  className="tableModalOpenModal"
                  onClick={() => parameterProps.setIsTableModalVisible(true)}
                >
                  <img
                    src={require("../../ImageSvgs/OpenNewBox.svg").default}
                  />
                </button>
              </div>

              {powerHiFiComponents.Power_Source && (
                <>
                  <div className="modalWhiteSubHeader modalSubHeaderDiv">
                    Solar Panels
                  </div>
                  <div className="modalrefOrbitDiv" style={{ margin: "12px" }}>
                    {solarPanels.Solar_Panel_X786 && (
                      <div
                        className="modalrefOrbitsBtn"
                        style={{
                          display: solarPanels.Solar_Panel_X786
                            ? "flex"
                            : "none",
                        }}
                      >
                        <span>Solar_Panel_X786</span>
                        <button className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn">
                          <img
                            src={
                              require("../../ImageSvgs/eye-outline.svg").default
                            }
                          />
                        </button>
                        <button
                          className="modalrefOrbitsCloseBtn modalrefOrbitsViewBtn"
                          onClick={() =>
                            showSolarPanels({
                              ...solarPanels,
                              Solar_Panel_X786: false,
                            })
                          }
                        >
                          <img
                            src={
                              require("../../ImageSvgs/CloseButton.svg").default
                            }
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="modalWhiteSubHeader modalSubHeaderDiv">
                    Battery
                  </div>
                  <div
                    className="modalrefOrbitDiv modalBusComponentsBtnDiv"
                    style={{ margin: "12px" }}
                  >
                    Add Battery Details
                  </div>
                </>
              )}
            </div>

            <div className="modalFooter">
              <button
                className="modalFooterclose"
                onClick={() => {
                  setisModalVisible(false);
                  props.setBottombarProps({ bottombarComponent: "",componentId: "", componentData : "" });
                }}
              >
                Close
              </button>
              <button
                className="modalFooterapply"
                onClick={() => {
                  setisModalVisible(false);
                  showSuccessMessage("Power Analysis - Hi-Fi Analysis successful");
                  props.setSideReportBarProps({isSideReportBarVisible: true});
                  props.setShowSidebarReportComponent("Power");
                }}
              >
                Analyse
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PowerAnalysisModalHiFi;
