import { useEffect, useState } from "react"
import * as Cesium from "cesium";
import useViewer from "./useViewer"
import useContacts from "./useContacts"

export type ContactStation = {
    "band": string[],
    "country": string,
    "id": string,
    "latitude": string,
    "location": string,
    "longitude": string,
    "name": string,
    "passes": any[],
    "provider": string,
    "startTime": number,
    "taskName": string,
    "type": string
}
export type GroundContact = {
    "status": string,
    "message": string,
    "groundStations": ContactStation[]
}

interface Pass {
    start: number;
    end: number;
    gsId: string;
}



const useGroundContact = () => {
    const { viewer } = useViewer()
    const { contactData, selectedContact, setSelectedContact, contacts, setContacts } = useContacts()
    const [loading, setLoading] = useState(true)
    const getContactStatus = (contacts, currentTime) => {
        const currentTimestamp = currentTime; // Convert to seconds
        let all_passes: Pass[] = []
        contacts.map(contact =>
            contact.passes.map(pass => {
                all_passes.push({
                    start: pass[0],
                    end: pass[1],
                    gsId: contact.id
                })
            })
        )
        all_passes.sort((a, b) => a.start - b.start);

        let ongoingContact: Pass | null = null;
        let upcomingContact: Pass[] = [];
        let latestPreviousContact: Pass[] = [];

        for (const pass of all_passes) {
            if (currentTimestamp >= pass.start && currentTimestamp <= pass.end) {
                ongoingContact = pass;
            } else if (currentTimestamp < pass.start) {
                upcomingContact.push(pass);
            } else if (currentTimestamp > pass.end) {
                latestPreviousContact.push(pass);
            }
        }

        const findContact = (pass: Pass | null): ContactStation | null =>
            pass ? contacts.find(contact => contact.id === pass.gsId) || null : null;

        return {
            ongoing_contact: findContact(ongoingContact),
            upcoming_contact: findContact(upcomingContact ? upcomingContact?.[0] : null),
            previous_contact: findContact(latestPreviousContact ? latestPreviousContact?.[latestPreviousContact?.length - 1] : null),
        };

    }


    const get_current_contact_details = (current_time) => {
        const { ongoing_contact, upcoming_contact, previous_contact } = getContactStatus(contactData, current_time);

        const selected_contact = ongoing_contact
            ? { contact_type: 'Ongoing', contact_data: ongoing_contact }
            : upcoming_contact
                ? { contact_type: 'Upcoming', contact_data: upcoming_contact }
                : previous_contact
                    ? { contact_type: 'Previous', contact_data: previous_contact }
                    : null;

        setContacts({
            previous_contact: previous_contact,
            ongoing_contact: ongoing_contact,
            upcoming_contact: upcoming_contact,
            current_time: current_time,
        })
        setSelectedContact(selected_contact)
        setLoading(false)
    }

    useEffect(() => {
        if (contactData?.length > 0 && viewer) {
            let Interval = setInterval(() => {
                const clock = viewer?.clock;
                if (clock) {
                    const cesium_time = clock.currentTime;
                    if (cesium_time) {
                        const current_time = Cesium.JulianDate.toDate(cesium_time).getTime() / 1000;
                        get_current_contact_details(current_time)
                    }
                }
            }, 1000);
            return () => clearInterval(Interval);
        }
    }, [contactData, selectedContact, contacts, viewer])


    return {
        loading,
        contacts,
        contactData,
        selectedContact,
    }
}

export default useGroundContact