
interface ConfigurationProps {
    device_details: any;
}

const Configuration: React.FC<ConfigurationProps> = ({ device_details }) => {
  return (
    <div className='d-flex flex-column gap-4 w-100'>
        <div className='d-flex flex-column w-100'>
            <h3 className='fs-16'>Basic Configurations</h3>
            <div className='d-flex w-100 gap-4'>
                <div className='d-flex flex-column gap-3 w-50 border-right'>
                    <span className='fs-14 color_history'>Uplink</span>
                    <div className='d-flex gap-3 w-100 flex-wrap'>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>2042.5 mHz</strong>
                            <span className='fs-14 color_history'>Center Frequency</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>128 kbaud</strong>
                            <span className='fs-14 color_history'>Data Rate</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>3 mHz</strong>
                            <span className='fs-14 color_history'>Bandwidth</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>2041-2050</strong>
                            <span className='fs-14 color_history'>Frequency Range</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>CCSDS 131.0-B</strong>
                            <span className='fs-14 color_history'>Frame Configuration</span>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column gap-3 w-50'>
                    <span className='fs-14 color_history'>Downlink</span>
                    <div className='d-flex gap-3 w-100 flex-wrap'>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>2042.5 mHz</strong>
                            <span className='fs-14 color_history'>Center Frequency</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>128 kbaud</strong>
                            <span className='fs-14 color_history'>Data Rate</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>3 mHz</strong>
                            <span className='fs-14 color_history'>Bandwidth</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>2041-2050</strong>
                            <span className='fs-14 color_history'>Frequency Range</span>
                        </div>
                        <div className='d-flex flex-column border rounded-3 w-200px px-4 py-3'>
                            <strong className='fs-16'>CCSDS 131.0-B</strong>
                            <span className='fs-14 color_history'>Frame Configuration</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column w-100'>
            <h3 className='fs-16'>Enabled Configurations</h3>
            <div className='d-flex w-100 gap-4'>
                <div className='d-flex flex-column gap-3 w-50 border-right'>
                    <span className='fs-14 color_history'>Uplink</span>
                    <div className='d-flex gap-3 w-100 flex-wrap'>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>FEC</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>DC Filtering</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>Roll off</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>Whitening</span>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column gap-3 w-50'>
                    <span className='fs-14 color_history'>Downlink</span>
                    <div className='d-flex gap-3 w-100 flex-wrap'>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>FEC</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>DC Filtering</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>Roll off</span>
                        </div>
                        <div className='d-flex gap-2 border rounded-3 w-200px px-4 py-3 align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#CCF54E"/>
                            </svg>
                            <span className='fs-14 color_history'>Whitening</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Configuration