import { OverlayTrigger, Popover } from "react-bootstrap"

interface CustomOverLayProps {
    children: React.ReactNode;
    title?: string;
    placement?: "top" | "bottom" | "left" | "right"
    data?: any
}


const CustomOverLay: React.FC<CustomOverLayProps> = ({ children, title, placement, data }) => {
    
  return (
    <OverlayTrigger
            placement={placement}
            overlay={
                <Popover id="popover-ground-station" className="py-0 rounded-2 d-flex flex-column bg-black border z-99">
                    <Popover.Body>
                        <div className="d-flex flex-wrap gap-3 aliign-items-center justify-content-start">
                            {title}
                        </div>
                    </Popover.Body>
                </Popover>
            }
        >
            <span>{children}</span>
    </OverlayTrigger>
  )
}

export default CustomOverLay