
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { containsSpecialChars, containsWhitespace } from "../../../CommonComponents/CommonFunctions";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import GroundStationService from "../../../../service/GroundStationService";

interface GroundStationFormprops {
  station_type?: string;
  configure_station?: any;
  provider_stations?: any;
  update_provider_details?: (provider_stations) => void;
  get_all_ground_stations?: () => void;
  create_manual_gs?: (station) => void;
  close: () => void;
  data?: any;
  mode: string
}

const GroundStationForm: FC<GroundStationFormprops> = ({ station_type, configure_station, provider_stations, update_provider_details, get_all_ground_stations, create_manual_gs, close, data, mode }) => {
  const params = useParams()
  const { mission_id } = params
  const [error, setError] = React.useState(false);
  const [groundStationName, setGroundStationName] = useState("");
  const [groundStationDetails, setGroundStationDetails] = useState({
    antenaSize: '',
    polarization: '',
    provider: '',
    altitude: '0',
  });
  const [groundStationPosition, setGroundStationPosition] = useState<any>({
    country: '',
    location: '',
    latitude: '',
    longitude: '',
    freqbands: [],
  });

  const scrollRef = useRef(null)

  const [bandsData, setBandsData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === 'Update' && data && Object.keys(data).length > 0) {
      setGroundStationName(data?.['groundStationName'])
      setGroundStationDetails({
        antenaSize: data?.['properties']?.['antenaSize'],
        polarization: data?.['properties']?.['polarization'] ? data?.['properties']?.['polarization'] : '',
        provider: data?.['properties']?.['provider'],
        altitude: data?.['properties']?.['altitude'] ? data?.['properties']?.['altitude'] : '0',
      })
      setGroundStationPosition({
        country: data?.['properties']?.['country'] ? data?.['properties']?.['country'].includes(',') ? data?.['properties']?.['country'].split(',')[1] : data?.['properties']?.['country'] : '',
        location: data?.['properties']?.['location'] ? data?.['properties']?.['location'] : '',
        latitude: data?.['properties']?.['latitude'],
        longitude: data?.['properties']?.['longitude'],
        freqbands: data?.['properties']?.['freqbands'],
      })

      let frequency_data = {}
      data?.['properties']?.['freqbands'].map((band) => {
        frequency_data = {
          ...frequency_data,
          [band]: {
            [band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`]: data?.['properties']?.[band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`],
            [band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`]: data?.['properties']?.[band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`],
            rxFreq: {
              [`${band.split('-')[0]}freq`]: {
                min: data?.['properties']['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'],
                max: data?.['properties']['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']
              }
            },
            txFreq: {
              [`${band.split('-')[0]}freq`]: {
                min: data?.['properties']['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'],
                max: data?.['properties']['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'],
              }
            }
          },
        }

      })
      setBandsData(frequency_data)
    }
  }, [data, mode])


  const validate_ground_station_data = () => {
    if (groundStationName === '' || groundStationPosition?.['country'] === '' || groundStationPosition?.['location'] === '' || groundStationPosition?.['latitude'] === '' ||
      groundStationPosition?.['longitude'] === '' || groundStationDetails?.['altitude'] === '' || groundStationPosition.freqbands?.length === 0) {
      const field_name = groundStationName === '' ? 'ground station name' : groundStationPosition?.['country'] === '' ? 'country' : groundStationPosition?.['location'] === '' ? 'location' : groundStationPosition?.['latitude'] === '' ? 'latitude' : groundStationPosition?.['longitude'] === '' ? 'longitude' : groundStationDetails?.['altitude'] === '' ? 'altitude' : 'freqbands'
      toast.error(field_name === 'freqbands' ? `Please select atleast one frequency` : `Please enter ${field_name}.`, { toastId: '0' })
      return true
    } else {
      return false
    }
  }

  const create_new_ground_station = () => {

    let rxFreq: any = {}
    let txFreq: any = {}
    let gt_eirp: any = {}
    let validation_success: any = false

    if (validate_ground_station_data()) {
      setError(true)
      return
    } else if (groundStationPosition.freqbands?.length > 0) {
      setError(false)
      groundStationPosition.freqbands.map((band: any, i) => {
        const rs_freq_range = band === 'UHF' ? [300, 3000] : band === 'X-band' ? [7800, 8500] : [2000, 4000]
        const ts_freq_range = band === 'UHF' ? [300, 3000] : [2000, 4000]
        const gt_range = [0, 1000]
        if (bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] && bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']) {
          if (+bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= +bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']) {
            if ((+bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= rs_freq_range[0]) && (+bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= rs_freq_range[1])) {
              rxFreq = {
                ...rxFreq,
                [`${band.split('-')[0]}freq`]: bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]
              }
            } else {
              toast.error(`The Frequency range of the ${band} Rx is from ${rs_freq_range[0]} MHz to ${rs_freq_range[1]} MHz.`, { toastId: 'o' })
              setError(true)
              validation_success = 'error'
              return
            }
          } else {
            toast.error(`${band} minimum Rx Frequency can't be greater than maximum Rx Frequency.`, { toastId: 'o' })
            setError(true)
            validation_success = 'error'
            return
          }
        } else {
          toast.error(`${band} ${!bandsData[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] ? 'min' : 'max'} Rx Frequency is Required.`, { toastId: 'o' })
          setError(true)
          validation_success = 'error'
          return
        }
        if (band !== 'X-band' && bandsData[band]?.['txFreq']) {
          if (bandsData[band]?.['txFreq']?.[`${band.split('-')?.[0]}freq`]?.['min'] || bandsData[band]?.['txFreq']?.[`${band.split('-')?.[0]}freq`]?.['max']) {
            if (+bandsData[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= +bandsData[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max']) {
              if ((+bandsData[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= ts_freq_range[0]) && (+bandsData[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= ts_freq_range[1])) {
                txFreq = {
                  ...txFreq,
                  [`${band.split('-')[0]}freq`]: bandsData[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]
                }
              } else {
                toast.error(`The Frequency range of the ${band} Tx is from ${ts_freq_range[0]} MHz to ${ts_freq_range[1]} MHz.`, { toastId: 'o' })
                setError(true)
                validation_success = 'error'
                return
              }
            } else {
              toast.error(`${band} minimum Tx Frequency can't be greater than maximum Tx Frequency.`, { toastId: 'o' })
              setError(true)
              validation_success = 'error'
              return
            }
          }
        }

        if (band === 'S-band' && bandsData[band]?.[`${band.split('-')[0]}_GT`] || bandsData[band]?.[`${band.split('-')[0]}_EIRP`]) {
          if (+bandsData[band]?.[`${band.split('-')[0]}_GT`] >= gt_range[0] && +bandsData[band]?.[`${band.split('-')[0]}_GT`] <= gt_range[1]) {
            gt_eirp = {
              ...gt_eirp,
              [band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`]: bandsData[band]?.[band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`],
              [band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`]: bandsData[band]?.[band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`]
            }
          } else {
            toast.error(`The Frequency range of the ${band} GT is from 0 to 1000.`, { toastId: 'o' })
            setError(true)
            validation_success = 'error'
            return
          }
        }
        if (band === 'UHF') {
          if (bandsData[band]?.['U_EIRP']) {
            gt_eirp = {
              ...gt_eirp,
              'U_EIRP': bandsData[band]?.['U_EIRP']
            }
          }
        }
        if (band === 'X-band') {
          if (bandsData[band]?.[`${band.split('-')[0]}_GT`]) {
            if (+bandsData[band]?.[`${band.split('-')[0]}_GT`] >= gt_range[0] && +bandsData[band]?.[`${band.split('-')[0]}_GT`] <= gt_range[1]) {
              gt_eirp = {
                ...gt_eirp,
                [`${band.split('-')[0]}_GT`]: bandsData[band]?.[`${band.split('-')[0]}_GT`],
              }
            } else {
              toast.error(`The Frequency range of the ${band} GT is from 0 to 1000.`, { toastId: 'o' })
              setError(true)
              validation_success = 'error'
              return
            }
          }
        }

        if (!validation_success && groundStationPosition.freqbands?.length === i + 1) {
          validation_success = 'success'
        }
      })
      let station = {
        groundStationName: groundStationName.trimEnd(),
        missionId: mission_id,
        properties: {
          ...groundStationDetails,
          ...groundStationPosition,
          location: `${groundStationPosition?.['location'].trimEnd()}`,
          country: `${groundStationPosition?.['country'].trimEnd()}`,
          polarization: (groundStationDetails?.['polarization'] && groundStationDetails?.['polarization'] !== '') ? `${groundStationDetails?.['polarization'].trimEnd()}` : '',
          rxFreq: rxFreq,
          txFreq: txFreq,
          ...gt_eirp
        },
        status: (data && data?.['properties']?.['provider'] && data?.['properties']?.['provider'] !== '') ? 'Configured' : 'Associate With Provider',
        groundStationId: data && data?.['groundStationId'] ? data?.['groundStationId'] : uuidv4(),
      }

      if (validation_success === 'success') {
        if (mode === 'Update') {
          update_ground_station(station)
        } else {
          create_ground_station(station)
        }
      }
    }
  }

  const update_ground_station = (station) => {
    setLoading(true)
    GroundStationService.updateGroundStations([station])?.then((res) => {
      if (res.data) {
        if (configure_station) {
          let filter_provider_stations = provider_stations && provider_stations?.filter(gs => gs?.['groundStationId'] !== station?.['groundStationId'])
          let all_provider_stations = [...filter_provider_stations, station]
          update_provider_details && update_provider_details(all_provider_stations)
        } else {
          get_all_ground_stations && get_all_ground_stations()
          toast.success('Ground Station updated successfully', { toastId: 'gs' })
        }
        setLoading(false)
        close()
      }
    }).catch((err) => {
      console.error(err);
      setLoading(false)
    })
  }

  const create_ground_station = (station) => {
    if (station_type && station_type === 'Manual') {
      create_manual_gs && create_manual_gs(station)
    } else {
      setLoading(true)
      GroundStationService.createGroundStation(station?.['groundStationName'], station)?.then((res) => {
        if (res) {
          toast.success('Ground Station created successfully', { toastId: 'gs' })
          setLoading(false)
          close()
        }
      }).catch((err) => {
        console.error(err);
        setLoading(false)
      })
    }
  }

  const scroll_to_bottom = () => {
    const container = scrollRef.current;
    if (container) {
      (container as HTMLElement).scrollTop = (container as HTMLElement).scrollHeight;
      (container as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="popup-model z-2">

      <div className='model-lg gap-3 w-80 p-0'>
        <div className='w-100 d-flex justify-content-between align-items-center px-5 pt-5'>
          <span className='form-heade-text fs-18' > Ground Station</span>
          <i onClick={close} className="fe fe-x-circle me-1 fs-25 cursor-pointer text-white"></i>
        </div>

        <div className="modal_devider" />

        <div className={`pop-scroll-sm pt-0 pb-3 px-5 ${loading ? 'opacity-50' : ''}`} ref={scrollRef}>
          <Form validated={error} className="d-flex flex-column gap-4">
            <div className="d-flex flex-wrap gap-3 w-100 justify-content-between mb-3">
              <div className="d-flex gap-2 flex-column w-30">
                <span className="form-content-text">{"Ground Station Name * :"}</span>
                <Form.Control
                  className="w-100"
                  required
                  disabled={loading}
                  type="text"
                  placeholder="Enter ground station name"
                  value={groundStationName}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    event.preventDefault();
                    let isNotValidName = containsSpecialChars(event.target.value)
                    if (isNotValidName) {
                      toast.error('Ground station name cannot contain special characters.', { toastId: 'o' })
                    } else if (event.target.value.length <= 20) {
                      setGroundStationName(event.target.value.trimStart())
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter ground station name
                </Form.Control.Feedback>
              </div>

              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Country * :"}</span>
                <Form.Control
                  className="w-100"
                  required
                  type="text"
                  disabled={loading}
                  placeholder="Enter country"
                  value={groundStationPosition.country}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    event.preventDefault();
                    let isNotValidName = containsSpecialChars(event.target.value)
                    if (isNotValidName) {
                      toast.error('Country name cannot contain special characters.', { toastId: 'o' })
                    } else if (event.target.value.length <= 100) {
                      setGroundStationPosition({
                        ...groundStationPosition,
                        country: event.target.value.trimStart()
                      })
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter country
                </Form.Control.Feedback>
              </div>

              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Location * :"}</span>
                <Form.Control
                  className="w-100"
                  required
                  type="text"
                  disabled={loading}
                  placeholder="Enter location"
                  value={groundStationPosition.location}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    event.preventDefault();
                    let isNotValidName = containsSpecialChars(event.target.value)
                    if (isNotValidName) {
                      toast.error('Location cannot contain special characters.', { toastId: 'o' })
                    } else if (event.target.value.length <= 100) {
                      setGroundStationPosition({
                        ...groundStationPosition,
                        location: event.target.value.trimStart()
                      })
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter location
                </Form.Control.Feedback>
              </div>
            </div>
            <span className="border-top w-100"></span>

            {/* ----------------Details Related Inputs--------------- */}


            <div className="d-flex flex-wrap gap-3 w-100 justify-content-between mb-3">
              <div className="d-flex flex-column gap-2 w-30 ">
                <span className="form-content-text">{"Latitude (degree) * :"}</span>
                <Form.Control
                  className="w-100"
                  min={-90}
                  max={90}
                  required
                  type="number"
                  step='any'
                  disabled={loading}
                  placeholder="Enter latitude (degree)"
                  value={groundStationPosition.latitude}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 69) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: any) => {
                    event.preventDefault();
                    if (event.target.value.toString().length <= 8) {
                      if (!isNaN(event.target.value) && Number(event.target.value) <= 90 && Number(event.target.value) >= -90) {
                        setGroundStationPosition({
                          ...groundStationPosition,
                          latitude: event.target.value
                        })
                      }
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {groundStationPosition.latitude === '' ? `Please enter latitude` : 'Latitude must be between -90 and 90.'}
                </Form.Control.Feedback>
              </div>
              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Longitude (degree) * :"}</span>
                <Form.Control
                  className="w-100"
                  min={-180}
                  max={180}
                  required
                  type="number"
                  step='any'
                  disabled={loading}
                  placeholder="Enter longitude (degree) "
                  value={groundStationPosition.longitude}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 69) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: any) => {
                    event.preventDefault();
                    if (!isNaN(event.target.value) && event.target.value.toString().length <= 8) {
                      if (Number(event.target.value) <= 180 && Number(event.target.value) >= -180) {
                        setGroundStationPosition({
                          ...groundStationPosition,
                          longitude: event.target.value
                        })
                      }
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {groundStationPosition.longitude === '' ? `Please enter longitude.` : `Longitude must be between -180 and 180.`}
                </Form.Control.Feedback>
              </div>

              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Altitude (m) * :"}</span>
                <Form.Control
                  className="w-100"
                  required
                  type="number"
                  step={'any'}
                  min={0}
                  max={10000}
                  disabled={loading}
                  placeholder="Enter altitude (m)"
                  value={groundStationDetails.altitude}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 69) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: any) => {
                    event.preventDefault();
                    if (!isNaN(event.target.value) && (Number(event.target.value) >= 0 && Number(event.target.value) <= 10000)) {
                      setGroundStationDetails({
                        ...groundStationDetails,
                        altitude: event.target.value
                      })
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {groundStationDetails.altitude === '' ? `Please enter altitude` : 'Altitude must be between 0 and 10000 meters.'}
                </Form.Control.Feedback>
              </div>
            </div>
            <span className="border-top w-100"></span>

            {/* ----------------Position Related Inputs--------------- */}

            <div className="d-flex flex-wrap gap-3 w-100 justify-content-between">

              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Antenna Size (m) :"}</span>
                <Form.Control
                  className="w-100"
                  type="number"
                  step={'any'}
                  disabled={loading}
                  placeholder="Enter antenna size (m)"
                  value={groundStationDetails.antenaSize}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 69) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: any) => {
                    event.preventDefault();
                    if (!isNaN(event.target.value) && Number(event.target.value) >= 0 && event.target.value.length <= 8) {
                      setGroundStationDetails({
                        ...groundStationDetails,
                        antenaSize: event.target.value
                      })
                    }
                  }}
                />
              </div>
              <div className="d-flex flex-column gap-2 w-30">
                <span className="form-content-text">{"Polarization :"}</span>
                <Form.Control
                  className="w-100"
                  type="text"
                  disabled={loading}
                  placeholder="Enter polarization"
                  value={groundStationDetails.polarization}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.value.length <= 100) {
                      setGroundStationDetails({
                        ...groundStationDetails,
                        polarization: event.target.value.trimStart()
                      })
                    }
                  }}
                />
              </div>

              <div className="d-flex flex-column gap-3 w-30">
                <span className="form-content-text">{"Frequency Bands * :"}</span>
                <div className="flex gap-3 align-items-center justify-content-start">
                  {["S-band", "X-band", "UHF"].map((band: any) => (
                    <button id={`${band}`} className={` w-100px align-items-center d-flex justify-content-center btn ${groundStationPosition.freqbands.includes(band) ? "btn-primary" : "btn-outline-primary"}`} key={band}
                      onClick={(e) => {
                        e.preventDefault()
                        scroll_to_bottom()
                        if (groundStationPosition.freqbands.includes(band)) {
                          setGroundStationPosition({
                            ...groundStationPosition,
                            freqbands: groundStationPosition.freqbands.filter((item) => item !== band)
                          })
                          if (bandsData[band]) {
                            delete bandsData[band];
                          }
                        } else {
                          setGroundStationPosition({
                            ...groundStationPosition,
                            freqbands: [...groundStationPosition.freqbands, band]
                          })
                        }
                      }}>
                      {band}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* ----------------Frequency Related Inputs--------------- */}

            {groundStationPosition.freqbands.length > 0 && groundStationPosition.freqbands.map(band => (
              <div className={`d-flex flex-wrap gap-3 w-100 ${band === 'X-band' ? 'justify-content-start' : 'justify-content-between'}`} key={band}>
                <div className="d-flex gap-2 w-100 align-items-center justify-content-between pt-5">
                  <span className="border-top w-45"></span>
                  <span className="fs-18 color-atmos-secondary">{band}</span>
                  <span className="border-top w-45"></span>
                </div>
                <div className="d-flex flex-column gap-3 w-30">
                  {band !== 'UHF' && <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{band} GT (dB/K) </span>
                    <Form.Control
                      className="w-100"
                      disabled={loading}
                      min={0}
                      max={1000}
                      type="number"
                      step='any'
                      placeholder={"Enter " + band + ' GT'}
                      value={bandsData?.[band]?.[band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && event.target.value.toString().length <= 7) {
                          if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1000) {
                            const data = {
                              ...bandsData,
                              [band]: {
                                ...bandsData?.[band],
                                [band === 'UHF' ? 'U_GT' : `${band.split('-')[0]}_GT`]: event.target.value
                              }
                            }
                            setBandsData(data)
                          }
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {`${band} GT range must be between 0 and 1000`}
                    </Form.Control.Feedback>
                  </div>}
                  {band !== 'X-band' && <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{band} {band === 'UHF' ? 'U_EIRP' : band.split('-')[0] + '_EIRP'} (dBW)  :</span>
                    <Form.Control
                      className="w-100"
                      type="number"
                      step='any'
                      disabled={loading}
                      placeholder={`Enter  ${band}  ${band === 'UHF' ? 'U_EIRP' : band.split('-')[0] + '_EIRP'}`}
                      value={bandsData?.[band]?.[band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && event.target.value.toString().length <= 7) {
                          const data = {
                            ...bandsData,
                            [band]: {
                              ...bandsData?.[band],
                              [band === 'UHF' ? 'U_EIRP' : `${band.split('-')[0]}_EIRP`]: event.target.value
                            }
                          }
                          setBandsData(data)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter {band} {band === 'UHF' ? 'U_EIRP' : band.split('-')[0] + '_EIRP'}
                    </Form.Control.Feedback>
                  </div>}
                </div>
                <div className={`d-flex flex-column gap-3 w-30 ${band === 'X-band' && 'ms-7'}`}>
                  <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{"Min Rx Frequency (MHz) * :"}</span>
                    <Form.Control
                      className="w-100"
                      min={band === 'UHF' ? 300 : band === 'S-band' ? 2000 : 7800}
                      max={(bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] &&
                        (band === 'UHF' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 3000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 300) ||
                        (band === 'S-band' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 4000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 2000) ||
                        (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 8500 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 7800)) ?
                        +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']
                        : band === 'UHF' ? 3000 : band === 'S-band' ? 4000 : 8500}
                      required
                      type="number"
                      step='any'
                      disabled={loading}
                      placeholder="Enter min rx frequency"
                      value={bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && Number(event.target.value) >= 0 && event.target.value.length <= 7) {
                          const data = {
                            ...bandsData,
                            [band]: {
                              ...bandsData?.[band],
                              rxFreq: {
                                ...bandsData?.[band]?.['rxFreq'],
                                [`${band.split('-')?.[0]}freq`]: {
                                  ...bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`],
                                  min: event.target.value
                                }
                              }
                            }
                          }
                          if (data[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '' && data[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '') {
                            delete data[band]['rxFreq'][`${band.split('-')[0]}freq`]
                          }
                          setBandsData(data)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '' ? 'Please enter min rx frequency' :
                        (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] > +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']) ?
                          'Min rx frequency must be less than max rx frequency' : `Min rx frequency range must be from ${band === 'UHF' ? 300 : band === 'S-band' ? 2000 : 7800} to 
                      ${(bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] &&
                            (band === 'UHF' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 3000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 300) ||
                            (band === 'S-band' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 4000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 2000) ||
                            (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 8500 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 7800)) ?
                            +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max']
                            : band === 'UHF' ? 3000 : band === 'S-band' ? 4000 : 8500}`}
                    </Form.Control.Feedback>
                  </div>
                  <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{"Max Rx Frequency (MHz) *:"}</span>
                    <Form.Control
                      className="w-100"
                      min={(bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] &&
                        (band === 'UHF' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 300 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 3000) ||
                        (band === 'S-band' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 2000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 4000) ||
                        (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 7800 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 8500)) ?
                        +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min']
                        : band === 'UHF' ? 300 : band === 'S-band' ? 2000 : 7800}
                      max={band === 'UHF' ? 3000 : band === 'S-band' ? 4000 : 8500}
                      required
                      type="number"
                      step='any'
                      disabled={loading}
                      placeholder="Enter max rx frequency"
                      value={bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && Number(event.target.value) >= 0 && event.target.value.length <= 7) {
                          const data = {
                            ...bandsData,
                            [band]: {
                              ...bandsData?.[band],
                              rxFreq: {
                                ...bandsData?.[band]?.['rxFreq'],
                                [`${band.split('-')[0]}freq`]: {
                                  ...bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`],
                                  max: event.target.value
                                }
                              }
                            }
                          }
                          if (data[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '' && data[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '') {
                            delete data[band]['rxFreq'][`${band.split('-')[0]}freq`]
                          }
                          setBandsData(data)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '' ? 'Please enter max rx frequency' :
                        (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['max'] < +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min']) ?
                          'Max rx frequency must be greater than min rx frequency' : `Max rx frequency range must be from ${(bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] &&
                            (band === 'UHF' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 300 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 3000) ||
                            (band === 'S-band' && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 2000 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 4000) ||
                            (+bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 7800 && +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 8500)) ?
                            +bandsData?.[band]?.['rxFreq']?.[`${band.split('-')[0]}freq`]?.['min']
                            : band === 'UHF' ? 300 : band === 'S-band' ? 2000 : 7800} to ${band === 'UHF' ? 3000 : band === 'S-band' ? 4000 : 8500}`}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {band !== 'X-band' && <div className="d-flex flex-column gap-3 w-30">
                  <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{"Min Tx Frequency (MHz) :"}</span>
                    <Form.Control
                      className="w-100"
                      min={band === 'UHF' ? 300 : 2000}
                      max={(bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] &&
                        (band === 'UHF' && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 3000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 300) ||
                        (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 4000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 2000)) ?
                        +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] : band === 'UHF' ? 3000 : 4000}
                      required={bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] ? true : false}
                      type="number"
                      step='any'
                      disabled={loading}
                      placeholder="Enter min tx frequency"
                      value={bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && Number(event.target.value) >= 0 && event.target.value.length <= 7) {
                          const data = {
                            ...bandsData,
                            [band]: {
                              ...bandsData?.[band],
                              txFreq: {
                                ...bandsData?.[band]?.['txFreq'],
                                [`${band.split('-')[0]}freq`]: {
                                  ...bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`],
                                  min: event.target.value
                                }
                              }
                            }
                          }
                          if (bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '' && bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '') {
                            delete data[band]['txFreq'][`${band.split('-')[0]}freq`]
                          }
                          setBandsData(data)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '' ? 'Please enter min tx frequency' :
                        (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] > +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max']) ?
                          'Min tx frequency must be less than max tx frequency' : `Min tx frequency range must be from ${band === 'UHF' ? 300 : 2000} to 
                      ${(bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] &&
                            (band === 'UHF' && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 3000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 300) ||
                            (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] <= 4000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] >= 2000)) ?
                            +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] : band === 'UHF' ? 3000 : 4000}`}
                    </Form.Control.Feedback>
                  </div>
                  <div className="d-flex flex-column gap-2 w-100">
                    <span className="form-content-text">{"Max Tx Frequency (MHz) :"}</span>
                    <Form.Control
                      className="w-100"
                      required={bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] ? true : false}
                      min={(bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] &&
                        (band === 'UHF' && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 300 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 3000) ||
                        (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 2000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 4000)) ?
                        +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] : band === 'UHF' ? 300 : 2000}
                      max={band === 'UHF' ? 3000 : 4000}
                      type="number"
                      step='any'
                      disabled={loading}
                      placeholder="Enter max tx frequency"
                      value={bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] || ''}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 69) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event: any) => {
                        event.preventDefault();
                        if (!isNaN(event.target.value) && Number(event.target.value) >= 0 && event.target.value.length <= 7) {
                          const data = {
                            ...bandsData,
                            [band]: {
                              ...bandsData?.[band],
                              txFreq: {
                                ...bandsData?.[band]?.['txFreq'],
                                [`${band.split('-')[0]}freq`]: {
                                  ...bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`],
                                  max: event.target.value
                                }
                              }
                            }
                          }
                          if (bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '' && bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] === '') {
                            delete data[band]['txFreq'][`${band.split('-')[0]}freq`]
                          }
                          setBandsData(data)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] === '' ? 'Please enter max tx frequency' :
                        (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['max'] < +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min']) ?
                          'Max tx frequency must be greater than min tx frequency' : `Max tx frequency range must be from ${(bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] &&
                            (band === 'UHF' && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 300 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 3000) ||
                            (+bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] >= 2000 && +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] <= 4000)) ?
                            +bandsData?.[band]?.['txFreq']?.[`${band.split('-')[0]}freq`]?.['min'] : band === 'UHF' ? 300 : 2000} to ${band === 'UHF' ? 3000 : 4000}`}
                    </Form.Control.Feedback>
                  </div>
                </div>}
              </div>
            ))}

          </Form>
        </div>

        <div className="d-flex justify-content-start flex-column gap-3">
          <div className="modal_devider" />
          <div className="d-flex justify-content-between align-items-center px-5 pb-5">
            <button
              disabled={loading}
              type="button"
              className="btn btn-outline-danger pt-1 pb-1 pe-7 ps-7 fs-14"
              onClick={close}
            >
              Cancel
            </button>
            <button
              disabled={loading}
              type="button"
              className="btn btn-primary pt-1 pb-1 pe-7 ps-7 fs-14 "
              onClick={() => {
                create_new_ground_station()
              }}
            >
              {data && data?.['groundStationId'] ? 'Update Ground Station' : `Create Ground Station`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroundStationForm;
