// @ts-nocheck
export const Steps = [
    
    {
        svg: <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.66709 12.6667C2.66709 13.084 3.28176 13.8107 4.70709 14.524C6.55243 15.4467 9.16976 16 12.0004 16C14.8311 16 17.4484 15.4467 19.2938 14.524C20.7191 13.8107 21.3338 13.084 21.3338 12.6667V9.772C19.1338 11.132 15.7698 12 12.0004 12C8.23109 12 4.86709 11.1307 2.66709 9.772V12.6667ZM21.3338 16.4387C19.1338 17.7987 15.7698 18.6667 12.0004 18.6667C8.23109 18.6667 4.86709 17.7973 2.66709 16.4387V19.3333C2.66709 19.7507 3.28176 20.4773 4.70709 21.1907C6.55243 22.1133 9.16976 22.6667 12.0004 22.6667C14.8311 22.6667 17.4484 22.1133 19.2938 21.1907C20.7191 20.4773 21.3338 19.7507 21.3338 19.3333V16.4387ZM0.000427246 19.3333L0.000427246 6C0.000427246 2.68667 5.37376 0 12.0004 0C18.6271 0 24.0004 2.68667 24.0004 6V19.3333C24.0004 22.6467 18.6271 25.3333 12.0004 25.3333C5.37376 25.3333 0.000427246 22.6467 0.000427246 19.3333ZM12.0004 9.33333C14.8311 9.33333 17.4484 8.78 19.2938 7.85733C20.7191 7.144 21.3338 6.41733 21.3338 6C21.3338 5.58267 20.7191 4.856 19.2938 4.14267C17.4484 3.22 14.8311 2.66667 12.0004 2.66667C9.16976 2.66667 6.55243 3.22 4.70709 4.14267C3.28176 4.856 2.66709 5.58267 2.66709 6C2.66709 6.41733 3.28176 7.144 4.70709 7.85733C6.55243 8.78 9.16976 9.33333 12.0004 9.33333Z"
                fill="#636363"/>
        </svg>,
        title: 'Payloads',
    },
    {
        svg: <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6667 14.0001C19.0204 14.0001 19.3595 14.1406 19.6095 14.3906C19.8596 14.6407 20.0001 14.9798 20.0001 15.3334V27.3334H17.3334V16.6668H6.66674V27.3334H4.00007V15.3334C4.00007 14.9798 4.14055 14.6407 4.3906 14.3906C4.64065 14.1406 4.97978 14.0001 5.33341 14.0001H18.6667ZM12.0001 19.3334V22.0001H9.33341V19.3334H12.0001ZM12.0001 6.00008C13.486 5.99964 14.9427 6.41304 16.2068 7.19397C17.471 7.97489 18.4927 9.09246 19.1574 10.4214L16.7707 11.6148C16.328 10.7283 15.6471 9.98271 14.8043 9.46159C13.9615 8.94048 12.9903 8.66444 11.9994 8.66444C11.0085 8.66444 10.0373 8.94048 9.1945 9.46159C8.35174 9.98271 7.67081 10.7283 7.22807 11.6148L4.84274 10.4214C5.50744 9.09246 6.52913 7.97489 7.7933 7.19397C9.05747 6.41304 10.5142 5.99964 12.0001 6.00008ZM12.0001 0.666749C14.4765 0.666214 16.9041 1.35537 19.0109 2.65698C21.1177 3.95858 22.8204 5.8212 23.9281 8.03608L21.5427 9.22942C20.6566 7.45737 19.2945 5.96714 17.609 4.92575C15.9235 3.88437 13.9813 3.33299 12.0001 3.33342C10.0188 3.33299 8.07662 3.88437 6.39113 4.92575C4.70565 5.96714 3.34352 7.45737 2.45741 9.22942L0.0707397 8.03608C1.17855 5.821 2.88141 3.95825 4.98845 2.65663C7.09549 1.355 9.52342 0.665966 12.0001 0.666749Z"
                fill="#636363"/>
        </svg>,
        title: 'Edge',
    },
    {
        svg: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0004 2V6H2.00043V2H22.0004ZM22.0004 0H2.00043C1.46999 0 0.961286 0.210714 0.586214 0.585786C0.211141 0.960859 0.000427246 1.46957 0.000427246 2V6C0.000427246 6.53043 0.211141 7.03914 0.586214 7.41421C0.961286 7.78929 1.46999 8 2.00043 8H22.0004C22.5309 8 23.0396 7.78929 23.4146 7.41421C23.7897 7.03914 24.0004 6.53043 24.0004 6V2C24.0004 1.46957 23.7897 0.960859 23.4146 0.585786C23.0396 0.210714 22.5309 0 22.0004 0ZM6.00043 12V22H2.00043V12H6.00043ZM6.00043 10H2.00043C1.46999 10 0.961286 10.2107 0.586214 10.5858C0.211141 10.9609 0.000427246 11.4696 0.000427246 12V22C0.000427246 22.5304 0.211141 23.0391 0.586214 23.4142C0.961286 23.7893 1.46999 24 2.00043 24H6.00043C6.53086 24 7.03957 23.7893 7.41464 23.4142C7.78971 23.0391 8.00043 22.5304 8.00043 22V12C8.00043 11.4696 7.78971 10.9609 7.41464 10.5858C7.03957 10.2107 6.53086 10 6.00043 10ZM22.0004 12V22H12.0004V12H22.0004ZM22.0004 10H12.0004C11.47 10 10.9613 10.2107 10.5862 10.5858C10.2111 10.9609 10.0004 11.4696 10.0004 12V22C10.0004 22.5304 10.2111 23.0391 10.5862 23.4142C10.9613 23.7893 11.47 24 12.0004 24H22.0004C22.5309 24 23.0396 23.7893 23.4146 23.4142C23.7897 23.0391 24.0004 22.5304 24.0004 22V12C24.0004 11.4696 23.7897 10.9609 23.4146 10.5858C23.0396 10.2107 22.5309 10 22.0004 10Z"
                fill="#636363"/>
        </svg>,
        title: 'Bus Design',
    },
    {
        svg: <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.8261 0H11.0096C10.3601 0 9.83529 0.524771 9.83529 1.17431V9.83486H1.17474C0.525198 9.83486 0.000427246 10.3596 0.000427246 11.0092V20.9908C0.000427246 21.6404 0.525198 22.1651 1.17474 22.1651H20.9913C21.6408 22.1651 22.1656 21.6404 22.1656 20.9908V12.3303H30.8261C31.4757 12.3303 32.0004 11.8055 32.0004 11.156V1.17431C32.0004 0.524771 31.4757 0 30.8261 0ZM12.3307 2.49541H19.6702V9.83486H12.3307V2.49541ZM9.83529 19.6697H2.49584V12.3303H9.83529V19.6697ZM19.6702 19.6697H12.3307V12.3303H19.6702V19.6697ZM29.505 9.83486H22.1656V2.49541H29.505V9.83486Z"
                fill="#636363"/>
        </svg>,
        title: 'Review',
    },
];
export const MAX_STEPS = Steps.length;
export const MINI_STEPS = 1;
export const PayloadSteps = [
    { title: 'Category', stepIndex: 1 },
    { title: 'Device', stepIndex: 2 },
];

export class ConfigredImaging {
  gsd: any = ''
  swath: any = '';
}

export class ConfiguredComm {
  'Frequency Bands': string[] = [];
  InterSatLinks: any = 'No';
  'Data Rate': any = '';
  'Number of Beams': any = '';
}

export class ConfiguredReq {
  numUnits: any = '0';
  selected_interfaces: any[] = [];
  downlinkDatasize : any = '1';
  time_per_orbit: any = '5';
  AltMeasureAccuracy: any = '1';
}


export const MAX_PAYLOADS_STEPS = PayloadSteps.length;
export const PayloadsList =  [
    {
        src: require('../../../assets/images/icons/icon-imaging.png'),
        id: 1,
        type: 'Earth Observation',
        text: 'Select this category for any Earth Observations related payloads, such as remote sensing, imaging, surveillance, etc.'
    },
    {
        src: require('../../../assets/images/icons/icon-communications.png'),
        id: 2,
        type: 'Communication',
        text: 'Select this category for any Communications related payloads, such as beacons, IoT, telecommunications, etc.'
    },
    {
        src: require('../../../assets/images/icons/icon-other.png'),
        id: 3,
        type: 'Other',
        text: 'Select this category for any other payloads not covered elsewhere.'
    },
];

export const InclinationAngle = [
    {
        value: 0,
        label: '0',
      },
      {
        value: 180,
        label: '180',
      },
];
export const AltitudeRange = [
    {
        value: 300,
        label: '300',
      },
      {
        value: 750,
        label: '750',
      },
];
