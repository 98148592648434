import { useContext } from 'react';
import { ContactsContext } from '../providers/ContactsProvider';

const useContacts = () => {
    const context = useContext(ContactsContext);
    if (context === undefined) {
        throw new Error('useContacts must be used within a ViewerProvider');
    }

    return context;
};

export default useContacts;