// @ts-nocheck
import { DateRangePicker, CustomProvider} from 'rsuite';

const TimeRangePicker = ({startDate, Ranges, onChangeTimeData }) => {
  const date=new Date().toLocaleString()?.split("/");
  
  const endDate= date[2].slice(0,4)+"-"+date[1]+"-"+date[0];

  return (
    <div className='w-100 pe-10'>
        <CustomProvider theme='dark'>
          <DateRangePicker
          ranges={Ranges}
          format="yyyy-MM-dd HH:mm:ss"
          onChange={onChangeTimeData}
          cleanable={true}
          placement="autoVerticalEnd"
          placeholder="Filter Date with Time Range"
          shouldDisableDate={DateRangePicker.allowedRange(startDate.split('T')[0], endDate)}
          size="sm"
          />
        </CustomProvider>
    </div>
  );
};

export default TimeRangePicker;