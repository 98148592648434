import { useEffect, useState } from "react"
import useContacts from "./useContacts"
import useViewer from "./useViewer"
import * as Cesium from "cesium";

type command = {
    "subsystem": string,
    "name": string,
    "id": string,
    "arg1": string,
    "arg2": string,
    "epoch": number,
    "duration": number,
    "status": string
}
export type ScheduleData = {
    "status": string,
    "location": string,
    "geoTrigger": string,
    "taskId": string,
    "taskName": string,
    "startTime": number,
    "endTime": number,
    "properties": {
        "FreqBand": string,
        "GroundStationName": string,
        "PayloadName": string,
        "SequenceName": string
    },
    "type": string,
    "commandsList": command[]
}

type SimulationStatus = {
    'simulation_start_time': number,
    'simulation_current_time': number,
    'simulation_status': number,
    'simulation_time_rel': number,
    'simulation_duration': number,
}

const useOnBoardSchedule = () => {
    const { viewer, current_simulation_time } = useViewer()
    const [simulationStatus, setSimulationStatus] = useState<SimulationStatus>({} as SimulationStatus)
    const { scheduleData, scheduleDetails, timelineScheduleData, selectedSchedule, setSelectedSchedule } = useContacts()
    const [loading, setLoading] = useState(false)

    const get_ongoingg_task = (current_time) => {
        const ongoing_task = scheduleData.filter((item) => {
            if (item.startTime <= current_time && item.endTime >= current_time) {
                return item
            }
        })
        return ongoing_task
    }
    const get_previous_task = (current_time) => {
        const prevoius_task = scheduleData.filter((item) => {
            if (item.endTime < current_time) {
                return item
            }
        })
        return prevoius_task?.length > 0 ? [prevoius_task[prevoius_task?.length - 1]] : []
    }
    const get_upcoming_task = (current_time) => {
        const upcoming_task = scheduleData.filter((item) => {
            if (item.startTime > current_time) {
                return item
            }
        })
        return upcoming_task?.length > 0 ? [upcoming_task[0]] : []
    }

    const select_schedule_data = (data: any) => {
        const ongoing_task = get_ongoingg_task(current_simulation_time / 1000)
        setSelectedSchedule({
            default_schedule: (ongoing_task.length > 0 && ongoing_task[0].startTime === data.startTime) ? true : false,
            schedule_data: data,
        })
    }

    const get_current_onboard_details = (current_time) => {
        const ongoing_task = get_ongoingg_task(current_time)
        const prevoius_task = get_previous_task(current_time)
        const upcoming_task = get_upcoming_task(current_time)
        if (selectedSchedule.default_schedule) {
            setSelectedSchedule({
                ...selectedSchedule,
                schedule_data: ongoing_task?.length > 0 ? ongoing_task[0] : prevoius_task?.length > 0 ? prevoius_task[0] : upcoming_task[0]
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        if (viewer && scheduleData?.length > 0) {
            let Interval = setInterval(() => {
                const clock = viewer?.clock;
                if (clock) {
                    const cesium_time = clock.currentTime;
                    if (cesium_time) {
                        const current_time = Cesium.JulianDate.toDate(cesium_time).getTime() / 1000
                        if (current_time) {
                            get_current_onboard_details(current_time)
                        }
                    }
                }
            }, 1000);
            return () => clearInterval(Interval);
        }
    }, [viewer, scheduleData, selectedSchedule]);

    return {
        loading,
        scheduleData,
        scheduleDetails,
        simulationStatus,
        select_schedule_data,
        timelineScheduleData,
    }
}

export default useOnBoardSchedule