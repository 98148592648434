import { FC, useEffect, useRef, useState } from "react";
import './ContactTimeline.scss'
import ContactTimeFrame from "./ContactTimeFrame";
import GroundContact from "../contact/GroundContact";
import CustomLoader from "../../../../custom_components/CustomLoader";
import useTimeline from "../../../../hooks/useTimeline";

interface ContactTimelineProps {
    simulation_start_time: number,
    simulation_end_time: number,
}


const GroundContactDetails: FC<ContactTimelineProps> = ({
    simulation_start_time,
    simulation_end_time,
}) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const { loading, set_window_width } = useTimeline()

    useEffect(() => {
        const container = containerRef.current;
        if (container instanceof HTMLElement) {
            const width = container.offsetWidth;
            set_window_width(width - 32);
        }
    }, [containerRef, window.innerWidth])

    return (
        <div className='contact__container hud__ground__contact__container' ref={containerRef}>
            {loading &&
                <div className='container__loading'>
                    <CustomLoader loading={loading} />
                </div>
            }
            <ContactTimeFrame
                simulation_start_time={simulation_start_time}
                simulation_end_time={simulation_end_time}
            />
            <GroundContact />
        </div>

    )
}

export default GroundContactDetails