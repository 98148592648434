
import Select from "react-select";
import React from 'react';

interface ModalDropdownProps {
  options: any[];
  multi_select?: boolean;
  handleSelectedOption: (option) => void;
  setOpenTaskPlanMenu: (data) => void;
  disabled?: boolean;
  placeholder?: string;
  selectedOption: any;
}

const ModalDropdown: React.FC<ModalDropdownProps> = ({ options, multi_select, handleSelectedOption, disabled, placeholder, selectedOption, setOpenTaskPlanMenu }) => {


  return (
    <Select
      onMenuClose={()=>{
        setOpenTaskPlanMenu(false)
      }}
      onMenuOpen={()=>{
        setOpenTaskPlanMenu(true)
      }}
      className={`modalSelectBtn  ${disabled ? "opacity-50" : ""}`}
      classNamePrefix="Select"
      placeholder={`Select ${placeholder}`}
      value={selectedOption}
      options={options}
      onChange={(e)=>{handleSelectedOption(e)}}
      isMulti={multi_select}
      isDisabled={disabled}
      menuPlacement="bottom"
    />
  );
};


export default ModalDropdown;
