// @ts-nocheck
import { useEffect, useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../Layouts/Loader/Loader';
import PageHeader from '../../../../Layouts/PageHeader/PageHeader';
import CatalogueService from '../../../../service/CatalogueService';
import MissionService from '../../../../service/MissionService';
import {
    usageStore,
} from "../../../../store/UsageSlice";
import CloseButton from '../../../CommonComponents/CloseButton';
import { calculateEdgeUsage, calculatePayloadUsage, calculateUsagePercentage, calculate_Total_AOP_Usage, navigateTo, truncateNumber } from '../../../CommonComponents/CommonFunctions';
import { Steps } from '../Stepper.constants';

const ReviewBuild = () => {
    const [getTemplate, setTemplate] = useState<any>({});
    const [getPayloads, setPayloads] = useState<any[]>([]);
    const [getEdges, setEdges] = useState<any[]>([]);
    const [satellieteDeta, setSatellieteData] = useState<any>({});
    const [hover, setHover] = useState(true)
    const [loading, setLoading] = useState(true)

    const [espaTempData, setEspaTempData] = useState({
        type: '',
        value: '',
    })

    const payloadSizePercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.size
    );
    const payloadWtPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.weight
    );
    const payloadPowerPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.power
    );
    const edgePowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgePowerUsage
    );
    const edgeSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeSizeUsage
    );
    const edgeWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeWeightUsage
    );

    const edgeSizePercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.size);
    const edgeWtPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.weight);
    const edgePowerPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.power);


    const payloadSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadSizeUsage
    );
    const payloadWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadWeightUsage
    );
    const payloadPowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPowerUsage
    );

    const busSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busSizeUsage
    );
    const busWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busWeightUsage
    );
    const busPowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busPowerUsage
    );
    const busSizePercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.size
    );
    const busWtPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.weight
    );
    const busPowerPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.power
    );

    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, mission_id, sat_name, sat_id } = params


    const get_satellite = () => {
        MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
            if (sat?.data) {
                setSatellieteData(sat?.data)
                CatalogueService.getTemplate(sat?.data?.['versions']?.[0]?.['assembly']?.['Template'])?.then((template) => {
                    if (template.data) {
                        let all_payloas = sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] : []
                        let all_edges = sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] : []
                        calculate_Total_AOP_Usage(all_payloas, all_edges, dispatch)
                        calculatePayloadUsage(all_payloas, dispatch)
                        calculateEdgeUsage(all_edges, dispatch)
                        setPayloads(all_payloas);
                        setEdges(all_edges);
                        setTemplate(template.data);
                        if (template?.data["template-attribute"]?.["type"] === 'espa') {
                            setEspaTempData({
                                type: template?.data["template-attribute"]?.["type"],
                                value: template?.data["template-attribute"]?.["sizeInXYZ"],
                            })
                        } else {
                            setEspaTempData({
                                type: '',
                                value: '',
                            })
                        }
                    }
                    setLoading(false)
                }).catch((err) => {
                    console.error(err);
                    setLoading(false)
                })
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }

    useEffect(() => {
        if (sat_id) {
            get_satellite()
        }
    }, [sat_id])


    useEffect(() => {
        if (getTemplate && Object.keys(getTemplate).length > 0) {
            const allParams = {
                temp: getTemplate,
                templateDataAvailable: true,
                dispatch: dispatch,
                powerUsage: payloadPowerUsage,
                edgePowerUsage: edgePowerUsage,
                edgeSizeUsage: edgeSizeUsage,
                edgeWeightUsage: edgeWeightUsage,
                sizeUsage: payloadSizeUsage,
                weightUsage: payloadWeightUsage
            }
            calculateUsagePercentage(allParams);
        }

    }, [getTemplate, payloadPowerUsage, edgePowerUsage])


    const update_satellite = () => {
        setLoading(true)
        let sat = {
            ...satellieteDeta,
            "swap-info": {
                totalUsedSize: truncateNumber((payloadSizeUsage + edgeSizeUsage + busSizeUsage), 1),
                totalUsedMass: truncateNumber((payloadWeightUsage + edgeWeightUsage + busWeightUsage), 1),
                totalUsedPower: truncateNumber((
                    Number(payloadPowerUsage) +
                    Number(edgePowerUsage) +
                    Number(busPowerUsage)
                ), 1),
                totalUsedSizePercent: truncateNumber((payloadSizePercent + edgeSizePercent + busSizePercent), 1),
                totalUsedMassPercent: truncateNumber((payloadWtPercent + edgeWtPercent + busWtPercent), 1),
                totalUsedPowerPercent: truncateNumber((payloadPowerPercent + edgePowerPercent + busPowerPercent), 1),
                totalPayloadSize: truncateNumber(payloadSizeUsage, 1),
                totalPayloadMass: truncateNumber(payloadWeightUsage, 1),
                totalPayloadPower: truncateNumber(payloadPowerUsage, 1),
            },
        };

        MissionService.update_satellites(sat_id, sat)?.then((res) => {
            if (res.data) {
                MissionService.putSatelliteConfig(sat_id).then((satConfig) => {
                    if (satConfig.data) {
                        console.log("Default Satellite configuration created", satConfig.data)
                    }
                    setLoading(false)
                }).catch(err => {
                    toast.error("Something went wrong while updating satellite.", { toastId: 'o' });
                    setLoading(false)
                    sat['draftMode'] = 'Yes'
                    MissionService.update_satellites(sat_id, sat)?.then((res) => {

                    }).catch((err) => {
                        toast.error("Something went wrong while update satellite", { toastId: 'o' });
                    });
                });
                navigateTo(`${mission_name}/${mission_id}/view_satellite/${sat_name}/${sat_id}/configure`, navigate)
            }
            setLoading(false)
        })
            .catch((err) => {
                toast.error("Something went wrong while adding payload ", { toastId: 'o' });
                setLoading(false)
            });



    }


    const navigate_to_step = (step) => {
        if (step === 'Payloads') {
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
        }
        else if (step === 'Edge') {
            if (satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'] && satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge']?.length > 0) {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
            } else {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
            }
        }
        else if (step === 'Bus Design') {
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`, navigate)
        }
    }


    return <div className='h-100 w-100'>
        {loading && <Loader />}
        <PageHeader sat_name={sat_name?.length > 15 ? sat_name.substring(0, 15) + "..." : sat_name} titles="Review Satellite" description="Review your configuration and save" active="New Mission" items={['Apps']}
            isButton={false}
            showSwap={Object.keys(getTemplate)?.length > 0 ? true : false}
            showPercent={true}
            type={espaTempData?.['type']}
            value={espaTempData?.['value']}
        />
        <div className='d-flex flex-wrap pop-scroll-xl align-content-start mt-3 py-5 ps-7'>
            <div className="d-flex w-100 flex-wrap gap-4">
                {getTemplate?.['uniqueId'] &&
                    <div className="common-card-box-preview-bus m-0" style={{ width: '440px' }}>
                        <div className="common-card-header d-flex justify-content-between align-items-start mb-1 pb-6">
                            <div><h4 className="mb-0" title={getTemplate?.['name']}>{getTemplate['name']}</h4></div>
                            <div className="btn-list more-button">
                                <Dropdown drop='end'>
                                    <Dropdown.Toggle variant="transparent text-white fs-20">
                                        <i className="fe fe-more-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1"
                                            onClick={() => {
                                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/edit_template/${getTemplate?.['name']}/${getTemplate?.['uniqueId']}`, navigate)
                                            }}>
                                            Edit
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item eventKey="2">Delete</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="common-card-content">
                            <div className="d-flex flex-wrap">
                                <div className="stats-alpha">
                                    <strong>{getTemplate?.['template-attribute']?.['bus-type']}</strong>
                                    <span>Bus Type</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{getTemplate?.['template-attribute']?.['maxWeightInKg'] + ' kg'}</strong>
                                    <span>Mass</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{getTemplate?.['template-attribute']?.['battery-capacity'] + ' w'}</strong>
                                    <span>Power</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{(getTemplate?.['template-attribute']?.['ADCS'] === 'ADCS') ? 'Discrete' : 'Integrated'}</strong>
                                    <span>ADCS</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{getTemplate?.['template-attribute']?.['NumSolarPanel']}</strong>
                                    <span>Solar Panels</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{getTemplate?.['template-attribute']?.['propulsion']}</strong>
                                    <span>Propulsion</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {getPayloads?.length > 0 && getPayloads?.map((data, i) => {
                    let payloadType = data?.['atmos-data']?.['component_specific_attributes']?.['payloadType'];
                    let weight = data?.['atmos-data']?.['weight_in_gms'];
                    return <div className="common-card-box-preview m-0" style={{ width: '440px' }} key={i}>
                        <div className="common-card-header d-flex justify-content-between align-items-center">
                            <div className="w-100 d-flex gap-2 align-items-center">
                                <img
                                    src={data['atmos-data']?.['src']}
                                    className="ms-2"
                                    width="30px"
                                    height="30px"
                                    alt={'icom'}
                                />
                                <div>
                                    <h4 className="mb-0">
                                        {data?.["user_data"]?.["payload_name"]}
                                    </h4>
                                    <div className="sat-sub-info">
                                        <span>
                                            {data?.['atmos-data']?.['component_specific_attributes']?.['payloadType']}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-list more-button">
                                <Dropdown drop='end'>
                                    <Dropdown.Toggle variant="transparent text-white fs-20">
                                        <i className="fe fe-more-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1"
                                            onClick={() => {
                                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/edit_payload/${data['user_data']['payload_name']}/${data['user_data']['id']}`, navigate);
                                            }}>
                                            Edit
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="common-card-content">
                            <div className="d-flex flex-wrap">
                                <div className="stats-alpha">
                                    <strong>{data?.["atmos-data"]?.['product-cookie']?.['Size'] + ' U'}</strong>
                                    <span>Size</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{(weight > 999) ? ((weight / 1000) + ' kg') : (weight + ' gm')}</strong>
                                    <span>Mass</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.["user_data"]?.['downlinkDatasize']} MB</strong>
                                    <span>Data Per Pass</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.["user_data"]?.['time_per_orbit']} min</strong>
                                    <span>Time Per Orbit</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.["user_data"]?.['aop'] + ' W'}</strong>
                                    <div className="d-flex align-items-center">
                                        <span>OAP</span>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Body>
                                                        <span className='color-text text-center d-flex'>Orbit Average Power</span>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <sup>
                                                <i className="fe fe-info ms-1 fs-12 text-info"></i>
                                            </sup>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data["user_data"]?.['AltMeasureAccuracy']}<sup>o</sup></strong>
                                    <span>Attitude Accuracy</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data["user_data"]?.['dutyCycle']} %</strong>
                                    <span>Pass Duty Cycle</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data['atmos-data']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                    <span>Peak Power</span>
                                </div>

                                {data?.['atmos-data']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'] && data?.['atmos-data']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length > 0 &&
                                    <div className="d-flex flex-column mt-3">
                                        <div className="d-flex gap-2">
                                            {data?.['atmos-data']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].map((val, i) => {
                                                return (
                                                    <div key={i} className="d-flex gap-2">
                                                        <strong>{val}</strong>
                                                        <strong>{`${data?.['atmos-data']?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length !== i + 1 ? '|' : ''}`}</strong>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <span>Frequency Bands</span>
                                    </div>}
                            </div>
                        </div>
                        <div className="common-card-footer">
                            <span className="footer-title">Interface</span>
                            <div className="d-flex w-100 flex-wrap">
                                {data?.['atmos-data']?.['component_specific_attributes']?.['interfaces'].map((item, i) => {
                                    if (item !== 'GPIO') {
                                        return (
                                            <div className={(data?.['atmos-data']?.['component_specific_attributes']?.['interfaces'].length > 0 && data?.['user_data']?.['selected_interfaces'].includes(item)) ? "badge-pill-active mb-2" : 'badge-pill-inactive mb-2'} key={i}>
                                                <span>{item}</span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={data?.['user_data']?.['selected_interfaces'].includes('GPIO') ? "badge-pill-active ms-1 d-flex mb-2" : "badge-pill-inactive ms-1 mb-2 d-flex"}>
                                                <span>GPIO</span>
                                                {data?.['user_data']?.['selected_interfaces'].includes('GPIO') && <span style={{ marginLeft: '2px' }}>{`(${data?.['user_data']?.['gpio_count']})`}</span>}
                                            </div>
                                        )
                                    }

                                })}
                            </div>
                        </div>
                    </div>
                })}

                {getEdges?.length > 0 && getEdges && getEdges.map((data, i) => {
                    return <div className="common-card-box-preview-edge m-0" key={i} style={{ width: '440px' }}>
                        <div className="common-card-header d-flex justify-content-between align-items-center mb-1 pb-6">
                            <div>
                                <h4 className="mb-0"> {data?.['edge-device']?.['atmos-data']?.['name']}</h4>
                            </div>
                            <div className="btn-list more-button">
                                <Dropdown drop='end'>
                                    <Dropdown.Toggle variant="transparent text-white fs-20">
                                        <i className="fe fe-more-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1"
                                            onClick={() => {
                                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/edit_edge/${data?.['edge-device']?.['atmos-data']?.['name']}/${data?.['edge-id']}`, navigate)
                                            }}>
                                            Edit
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="common-card-content">
                            <div className="d-flex flex-wrap mt-1">
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['CPU Cores'] + ' Cores'}</strong>
                                    <span>CPU Cores</span>
                                </div>
                                {data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['GPU'] !== 'n/a' &&
                                    <div className="stats-alpha">
                                        <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['GPU']}</strong>
                                        <span>GPU</span>
                                    </div>
                                }
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Memory']}</strong>
                                    <span>Memory</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Memory Type']}</strong>
                                    <span>Memory Type</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Storage']}</strong>
                                    <span>Storage</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Performance']}</strong>
                                    <span>Performance</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.["user_data"]?.['time_per_orbit']} min</strong>
                                    <span>Time Per Orbit</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['atmos-data']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                    <span>Peak Power</span>
                                </div>
                                <div className="stats-alpha">
                                    <strong>{data?.['edge-device']?.['user_data']?.['aop'] + ' W'}</strong>
                                    <div className="d-flex align-items-center">
                                        <span>OAP</span>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-basic">
                                                    <Popover.Body>
                                                        <span className='color-text text-center d-flex'>Orbit Average Power</span>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <sup>
                                                <i className="fe fe-info ms-1 fs-12 text-info"></i>
                                            </sup>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="common-card-content border-top">
                            <div className="d-flex">
                                <div>{data?.['edge-ssd']?.['atmos-data']?.['name']}</div>
                                {data?.['edge-ssd']?.['user_data']?.['numUnits'] && <div className="ms-2">({data?.['edge-ssd']?.['user_data']?.['numUnits']})</div>}
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="stats-alpha w-22">
                                    <strong>{data?.['edge-ssd']?.['atmos-data']?.['product-cookie']?.['Capacity']}</strong>
                                    <span>Capacity</span>
                                </div>
                                <div className="stats-alpha w-32">
                                    <strong>{data?.['edge-ssd']?.['atmos-data']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                    <span>Peak Power</span>
                                </div>
                                <div className="stats-alpha w-42 border-left ps-3">
                                    <strong>{data?.['edge-ssd']?.['user_data']?.['aop'] + ' W'}</strong>
                                    <span>Orbit Average Power (w)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>


        <div className="navigator-btn gap-3">
            <CloseButton />
            <div className="btn-group btn-grp-nav">
                <Button
                    variant={hover ? "outline-primary" : "primary"}
                    type="button"
                    className={`d-flex align-items-center btn ${hover ? "btn-outline-primary" : "btn-primary"
                        } pt-1 pb-1 pe-4 ps-3 fs-14`}
                    onClick={() => {
                        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`, navigate)
                    }}
                    onMouseEnter={() => {
                        setHover(false);
                    }}
                    onMouseLeave={() => {
                        setHover(true);
                    }}
                >
                    <i className="fe fe-chevron-left me-1 fs-16"></i> Back
                </Button>
                <Button
                    variant={hover ? "primary" : "outline-primary"}
                    type="submit"
                    className={`d-flex align-items-center btn ${hover ? "btn-primary" : "btn-outline-primary"
                        } pt-1 pb-1 pe-3 ps-4 fs-14`}
                    onClick={() => {
                        update_satellite()
                    }}
                >
                    View Your Satellite
                    <i className="fe fe-chevron-right ms-1 fs-16"></i>
                </Button>
            </div>
        </div>


        <div className="stepper-container">
            <div className="breadcrumb">
                {Steps.map((data) => {
                    return <a
                        onClick={() => { navigate_to_step(data.title) }}
                        className={data.title === 'Review' ? 'active' : ''}
                        key={data.title}>
                        <span className="breadcrumb__inner">
                            <span className="breadcrumb__title">
                                {data.svg}
                            </span>
                            <span className="breadcrumb__desc">{data.title}</span>
                        </span>
                    </a>
                })}
            </div>
        </div>
    </div>;
};
export default ReviewBuild;
